import {
  BanIcon,
  KeyIcon,
  PencilIcon,
  PlusIcon,
  SearchIcon,
} from '@heroicons/react/outline';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useApp } from '../../app.hook';
import { ROUTES_MAP } from '../../app.map.routes';
import { Usuario } from '../../app.models';
import Pagination from '../../components/layout/pagination';
import ModalConfirmation from '../../components/modalConfirmation';
import SelectCliente from '../../components/selectCliente';
import ModalPassword from './modalPassword';
import { useUsers } from './users.hook';
import { updUsuario } from './users.service';
import { formatarTelefone } from '../../utils/masks';

interface TableUsersProps {
  users: Usuario[];
  afterAction: () => void;
}

export default function TableUsers(props: TableUsersProps) {
  // const { searchOnPageUsers, setSearchOnPageUsers } = useApp();
  const {
    setFilter,
    skip,
    setSkip,
    info,
    defCliente,
    setDefCliente,
    loadUsers,
    filterText,
    setFilterText,
  } = useUsers();
  const navigate = useNavigate();
  const [modalConfirm, setModalConfirm] = useState<boolean>(false);
  const [modalPassword, setModalPassword] = useState<boolean>(false);

  const { isPerfilAdmin, isPerfilManager, currentClientAtivo } = useApp();

  const selectedUser = useRef<Usuario>({
    id: null,
    nome: 'N/D',
  } as unknown as Usuario);

  useEffect(() => {
    if (filterText === '') setFilter('');
  }, [filterText, setFilter]);

  const applyFilter = () => {
    setFilter(filterText);
  };

  const openPasswordModal = (user: Usuario) => {
    selectedUser.current = user;
    new Promise((p) => setTimeout(p, 100)).then(() => {
      setModalPassword(true);
    });
  };

  const updPassword = (user: Usuario) => {
    updUsuario(user).then(loadUsers);
  };

  return (
    <>
      <div className="bg-gray-200 my-2 p-2 flex items-center justify-between">
        <div className="flex space-x-2 items-center">
          <span className="flex justify-between items-center bg-white rounded-full text-gray-600 pl-4 max-w-xs">
            <input
              className="w-full bg-white py-2 px-4 border-0 outline-none focus:outline-none focus:shadow-none"
              type="search"
              autoFocus
              autoComplete="off"
              placeholder="Nome, email ou telefone"
              value={filterText}
              onChange={(e) => setFilterText(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  applyFilter();
                }
              }}
            />
            <button onClick={applyFilter} className="hover:text-gray-400">
              <SearchIcon className="w-10 h-10 p-2" />
            </button>
          </span>
          {isPerfilManager && (
            <span className="mx-2">{currentClientAtivo?.nome}</span>
            // <SelectCliente
            //   action={(clienteId) => {
            //     setDefCliente(clienteId.toString());
            //   }}
            //   clear={() => {
            //     setDefCliente('');
            //   }}
            //   resetSkip={() => {
            //     null;
            //   }}
            //   clienteId={defCliente}
            // />
          )}
        </div>

        {defCliente && (isPerfilAdmin || isPerfilManager) && (
          <button
            type="button"
            title="Inserir"
            className="bg-red-500 hover:opacity-70 p-1 m-1 text-default-white rounded-full"
            onClick={() => navigate('/usuarios/formulario')}
          >
            <PlusIcon className="h-6 w-6" />
          </button>
        )}
      </div>
      <table className="divide-y divide-gray-200 text-xs min-w-full">
        <thead className="bg-gray-100 sticky top-0">
          <tr className="px-2">
            <th
              scope="col"
              className="px-1 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Nome
            </th>
            <th
              scope="col"
              className="px-1 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Cliente
            </th>
            <th
              scope="col"
              className="px-1 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Email
            </th>
            <th
              scope="col"
              className="px-1 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Telefone
            </th>
            <th
              scope="col"
              className="py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Perfil
            </th>
            <th
              scope="col"
              className="px-1 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Ativo
            </th>
            <th
              scope="col"
              className="px-1 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider text-center"
            >
              Ações
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-400">
          {props.users.map((user) => (
            <tr
              key={user.id}
              className="even:bg-gray-50 py-[1] hover:bg-gray-300"
            >
              <td className="align-middle">{user.nome}</td>
              <td className="align-middle">{user?.cliente?.nome}</td>
              <td className="align-middle">{user.email}</td>
              <td className="align-middle">
                {formatarTelefone(user?.telefone ?? '')}
              </td>
              <td className="align-middle">{user.perfil}</td>
              <td className="align-middle">{user.isAtivo ? 'Sim' : 'Não'}</td>

              <td className="text-center">
                <div className="flex justify-center space-x-2">
                  <button
                    type="button"
                    title="Editar"
                    className="bg-blue-500 hover:opacity-70 p-1 m-1 text-default-white rounded-full"
                    onClick={() => navigate(ROUTES_MAP.usersFormID(user.id))}
                  >
                    <PencilIcon className="h-4 w-4" />
                  </button>
                  <button
                    type="button"
                    title="Alterar Senha"
                    className="bg-yellow-500 hover:opacity-70 p-1 m-1 text-default-white rounded-full"
                    onClick={() => openPasswordModal(user)}
                  >
                    <KeyIcon className="h-4 w-4" />
                  </button>
                  {isPerfilAdmin ||
                    (isPerfilManager && (
                      <button
                        type="button"
                        title="Excluir"
                        className="bg-red-500 hover:opacity-70 p-1 m-1 text-default-white rounded-full"
                        onClick={() => setModalConfirm(true)}
                      >
                        <BanIcon className="h-4 w-4" />
                      </button>
                    ))}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="">
        <Pagination
          curPage={skip}
          qtdeReg={info?.qtdeTotal || 0}
          perPage={10}
          handlePageClick={({ selected }) => setSkip(selected)}
        />
      </div>
      <ModalConfirmation
        isOpen={modalConfirm}
        setIsOpen={setModalConfirm}
        title={'Confirmação'}
        question={'Excluir este usuário?'}
        answer={() => null}
      />
      <ModalPassword
        isOpen={modalPassword}
        setIsOpen={setModalPassword}
        user={selectedUser.current}
        afterAltSenha={loadUsers}
      />
    </>
  );
}
