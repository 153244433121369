import React, { useCallback, useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { AppProvider } from '../app.hook';
import Layout from '../components/layout';
import { ProductProvider } from './product/product.hook';
import { CartProvider } from './cart/cart.hook';
import 'react-toastify/dist/ReactToastify.css';
import ShowProduct from './product/showProduct';
import { ROUTES_MAP } from '../app.map.routes';

import Promotions from './promotions';
import Product from './product';
import Cart from './cart';
import MyCarts from './myCarts';
import MyOrders from './myOrders';
import Transmission from './myOrders/transmission';
import UploadAll from './upload/upload';
import { UploadProvider } from './upload/upload.hook';
// import UploadProductList from './upload/uploadProductList';
import UploadProduct from './upload/uploadProduct';
import CadUsers from './users';
import Users from './users/users';
import UserForm from './users/userForm';
import MyProfile from './myProfile';
import { OrderProvider } from './myOrders/order.hook';
import { CartsProvider } from './myCarts/carts.hook';
import { UsersProvider } from './users/users.hook';
import AllPromotions from './promotions/allPromotions';
import UploadBanner from './upload/uploadBanner';
import ViewOferta from './promotions/viewOferta';
import OrderSuccess from './cart/orderSuccess';
import NotFound from './notFound';
import Painel from './painel';
import Metrics from './painel/metrics';
import PageTest from './PageTest';
import Order from './orderCounter';
import { getEmpresaSemToken } from '../app.service';

const App: React.FC = () => {
  const loadFromEmpresa = useCallback(async () => {
    const empresa = await getEmpresaSemToken();
    document.title = empresa.nomeFantasia;
  }, []);

  useEffect(() => {
    loadFromEmpresa();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="font-display">
      <AppProvider>
        <CartProvider>
          <ProductProvider>
            <OrderProvider>
              <CartsProvider>
                <UsersProvider>
                  <ToastContainer />
                  <BrowserRouter>
                    <Routes>
                      <Route element={<Layout />}>
                        {/* product */}
                        <Route path={ROUTES_MAP.product}>
                          <Route index element={<Product />} />
                          <Route
                            path={ROUTES_MAP.productId}
                            element={<ShowProduct />}
                          />
                        </Route>
                        {/* cart */}
                        <Route path={ROUTES_MAP.cart} element={<Cart />} />
                        {/* my carts */}
                        <Route
                          path={ROUTES_MAP.myCarts}
                          element={<MyCarts />}
                        />
                        <Route
                          path={ROUTES_MAP.orderSuccess}
                          element={<OrderSuccess />}
                        />
                        {/* upload */}
                        <Route path={ROUTES_MAP.upload}>
                          <Route
                            path={ROUTES_MAP.uploadProductId}
                            element={
                              <UploadProvider>
                                <UploadProduct />
                              </UploadProvider>
                            }
                          />
                          <Route
                            path={ROUTES_MAP.uploadBannerList}
                            element={
                              <UploadProvider>
                                <UploadBanner />
                              </UploadProvider>
                            }
                          />
                          {/* <Route
                      path={ROUTES_MAP.uploadProductList}
                      element={
                        <UploadProvider>
                          <UploadProductList />
                        </UploadProvider>
                      }
                    /> */}
                          <Route
                            index
                            element={
                              <UploadProvider>
                                <UploadAll />
                              </UploadProvider>
                            }
                          />
                        </Route>

                        {/* my orders */}
                        <Route path={ROUTES_MAP.myOrders}>
                          <Route index element={<MyOrders />} />
                          <Route
                            path={ROUTES_MAP.transmission}
                            element={<Transmission />}
                          />
                        </Route>

                        {/* users */}
                        <Route path={ROUTES_MAP.users} element={<CadUsers />}>
                          <Route index element={<Users />} />
                          <Route
                            path={ROUTES_MAP.usersForm}
                            element={<UserForm />}
                          />
                          <Route
                            path={ROUTES_MAP.usersForm + '/:id'}
                            element={<UserForm />}
                          />
                        </Route>

                        {/* my profile */}

                        <Route
                          path={ROUTES_MAP.myProfile}
                          element={<MyProfile />}
                        />

                        {/* ofertas */}
                        <Route
                          path={ROUTES_MAP.ofertas}
                          element={<AllPromotions />}
                        />
                        <Route
                          path={ROUTES_MAP.oferta}
                          element={<ViewOferta />}
                        />

                        {/* promocoes */}
                        <Route index element={<Promotions />} />

                        {/* painel */}
                        <Route path={ROUTES_MAP.painel} element={<Painel />}>
                          <Route index element={<Metrics />} />
                        </Route>

                        {/* testes */}
                        <Route path="/teste" element={<PageTest />} />

                        {/* not found */}
                        <Route path="*" element={NotFound} />
                      </Route>
                      {/* pedido */}
                      <Route path={ROUTES_MAP.balcao}>
                        <Route index element={<Order />} />
                      </Route>
                    </Routes>
                  </BrowserRouter>
                </UsersProvider>
              </CartsProvider>
            </OrderProvider>
          </ProductProvider>
        </CartProvider>
      </AppProvider>
    </div>
  );
};

export default App;
