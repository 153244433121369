import { CheckIcon } from '@heroicons/react/outline';
import clsx from 'clsx';
import { useRef } from 'react';

interface ColorChangeProps {
  colors: string[];
  value: string;
  onChange: (value: string) => void;
}

function reverseColor(color: string): string {
  // Remove o '#' se estiver presente
  if (color.startsWith('#')) {
    color = color.slice(1);
  }

  // Converte para maiúsculas para padronizar
  color = color.toUpperCase();

  // Se a cor for branca (ou uma variação disso), retorna preto
  if (color === 'FFFFFF') {
    return '#000000';
  }

  // Calcula a cor inversa
  let inverseColor = '#';
  for (let i = 0; i < 3; i++) {
    // Extrai cada componente da cor, converte para decimal, inverte e converte de volta para hexadecimal
    const component = 255 - parseInt(color.substr(i * 2, 2), 16);
    inverseColor += component.toString(16).padStart(2, '0');
  }

  return inverseColor.toUpperCase();
}
const Color = (color: string, isSelected: boolean) => {
  return (
    <div
      style={{ backgroundColor: color }}
      className="w-9 h-9 rounded-md border border-slate-300 border-solid"
    >
      {isSelected && (
        <CheckIcon
          style={{
            color: reverseColor(color),
          }}
          className="w-9 h-9 text-white"
        />
      )}
    </div>
  );
};

const ColorChange: React.FC<ColorChangeProps> = ({
  colors,
  value,
  onChange,
}) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  // verificar se o scrollContainerRef.current é nulo
  const clientWidth = scrollContainerRef.current?.clientWidth as number;
  console.log('🚀 ~ clientWidth:', clientWidth);
  const scrollWidth = scrollContainerRef.current?.scrollWidth as number;
  console.log('🚀 ~ scrollWidth:', scrollWidth);
  const isScrollable = scrollWidth > clientWidth;

  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({
        left: -(clientWidth || 100),
        behavior: 'smooth',
      });
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({
        left: clientWidth || 100,
        behavior: 'smooth',
      });
    }
  };

  return (
    <div className="flex items-center">
      {isScrollable && (
        <button
          onClick={scrollLeft}
          type="button"
          className="p-1 cursor-pointer hover:text-slate-900"
        >
          &#9664; {/* Seta para esquerda */}
        </button>
      )}
      <div
        ref={scrollContainerRef}
        className={clsx(
          'max-w-full h-12 overflow-hidden flex gap-1 items-center p-1',
          'rounded-md border border-slate-300 border-solid'
        )}
      >
        <button onClick={() => onChange('')} type="button" className="p-1">
          {Color('#ffffff', value === '')}
        </button>
        {colors.map((color) => (
          <button
            key={color}
            onClick={() => onChange(color)}
            type="button"
            className="p-1"
          >
            {Color(color, value === color)}
          </button>
        ))}
      </div>
      {isScrollable && (
        <button
          onClick={scrollRight}
          type="button"
          className="p-1 cursor-pointer hover:text-slate-900"
        >
          &#9654; {/* Seta para direita */}
        </button>
      )}
    </div>
  );
};

export default ColorChange;
