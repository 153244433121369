/* This example requires Tailwind CSS v2.0+ */
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
} from '@heroicons/react/outline';
import { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import useMedia from 'use-media';

interface PaginatorProps {
  qtdeReg: number;
  curPage: number;
  perPage: number;
  handlePageClick: (selectedItem: { selected: number }) => void;
}

export default function AppPaginator(props: PaginatorProps) {
  const [pageCount, setPageCount] = useState(0);
  const isSmall = useMedia({ maxWidth: 480 });

  useEffect(() => {
    if (!props.perPage || !props.qtdeReg) return;
    const count = Math.ceil(props.qtdeReg / props.perPage);
    setPageCount(count);
  }, [props.qtdeReg, props.perPage]);

  if (pageCount < 2) return null;

  return (
    <ReactPaginate
      className="flex justify-between md:justify-start text-xs"
      pageClassName="mx-[2px] flex flex-col justify-center bg-gray-200 text-gray-700 hover:bg-negative hover:text-gray-600 rounded-lg"
      activeClassName="bg-negative text-gray-800 font-bold"
      pageLinkClassName="px-3 py-1"
      breakLabel="..."
      nextLabel={
        isSmall ? <ChevronDoubleRightIcon className="w-4 h-4" /> : 'Próximo'
      }
      nextClassName="mx-[2px] flex flex-col justify-center bg-gray-200 text-gray-700 hover:bg-negative hover:text-gray-600 rounded-lg"
      nextLinkClassName="px-3 py-1"
      onPageChange={props.handlePageClick}
      pageRangeDisplayed={1}
      marginPagesDisplayed={1}
      pageCount={pageCount}
      initialPage={props.curPage ?? 0}
      forcePage={0}
      containerClassName="flex-1 flex justify-between"
      breakClassName="relative inline-flex items-center px-3 py-1 border border-gray-300 text-xs font-medium rounded-md text-gray-700 bg-white hover:bg-gray-300 shadow"
      previousLabel={
        isSmall ? <ChevronDoubleLeftIcon className="w-4 h-4" /> : 'Anterior'
      }
      previousClassName="mx-[2px] flex flex-col justify-center bg-gray-200 text-gray-700 hover:bg-negative hover:text-gray-600 rounded-lg"
      previousLinkClassName="px-3 py-1"
      // forcePage={props.curPage || 0}
    />
  );
}
