/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Info, Usuario } from '../../app.models';
import { getCryptoLocalStorage } from '../../app.service';
import { LOCAL_STORAGE_DEFAUT_CLI } from '../../app.settings';
import { getUsuarios } from './users.service';

interface IProvider {
  children: React.ReactNode;
}

interface IContext {
  loading: boolean;
  filtered: Usuario[];
  setFilter: (filter: string) => void;
  skip: number;
  setSkip: (selected: number) => void;
  info: Info;
  take: number;
  setTake: (selected: number) => void;
  defCliente: string;
  setDefCliente: (defCliente: string) => void;
  loadUsers: () => void;
  filterText: string;
  setFilterText: (filterText: string) => void;
}

const Context = createContext<IContext>({} as IContext);

const UsersProvider: React.FC<IProvider> = ({ children }: IProvider) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [filtered, setFiltered] = useState<Usuario[]>([]);
  const [filterText, setFilterText] = useState<string>('');
  const [filter, setFilter] = useState<string>('');
  const [info, setInfo] = useState({} as Info);
  const [skip, setSkip] = useState<number>(0);
  const [take, setTake] = useState<number>(10);

  const [defCliente, setDefCliente] = useState(
    getCryptoLocalStorage(LOCAL_STORAGE_DEFAUT_CLI)?.clienteId.toString()
  );

  const lastDefCliente = useRef<string>(
    getCryptoLocalStorage(LOCAL_STORAGE_DEFAUT_CLI)?.clienteId.toString()
  );

  const loadUsers = useCallback(async () => {
    setLoading(true);
    const users = await getUsuarios(defCliente, skip, 10, filter);
    const inf = users.info;
    const us = users.usuarios;
    setFiltered(us);
    setInfo(inf);
    setLoading(false);
  }, [defCliente, filter, skip]);

  useEffect(() => {
    if (defCliente === lastDefCliente.current) return;
    loadUsers();
  }, [defCliente, loadUsers]);

  useEffect(() => {
    loadUsers();
  }, [loadUsers]);

  return (
    <Context.Provider
      value={{
        loading,
        filtered,
        setFilter,
        info,
        skip,
        setSkip,
        take,
        setTake,
        defCliente,
        setDefCliente,
        loadUsers,
        filterText,
        setFilterText,
      }}
    >
      {children}
    </Context.Provider>
  );
};

const useUsers = (): IContext => {
  const context = useContext(Context);
  return context;
};

export { UsersProvider, useUsers };
