import React, { useEffect, useState } from 'react';
import { ShoppingCartIcon } from '@heroicons/react/outline';
import { useNavigate } from 'react-router-dom';
import { ROUTES_MAP } from '../app.map.routes';
import { useCart } from '../pages/cart/cart.hook';
import { LoadingSVG } from './loadingSVG';
import { classNames } from '../utils/functions';

export default function ShoppingCart() {
  const { loading, currentCart, renderCart, loadCart } = useCart();
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setRefresh] = useState(0);

  useEffect(() => {
    setRefresh(renderCart);
  }, [renderCart]);

  if (loading) return <LoadingSVG className="text-default-white px-5 py1" />;

  return (
    <button
      type="button"
      onClick={() => {
        loadCart();
        navigate(ROUTES_MAP.cart);
      }}
      className="px-1 py-2 group"
    >
      <span
        className={classNames(
          currentCart.qtdeTotal &&
            (currentCart.qtdeTotal.toString() || '0').length > 1
            ? 'px-[3px]'
            : 'px-[6px]',
          'relative text-[12px] z-20  py-[2px] ',
          'bg-default-white text-red-700 rounded-full top-3 -right-[3px] font-bold'
        )}
      >
        {currentCart.qtdeTotal}
      </span>
      <span className="text-default-white relative z-10 -top-0">
        <ShoppingCartIcon className="h-9 w-9 group-hover:scale-110" />
      </span>
      <span className="relative z-10 -top-[6px] text-[0.7em] text-default-white">
        #{currentCart.id}
      </span>
    </button>
  );
}
