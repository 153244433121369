import { useEffect, useState } from 'react';
import InputQtde from '../../../components/inputQtde';
import { parseCurrency, parseCurrencyBRL } from '../../../utils/numberHandler';

interface CardProductsProps {
  products: any[];
  itemHandler: (qtde: number, idx: number) => void;
}

export default function CardProducts({
  products,
  itemHandler,
}: CardProductsProps) {
  const [items, setItems] = useState<any[]>([]);

  useEffect(() => {
    setItems(products);
  }, [products]);

  return (
    <div className="px-2 flex flex-col space-y-2">
      {items &&
        items.map((item, index) => (
          <div
            key={item.id}
            className="m-1 p-1 border-[1px] border-solid border-gray-300"
          >
            <div className="flex flex-col justify-center">
              <div className="h-24 w-24 mx-auto">
                <img
                  style={{
                    width: '120px',
                    height: '120px',
                    objectFit: 'contain',
                    borderRadius: '0.375rem',
                    margin: '0 auto',
                  }}
                  src={
                    item.images[0]
                      ? process.env.REACT_APP_API_URL +
                        '/images/produtos/' +
                        item.id +
                        '/' +
                        item.images[0].filename
                      : 'https://via.placeholder.com/110x110?text=' +
                        item.codigoSgi
                  }
                  // src={item.produto.images[0].small}
                  alt={item.descricao}
                  className="w-full relative -top-6"
                />
              </div>
              <div className="ml-4">
                <div className="text-sm font-medium text-gray-900">
                  {item.descricao}
                </div>
                {/* <div className="text-[0.7em] text-gray-500">
                      {item.produto.descricaoCompleta}
                    </div> */}
                <div className="text-[0.7em] text-gray-500">
                  <span className="">Categoria: </span>
                  <span className="font-bold">{item.categoria.descricao}</span>
                </div>
                <div className="text-[0.7em] text-gray-500">
                  <span className="">Referência: </span>
                  <span className="font-bold">{item.referencia}</span>
                </div>
                <div className="text-[0.7em] text-gray-500">
                  <span className="">Código: </span>
                  <span className="font-bold">{item.codigoSgi}</span>
                </div>
                <div className="text-[0.7em] text-gray-500">
                  <span className="">R$ </span>
                  <span className="font-bold text-lg">
                    {parseCurrency(item.unitarioLiquido)}
                  </span>
                </div>
              </div>
            </div>

            <div className="border-b-[1px] border-solid border-gray-300 my-2" />

            <div className="flex justify-between">
              <InputQtde
                value={item.qtdeDisponivel}
                changeValue={(qtde: number) => itemHandler(qtde, index)}
              />

              <span className="text-lg font-bold text-gray-500">
                {parseCurrencyBRL(item.totalItem)}
              </span>
            </div>
          </div>
        ))}
    </div>
  );
}
