import { Cliente } from '../../app.models';
import api from '../../axios';

export async function getClienteMarkups(clienteId: string): Promise<any> {
  const url = `clientes/${clienteId}/categorias`;
  const markups = (await api.get(url)).data;
  return markups;
}

export async function delClienteMarkup(
  categoriaId: string,
  updated_at: string
): Promise<any> {
  const url = `clientes/categorias/${categoriaId}`;
  const resp = (
    await api.delete(url, {
      data: { updated_at },
    })
  ).data;
  return resp;
}

export async function addClienteMarkup(
  clienteId: number,
  categoriaId: number,
  markup: number
): Promise<any> {
  const url = `clientes/categorias`;
  const resp = (
    await api.post(url, {
      categoriaId,
      clienteId,
      markup,
    })
  ).data;
  return resp;
}

export async function altClienteMarkup(
  clienteId: number,
  categoriaId: number,
  markup: number,
  markupId: number,
  updated_at: string
): Promise<any> {
  const url = `clientes/categorias/${markupId}`;
  const resp = (
    await api.put(url, {
      clienteId,
      categoriaId,
      markup,
      updated_at,
    })
  ).data;
  return resp;
}

export async function altCliente(data: Cliente) {
  const url = `clientes/${data.id}`;
  delete (data as any).id;
  const resp = (await api.put(url, { cliente: { ...data } })).data;
  return resp;
}

export async function getCliente(clienteId: number) {
  const url = `clientes/${clienteId}`;
  const resp = (await api.get(url)).data;
  return resp;
}
