/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  BanIcon,
  ShoppingBagIcon,
  ShoppingCartIcon,
  ThumbUpIcon,
} from '@heroicons/react/outline';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Info, Pedido } from '../../app.models';
import { getCryptoLocalStorage } from '../../app.service';
import { LOCAL_STORAGE_DEFAUT_CLI } from '../../app.settings';
import { IStep } from '../../components/appStep';
import { getPedidos } from './pedido.service';

interface IOrderStatus {
  situacao: string;
  id: number;
  icone: React.ReactElement;
  className: string;
}

interface IProvider {
  children: React.ReactNode;
}

interface IContext {
  loading: boolean;
  pedidoStatus: IOrderStatus[];
  dtIni: Date | undefined;
  setDtIni: (dtIni: Date | undefined) => void;
  dtEnd: Date | undefined;
  setDtEnd: (dtEnd: Date | undefined) => void;
  pedidos: Pedido[];
  info: Info | undefined;
  skip: number | undefined;
  setSkip: (skip: number | undefined) => void;
  filters: string | undefined;
  setFilters: (filters: string) => void;
  steps: IStep[];
  defCliente: string;
  setDefCliente: (defCliente: string) => void;

  selectedTabelaId: string;
  setSelectedTabelaId: (defCliente: string) => void;
  loadOrders: () => void;
}
const Context = createContext<IContext>({} as IContext);

const OrderProvider: React.FC<IProvider> = ({ children }: IProvider) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [pedidos, setPedidos] = useState<Pedido[]>([]);
  const [info, setInfo] = useState<Info | undefined>();
  const [dtIni, setDtIni] = useState<Date | undefined>();
  const lastDtIni = useRef<Date>();
  const [dtEnd, setDtEnd] = useState<Date | undefined>();
  const lastDtEnd = useRef<Date>();
  const [skip, setSkip] = useState<number | undefined>();
  const lastSkip = useRef<number>();
  const [filters, setFilters] = useState<string>();
  const lastFilters = useRef<string>();
  const [defCliente, setDefCliente] = useState(
    getCryptoLocalStorage(LOCAL_STORAGE_DEFAUT_CLI)?.clienteId.toString()
  );
  const lastDefCliente = useRef<string>(
    getCryptoLocalStorage(LOCAL_STORAGE_DEFAUT_CLI)?.clienteId.toString()
  );

  const [selectedTabelaId, setSelectedTabelaId] = useState('');

  const loadOrders = useCallback(() => {
    setLoading(true);
    getPedidos({
      dataI: dtIni,
      dataF: dtEnd,
      skip,
      situacao: filters,
      defaultCli: defCliente,
    }).then((data) => {
      data.info && setInfo(data.info);
      data.pedidos && setPedidos(data.pedidos);
      setLoading(false);
    });
  }, [dtIni, dtEnd, skip, filters, defCliente]);

  useEffect(() => {
    if (
      skip === lastSkip.current &&
      dtIni === lastDtIni.current &&
      dtEnd === lastDtEnd.current &&
      filters === lastFilters.current &&
      defCliente === lastDefCliente.current
    )
      return;
    lastSkip.current = skip;
    lastDtIni.current = dtIni;
    lastDtEnd.current = dtEnd;
    lastDefCliente.current = defCliente;
    loadOrders();
  }, [defCliente, dtEnd, dtIni, filters, loadOrders, skip]);

  const pedidoStatus = [
    {
      situacao: 'Pendente',
      id: 0,
      icone: <ShoppingCartIcon className="w-5 h-5" />,
      className: 'text-blue-500',
    },
    {
      situacao: 'Transmitido',
      id: 1,
      icone: <ThumbUpIcon className="w-5 h-5" />,
      className: 'text-green-500',
    },

    {
      situacao: 'Cancelado',
      id: 70,
      icone: <BanIcon className="w-5 h-5" />,
      className: 'text-red-500',
    },
    {
      situacao: 'Entregue',
      id: 90,
      icone: <ShoppingBagIcon className="w-5 h-5" />,
      className: 'text-purple-500',
    },
  ];

  const steps = [
    {
      title: 'Selecionar',
      description: 'Seleção de Carrinhos',
    },
    {
      title: 'Resumo',
      description: 'Resumo dos Produtos',
    },
    {
      title: 'Finalização',
      description: 'Finalizar e transmitir',
    },
  ];

  return (
    <Context.Provider
      value={{
        loading,
        pedidoStatus,
        dtIni,
        setDtIni,
        dtEnd,
        setDtEnd,
        info,
        pedidos,
        skip,
        setSkip,
        filters,
        setFilters,
        steps,
        defCliente,
        setDefCliente,
        loadOrders,
        selectedTabelaId,
        setSelectedTabelaId,
      }}
    >
      {children}
    </Context.Provider>
  );
};

const useOrder = (): IContext => {
  const context = useContext(Context);
  return context;
};

export { OrderProvider, useOrder };
