/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// eslint-disable-next-line @typescript-eslint/no-var-requires
const moment = require('moment');
import 'moment/locale/pt-br';

const addTime = (timeIni: string, step: string, stepQtde: number): string => {
  const time = timeIni.split(':');
  const mment = moment().hour(time[0]).minute(time[1]);
  const stepCalc = parseInt(step);
  const add = stepCalc * stepQtde;

  console.log(
    `time => ${time}, stepCalc => ${stepCalc}, stepQtde => ${stepQtde}, add => ${add}`
  );

  return mment.add(add, 'm').format('hh:mm');
};

const getFormatedDate = (date?: string) => {
  if (date) return moment(date).format('DD/MM/YYYY');
  else return 'N/D';
};

const getFormatedDateTime = (date?: string) => {
  if (date) return moment(date).format('DD/MM/YYYY - HH:mm');
  else return 'N/D';
};

const getFormatedDateWeek = (date: string) => {
  const day = moment(date).format('dddd');
  return day;
};

const getNumDaysExpired = (expiration: string) => {
  const exp = moment(expiration);
  const days = moment().diff(exp, 'days');
  console.log({ exp: exp.toDate(), days });
  return days > 0 ? `(${days})` : '';
};

const getOddDate = (date: string) => {
  const day: number = moment(date).date();
  return day % 2 === 0;
};

const isExpired = (expiredDate: string) => {
  return moment().isAfter(expiredDate) as boolean;
};

const isPostExpiration = (expiration: string, date: string) => {
  return moment(date).isAfter(expiration) as boolean;
};

const dayWeekColorCss = (date: string) => {
  const day: number = moment(date).day();
  switch (day) {
    case 0:
      return 'bg-red-200 text-black';
    case 1:
      return 'bg-yellow-100 text-black';
    case 2:
      return 'bg-pink-100 text-black';
    case 3:
      return 'bg-green-100 text-black';
    case 4:
      return 'bg-yellow-300 text-black';
    case 5:
      return 'bg-blue-100 text-black';
    case 6:
      return 'bg-purple-100 text-black';
    default:
      return '';
  }
};

const MONTHS = [
  'JANEIRO',
  'FEVEREIRO',
  'MARÇO',
  'ABRIL',
  'MAIO',
  'JUNHO',
  'JULHO',
  'AGOSTO',
  'SETEMBRO',
  'OUTUBRO',
  'NOVEMBRO',
  'DEZEMBRO',
];

const DAYS = ['DOM', 'SEG', 'TER', 'QUA', 'QUI', 'SEX', 'SAB'];

const MONTHS_NUMBER = [
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12',
];

export {
  addTime,
  getFormatedDate,
  getFormatedDateTime,
  getFormatedDateWeek,
  getOddDate,
  dayWeekColorCss,
  MONTHS,
  DAYS,
  MONTHS_NUMBER,
  isExpired,
  isPostExpiration,
  getNumDaysExpired,
};
