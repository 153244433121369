import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Search } from '../../app.models';
import FilterOferta from './filterOferta';
import { getDestaque } from './promotions.service';

export default function ViewOferta() {
  const [list, setList] = useState<Search>({} as Search);
  const [loading, setLoading] = useState<boolean>(false);
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      setLoading(true);
      getDestaque(id).then((data) => {
        setList(data);
        setLoading(false);
      });
    }
  }, [id]);

  if (loading)
    return (
      <div className="flex flex-col justify-center items-center h-[88vh] animate-pulse">
        <span className="mx-auto"></span>Aguarde ...
      </div>
    );

  return (
    <div className="mt-28 md:mt-0 flex h-[88vh]">
      <FilterOferta listProdutos={list} filterEixos={[]} loading={loading} />
    </div>
  );
}
