import { IMenu, MENU_ITEMS } from './menu';
import {
  ChevronDownIcon,
  ChevronRightIcon,
  ShoppingCartIcon,
} from '@heroicons/react/outline';
import { Disclosure } from '@headlessui/react';
import logoSvg from '../../assets/svg/logo-full.svg';
import { ReactSVG } from 'react-svg';
import { useNavigate, useLocation } from 'react-router-dom';
// import MenuSwitch from './menuSwitch';
import { ROUTES_MAP } from '../../app.map.routes';
import { isMenuActive } from '../../utils/functions';
import { useCart } from '../../pages/cart/cart.hook';

interface MenuLeftProps {
  onClick?: () => void;
  menuMin: boolean;
  setMenuMin: (value: boolean) => void;
}

const menuSimpleClass =
  'flex items-center justify-between w-full text-primary1 md:text-default-white hover:opacity-50 ml-0';
const menuSubClass =
  'flex flex-col w-full text-primary1 md:text-default-white cursor-pointer items-center hover:opacity-50 my-4 ml-1';

const MenuSimple = (menu: IMenu) => {
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <button
      className={menuSimpleClass}
      onClick={() => {
        if (location.pathname === menu.link.trim()) return;
        navigate(menu.link);
        menu.onClick && menu.onClick();
      }}
    >
      <div className="flex items-center space-x-1">
        <span
          className={`p-2 ${
            isMenuActive(menu.link, location.pathname)
              ? 'text-default-white bg-primary1'
              : 'bg-default-white text-primary1'
          } rounded-full shadow`}
        >
          {menu.icon}
        </span>
        {menu.title && (
          <span className="text-sm font-bold truncate">{menu.title}</span>
        )}
      </div>
    </button>
  );
};

const MenuSub = (menu: IMenu) => {
  return (
    <Disclosure>
      {({ open }) => (
        <>
          <Disclosure.Button className={menuSimpleClass}>
            <div className="flex items-center space-x-1">
              <span
                className={`p-2 ${
                  isMenuActive(menu.link, location.pathname)
                    ? 'bg-default-white text-primary1'
                    : 'bg-primary1 '
                } rounded-full shadow`}
              >
                {menu.icon}
              </span>
              <span className="text-sm font-bold truncate">{menu.title}</span>
            </div>
            <div>
              {open ? (
                <ChevronDownIcon className="w-6 h-6" />
              ) : (
                <ChevronRightIcon className="w-6 h-6" />
              )}
            </div>
          </Disclosure.Button>
          <Disclosure.Panel>
            <ul className="flex flex-col">
              {menu.subMenu.map((submenu, subIdx) => (
                <li key={subIdx} className={menuSubClass}>
                  {submenu.subMenu.length === 0 ? (
                    <MenuSimple onClick={submenu.onClick} {...submenu} />
                  ) : (
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    <MenuSub {...submenu} />
                  )}
                </li>
              ))}
            </ul>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default function MenuLeft(props: MenuLeftProps) {
  const location = useLocation();
  const navigate = useNavigate();
  const { currentCart } = useCart();
  return (
    <div className="px-8">
      <div className="py-1 hidden md:block">
        {/* <MenuSwitch checked={props.menuMin} onChange={props.setMenuMin} /> */}
      </div>
      <div className="h-16 w-full items-center hidden md:flex mt-4">
        <ReactSVG
          src={logoSvg}
          className="text-white fill-current w-full shadow-lg"
        />
      </div>
      <ul className="mt-2 pb-6 w-full flex flex-col space-y-8">
        {MENU_ITEMS.map((item, idx) => (
          <li key={idx}>
            {item.subMenu.length > 0 ? (
              <MenuSub {...item} />
            ) : (
              <MenuSimple onClick={props.onClick} {...item} />
            )}
          </li>
        ))}
        {location.pathname !== ROUTES_MAP.cart && (
          <li className="md:hidden">
            <button
              className={menuSimpleClass}
              onClick={() => {
                navigate(ROUTES_MAP.cart);
                props.onClick && props.onClick();
              }}
            >
              <div className="flex items-center space-x-1">
                <span
                  className={`p-2 text-default-white bg-gray-700 rounded-full shadow`}
                >
                  <ShoppingCartIcon className="w-6 h-6" />
                </span>
                <span className="text-sm font-bold truncate text-gray-700">
                  #{currentCart.id} - Carrinho Atual
                </span>
              </div>
            </button>
          </li>
        )}
      </ul>
    </div>
  );
}
