import { EyeIcon, ShoppingCartIcon } from '@heroicons/react/outline';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES_MAP } from '../../app.map.routes';
import { Produto } from '../../app.models';
// import { Progress } from '../../components/progress';
import { useCart } from '../cart/cart.hook';
import { getCart } from '../cart/cart.service';
import ModalSelectCard from '../myCarts/modalSelectCard';
import ModalProduct from './modalProduct';
import { useApp } from '../../app.hook';
import { useProduct } from './product.hook';
import { EnumOrigens } from '../../utils/origens';
import ExibirPrecoConsumidorFinal from '../../components/ExibirPrecoConsumidorFinal';
import ButtonComprar from '../../components/buttonComprar';

interface TableProductProps {
  items: Produto[];
  origem?: string;
}

export default function TableProduct(props: TableProductProps) {
  const { isPerfilManager } = useApp();
  const { setOrigem } = useProduct();
  const [modalOpen, setOpenModal] = useState<boolean>(false);
  const [product, setProduct] = useState<Produto>();

  const navigate = useNavigate();
  const detail = (productId: number) => {
    setOrigem(props.origem ?? EnumOrigens.PRODUTO);
    navigate(ROUTES_MAP.productById(productId));
  };

  const { currentCart } = useCart();
  const [openModalSelectCart, setOpenModalSelectCart] =
    useState<boolean>(false);

  const buy = async (product: Produto) => {
    const verificaSituacao = (await getCart(currentCart.id)).situacaoCarrinho;

    if (verificaSituacao === 0) {
      setProduct(product);
      setOpenModal(true);
    } else {
      setOpenModalSelectCart(true);
    }
  };

  const modalSelectCard = useMemo(
    () => (
      <ModalSelectCard
        isOpen={openModalSelectCart}
        setIsOpen={setOpenModalSelectCart}
      />
    ),
    [openModalSelectCart]
  );

  const modaProduct = useMemo(
    () => (
      <ModalProduct
        isOpen={modalOpen}
        setIsOpen={setOpenModal}
        product={product}
      />
    ),
    [modalOpen, product]
  );

  return (
    <>
      <table className="divide-y divide-gray-200 text-xs">
        <thead className="bg-gray-100 sticky top-0">
          <tr className="">
            <th
              scope="col"
              className="pl-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Descrição
            </th>
            <th
              scope="col"
              className="py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Ref.
            </th>
            <th
              scope="col"
              className="pl-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Código
            </th>
            {isPerfilManager && (
              <th
                scope="col"
                className="pl-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Saldo
              </th>
            )}
            <th
              scope="col"
              className="pl-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Categoria
            </th>
            <th
              scope="col"
              className="pl-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Valor
            </th>
            <th scope="col" className="relative px-6 py-3">
              <span className="sr-only">Edit</span>
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-400">
          {props.items.map((item) => (
            <tr key={item.id} className="even:bg-gray-50 py-[1] group">
              <td className="whitespace-nowrap">
                <div className="line-clamp-1 group-hover:line-clamp-4 pl-3">
                  {item.descricaoSite}
                </div>
                {item.promocao && (
                  <span className="pl-3 text-sm text-amber-500 font-bold">
                    OFERTA -{' '}
                    {item.promocao.qtdeDisponivel > 1
                      ? item.promocao.qtdeDisponivel + ' restantes'
                      : item.promocao.qtdeDisponivel + ' restante'}
                  </span>
                )}
              </td>
              <td className="w-24">{item.referencia}</td>
              <td className="whitespace-nowrap">{item.codigoSgi}</td>
              {isPerfilManager && (
                <td className="whitespace-nowrap text-center">
                  {item?.saldo?.saldo ?? 0}
                </td>
              )}
              <td className="whitespace-nowrap">{item.categoria.descricao}</td>
              <td className="whitespace-nowrap">
                {item.isPromocao ? (
                  <div className="flex flex-col my-1 text-sm">
                    <span className="flex space-x-2 md:text-sm lg:text-md line-through text-gray-400">
                      {/* <span>{parseCurrency(item.preco)}</span> */}
                      <ExibirPrecoConsumidorFinal
                        item={item}
                        field="preco"
                        fieldConsumidorFinal="precoConsumidorFinal"
                      />
                    </span>
                    <span className="flex space-x-2 text-sm text-amber-500 font-bold">
                      {/* <span>{parseCurrency(item.precoPromo)}</span> */}
                      <ExibirPrecoConsumidorFinal
                        item={item}
                        field="precoPromo"
                        fieldConsumidorFinal="precoPromoConsumidorFinal"
                      />
                    </span>
                  </div>
                ) : (
                  <div className="flex flex-col justify-center my-0 py-2">
                    <div className="flex my-3">
                      <span className="flex space-x-2 font-bold text-md">
                        {/* <span>{parseCurrency(item.precoPromo)}</span> */}
                        <ExibirPrecoConsumidorFinal
                          item={item}
                          field="precoPromo"
                          fieldConsumidorFinal="precoPromoConsumidorFinal"
                        />
                      </span>
                    </div>
                  </div>
                )}
              </td>

              <td className="align-middle">
                <div className="flex items-center pr-3">
                  {/* <button
                    type="button"
                    title="Comprar"
                    className="bg-primary1 p-1 m-1 text-default-white rounded-full"
                    onClick={() => buy(item)}
                  >
                    <ShoppingCartIcon className="h-4 w-4" />
                  </button> */}
                  <ButtonComprar
                    isIcon={true}
                    produto={item}
                    action={() => buy(item)}
                  />
                  <button
                    type="button"
                    title="Ver Detalhes"
                    className="bg-primary1 p-1 m-1 text-default-white rounded-full"
                    onClick={() => detail(item.id)}
                  >
                    <EyeIcon className="h-4 w-4" />
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {modaProduct}
      {modalSelectCard}
    </>
  );
}
