/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useState } from 'react';
// import InstallAppIcon from '../assets/svg/install-app.svg';
import { classNames } from '../utils/functions';
import { ReactComponent as InstallAppIcon } from '../assets/svg/install-app.svg';

const InstallPWA = () => {
  const [supportsPWA, setSupportsPWA] = useState(false);
  const [promptInstall, setPromptInstall] = useState(null);

  useEffect(() => {
    const handler = (e: any) => {
      e.preventDefault();
      setSupportsPWA(true);
      setPromptInstall(e);
    };
    window.addEventListener('beforeinstallprompt', handler);

    return () => window.removeEventListener('transitionend', handler);
  }, []);

  const onClick = (evt: any) => {
    evt.preventDefault();
    if (!promptInstall) {
      return;
    }
    // @ts-ignore
    promptInstall.prompt();
  };
  if (!supportsPWA) {
    return null;
  }
  return (
    <>
      <button
        className={classNames(
          'bg-default-white fill-current',
          'w-10 h-10 relative z-50 transition-all duration-300 ease-in-out',
          'rounded-full shadow',
          'md:bg-primary1',
          'hover:bg-default-white hover:text-primary1',
          'flex flex-col justify-center items-center gap-1'
        )}
        id="setup_button"
        aria-label="Install app"
        title="Install app"
        onClick={onClick}
      >
        <InstallAppIcon className="w-3" />
        <span className="text-[0.5em]">Instalar</span>
      </button>
    </>
  );
};

export default InstallPWA;
