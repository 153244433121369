import { Transition, Dialog } from '@headlessui/react';
import { DotsHorizontalIcon, XIcon } from '@heroicons/react/outline';
import {
  Fragment,
  LegacyRef,
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Marca, Modelo } from '../../app.models';
import { useApp } from '../../app.hook';
import { classNames } from '../../utils/functions';
import Display from '../product/display';
import { SearchType } from '.';

interface ModalBuscaPorMarcaModeloProps {
  onSearch: (modelo: Modelo, origem: string) => void;
  searchTipo: SearchType;
}

const ModalBuscaPorMarcaModelo = forwardRef(
  (
    { onSearch, searchTipo }: ModalBuscaPorMarcaModeloProps,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ref: LegacyRef<HTMLButtonElement>
  ) => {
    const [position, setPosition] = useState<number>(0);
    const refInner = useRef<HTMLDivElement | null>(null);

    const { marcas, modelos } = useApp();
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const [selectedSearchedModelo, setSelectedSearchedModelo] = useState<
      number | undefined
    >(undefined);

    const [selectedMarca, setSelectedMarca] = useState<Marca>();
    const [selectedModelo, setSelectedModelo] = useState<Modelo>();
    const [selectedLastMarca, setSelectedLastMarca] = useState<Marca>();

    const filteredModelos = modelos.filter(
      (m) => m.marcaId === selectedMarca?.id
    );

    function getModalTitle() {
      return selectedMarca?.marcaDesc || 'Selecione a marca';
    }

    const open = () => {
      setIsOpen(true);
    };

    const close = () => {
      setIsOpen(false);
    };

    const selectMarca = useCallback((marca: Marca) => {
      setSelectedMarca(marca);
      setSelectedLastMarca(marca);
    }, []);

    const selectModelo = useCallback(
      (modelo: Modelo) => {
        setSelectedModelo(modelo);
        onSearch(modelo, 'modal');
        setSelectedSearchedModelo(modelo.id);
        close();
      },
      [onSearch]
    );

    useEffect(() => {
      const t = setTimeout(() => {
        if (isOpen) {
          if (selectedMarca) {
            refInner.current?.scrollTo({
              top: position || 0,
              behavior: 'smooth',
            });
          }
          // colocar o focus no primeiro item
          if (selectedMarca) {
            const buttons = refInner.current?.querySelectorAll('button');
            const selected = Array.from(buttons || []).find(
              (b) => b.getAttribute('data-selected') === 'true'
            );
            if (selected) {
              selected.focus();
            }
            // const first = refInner.current?.querySelector('button');
            // first?.focus();
          } else {
            refInner.current?.querySelector('button')?.focus();
          }
        }
      }, 100);

      return () => {
        clearTimeout(t);
      };
    }, [isOpen, position, selectedMarca]);

    useEffect(() => {
      if (searchTipo !== 'marcaModelo') {
        setSelectedMarca(undefined);
        setSelectedModelo(undefined);
        setPosition(0);
      }
    }, [searchTipo]);

    // controle de movimentação do teclado, teclas de seta usando tabulação
    useEffect(() => {
      const handleKeyDown = (e: KeyboardEvent) => {
        if (e.key === 'ArrowLeft') {
          const items = refInner.current?.querySelectorAll('button');
          if (!items) return;
          const getSelectedTabIndex = (
            items: NodeListOf<HTMLButtonElement>
          ) => {
            for (let i = 0; i < items.length; i++) {
              if (items[i] === document.activeElement) {
                return i;
              }
            }
            return -1;
          };
          const indexSelected = getSelectedTabIndex(items);
          console.log({ indexSelected });
          items[indexSelected - 1]?.focus();
        }

        if (e.key === 'ArrowRight') {
          const items = refInner.current?.querySelectorAll('button');
          if (!items) return;
          const getSelectedTabIndex = (
            items: NodeListOf<HTMLButtonElement>
          ) => {
            for (let i = 0; i < items.length; i++) {
              if (items[i] === document.activeElement) {
                return i;
              }
            }
            return -1;
          };
          const indexSelected = getSelectedTabIndex(items);
          console.log({ indexSelected });
          items[indexSelected + 1]?.focus();
        }
      };
      window.addEventListener('keydown', handleKeyDown);
      return () => {
        window.removeEventListener('keydown', handleKeyDown);
      };
    }, [refInner]);

    return (
      <>
        <div className="flex flex-col">
          <div className="flex-grow-0 flex flex-col md:flex-row space-x-2 md:space-y-0 items-center text-sm md:font-bold px-1">
            <button
              ref={ref}
              className={`group flex flex-grow-0 items-center space-x-2 px-2 hover:bg-default-white py-1 rounded-full group`}
              onClick={() => {
                open();
              }}
            >
              {selectedMarca ? (
                <img
                  style={{
                    width: '30px',
                    height: '30px',
                    objectFit: 'contain',
                  }}
                  className="bg-white p-[1px] rounded-full lg:flex"
                  src={selectedMarca?.images[0].img}
                />
              ) : (
                <DotsHorizontalIcon className="w-5 h-5 text-gray-400 bg-gray-200 p-[1px] rounded-full lg:flex group-hover:text-primary3" />
              )}
              <span
                className={classNames(
                  'font-bold text-xs text-gray-600 md:text-default-white group-hover:text-primary3',
                  'md:text-primary1'
                )}
              >
                <div>
                  {String(
                    selectedMarca?.marcaDesc || 'Selecione uma marca'
                  ).toLocaleUpperCase()}
                </div>
                {/* <div className="text-[0.7em] text-black font-thin">
                  {selectedMarca?.marcaDesc ? 'Clique para alterar' : ''}
                </div> */}
              </span>
            </button>

            {selectedMarca?.id && (
              <button
                className={`group flex flex-grow-0 items-center space-x-2 px-2 hover:bg-default-white rounded-full`}
                onClick={() => {
                  open();
                }}
              >
                {selectedModelo ? (
                  <img
                    style={{
                      width: '40px',
                      height: '40px',
                      objectFit: 'contain',
                    }}
                    className="bg-white p-[1px] rounded-full lg:flex"
                    src={selectedModelo?.images[0].img}
                  />
                ) : (
                  <DotsHorizontalIcon className="w-5 h-5 text-gray-400 bg-slate-200 p-[1px] rounded-full lg:flex group-hover:text-primary3" />
                )}
                <span
                  className={classNames(
                    'font-bold text-gray-600 group-hover:text-primary3 text-xs',
                    'md:text-primary1'
                  )}
                >
                  <div>
                    {selectedModelo?.modeloDesc || 'Selecione o modelo'}
                  </div>
                  {/* <div className="text-[0.7em] text-black font-thin">
                    {selectedModelo?.modeloDesc ? 'Clique para alterar' : ''}
                  </div> */}
                </span>
              </button>
            )}
          </div>
        </div>
        <Transition appear show={isOpen} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-50 overflow-y-auto"
            onClose={close}
          >
            <div className="px-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-black opacity-20" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="inline-block h-screen align-middle"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="inline-block font-display w-full max-w-4xl overflow-hidden align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                  <Dialog.Title
                    as="h3"
                    className="flex justify-between mb-2 bg-primary2 text-default-white px-4 py-2"
                  >
                    <span className="text-lg flex-1 font-bold leading-6 font-display flex gap-5 items-center">
                      <span>{getModalTitle()}</span>
                      <div className="flex-1" />
                      {selectedMarca && (
                        <button
                          className="group flex items-center space-x-2 px-2 py-1 bg-white/30 hover:bg-default-white rounded-full mr-10"
                          onClick={() => {
                            setSelectedMarca(undefined);
                            setSelectedModelo(undefined);
                            setPosition(0);
                          }}
                        >
                          <DotsHorizontalIcon className="w-5 h-5 text-gray-400 bg-gray-200 p-[1px] rounded-full lg:flex" />
                          <span className="text-white text-xs group-hover:text-slate-400">
                            Selecione outra marca aqui
                          </span>
                        </button>
                      )}
                    </span>
                    <button onClick={close} className="hover:text-gray-400">
                      <XIcon className="w-5 h-5" />
                    </button>
                  </Dialog.Title>
                  {/* content */}
                  <div
                    ref={refInner}
                    className="h-[71vh] grid grid-cols-4 gap-1 overflow-hidden overflow-y-auto px-10"
                  >
                    {selectedMarca
                      ? filteredModelos.map(
                          (mo) =>
                            mo.isVisible && (
                              <Display
                                key={mo.id}
                                id={mo.id}
                                selected={selectedSearchedModelo}
                                title={mo.modeloDesc}
                                subTitle={`Ano: ${mo.anoI} - ${mo.anoF}`}
                                img={mo.images[0].img}
                                onClick={() => {
                                  selectModelo(mo);
                                  setPosition(refInner.current?.scrollTop || 0);
                                }}
                              />
                            )
                        )
                      : marcas.map(
                          (ma) =>
                            ma.isVisible && (
                              <Display
                                key={ma.id}
                                id={ma.id}
                                selected={selectedLastMarca?.id}
                                title={ma.marcaDesc}
                                img={ma.images[0].img}
                                onClick={() => {
                                  selectMarca(ma);
                                  setPosition(refInner.current?.scrollTop || 0);
                                }}
                              />
                            )
                        )}
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition>
      </>
    );
  }
);

export default ModalBuscaPorMarcaModelo;
