import {
  ClipboardListIcon,
  DesktopComputerIcon,
  FireIcon,
  QrcodeIcon,
  ShoppingCartIcon,
  UploadIcon,
  UserIcon,
} from '@heroicons/react/outline';
import { UsersIcon } from '@heroicons/react/solid';
import { createElement } from 'react';
import { ROUTES_MAP } from '../../app.map.routes';

export interface IMenu {
  title: string;
  link: string;
  icon: JSX.Element;
  subMenu: IMenu[];
  isAdmin: boolean;
  isManage: boolean;
  onClick?: () => void;
}

export const MENU_ITEMS: IMenu[] = [
  {
    title: 'Destaques',
    link: ROUTES_MAP.promotions,
    icon: createElement(FireIcon, { className: 'h-6 w-6' }),
    isAdmin: false,
    isManage: false,
    subMenu: [],
  },
  {
    title: 'Produtos',
    link: ROUTES_MAP.product + '?take=100&skip=0',
    icon: createElement(QrcodeIcon, { className: 'h-6 w-6' }),
    isAdmin: false,
    isManage: false,
    subMenu: [],
  },
  {
    title: 'Balção',
    link: ROUTES_MAP.balcao,
    icon: createElement(DesktopComputerIcon, { className: 'h-6 w-6' }),
    isAdmin: false,
    isManage: false,
    subMenu: [],
  },
  {
    title: 'Meus Carrinhos',
    link: ROUTES_MAP.myCarts,
    icon: createElement(ShoppingCartIcon, { className: 'h-6 w-6' }),
    isAdmin: false,
    isManage: false,
    subMenu: [],
  },
  {
    title: 'Meus Pedidos',
    link: ROUTES_MAP.myOrders,
    icon: createElement(ClipboardListIcon, { className: 'h-6 w-6' }),
    isAdmin: false,
    isManage: false,
    subMenu: [],
  },
  {
    title: 'Usuários',
    link: ROUTES_MAP.users,
    icon: createElement(UsersIcon, { className: 'h-6 w-6' }),
    isAdmin: false,
    isManage: false,
    subMenu: [],
  },
  {
    title: 'Upload',
    link: ROUTES_MAP.upload,
    icon: createElement(UploadIcon, { className: 'h-6 w-6' }),
    isAdmin: false,
    isManage: true,
    subMenu: [],
  },
  {
    title: 'Meu Painel',
    link: ROUTES_MAP.painel,
    icon: createElement(UserIcon, { className: 'h-6 w-6' }),
    isAdmin: false,
    isManage: true,
    subMenu: [],
  },
  {
    title: 'Meu Perfil',
    link: ROUTES_MAP.myProfile,
    icon: createElement(UserIcon, { className: 'h-6 w-6' }),
    isAdmin: false,
    isManage: false,
    subMenu: [],
  },
];
