import { useState } from 'react';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { Popover } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/outline';
import { getFormatedDate } from '../utils/datetimeHandler';
import { classNames } from '../utils/functions';
import { format, parse } from 'date-fns';
import { DebounceInput } from 'react-debounce-input';
import Button from './layout/button';
import { usePopper } from 'react-popper';

// link documentacao date-range => https://github.com/hypeserver/react-date-range

interface DateRangeInputProps {
  starDate?: Date;
  endDate?: Date;
  action: (startDate: Date | undefined, endDate: Date | undefined) => void;
}

const DEBOUCE_TIME = 100;

export default function DateRangeInput({
  action,
  starDate,
  endDate,
}: DateRangeInputProps) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [referenceElement, setReferenceElement] = useState({} as any);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [popperElement, setPopperElement] = useState({} as any);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
  });

  const [_starDate, setStarDate] = useState<string | undefined>(
    starDate ? format(starDate, 'yyyy-MM-dd') : undefined
  );
  const [_endDate, setEndDate] = useState<string | undefined>(
    endDate && format(endDate, 'yyyy-MM-dd')
  );

  const save = () => {
    if (
      // moment(_starDate).format('LL') !== moment(starDate).format('LL') ||
      // moment(_endDate).format('LL') !== moment(endDate).format('LL')
      _starDate &&
      _endDate
    ) {
      action(
        parse(_starDate, 'yyyy-MM-dd', new Date(_starDate)),
        parse(_endDate, 'yyyy-MM-dd', new Date(_endDate))
      );
    }
  };

  const clear = () => {
    setStarDate(undefined);
    setEndDate(undefined);
    action(undefined, undefined);
  };

  return (
    <Popover className="">
      {({ open, close }) => {
        return (
          <>
            <Popover.Button
              className={classNames(
                open ? '' : 'text-opacity-90',
                'text-sm text-gray-600 group bg-gray-200 px-3 py-2 rounded-md inline-flex items-center font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75'
              )}
              ref={setReferenceElement}
            >
              <span className="line-clamp-2">
                {_starDate && _endDate
                  ? `${getFormatedDate(_starDate)}
                  à
                  ${getFormatedDate(_endDate)}`
                  : `Período`}
              </span>
              <ChevronDownIcon
                className={`${open ? '' : 'text-opacity-70'}
                  ml-2 h-5 w-5 text-gray-400 group-hover:text-opacity-80 transition ease-in-out duration-150`}
                aria-hidden="true"
              />
            </Popover.Button>

            <Popover.Panel
              className={classNames([
                'mt=0 z-10 p-4 transform -translate-x-1/2 bg-white shadow rounded-xl flex flex-col space-y-1 text-gray-600',
              ])}
              ref={setPopperElement}
              style={styles.popper}
              {...attributes.popper}
            >
              <div className="flex flex-col">
                <span className="text-xs">Data Inicial:</span>
                <DebounceInput
                  debounceTimeout={DEBOUCE_TIME}
                  type="date"
                  className="bg-gray-100 px-2 py-2"
                  value={_starDate}
                  onChange={(e) => setStarDate(e.target.value)}
                  autoFocus={true}
                />
              </div>
              <div className="flex flex-col">
                <span className="text-xs">Data Final:</span>
                <DebounceInput
                  type="date"
                  debounceTimeout={DEBOUCE_TIME}
                  className="bg-gray-100 px-2 py-2"
                  value={_endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </div>
              <div className="py-2 flex justify-between">
                <Button
                  title="Limpar"
                  color="bg-default-white text-primary2 mr-2 hover:bg-gray-100 hover:text-gray-700"
                  full="true"
                  onClick={() => {
                    clear();
                    close();
                  }}
                />
                <Button
                  title="OK"
                  color="bg-green-400 text-white hover:bg-gray-100 hover:text-gray-700"
                  full="true"
                  onClick={() => {
                    save();
                    close();
                  }}
                />
              </div>
            </Popover.Panel>
          </>
        );
      }}
    </Popover>
  );
}
