// import React, { Fragment } from 'react';
import { useApp } from '../app.hook';
import { parseCurrency } from '../utils/numberHandler';
import clsx from 'clsx';
// import { Popover, Transition } from '@headlessui/react';

interface Props {
  item: Record<string, any>;
  className?: string;
  field: string;
  fieldConsumidorFinal: string;
  isTotal?: boolean;
  isColumn?: boolean;
}

export default function ExibirPrecoConsumidorFinal({
  item,
  className,
  field,
  fieldConsumidorFinal,
  isTotal = false,
  isColumn = false,
}: Props) {
  const { showPrecoConsumidorFinal } = useApp();

  // const [preco, setPreco] = React.useState<number>(0);

  const precoFormatado = (preco: string | number) => {
    return parseCurrency(preco);
  };

  // efeitos
  // React.useEffect(() => {
  //   if (item) {
  //     if (showPrecoConsumidorFinal) {
  //       setPreco(item[fieldConsumidorFinal] || 0);
  //     } else {
  //       setPreco(item[field] || 0);
  //     }
  //   }
  // }, [field, fieldConsumidorFinal, item, showPrecoConsumidorFinal]);

  if (!showPrecoConsumidorFinal) {
    return <span className={className}>{precoFormatado(item[field])}</span>;
  } else {
    if (isColumn) {
      return (
        <div className={clsx('font-thin flex flex-col h-full justify-start')}>
          <span className={clsx('text-[0.8em] text-slate-400')}>
            {precoFormatado(item[field])}
          </span>
          <span className={clsx(className, 'text-end')}>
            {precoFormatado(item[fieldConsumidorFinal])}
          </span>
        </div>
      );
    }

    return (
      <div
        className={clsx(
          'font-thin',
          isTotal
            ? 'flex justify-between gap-5 min-w-fit'
            : 'flex justify-between gap-5 w-full'
        )}
      >
        <span className={clsx('text-xs text-slate-400 items-end')}>
          {precoFormatado(item[field])}
        </span>
        <span className={clsx(className, 'text-end')}>
          {precoFormatado(item[fieldConsumidorFinal])}
        </span>
      </div>
    );
  }

  // if (disableClick) {
  //   return <span className={className}>{precoFormatado}</span>;
  // }

  // return (
  //   <>
  //     <Popover className="relative">
  //       {({ open }) => (
  //         <div className="bg-red-500 w-full">
  //           <Popover.Button
  //             className={clsx(
  //               open && 'grayscale',
  //               className && className,
  //               'w-full'
  //             )}
  //           >
  //             {precoFormatado}
  //           </Popover.Button>
  //           <Transition
  //             as={Fragment}
  //             enter="transition ease-out duration-200"
  //             enterFrom="opacity-0 translate-y-1"
  //             enterTo="opacity-100 translate-y-0"
  //             leave="transition ease-in duration-150"
  //             leaveFrom="opacity-100 translate-y-0"
  //             leaveTo="opacity-0 translate-y-1"
  //           >
  //             <Popover.Panel className="absolute left-14 z-50 -top-16 w-screen max-w-fit -translate-x-1/2 transform px-4 sm:px-0">
  //               <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5 bg-white py-2 px-4 flex flex-col gap-1">
  //                 <span className="text-xs text-slate-600 whitespace-nowrap">
  //                   Preço Custo:
  //                 </span>
  //                 <span className="">{parseCurrency(item[field] || 0)}</span>
  //               </div>
  //             </Popover.Panel>
  //           </Transition>
  //         </div>
  //       )}
  //     </Popover>
  //   </>
  // );
}
