import { LogoutIcon } from '@heroicons/react/solid';
import { logoff } from '../../app.service';
import { useLocation } from 'react-router-dom';
import { classNames } from '../../utils/functions';
import InstallPWA from '../installPWA';
import { Switch } from '@headlessui/react';
import { useApp } from '../../app.hook';

interface MenuLeftBottomProps {
  orientation?: string;
}

export default function MenuLeftBottom(props: MenuLeftBottomProps) {
  const location = useLocation();
  const {
    toggleShowPrecoConsumidorFinal,
    innerShowPrecoConsumidorFinal,
    consumidorFinalAtivo,
    isPerfilManager,
  } = useApp();
  return (
    <div className="px-2 border-t md:border-default-white">
      <ul
        className={classNames(
          'w-full',
          props?.orientation === 'column'
            ? 'flex flex-col items-center justify-center gap-2'
            : 'flex items-center justify-between'
        )}
      >
        {consumidorFinalAtivo && (
          <li>
            <Switch
              checked={innerShowPrecoConsumidorFinal}
              onChange={toggleShowPrecoConsumidorFinal}
              className={`${
                innerShowPrecoConsumidorFinal ? 'bg-primary1' : 'bg-gray-400'
              } relative inline-flex h-6 w-11 items-center rounded-full`}
            >
              <span className="sr-only">Exibir Preço ao Consumidor</span>
              <span
                className={`${
                  innerShowPrecoConsumidorFinal
                    ? 'translate-x-6'
                    : 'translate-x-1'
                } inline-block h-4 w-4 transform rounded-full bg-white transition`}
              />
            </Switch>
          </li>
        )}
        {!isPerfilManager && (
          <li
            className={classNames(
              props?.orientation === 'column' ? '' : 'pt-5 pb-3 px-4',
              'cursor-pointer text-primary1 group md:text-white'
            )}
          >
            <InstallPWA />
          </li>
        )}
        <li
          className={classNames(
            props?.orientation === 'column' ? 'py-1 px-0' : 'pt-5 pb-3 px-4',
            'cursor-pointer text-primary1 group md:text-white'
          )}
        >
          <button
            className={classNames(
              'flex justify-start',
              'text-primary1 bg-default-white',
              'w-9 h-9 relative z-50',
              'rounded-full shadow',
              'md:bg-primary1 md:text-default-white',
              'hover:bg-default-white hover:text-primary1'
            )}
            title="Logoff"
            onClick={logoff}
          >
            <div className="flex justify-start items-center space-x-1">
              <span className={classNames('p-2')}>
                <LogoutIcon className="h-5 w-5" />
              </span>
            </div>
          </button>
        </li>
      </ul>
    </div>
  );
}
