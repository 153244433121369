/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useRef, useState } from 'react';
import { Popover } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/outline';

import { classNames, truncateStringWithEllipsis } from '../utils/functions';
import { usePopper } from 'react-popper';
import { Cliente } from '../app.models';
import { getClienteById, getClientes } from '../pages/cart/cart.service';
import AppPaginator from './layout/pagination';
import useMedia from 'use-media';

export interface Filter {
  display: string;
  value: any;
}

interface IProps {
  action: (clienteId: number) => void;
  clear?: () => void;
  clienteId: string;
  resetSkip?: () => void;
}

export default function SelectCliente(props: IProps) {
  const isSmall = useMedia({ maxWidth: 480 });
  const [referenceElement, setReferenceElement] = useState({} as any);
  const [popperElement, setPopperElement] = useState({} as any);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
    strategy: 'fixed' ? 'fixed' : 'absolute',
  });

  const [searchFor, setSearchFor] = useState('');
  const [clientes, setClientes] = useState<Cliente[]>([]);
  const [loading, setLoading] = useState(false);
  const [skip, setSkip] = useState(0);
  const [qtdeReg, setQtdeReg] = useState(0);
  const ref = useRef<HTMLInputElement>(null);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setRender] = useState(0);

  const [currentCliente, setCurrentCliente] = useState<Cliente>();

  useEffect(() => {
    new Promise((t) => setTimeout(t, 100)).then(
      () => ref.current && ref.current.focus()
    );
    if (props.clienteId) {
      getClienteById(props.clienteId).then(setCurrentCliente);
    }
  }, [props.clienteId]);

  useEffect(() => {
    if (searchFor.length > 2) {
      setLoading(true);
      getClientes(searchFor, 10, skip).then((data) => {
        setLoading(false);
        setQtdeReg(data.info.qtdeTotal);
        setClientes(data.clientes);
      });
    } else {
      setClientes([]);
      setSkip(0);
      setQtdeReg(0);
    }
  }, [searchFor, skip]);

  const setSelected = (clienteId: number) => {
    props.action(clienteId);
    props.resetSkip && props.resetSkip();
  };

  const clear = () => {
    setCurrentCliente(undefined);
    props.clear && props.clear();
    props.resetSkip && props.resetSkip();
  };

  return (
    <Popover>
      {({ open, close }) => {
        return (
          <>
            <Popover.Button
              className={classNames(
                'border-2 border-white border-solid',
                open ? '' : 'text-opacity-90',
                'text-sm text-gray-600 group bg-gray-200 px-3 py-2 rounded-md inline-flex items-center font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75'
              )}
              ref={setReferenceElement}
            >
              <div title={currentCliente?.nome || 'Cliente (todos)'}>
                {truncateStringWithEllipsis(
                  currentCliente?.nome || 'Cliente (todos)',
                  25
                )}
              </div>

              <ChevronDownIcon
                className={`${open ? '' : 'text-opacity-70'}
                  ml-2 h-5 w-5 text-gray-400 group-hover:text-opacity-80 transition ease-in-out duration-150`}
                aria-hidden="true"
              />
            </Popover.Button>

            <Popover.Panel
              className={classNames([
                'p-4 z-10 bg-white shadow rounded-xl flex flex-col space-y-1 text-gray-600',
              ])}
              ref={setPopperElement}
              style={styles.popper}
              {...attributes.popper}
            >
              <div className="flex flex-col font-display p-2 w-full md:w-[50vw]">
                <div className="flex justify-start space-x-1">
                  <label className="relative block">
                    <span className="sr-only">Busca</span>
                    <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5 stroke-gray-400"
                        viewBox="0 0 20 20"
                        fill="none"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                        />
                      </svg>
                    </span>
                    <input
                      className="placeholder:italic placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-gray-300 focus:ring-gray-300 focus:ring-1 sm:text-sm"
                      placeholder="Buscar por nome..."
                      type="search"
                      value={searchFor}
                      onChange={(e) => setSearchFor(e.target.value)}
                      ref={ref}
                      autoFocus={true}
                    />
                  </label>
                  {/* {currentCliente && props.clear && ( */}
                  <button
                    className="text-sm text-red-800 bg-red-300 px-3 py-2 rounded-lg hover:opacity-80"
                    onClick={() => {
                      clear();
                      close();
                    }}
                  >
                    Limpar Filtro
                  </button>
                  {/* )} */}
                </div>
                {isSmall ? (
                  loading ? (
                    <div className="flex flex-col items-center justify-center h-full">
                      Aguarde ...
                    </div>
                  ) : (
                    <div className="flex flex-col space-y-2">
                      <div className="h-[35vh] mt-2 overflow-hidden overflow-y-auto">
                        {clientes.map((item) => (
                          <div
                            key={item.id}
                            className="border-b-[1px] border-solid border-gray-300 even:bg-gray-100 hover:bg-gray-300 cursor-pointer py-3"
                            onClick={() => {
                              setCurrentCliente(item);
                              close();
                            }}
                          >
                            <div className="px-1 py-0 flex justify-between">
                              <div className="text-md text-gray-900 text-left font-bold">
                                {item.nome}
                              </div>
                            </div>
                            <div className="px-1 py-0 flex justify-between">
                              <span className="text-sm text-gray-900 text-left">
                                Apelido:
                              </span>
                              <div className="text-sm text-gray-900 text-left">
                                {item.apelido}
                              </div>
                            </div>
                            <div className="px-1 py-0 flex justify-between">
                              <span className="text-sm text-gray-900 text-left">
                                CPF/CNPJ:
                              </span>
                              <div className="text-sm text-gray-900 text-left">
                                {item.cpf_cnpj}
                              </div>
                            </div>
                            <div className="px-1 py-0 flex justify-between">
                              <span className="text-sm text-gray-900 text-left">
                                Cel:
                              </span>
                              <div className="text-sm text-gray-900 text-left">
                                {item.foneNumero}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                      {clientes.length > 9 && (
                        <AppPaginator
                          qtdeReg={qtdeReg}
                          curPage={skip}
                          perPage={10}
                          handlePageClick={({ selected }) => {
                            setSkip(selected);
                          }}
                        />
                      )}
                    </div>
                  )
                ) : (
                  <div className="h-[45vh] mt-2 overflow-hidden overflow-y-auto">
                    {loading ? (
                      <div className="flex flex-col items-center justify-center h-full">
                        Aguarde ...
                      </div>
                    ) : (
                      <div className="flex flex-col space-y-2">
                        <table className="w-full divide-y divide-gray-200">
                          <thead className="bg-gray-100 sticky top-0 z-10">
                            <tr>
                              <th
                                scope="col"
                                className="px-1 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                              >
                                Nome
                              </th>
                              <th
                                scope="col"
                                className="px-1 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                              >
                                Apelido
                              </th>
                              <th
                                scope="col"
                                className="px-1 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                              >
                                CPF/CNPJ
                              </th>
                              <th
                                scope="col"
                                className="px-1 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                              >
                                Contato
                              </th>
                            </tr>
                          </thead>
                          <tbody className="">
                            {clientes.length > 0 &&
                              clientes.map((item) => (
                                <tr
                                  key={item.id}
                                  className="border-b-[1px] border-solid border-gray-300 even:bg-gray-100 hover:bg-gray-300 cursor-pointer"
                                  onClick={() => {
                                    close();
                                    setSelected(item.id);
                                  }}
                                >
                                  {/* item */}
                                  <td className="px-1 py-0">
                                    <div className="text-sm text-gray-900 text-left">
                                      {item.nome}
                                    </div>
                                  </td>
                                  <td className="px-1 py-0">
                                    <div className="text-sm text-gray-900 text-left">
                                      {item.apelido}
                                    </div>
                                  </td>
                                  <td className="px-1 py-0">
                                    <div className="text-sm text-gray-900 text-left">
                                      {item.cpf_cnpj}
                                    </div>
                                  </td>
                                  <td className="px-1 py-0">
                                    <div className="text-sm text-gray-900 text-left">
                                      {item.foneNumero}
                                    </div>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                        {clientes.length > 9 && (
                          <AppPaginator
                            qtdeReg={qtdeReg}
                            curPage={skip}
                            perPage={10}
                            handlePageClick={({ selected }) => {
                              setSkip(selected);
                            }}
                          />
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </Popover.Panel>
          </>
        );
      }}
    </Popover>
  );
}
