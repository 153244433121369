/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

export const parseCurrency = (num: number | string | undefined): string => {
  const rawNumber: number[] = [];
  if (typeof num === 'string') {
    rawNumber[0] = Number(num.replace('.', '').replace(',', '.'));
  } else if (typeof num === 'undefined') {
    rawNumber[0] = 0;
  } else {
    rawNumber[0] = num;
  }

  return rawNumber[0].toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });
};

export const currencyToDouble = (num: string | number): number => {
  const rawNumber = [];
  if (typeof num === 'string') rawNumber.push(num);
  if (typeof num === 'number') rawNumber.push(String(num));
  return Number(rawNumber[0].replace('.', '').replace(',', '.'));
};

export const round = (number: number, decimalPlaces: number) => {
  const factorOfTen = Math.pow(10, decimalPlaces);
  return Math.round(number * factorOfTen) / factorOfTen;
};

export const parseCurrencyBRL = (value: number): string => {
  if (value)
    return value.toLocaleString('pt-br', {
      style: 'currency',
      currency: 'BRL',
    });
  else return 'R$ 0,00';
};
