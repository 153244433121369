import { SearchIcon } from '@heroicons/react/outline';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES_MAP } from '../app.map.routes';
import { useProduct } from '../pages/product/product.hook';
import { classNames } from '../utils/functions';
import { EnumOrigens } from '../utils/origens';

interface Props {
  reverseColors?: boolean;
  action?: () => void;
}

export default function ModeloSearch({ reverseColors, action }: Props) {
  const [simpleText, setSimpleText] = useState('');
  const { searchModelo, setSearchModelo, setOrigem } = useProduct();
  const navigate = useNavigate();

  useEffect(() => {
    if (!searchModelo) {
      return;
    }
    setSimpleText(searchModelo);
  }, [searchModelo]);

  const search = () => {
    setOrigem(EnumOrigens.VEICULO);
    if (!simpleText) {
      setSearchModelo(simpleText);
      navigate(`${ROUTES_MAP.product}/?busca=`);
      return;
    }
    navigate(`${ROUTES_MAP.product}/?busca=${simpleText}`);
    if (action) action();
  };
  // teste
  return (
    <div className="flex flex-col">
      <span
        className={classNames(
          'text-[0.8em] mb-2',
          reverseColors ? 'text-black' : 'text-default-white'
        )}
      >
        Pesquisar por nome do Veiculo
      </span>
      <div className="flex items-center bg-white rounded-full text-gray-600 pr-1 md:w-full md:min-w-[9vw] overflow-hidden">
        <input
          className="bg-white w-full focus:outline-none focus:shadow-none ml-2 pl-2 ring-0 outline-none border-0"
          type="search"
          placeholder="Pesquisar Veículo..."
          value={simpleText}
          onChange={(e) => setSimpleText(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              search();
            }
          }}
        />
        <span className="w-1" />
        <button onClick={search} className="hover:text-gray-400">
          <SearchIcon className="w-8 h-8 p-1" />
        </button>
      </div>
    </div>
  );
}
