import React from 'react';
import TabSerach from '../components/tabSerach';

export default function PageTest() {
  return (
    <div className="relative z-[3]">
      <TabSerach />
    </div>
  );
}
