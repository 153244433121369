import React from 'react';
import clsx from 'clsx';
import MenuMarcaModelo from './menuMarcaModelo';
import { useApp } from '../app.hook';
import ModalCategoriaSearch from './modalCategoriaSearch';
import { EnumOrigens } from '../utils/origens';
import { useProduct } from '../pages/product/product.hook';
import { useNavigate } from 'react-router-dom';
import { ROUTES_MAP } from '../app.map.routes';
import { getTake } from '../utils/functions';

export default function MultiSearchSimple() {
  const navigate = useNavigate();
  const { empresa } = useApp();
  const { setOrigem } = useProduct();
  const _take = getTake();

  const showPesquisaVeiculo = empresa?.exibirPesquisaVeiculos || false;
  const showPesquisaCategoria = empresa?.exibirPesquisaCategorias || false;

  return (
    <div className="flex justify-center w-full">
      <div
        className={clsx(
          'flex w-full justify-center items-center max-w-screen-lg',
          'z-10',
          'rounded-b-md',
          'gap-2 px-4 py-2',
          'max-w-fit',
          'bg-primary1',
          // 'bg-gradient-to-b from-slate-400 to-gray-400',
          // 'bg-[#281e5d]',
          'shadow-md'
        )}
      >
        {showPesquisaCategoria ? (
          <ModalCategoriaSearch
            // ref={refSearchCategoria}
            onSearch={(categ) => {
              setOrigem(EnumOrigens.CATEGORIA);
              // navigate(
              //   ROUTES_MAP.product +
              //     '/?categoria=' +
              //     categ.id +
              //     '&take=' +
              //     _take.toString() +
              //     `&skip=0&tags={"categorias":[${categ.id}]}`
              // );
              navigate(
                encodeURI(
                  `${
                    ROUTES_MAP.product
                  }?take=${_take.toString()}&skip=0&tags={"categorias":[${
                    categ.id
                  }]}`
                )
              );
            }}
          />
        ) : null}
        {showPesquisaVeiculo ? (
          <div className={clsx('flex gap-2 justify-between')}>
            <MenuMarcaModelo reverseColors={false} transparent />
          </div>
        ) : null}
      </div>
    </div>
  );
}
