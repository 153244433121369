import { useEffect, useState } from 'react';
import { ProdutoCart } from '../app.models';
import { parseCurrencyBRL } from '../utils/numberHandler';
import cart from '../pages/cart';
import ExibirPrecoConsumidorFinal from './ExibirPrecoConsumidorFinal';

interface TableShowListProductsProps {
  products: ProdutoCart[];
}

export default function TableShowListProducts({
  products,
}: TableShowListProductsProps) {
  const [items, setItems] = useState<ProdutoCart[]>([]);

  useEffect(() => {
    setItems(products);
  }, [products]);

  return (
    <table className="w-full divide-y divide-gray-200">
      <thead className="bg-gray-100 sticky top-0 z-[1]">
        <tr>
          <th
            scope="col"
            className="px-1 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Item
          </th>
          <th
            scope="col"
            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Produto
          </th>
          <th
            scope="col"
            className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Qtde
          </th>
          <th
            scope="col"
            className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Valor
          </th>
        </tr>
      </thead>
      <tbody className="">
        {items &&
          items.map((item, index) => (
            <tr
              key={item.id}
              className="border-b-[1px] border-solid border-gray-300"
            >
              {/* item */}
              <td className="px-1 py-4 whitespace-nowrap align-middle w-1/12">
                <div className="text-md font-bold text-gray-900 text-center">
                  {index + 1}
                </div>
              </td>
              {/* imagem/produto*/}
              <td className="px-6 py-4 align-middle w-8/12">
                <div className="flex items-center">
                  <div className="ml-4">
                    <div className="text-sm font-medium text-gray-900">
                      {item.produto.descricaoSite}
                    </div>
                    {/* <div className="text-[0.7em] text-gray-500">
                      {item.produto.descricaoSiteCompleta}
                    </div> */}
                    <div className="text-[0.7em] text-gray-500">
                      <span className="">Categoria: </span>
                      <span className="font-bold">
                        {item.produto?.categoria?.descricao}
                      </span>
                    </div>
                    <div className="text-[0.7em] text-gray-500">
                      <span className="">Referência: </span>
                      <span className="font-bold">
                        {item.produto.referencia}
                      </span>
                    </div>
                    <div className="text-[0.7em] text-gray-500">
                      <span className="">Código: </span>
                      <span className="font-bold">
                        {item.produto.codigoSgi}
                      </span>
                    </div>
                    <div>
                      <div className="relative -top-4 flex flex-col justify-start my-0 py-2">
                        <div className="flex my-3 justify-end">
                          <span className="flex space-x-2 font-bold text-xl">
                            {/* <span>
                              {parseCurrencyBRL(item.unitarioLiquido)}
                            </span> */}
                            <ExibirPrecoConsumidorFinal
                              item={item}
                              field="unitarioLiquido"
                              fieldConsumidorFinal="unitarioLiquidoConsumidorFinal"
                              isTotal
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
              {/* qtde */}
              <td className="px-6 py-4 whitespace-nowrap align-middle w-2/12">
                <div className="text-lg text-center">{item.qtde}</div>
              </td>
              {/* valor */}
              <td className="px-6 py-4 whitespace-nowrap text-lg font-bold text-gray-500 align-middle w-1/12 text-right">
                {/* {parseCurrencyBRL(item.totalItem)} */}
                <ExibirPrecoConsumidorFinal
                  item={item}
                  field="totalItem"
                  fieldConsumidorFinal="totalItemConsumidorFinal"
                  isTotal
                />
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  );
}
