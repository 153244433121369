import { useCallback, useEffect, useState } from 'react';
import { Switch, Tab } from '@headlessui/react';
import {
  OfficeBuildingIcon,
  ChartPieIcon,
  SaveIcon,
} from '@heroicons/react/outline';
import clsx from 'clsx';
import { Controller, useForm } from 'react-hook-form';
import { IClienteMarkup } from '../markup.interface';
import {
  addClienteMarkup,
  altCliente,
  altClienteMarkup,
  delClienteMarkup,
} from '../service';
import { toast } from 'react-toastify';
import { Cliente } from '../../../app.models';
import ListCategorias from './ListCategorias';
import MaskedInput from 'react-text-mask';
import getMask, { somenteNumero } from '../../../utils/masks';
import ModalConfirmationChangeForm from '../../../components/modalConfirmationChangeForm';
import { useCallbackPrompt } from '../../../utils/useCallbackPrompt';
import ColorChange from '../../../components/colorChange';

const tabClass = (selected: boolean, disabled = false) =>
  clsx(
    'rounded-lg text-xl font-medium leading-5 h-16 w-full max-w-[50%]',
    'ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
    disabled
      ? 'text-slate-300'
      : selected
      ? 'bg-primary1 shadow text-white'
      : 'text-primary1 hover:bg-white/[0.12] hover:text-primary2'
  );

const tabPanelClass = () =>
  clsx(
    'rounded-xl bg-slate-100',
    'ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
    'shadow-md',
    'overflow-y-auto overflow-hidden',
    'h-[calc(100vh-14rem)] p-4'
  );

interface Props {
  qtde?: number;
  clientMarkups: IClienteMarkup[];
  reloadMarkups: () => void;
  reloadClient: () => void;
  currentClient: Cliente;
}

export default function TabsProfile({
  qtde,
  clientMarkups,
  reloadMarkups,
  reloadClient,
  currentClient,
}: Props) {
  const [currentMarkups, setCurrentMarkups] = useState<IClienteMarkup>(
    {} as IClienteMarkup
  );
  const formEmpresa = useForm<Cliente>({
    defaultValues: currentClient,
  });

  const [selectedIndex, setSelectedIndex] = useState(0);

  const exibirPrecoConsumidor = formEmpresa.watch('exibirPrecoConsumidor');
  const markupPorCategoria = formEmpresa.watch('markupPorCategoria');

  const setMarkups = useCallback(
    (markup: IClienteMarkup, isEdit: boolean) => {
      if (isEdit) {
        altClienteMarkup(
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          currentClient.id!,
          markup.id,
          markup.markup,
          markup.idTabela,
          markup.updated_at.toString()
        )
          .catch((e) => {
            const msg =
              e.response?.data?.message ||
              e.message ||
              'Erro ao atualizar empresa';
            toast.error(msg);
          })
          .finally(() => {
            reloadMarkups();
          });
      } else {
        // inserir novo markup
        addClienteMarkup(
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          currentClient.id!,
          markup.id,
          markup.markup
        )
          .catch((e) => {
            const msg =
              e.response?.data?.message ||
              e.message ||
              'Erro ao atualizar empresa';
            toast.error(msg);
          })
          .finally(() => {
            reloadMarkups();
          });
      }
      //limpar currentMarkups
      setCurrentMarkups({} as IClienteMarkup);
    },
    [currentClient.id, reloadMarkups]
  );

  const deleteMarkup = useCallback(
    (markupId: number, updated_at: string) => {
      console.log('deleteMarkup', markupId, updated_at);
      delClienteMarkup(markupId.toString(), updated_at)
        .then(() => {
          setCurrentMarkups({} as IClienteMarkup);
        })
        .catch((e) => {
          const msg =
            e.response?.data?.message ||
            e.message ||
            'Erro ao atualizar empresa';
          toast.error(msg);
        })
        .finally(() => {
          reloadMarkups();
        });
    },
    [reloadMarkups]
  );

  useEffect(() => {
    formEmpresa.reset(currentClient);
  }, [currentClient, formEmpresa]);

  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(showDialog);

  useEffect(() => {
    setShowDialog(formEmpresa.formState.isDirty);
  }, [formEmpresa.formState]);

  const submit = (data: Cliente) => {
    altCliente(data)
      .then(() => {
        // reload client
        // mensagens.sucesso('Empresa atualizada com sucesso');
        formEmpresa.reset(data);
        toast.success('Empresa atualizada com sucesso');
      })
      .catch((e) => {
        const msg =
          e.response?.data?.message || e.message || 'Erro ao atualizar empresa';
        toast.error(msg);
      })
      .finally(() => {
        setShowDialog(false);
        confirmNavigation;
        reloadClient();
      });
  };

  const corAlternativa = formEmpresa.watch('corAlternativaPrecoConsumidor');
  const tel = formEmpresa.watch('foneNumero');
  const [mask, setMask] = useState<any>(getMask('telefone-10'));

  useEffect(() => {
    if (tel && somenteNumero(tel).length > 10) {
      setMask(getMask('telefone-12'));
    }
    setMask(getMask('telefone-10'));
  }, [tel]);
  return (
    <>
      <Tab.Group selectedIndex={selectedIndex} manual>
        <Tab.List className="flex space-x-1 rounded-xl bg-gray-100 p-1">
          <Tab className={({ selected }) => tabClass(selected)}>
            <div
              onClick={() => setSelectedIndex(0)}
              className="flex gap-3 items-center px-4"
            >
              <span className="flex items-center justify-center rounded-full p-2 bg-white border border-primary1 border-solid">
                <OfficeBuildingIcon className={`w-6 h-6 text-primary1 `} />
              </span>
              <span>Cliente</span>
            </div>
          </Tab>
          {exibirPrecoConsumidor && (
            <Tab
              className={({ selected }) => tabClass(selected)}
              disabled={!markupPorCategoria}
            >
              <div
                onClick={() => setSelectedIndex(1)}
                className={clsx(
                  'flex gap-3 items-center px-4',
                  !markupPorCategoria && 'grayscale'
                )}
              >
                <span
                  className={`flex items-center justify-center rounded-full p-2 bg-white border border-primary1 border-solid`}
                >
                  <ChartPieIcon className={`w-6 h-6 text-primary1 `} />
                </span>
                <span>Margem de Lucro por Categoria</span>
              </div>
            </Tab>
          )}
        </Tab.List>
        <Tab.Panels className="mt-2">
          <Tab.Panel className={() => tabPanelClass()}>
            <form
              onSubmit={formEmpresa.handleSubmit(submit)}
              className="grid grid-cols-1 gap-4"
            >
              <div className="grid grid-cols-2 gap-4">
                {/* razao social */}
                <label className="block">
                  <span className="text-gray-700">Razão Social</span>
                  <input
                    type="text"
                    className="form-input"
                    placeholder=""
                    {...formEmpresa.register('nome')}
                  />
                </label>
                {/* nome fantasia */}
                <label className="block">
                  <span className="text-gray-700">Nome Fantasia</span>
                  <input
                    type="text"
                    className="form-input"
                    placeholder=""
                    {...formEmpresa.register('apelido')}
                  />
                </label>
              </div>
              <div className="grid grid-cols-2 gap-4">
                {/* telefone */}
                <label className="block">
                  <span className="text-gray-700">Telefone</span>
                  <Controller
                    name="foneNumero"
                    control={formEmpresa.control}
                    render={({ field }) => (
                      <MaskedInput
                        mask={mask as any}
                        type="text"
                        className="form-input"
                        placeholder=""
                        onKeyPress={(e) => {
                          // verificar o tamanho do texto
                          if (
                            somenteNumero(e.currentTarget.value).length === 10
                          ) {
                            setMask(getMask('telefone-11'));
                          }
                        }}
                        {...field}
                      />
                    )}
                  />
                </label>

                {/* exibir preço consumidor */}
                <label className="flex justify-between items-center">
                  <span className="text-gray-700">
                    Exibir Preço ao Consumidor
                  </span>
                  <Controller
                    name="exibirPrecoConsumidor"
                    control={formEmpresa.control}
                    render={({ field }) => (
                      <Switch
                        checked={field.value}
                        onChange={field.onChange}
                        className={`${
                          field.value ? 'bg-primary1' : 'bg-gray-500'
                        } relative inline-flex h-6 w-11 items-center rounded-full`}
                      >
                        <span className="sr-only">
                          Exibir Preço ao Consumidor
                        </span>
                        <span
                          className={`${
                            field.value ? 'translate-x-6' : 'translate-x-1'
                          } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                        />
                      </Switch>
                    )}
                  />
                </label>
              </div>
              {exibirPrecoConsumidor && (
                <fieldset className="border border-gray-200 border-solid rounded-md bg-white rounded-tl-none">
                  <legend className="px-4 py-2 bg-slate-500 text-white rounded-md border border-gray-500 border-solid">
                    Margem de Lucro
                  </legend>
                  <div className="grid grid-cols-2 gap-4 px-2 py-4">
                    {/* margen de lucro padrao */}
                    <div className="space-y-2">
                      <div className="grid grid-cols-1 gap-2">
                        <label className="block">
                          <span className="text-gray-700">
                            Margem de Lucro Padrão (%)
                          </span>
                          <input
                            type="number"
                            className={clsx(
                              'form-input',
                              formEmpresa.formState.errors.markupDefault
                                ? 'bg-red-100'
                                : ''
                            )}
                            placeholder=""
                            min={0}
                            max={1000}
                            {...formEmpresa.register('markupDefault', {
                              valueAsNumber: true,
                              required: true,
                              min: 100,
                              max: 1000,
                            })}
                          />
                        </label>
                        {/* cor alternativa */}
                        <label className="block">
                          <span className="text-gray-700">
                            Cor Alternativa Preço ao Consumidor
                          </span>
                          {/* <select
                            style={{
                              backgroundColor: corAlternativa,
                              color: corAlternativa ? 'white' : 'black',
                            }}
                            className="form-input"
                            {...formEmpresa.register(
                              'corAlternativaPrecoConsumidor'
                            )}
                          >
                            <option value="">Selecione uma cor ...</option>
                            {[
                              '#B71C1C',
                              '#651FFF',
                              '#1A237E',
                              '#004D40',
                              '#F57F17',
                              '#3E2723',
                            ].map((cor, idx) => {
                              return (
                                <option
                                  style={{ backgroundColor: cor }}
                                  key={idx}
                                  value={cor}
                                >
                                  {cor}
                                </option>
                              );
                            })}
                          </select> */}
                          <ColorChange
                            colors={[
                              '#B71C1C',
                              '#651FFF',
                              '#1A237E',
                              '#004D40',
                              '#F57F17',
                              '#3E2723',
                              '#000000',
                              '#B0B7B0',
                            ]}
                            value={corAlternativa}
                            onChange={(value) =>
                              formEmpresa.setValue(
                                'corAlternativaPrecoConsumidor',
                                value
                              )
                            }
                          />
                        </label>
                      </div>
                    </div>
                    <div className="flex flex-col gap-4">
                      {/* controlar margem por categoria */}
                      <label className="flex justify-between items-center">
                        <span className="text-gray-700">
                          Controlar Margem de Lucro por Categoria
                        </span>
                        <Controller
                          name="markupPorCategoria"
                          control={formEmpresa.control}
                          render={({ field }) => (
                            <Switch
                              checked={field.value}
                              onChange={field.onChange}
                              className={`${
                                field.value ? 'bg-primary1' : 'bg-gray-500'
                              } relative inline-flex h-6 w-11 items-center rounded-full`}
                            >
                              <span className="sr-only">
                                Exibir Preço ao Consumidor
                              </span>
                              <span
                                className={`${
                                  field.value
                                    ? 'translate-x-6'
                                    : 'translate-x-1'
                                } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                              />
                            </Switch>
                          )}
                        />
                      </label>
                      <button
                        type="button"
                        className="btn btn-primary max-w-fit disabled:grayscale"
                        disabled={!markupPorCategoria}
                        onClick={() => setSelectedIndex(1)}
                      >
                        <SaveIcon className="w-4 h-4" />
                        <span>Gerenciar Categorias</span>
                      </button>
                    </div>
                  </div>
                </fieldset>
              )}
              <div className="my-2">
                <button
                  type="submit"
                  className="btn btn-primary max-w-fit"
                  disabled={formEmpresa.formState.isSubmitting}
                >
                  <SaveIcon className="w-4 h-4" />
                  <span>Salvar</span>
                </button>
              </div>
            </form>
          </Tab.Panel>
          <Tab.Panel className={() => tabPanelClass()}>
            <ListCategorias
              markups={clientMarkups}
              setMarkup={setMarkups}
              resetMarkup={deleteMarkup}
            />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
      <ModalConfirmationChangeForm
        isOpen={showPrompt as boolean}
        setIsOpen={setShowDialog}
        title="Alterações pendentes"
        question="Existem alterações NÃO salvas, deseja sair mesmo assim?"
        confirm={confirmNavigation as any}
        cancel={cancelNavigation as any}
      />
    </>
  );
}
