import { useCallback, useEffect, useMemo, useState } from 'react';
import { Usuario } from '../../app.models';
import { getApiVersion, getAuthUser } from '../../app.service';
import { useApp } from '../../app.hook';
import { getClienteMarkups } from './service';
import InfoClient from './components/InfoClient';
import TabsProfile from './components/TabsProfile';

export default function MyProfile() {
  const [user, setUser] = useState<Usuario | null>();
  const [apiVersion, setApiVersion] = useState<Record<string, string>>({});
  const {
    isPerfilManager,
    isPerfilAdmin,
    categorias,
    categoriasRaw,
    currentClientAtivo,
  } = useApp();

  const [currentClient, setCurrentClient] = useState<any>(null);
  const [clientMarkups, setClientMarkups] = useState<any[]>([]);

  const loadClient = useCallback(async () => {
    const _user = getAuthUser();
    getApiVersion().then(setApiVersion);
    setUser(_user);
    setCurrentClient(currentClientAtivo);
    console.log('🚀 ~ loadClient ~ currentClientAtivo:', currentClientAtivo);
  }, [currentClientAtivo]);

  const loadMarkups = useCallback(() => {
    getClienteMarkups(currentClient.id).then((markups) => {
      const clientMarkups = markups.map((m: any) => ({
        ...categoriasRaw.find((c) => c.id === m.categoriaId),
        idTabela: m.id,
        markup: m.markup,
        updated_at: m.updated_at,
        markupId: m.id,
      }));

      setClientMarkups(clientMarkups);
    });
  }, [categoriasRaw, currentClient]);

  // insere valores default
  useEffect(() => {
    loadClient();
  }, [loadClient]);

  // carrega os markups do cliente e formulario da empresa
  useEffect(() => {
    if (currentClient) {
      loadMarkups();
    }
  }, [categorias, categoriasRaw, currentClient, loadMarkups]);

  const Info = useMemo(
    () => (
      <InfoClient
        client={currentClient}
        user={user}
        apiVersion={apiVersion}
        isPerfilManager={isPerfilManager}
      />
    ),
    [apiVersion, currentClient, isPerfilManager, user]
  );

  if (isPerfilManager || isPerfilAdmin) {
    return (
      <div className="grid grid-cols-[1fr_4fr] gap-3">
        <div className="w-full">{Info}</div>
        <div className="py-9 px-4">
          <TabsProfile
            currentClient={currentClient || {}}
            clientMarkups={clientMarkups}
            reloadMarkups={loadMarkups}
            reloadClient={() => {
              window.location.reload();
            }}
            qtde={clientMarkups.length}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="w-full flex justify-center">
      <div className="w-full max-w-fit">{Info}</div>
    </div>
  );
}
