import { useEffect } from 'react';
import Carts from './carts';
import { useCarts } from './carts.hook';

export default function MyCarts() {
  const { loadCarts } = useCarts();
  useEffect(() => {
    loadCarts();
  }, [loadCarts]);

  return <Carts />;
}
