// import { OrderProvider } from './order.hook';
import { useEffect } from 'react';
import { useOrder } from './order.hook';
import Orders from './orders';

export default function MyOrders() {
  const { loadOrders } = useOrder();
  useEffect(() => {
    loadOrders();
  }, [loadOrders]);
  return <Orders />;
}
