import { Popover, Transition } from '@headlessui/react';
import { XIcon, SearchCircleIcon } from '@heroicons/react/outline';
import React, { Fragment, useMemo } from 'react';
import { ReactSVG } from 'react-svg';
import MenuLeft from './menuLeft';
import MenuLeftBottom from './menuLeftBottom';

export default function MenuMultibusca() {
  const buttomMenu = useMemo(
    () => (
      <div className="flex items-center justify-center">
        <Popover.Button className="flex items-center justify-center gap-2 px-3 text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
          <SearchCircleIcon className="h-10 w-10" aria-hidden="true" />
          <span className="">Multi-busca</span>
        </Popover.Button>
      </div>
    ),
    []
  );
  return (
    <Popover className="w-full absolute top-28 shadow z-50 bg-white">
      <div className="h-[5rem]">
        <div className="md:hidden p-2">
          <div className="pt-5 px-5">{buttomMenu}</div>
        </div>
      </div>
      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel
          focus
          className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
        >
          {({ close }) => (
            <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
              <div className="pt-5 px-5">{buttomMenu}</div>
              {/* content */}
              <div className="h-[60vh]"></div>
            </div>
          )}
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}
