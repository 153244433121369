import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { Fragment, useState, useRef, useEffect } from 'react';
import Button from './layout/button';
import { Usuario } from '../app.models';
import { toast } from 'react-toastify';
import MaskedInput from 'react-text-mask';
import getMask from '../utils/masks';

interface modalConfirmationNumberWhatsappProps {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  currentUser?: Usuario | undefined;
  action: (number: string) => void;
}

export default function ModalConfirmationNumberWhatsapp(
  props: modalConfirmationNumberWhatsappProps
) {
  // const ref = useRef<HTMLInputElement>(null);
  function closeModal() {
    props.setIsOpen(false);
  }

  function raplacePhone(phone: string) {
    if (!phone) return '';
    return phone.replace(/\D/g, '');
  }

  function phoneValidate(phone: string) {
    const regex = RegExp(
      /^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)(?:((?:9\s?\d|[2-9])\d{3})\-?(\d{4}))$/gm
    );

    return regex.test(phone);
  }

  const confirm = () => {
    if (!phoneValidate(number)) {
      toast.error('Número inválido');
      return;
    }
    const formatedNumber = raplacePhone(number);
    props.action('55' + formatedNumber);
    closeModal();
  };

  const [number, setNumber] = useState<string>(
    props.currentUser ? raplacePhone(props.currentUser.telefone) : ''
  );

  return (
    <div>
      <Transition appear show={props.isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={closeModal}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black opacity-20" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-xl p-0 my-1 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <Dialog.Title
                  as="h3"
                  className="flex justify-between mb-2 bg-primary2 text-default-white px-4 py-2"
                >
                  <span className="text-lg font-bold leading-6 font-display">
                    Confirmar número de envio para o WhatsApp
                  </span>
                  <button onClick={closeModal} className="hover:text-gray-400">
                    <XIcon className="w-5 h-5" />
                  </button>
                </Dialog.Title>
                <div className="flex flex-col font-display py-2 px-6">
                  <div className="flex flex-col w-full">
                    <label
                      htmlFor="nome"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Whatsapp
                    </label>
                    <div className="mt-1 relative shadow-sm">
                      <MaskedInput
                        mask={getMask('celular')}
                        type="text"
                        autoFocus={true}
                        className="form-input px-3 py-2 w-full rounded-md bg-white text-gray-700 border-gray-200 border-2 focus:outline-none focus:bg-white focus:border-gray-500"
                        placeholder="Número do Whatsapp"
                        autoComplete="off"
                        value={number}
                        onChange={(e) => setNumber(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="flex justify-between mt-4">
                    <Button
                      title="Fechar"
                      type="button"
                      onClick={closeModal}
                      color="bg-red-500 text-white hover:bg-red-400 hover:text-gray-100"
                    />
                    <Button
                      title="Enviar"
                      type="button"
                      onClick={confirm}
                      color="bg-green-500 text-white hover:bg-green-400 hover:text-gray-100"
                    />
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}
