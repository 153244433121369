import {
  MinusCircleIcon,
  PlusCircleIcon,
  TrashIcon,
} from '@heroicons/react/outline';
import { InputHTMLAttributes } from 'react';
import { DebounceInput } from 'react-debounce-input';

interface InputQtdeMinProps extends InputHTMLAttributes<HTMLInputElement> {
  action: (qtde: number) => void;
  delAction: () => void;
  initialValue: number;
}

export default function InputQtdeMin(props: InputQtdeMinProps) {
  return (
    <div className="flex flex-col space-y-[2px] items-center my-1">
      <div className="flex h-6">
        <button
          type="button"
          className="rounded-l-full py-1 px-2 bg-primary2 text-default-white hover:bg-primary1 hover:text-gray-50"
          onClick={() => {
            const qtde = props.initialValue - 1;
            if (qtde > 0) props.action(qtde);
          }}
        >
          <MinusCircleIcon className="w-4 h-4 " />
        </button>
        <DebounceInput
          type="text"
          pattern="[0-9]*"
          debounceTimeout={500}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onKeyPress={(event: any) => {
            if (!/[0-9]/.test(event.key)) {
              event.preventDefault();
            }
          }}
          className="w-10 px-2 py-1 bg-gray-300 text-center text-xs"
          value={props.initialValue}
          onChange={(e) =>
            e.target.value && props.action(parseInt(e.target.value))
          }
        />
        <button
          type="button"
          className="rounded-r-full py-1 px-2 bg-primary2 text-default-white hover:bg-primary1 hover:text-gray-50"
          onClick={() => {
            props.action(props.initialValue + 1);
          }}
        >
          <PlusCircleIcon className="w-4 h-4" />
        </button>
      </div>
      <div className="w-24">
        <button
          type="button"
          className="text-primary1 flex justify-center items-center hover:text-primary3"
          onClick={props.delAction}
        >
          <TrashIcon className="w-3 h-3" />
          <span className="text-[0.7em]">Remover item</span>
        </button>
      </div>
    </div>
  );
}
