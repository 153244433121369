import { UserIcon, BriefcaseIcon } from '@heroicons/react/outline';
import {
  truncateString,
  truncateStringWithEllipsis,
} from '../../../utils/functions';
import { useApp } from '../../../app.hook';
import CurrentClient from '../../../components/currentCliente';

interface Props {
  user: any;
  client: any;
  apiVersion: any;
  isPerfilManager: boolean;
}

export default function InfoClient({
  user,
  client,
  apiVersion,
  isPerfilManager,
}: Props) {
  // const { updateDefaultCli } = useApp();

  return (
    <div className="px-6 py-10">
      <div className="flex flex-col item-center gap-3 px-6 py-10 bg-primary1 rounded-t-md">
        <div className="mx-auto h-20 w-20 p-3 rounded-full ring-4 ring-white text-white shadow-lg drop-shadow">
          <UserIcon className="" />
        </div>
        <div className="text-white font-bold text-3xl text-center">
          <span>{user?.nome.toUpperCase()}</span>
        </div>
        <hr />
        <div className="text-white flex flex-col">
          <span>{user?.email}</span>
        </div>
        <div className="text-white flex space-x-4">
          <span>Perfil: </span>
          <span>{user?.perfil}</span>
        </div>
      </div>
      <div className="flex flex-col item-center gap-3 px-6 py-1 bg-gray-500 rounded-b-md">
        <div className="flex space-x-4 items-center mt-6">
          <div className="h-8 w-8 p-1 rounded-full ring-4 ring-white text-white  shadow-lg drop-shadow">
            <BriefcaseIcon className="w-full h-full" />
          </div>
          <h1 className="text-white font-bold text-3xl flex flex-col">
            <span>Cliente</span>
          </h1>
        </div>
        {/* {isPerfilManager ? (
          <>
            <div className="bg-primary1 rounded-md">
              <CurrentClient hiddenIcon />
            </div>
          </>
        ) : ( */}
        <div className="text-white flex flex-col">
          <span className="font-bold">
            {truncateString(client?.nome ?? '', 20)}
          </span>
        </div>
        {/* )} */}

        <hr />
        <div className="text-white flex gap-4 mb-2">
          <span>Api Versão: {apiVersion?.versao ?? ''}</span>
          <span>App Versão: {process.env?.REACT_APP_VERSION ?? ''}</span>
        </div>
      </div>
    </div>
  );
}
