import { IMenu, MENU_ITEMS } from './menu';
import { ChevronDownIcon } from '@heroicons/react/outline';
import { Disclosure } from '@headlessui/react';
import { useNavigate } from 'react-router-dom';
// import ReactTooltip from 'react-tooltip';
// import MenuSwitch from './menuSwitch';
import { classNames, isMenuActive } from '../../utils/functions';
import { useApp } from '../../app.hook';

const menuSimpleClass = (active: boolean, containTitle = true) =>
  classNames(
    'flex justify-start',
    'text-primary1 md:text-default-white',
    'w-10 h-10 relative z-50 transition-all duration-300 ease-in-out ml-2',
    containTitle && 'group-hover:w-full group-hover:left-0',
    'rounded-full shadow',
    !active && 'hover:bg-slate-200',
    active ? 'text-default-white bg-primary1' : 'bg-default-white text-primary1'
  );
const menuSubClass =
  'flex flex-col w-full text-primary1 md:text-default-white cursor-pointer items-center my-4 hover:opacity-50';

const MenuSimple = (menu: IMenu) => {
  const navigate = useNavigate();
  return (
    <div className="group flex flex-col justify-center">
      <button
        className={menuSimpleClass(
          isMenuActive(menu.link, location.pathname),
          menu.title !== ''
        )}
        onClick={() => {
          if (location.pathname === menu.link.trim()) return;
          if (!menu.link) return;
          navigate(menu.link);
          menu.onClick && menu.onClick();
        }}
      >
        <div className="flex justify-start items-center space-x-1">
          <span
            className={classNames(
              isMenuActive(menu.link, location.pathname)
                ? 'text-default-white'
                : ' text-primary1',
              'p-2'
            )}
            data-tip={menu.title}
          >
            {menu.icon}
          </span>
          {menu.title && (
            <span
              className={classNames(
                'hidden group-hover:flex',
                isMenuActive(menu.link, location.pathname)
                  ? 'text-default-white'
                  : 'text-primary1',
                'pr-4 whitespace-nowrap',
                'transition-transform duration-300 ease-in-out'
              )}
            >
              {menu.title}
            </span>
          )}
        </div>
      </button>
      <div className="h-3">
        <h1
          className={classNames(
            'text-[0.6em] w-full text-center group-hover:hidden my-[3px] text-white hover:pointer-events-none',
            isMenuActive(menu.link, location.pathname)
              ? 'text-primary1'
              : 'text-white'
          )}
        >
          {menu.title}
        </h1>
      </div>
    </div>
  );
};

const MenuSub = (menu: IMenu) => {
  return (
    <Disclosure>
      {({ open }) => (
        <>
          <Disclosure.Button className={menuSimpleClass(false)}>
            <div className="flex items-center space-y-1 mx-auto">
              <span
                className={`p-2 ${
                  isMenuActive(menu.link, location.pathname)
                    ? 'text-primary1 bg-default-white'
                    : 'bg-primary1 text-default-white'
                } rounded-full shadow`}
                data-tip={menu.title}
              >
                {menu.icon}
              </span>
            </div>
            <div className="mx-auto">
              {open ? <ChevronDownIcon className="w-6 h-6" /> : null}
            </div>
          </Disclosure.Button>
          <Disclosure.Panel>
            <ul className="flex flex-col">
              {menu.subMenu.map((submenu, subIdx) => (
                <li key={subIdx} className={menuSubClass}>
                  {submenu.subMenu.length === 0 ? (
                    <MenuSimple {...submenu} />
                  ) : (
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    <MenuSub {...submenu} />
                  )}
                </li>
              ))}
            </ul>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

interface MenuLeftMinProps {
  menuMin: boolean;
  setMenuMin: (value: boolean) => void;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function MenuLeftMin(props: MenuLeftMinProps) {
  const { isPerfilManager, isPerfilAdmin } = useApp();

  return (
    <div className="grid grid-cols-1">
      {/* <div className="py-1">
        <MenuSwitch checked={props.menuMin} onChange={props.setMenuMin} />
      </div> */}
      {/* <ReactTooltip delayShow={100} /> */}
      <div className="w-full items-center h-12 hidden md:flex mt-1 hover:opacity-50">
        {/* <button
          type="button"
          onClick={() => history.push('/')}
          className={classNames('text-default-white fill-current w-full')}
        >
          <ReactSVG src={logoSvg} width={16} />
        </button> */}
      </div>
      <ul className="mt-6 pb-6 flex flex-col items-start space-y-3 mx-1">
        {MENU_ITEMS.map((item, idx) => {
          if (item.isAdmin && !isPerfilAdmin) {
            return null;
          }

          if (item.isManage && !isPerfilManager) {
            return null;
          }

          return (
            <li key={idx}>
              {/* <MenuSimple {...item} /> */}
              {item.subMenu.length > 0 ? (
                <MenuSub {...item} />
              ) : (
                <MenuSimple {...item} />
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
}
