// import { useProduct } from './hook';

import FiltersText from './filtersText';

interface ProductsTextFilterProps {
  handlerPage?: (page: number) => void;
}

export default function ProductsTextFilter(props: ProductsTextFilterProps) {
  return (
    <div className="">
      <div className="h-full md:shadow md:h-[88vh] md:overflow-hidden md:overflow-y-auto">
        <FiltersText handlerPage={props.handlerPage} />
      </div>
    </div>
  );
}
