import React, { Fragment, useEffect } from 'react';
import { Transition, Dialog } from '@headlessui/react';
import { FilterIcon, XCircleIcon, XIcon } from '@heroicons/react/outline';
import { IParams, IParamsType } from '../metrics';
import moment from 'moment';

interface Props {
  open: boolean;
  params: IParams;
  closeModal(): void;
  onApply(params: Partial<IParams>): void;
  onClear(params: Partial<IParams>): void;
}

export default function ModalFilter({
  params,
  open,
  closeModal,
  onApply,
  onClear,
}: Props) {
  const [type, setType] = React.useState<IParamsType>('competencia');
  const [mes, setMes] = React.useState<string | undefined>();
  const [ano, setAno] = React.useState<string | undefined>();
  const [dataI, setDataI] = React.useState<string | undefined>();
  const [dataF, setDataF] = React.useState<string | undefined>();

  useEffect(() => {
    setType(params.type ?? 'competencia');
    setMes(params.mes ?? moment().format('MM'));
    setAno(params.ano ?? moment().format('YYYY'));
    setDataI(params.dataI);
    setDataF(params.dataF);
  }, [params]);

  function apply() {
    onApply({
      ano,
      mes,
      dataI,
      dataF,
      type,
    });
    closeModal();
  }

  function clear() {
    onClear({
      ano: moment().format('YYYY'),
      mes: moment().format('MM'),
      dataI: undefined,
      dataF: undefined,
      type: 'competencia',
    });
    closeModal();
  }

  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={closeModal}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-20" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-lg p-0 my-1 overflow-hidden text-left align-middle transition-all transform bg-gradient-to-tr to-white from-slate-200 shadow-xl rounded-2xl">
              {/* <Dialog.Title
                as="h3"
                className="flex justify-between mb-2 bg-primary2 text-default-white px-4 py-2"
              >
                <span className="text-lg font-bold leading-6 font-display">
                  Filtros
                </span>
                <button onClick={closeModal} className="hover:text-gray-400">
                  <XIcon className="w-5 h-5" />
                </button>
              </Dialog.Title> */}
              <div className="flex flex-col font-display p-2">
                <div className="flex gap-2">
                  <span
                    className={`flex-1 truncate text-xs px-2 py-1 font-bold ${
                      type === 'competencia'
                        ? 'bg-primary2 text-default-white'
                        : 'bg-slate-300 text-slate-800'
                    } rounded-md cursor-pointer`}
                    onClick={() => setType('competencia')}
                  >
                    Por Competência
                  </span>
                  <span
                    className={`flex-1 truncate text-xs px-2 py-1 font-bold ${
                      type === 'periodo'
                        ? 'bg-primary2 text-default-white'
                        : 'bg-slate-300 text-slate-800'
                    } rounded-md cursor-pointer`}
                    onClick={() => setType('periodo')}
                  >
                    Por Período
                  </span>
                </div>
                {type === 'competencia' && (
                  <div className="grid grid-cols-[1fr_2fr_2fr] gap-3 py-2 content-center">
                    <span className="flex items-center">Competência</span>
                    <select
                      value={mes}
                      onChange={(e) => setMes(e.target.value)}
                      className="border border-solid border-slate-200 rounded-md px-2 py-1 text-sm"
                    >
                      {[
                        { value: '01', label: 'Janeiro' },
                        { value: '02', label: 'Fevereiro' },
                        { value: '03', label: 'Março' },
                        { value: '04', label: 'Abril' },
                        { value: '05', label: 'Maio' },
                        { value: '06', label: 'Junho' },
                        { value: '07', label: 'Julho' },
                        { value: '08', label: 'Agosto' },
                        { value: '09', label: 'Setembro' },
                        { value: '10', label: 'Outubro' },
                        { value: '11', label: 'Novembro' },
                        { value: '12', label: 'Dezembro' },
                      ].map((el) => (
                        <option key={el.value} value={el.value}>
                          {el.label}
                        </option>
                      ))}
                    </select>
                    <input
                      type="text"
                      value={ano}
                      onChange={(e) => setAno(e.target.value)}
                      className="border border-solid border-slate-200 rounded-md px-2 py-1 text-sm"
                    />
                  </div>
                )}
                {type === 'periodo' && (
                  <div className="grid grid-cols-[1fr_4fr] gap-3 py-2 content-center">
                    <span className="flex items-center">Período</span>
                    <div className="w-full grid grid-cols-2 gap-1 justify-end">
                      <input
                        type="date"
                        value={dataI}
                        onChange={(e) => setDataI(e.target.value)}
                        className="border border-solid border-slate-200 rounded-md px-2 py-1 text-sm"
                      />
                      <input
                        type="date"
                        value={dataF}
                        onChange={(e) => setDataF(e.target.value)}
                        className="border border-solid border-slate-200 rounded-md px-2 py-1 text-sm"
                      />
                    </div>
                  </div>
                )}
                <div className="flex justify-between mt-8 w-full">
                  <div className="flex gap-1">
                    <button
                      className="flex gap-1 justify-center bg-slate-400 hover:bg-slate-500 hover:text-green-100 text-default-white rounded-md px-2 py-1 text-sm"
                      onClick={closeModal}
                    >
                      <XIcon className="w-5 h-5" />
                      <span>Fechar</span>
                    </button>
                  </div>
                  <div className="flex gap-1">
                    <button
                      className="flex gap-1 justify-center bg-amber-400 hover:bg-amber-500 hover:text-green-100 text-default-white rounded-md px-2 py-1 text-sm"
                      onClick={clear}
                    >
                      <XCircleIcon className="w-5 h-5" />
                      <span>Limpar Filtros</span>
                    </button>
                    <button
                      className="flex gap-1 justify-center bg-green-400 hover:bg-green-500 hover:text-green-100 text-default-white rounded-md px-2 py-1 text-sm"
                      onClick={apply}
                    >
                      <FilterIcon className="w-5 h-5" />
                      <span>Filtrar</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}
