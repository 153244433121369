// import { useProduct } from './hook';

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useApp } from '../../app.hook';
import { ROUTES_MAP } from '../../app.map.routes';
// import FiltersCategorias from './filtersCategorias';
import FiltersText from './filtersText';
import { useProduct } from './product.hook';
import ShowCategorias from './showCategorias';
import { getTake } from '../../utils/functions';

export default function ProductsCategoriasFilter() {
  // const isSmall = useMedia({ maxWidth: 480 });
  const navigate = useNavigate();
  const { categorias } = useApp();
  const { selectedCategoria } = useProduct();
  const _take = getTake();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [categs, setCategs] = useState<any[]>([]);

  useEffect(() => {
    setCategs(categorias);
  }, [categorias]);

  if (selectedCategoria && selectedCategoria) {
    // console.log({ selectedCategoria });
    return (
      <div className="">
        <div className="h-full md:shadow md:h-[88vh] md:overflow-hidden md:overflow-y-auto">
          <FiltersText />
        </div>
      </div>
    );
  }

  return (
    <div className="h-full md:shadow md:h-[88vh] md:overflow-hidden md:overflow-y-auto">
      <div className="p-4 overflow-hidden overflow-y-auto grid gap-x-4 gap-y-6 md:grid-cols-2 lg:grid-cols-4">
        {categs
          .sort((a, b) => a.descricao.localeCompare(b.descricao))
          .map(
            (categ, idx) =>
              categ.values && (
                <ShowCategorias
                  nodes={categ.values}
                  title={categ.descricao}
                  onClick={(categId) => {
                    if (selectedCategoria && categId === selectedCategoria) {
                      navigate(ROUTES_MAP.product + '/?categoria=');
                    } else {
                      navigate(
                        ROUTES_MAP.product +
                          '/?categoria=' +
                          categId +
                          '&take=' +
                          _take.toString() +
                          `&skip=0&tags={"categorias":[${categId}]}&filtros=`
                      );
                    }
                  }}
                  key={idx}
                />
              )
          )}
      </div>
    </div>
  );
}
