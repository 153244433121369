/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Produto, VeiculosByProduct } from '../../app.models';
import { getProduct, getVeiculosByProduct } from '../../app.service';
import ReactImageMagnify from 'react-image-magnify';
import Button from '../../components/layout/button';
import ModalProduct from './modalProduct';
import useMedia from 'use-media';
import { camelCaseToNormal, classNames, getTake } from '../../utils/functions';
import { useApp } from '../../app.hook';
import { UploadIcon } from '@heroicons/react/outline';
import { ROUTES_MAP } from '../../app.map.routes';
import { getCart } from '../cart/cart.service';
import { useCart } from '../cart/cart.hook';
import ModalSelectCard from '../myCarts/modalSelectCard';
import { Progress } from '../../components/progress';
import { Tab } from '@headlessui/react';
import LazyLoadingImage from '../../components/lazyLadingImage';
import DisplayProductCardRelacionados from './displayProductCardRelacionados';
import { useProduct } from './product.hook';
import { EnumOrigens } from '../../utils/origens';
import TabSearchWraper from '../../components/TabSearchWraper';
import ExibirPrecoConsumidorFinal from '../../components/ExibirPrecoConsumidorFinal';
import ButtonComprar from '../../components/buttonComprar';

const defaultTabClass = (selected: boolean) =>
  classNames(
    'w-full  leading-5 font-medium text-primary1',
    'focus:outline-none bg-gray-100',
    'text-2xl py-2',
    selected
      ? 'text-black text-sm border-b-2 border-b-primary1 border-b-solid font-bold'
      : 'text-gray-500 text-xs border-b-2 border-b-gray-100 border-b-solid hover:bg-white/[0.12] hover:text-gray-200'
  );

export default function ShowProduct() {
  const { setOrigem, getOrigem } = useProduct();
  const _take = getTake();
  const { isPerfilManager, marcas, modelos, empresa } = useApp();
  const navigate = useNavigate();
  const isSmall = useMedia({ maxWidth: 480 });
  const { id } = useParams();
  const [modalOpen, setOpenModal] = useState<boolean>(false);
  const [produto, setProduto] = useState<Produto>();
  const [relacionados, setRelacionados] = useState<Produto[]>();
  const [veiculosCompativeis, setVeiculosCompativeis] = useState<
    VeiculosByProduct[]
  >([]);
  const [fabricantes, setFabricantes] = useState<Record<string, any>[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [attributs, setAttributs] = useState<Record<string, any>[]>([]);
  const [tags, setTags] = useState<Record<string, any>[]>([]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const divRef = useRef<HTMLDivElement>(null);

  // produto && console.log({ produto });
  const loadCompativeis = useCallback(async () => {
    const data = [];

    const veiculos = await getVeiculosByProduct(Number(id));
    setVeiculosCompativeis(veiculos);
    // const fabs = await getProductIdFabricante(Number(id));
    // setFabricantes(fabs);
  }, [id]);

  useEffect(() => {
    if (id) {
      if (getOrigem() === undefined) {
        setOrigem(EnumOrigens.PRODUTO);
      }
      setProduto(undefined);
      getProduct(Number(id), getOrigem()).then((data) => {
        setProduto(data.produto);
        setRelacionados(data.relacionados);

        setFabricantes(data.produto.codigoFabricante);

        const attribs = JSON.parse(data.produto.atributosPesquisa);
        if (attribs) {
          const formatedAttribs = attribs.schema.map(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (at: Record<string, any>) => ({
              ...at,
              key: camelCaseToNormal(at.key),
              formatedText: attribs.dados[at.key],
            })
          );

          setAttributs(formatedAttribs);
        }

        const tgs = JSON.parse(data.produto.tags);
        setOrigem(undefined);
        loadCompativeis();
        // console.log('🚀 ~ getProduct ~ tgs', tgs);
      });

      if (divRef.current) divRef.current.scrollTo(0, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (produto) {
      setCurrentImage({
        small: produto.images[0].small,
        medium: produto.images[0].medium,
        big: produto.images[0].big,
      });
    }
  }, [produto]);

  const [currentImage, setCurrentImage] = useState<{
    small: string;
    medium: string;
    big: string;
  } | null>(null);

  const { currentCart } = useCart();
  const [openModalSelectCart, setOpenModalSelectCart] =
    useState<boolean>(false);

  const buy = async () => {
    const verificaSituacao = (await getCart(currentCart.id)).situacaoCarrinho;

    if (verificaSituacao === 0) {
      setOpenModal(true);
    } else {
      setOpenModalSelectCart(true);
    }
  };

  if (!produto || !relacionados || !veiculosCompativeis)
    return (
      <div className="flex flex-col justify-center items-center h-[88vh] animate-pulse">
        <span className="">Aguarde ...</span>
      </div>
    );

  // if (isSmall)
  //   return (
  //     <>
  //       <div
  //         ref={divRef}
  //         className="mt-32 p-2 flex flex-col overflow-hidden overflow-y-auto"
  //       >
  //         <div className="flex flex-col space-y-2">
  //           <div className="relative z-10">
  //             <img
  //               style={{
  //                 width: '200px',
  //                 height: '160px',
  //                 objectFit: 'contain',
  //                 borderRadius: '0.375rem',
  //                 margin: '0 auto',
  //               }}
  //               src={produto.images[0].medium}
  //               alt={produto.descricaoSite}
  //               className="w-full"
  //               onError={(e) =>
  //                 replaceImgWithError(
  //                   e,
  //                   'https://via.placeholder.com/160x120?text=Sem Imagem'
  //                 )
  //               }
  //             />

  //             <div className="flex flex-col space-y-2 items-center">
  //               {produto.isPromocao ? (
  //                 <div className="flex flex-col my-1 w-full">
  //                   <div className="flex justify-between">
  //                     <span className="flex space-x-2 text-md line-through text-gray-400">
  //                       <span>{parseCurrency(produto.preco)}</span>
  //                     </span>
  //                     <span className="flex space-x-2 font-bold text-xl text-primary1">
  //                       <span>{parseCurrency(produto.precoPromo)}</span>
  //                     </span>
  //                   </div>
  //                   {produto.promocao && (
  //                     <div className="flex justify-center">
  //                       <Progress
  //                         qtdeTotal={produto.promocao.qtdeEmPromocao}
  //                         qtdeMovimentada={produto.promocao.qtdeMovimentada}
  //                         qtdeDisponivel={produto.promocao.qtdeDisponivel}
  //                       />
  //                     </div>
  //                   )}
  //                 </div>
  //               ) : (
  //                 <div className="relative flex flex-col justify-center my-1">
  //                   <div className="flex my-3 justify-end">
  //                     <span className="flex space-x-2 font-bold text-xl">
  //                       <span>{parseCurrency(produto.precoPromo)}</span>
  //                     </span>
  //                   </div>
  //                 </div>
  //               )}
  //               <div className="my-2">
  //                 <Button title="Comprar" onClick={buy} />
  //               </div>
  //             </div>
  //           </div>
  //           <div className="flex flex-col space-y-3">
  //             <div className="text-xl text-center font-bold">
  //               {produto.descricaoSite}
  //             </div>
  //             <div className="text-md odd:bg-gray-100 odd:rounded-lg px-2 py-2">
  //               <span className="font-bold ">Categoria</span>:{' '}
  //               {produto.categoria?.descricao || 'N/D'}
  //             </div>
  //             <div className="text-md odd:bg-gray-100 odd:rounded-lg px-2 py-2">
  //               <span className="font-bold">Referência</span>:{' '}
  //               {produto.referencia}
  //             </div>
  //             <div className="text-md odd:bg-gray-100 odd:rounded-lg px-2 py-2">
  //               <span className="font-bold">Código</span>: {produto.codigoSgi}
  //             </div>
  //             <div className="text-md odd:bg-gray-100 odd:rounded-lg px-2 py-2">
  //               <span className="font-bold">Características</span>:{' '}
  //               {produto.descricaoCompleta}
  //             </div>
  //             {isPerfilManager && (
  //               <div className="flex justify-center">
  //                 <Button
  //                   title="Upload de Imagens"
  //                   icon={<UploadIcon className="w-4 h-4" />}
  //                   // color="bg-gray-500 text-white hover:bg-gray-300 hover:text-gray-700"
  //                   onClick={() =>
  //                     navigate(ROUTES_MAP.uploadProductById(produto.id))
  //                   }
  //                 />
  //               </div>
  //             )}
  //           </div>
  //           <ModalProduct
  //             isOpen={modalOpen}
  //             setIsOpen={setOpenModal}
  //             product={produto}
  //           />
  //         </div>
  //         {relacionados && relacionados.length > 0 && (
  //           <div className="divide-x-2">
  //             <div className="px-4 py-4 my-4 text-2xl font-bold bg-gray-100">
  //               Produtos Relacionados
  //             </div>
  //             <div className="grid gap-x-4 gap-y-6 md:grid-cols-2 lg:grid-cols-4">
  //               {relacionados?.map((rel, index) => (
  //                 <div className="p-0" key={index}>
  //                   <DisplayProductCard product={rel} />
  //                 </div>
  //               ))}
  //             </div>
  //           </div>
  //         )}
  //       </div>
  //       <ModalSelectCard
  //         isOpen={openModalSelectCart}
  //         setIsOpen={setOpenModalSelectCart}
  //       />
  //     </>
  //   );

  return (
    <TabSearchWraper
      // ref={divRef}
      className="h-[88vh] p-2 flex flex-col overflow-hidden overflow-y-auto"
    >
      {/* {!pesquisasTopBar && (
        <div className="flex justify-center mb-3">
          <TabSerach />
        </div>
      )} */}
      <div className="grid grid-cols-[3fr_4fr_3fr] gap-4">
        <div className="grid grid-cols-[10fr_2fr]">
          <div className="relative z-[1] w-full">
            {currentImage ? (
              <ReactImageMagnify
                {...{
                  imageStyle: {
                    objectFit: 'contain',
                    borderRadius: '0.375rem',
                    width: '100%',
                  },
                  className: 'mt-3 relative z-10 bg-white',
                  enlargedImageContainerDimensions: {
                    width: '200%',
                    height: '171%',
                  },
                  smallImage: {
                    alt: 'imagem',
                    isFluidWidth: false,
                    src: currentImage.medium,
                    width: 300,
                    height: 220,
                  },
                  largeImage: {
                    src: currentImage.big,
                    width: 600,
                    height: 380,
                  },
                }}
              />
            ) : (
              <div className="flex justify-center items-center h-96 w-80 bg-gray-100">
                <span className="text-2xl text-gray-400">Sem Imagem</span>
              </div>
            )}
            <div className="flex flex-col space-y-2 items-center">
              {/* <div className="flex space-x-2 items-center">
              <span className="flex space-x-2 text-md line-through text-gray-300">

                <span>{parseCurrency(produto.preco)}</span>
              </span>
              <span className="flex space-x-2 font-bold text-2xl">

                <span>{parseCurrency(produto.precoPromo)}</span>
              </span>
            </div> */}
              {produto.isPromocao ? (
                <div className="flex flex-col my-1 w-full">
                  <div className="flex justify-between">
                    <span className="flex space-x-2 text-md line-through text-gray-400">
                      {/* <span>{parseCurrency(produto.preco)}</span> */}
                      <ExibirPrecoConsumidorFinal
                        item={produto}
                        field="preco"
                        fieldConsumidorFinal="precoConsumidorFinal"
                      />
                    </span>
                    {/* <span className="flex space-x-2 font-bold text-xl text-primary1">
                    <span>{parseCurrency(produto.precoPromo)}</span>
                  </span> */}
                    <span className="flex text-md my-1">
                      <ExibirPrecoConsumidorFinal
                        item={produto}
                        field="precoPromo"
                        fieldConsumidorFinal="precoPromoConsumidorFinal"
                      />
                    </span>
                  </div>
                  {produto.promocao && (
                    <div className="flex justify-center">
                      <Progress
                        qtdeTotal={produto.promocao.qtdeEmPromocao}
                        qtdeMovimentada={produto.promocao.qtdeMovimentada}
                        qtdeDisponivel={produto.promocao.qtdeDisponivel}
                      />
                    </div>
                  )}
                </div>
              ) : (
                <div className="relative flex flex-col justify-center my-1">
                  <div className="flex my-3 justify-end">
                    <span className="flex space-x-2 font-bold text-xl">
                      {/* <span>{parseCurrency(produto.precoPromo)}</span> */}
                      <ExibirPrecoConsumidorFinal
                        item={produto}
                        field="precoPromo"
                        fieldConsumidorFinal="precoPromoConsumidorFinal"
                      />
                    </span>
                  </div>
                </div>
              )}
              <div className="my-2">
                {/* <Button title="Comprar" onClick={buy} /> */}
                <ButtonComprar produto={produto} action={buy} />
              </div>
            </div>
          </div>
          <div className="relative z-0 h-full bg-slate-100 flex flex-col gap-1 p-1 overflow-hidden overflow-y-auto">
            {produto.images.map((img, i) => (
              <button
                key={i}
                className="hover:border-[1px] hover:border-slate-500"
                onClick={() => {
                  setCurrentImage(img);
                }}
              >
                <img src={img.small} className="w-48" />
              </button>
            ))}
          </div>
        </div>
        <div className="flex flex-col space-y-1 w-full">
          <div className="text-2xl font-bold">{produto.descricaoSite}</div>
          <div className="text-md even:bg-gray-100 even:rounded-lg px-2 py-2 flex justify-between">
            <span className="font-bold">Código</span>{' '}
            <span>{produto.codigoSgi}</span>
          </div>
          {isPerfilManager && (
            <div className="text-md odd:bg-gray-100 odd:rounded-lg px-2 py-2 flex justify-between">
              <span className="font-bold">Saldo</span>{' '}
              <span>{produto?.saldo?.saldo ?? 0}</span>
            </div>
          )}
          {attributs
            .filter((att) => att.formatedText)
            .map((att, i) => (
              <div
                key={i}
                className="text-md even:bg-gray-100 even:rounded-lg px-2 py-2 flex justify-between"
              >
                <span className="font-bold">{att.key}</span>
                <span>{att.formatedText}</span>
              </div>
            ))}
          {isPerfilManager && (
            <div className="flex">
              <Button
                title="Upload de Imagens"
                icon={<UploadIcon className="w-4 h-4" />}
                // color="bg-gray-500 text-white hover:bg-gray-300 hover:text-gray-700"
                onClick={() =>
                  navigate(ROUTES_MAP.uploadProductById(produto.id))
                }
              />
            </div>
          )}
        </div>
        {empresa.exibirPesquisaVeiculos && (
          <div className="w-full">
            <div className="text-xl font-medium">Códigos de Fabricantes</div>
            <table className="w-full">
              <thead className="text-lg bg-slate-200">
                <tr>
                  <th
                    scope="col"
                    className="px-1 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Código
                  </th>
                  <th
                    scope="col"
                    className="px-1 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Fabricante
                  </th>
                </tr>
              </thead>
              <tbody className="">
                {fabricantes.map((f, i) => (
                  <tr
                    key={i}
                    className="border-b-[1px] border-solid border-gray-300 even:bg-gray-100 hover:bg-gray-300 cursor-pointer"
                  >
                    <td className="px-1 py-2">
                      <div className="text-sm text-gray-900 text-left">
                        {f.idFabricante}
                      </div>
                    </td>
                    <td className="px-1 py-2">
                      <div className="text-sm text-gray-900 text-left">
                        {f.fabricante.fabricanteDesc}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
      <div className="my-6">
        <Tab.Group>
          <Tab.List className="flex p-1 bg-gray-100 rounded-xl my-3">
            <Tab className={({ selected }) => defaultTabClass(selected)}>
              Produtos Relacionados
            </Tab>
            {empresa.exibirPesquisaVeiculos && (
              <Tab className={({ selected }) => defaultTabClass(selected)}>
                Veículos Compatíveis
              </Tab>
            )}
          </Tab.List>
          <Tab.Panels>
            <Tab.Panel className="grid gap-x-4 gap-y-6 md:grid-cols-2 lg:grid-cols-4">
              {relacionados?.map((rel, index) => (
                <DisplayProductCardRelacionados key={index} product={rel} />
              ))}
            </Tab.Panel>
            {empresa.exibirPesquisaVeiculos && (
              <Tab.Panel>
                {veiculosCompativeis.length > 0 ? (
                  <div className="flex flex-wrap justify-center">
                    {veiculosCompativeis.map((veiculo, index) => (
                      <div
                        onClick={() => {
                          setOrigem(EnumOrigens.VEICULO_COMPATIVEL);
                          // console.log({ veiculo });
                          navigate(
                            ROUTES_MAP.product +
                              '/?marca=' +
                              veiculo.marcaId +
                              '&tipo=' +
                              veiculo.modeloId +
                              '&take=' +
                              _take.toString() +
                              `&skip=0&tags={"modelos":[${veiculo.modeloId}]}&filtros=`
                          );
                        }}
                        key={index}
                        className={classNames(
                          'flex flex-col m-2 md:m-3 p-2 group ring-4 ring-gray-100 shadow-xs rounded-md cursor-pointer hover:ring-gray-300 hover:ring-2 hover:shadow-md'
                        )}
                      >
                        <div className="text-xs flex justify-between items-center ">
                          <LazyLoadingImage
                            classNames={['w-8 h-8']}
                            alt={veiculo.marcaDesc}
                            height={43}
                            width={43}
                            src={
                              marcas.find((m) => m.id === veiculo.marcaId)
                                ?.images[0].img ||
                              'https://via.placeholder.com/100?text=Sem Imagem'
                            }
                            efect="blur"
                          />
                          <span>{veiculo.marcaDesc}</span>
                        </div>
                        <LazyLoadingImage
                          classNames={['mx-auto']}
                          alt={veiculo.modeloDesc}
                          height={150}
                          width={150}
                          src={
                            modelos.find((m) => m.id === veiculo.modeloId)
                              ?.images[0].img ||
                            'https://via.placeholder.com/100?text=Sem Imagem'
                          }
                          efect="blur"
                        />
                        <div className="text-xs w-[150px] text-center">
                          Ano: {veiculo.anoI} - {veiculo.anoF}
                        </div>
                        <div className="text-md text-center line-clamp-2 group-hover:line-clamp-4 w-[150px] font-bold text-slate-700">
                          {veiculo.modeloDesc}
                        </div>
                      </div>
                      // <Display
                      //   key={veiculo.modeloId}
                      //   id={veiculo.modeloId}
                      //   selected={undefined}
                      //   title={veiculo.modeloDesc}
                      //   img={
                      //     modelos.find((m) => m.id === veiculo.modeloId)
                      //       ?.images[0].img ||
                      //     'https://via.placeholder.com/100?text=Sem Imagem'
                      //   }
                      //   onClick={() => null}
                      // />
                    ))}
                  </div>
                ) : (
                  <div className="flex justify-center items-center h-40">
                    Sem referências
                  </div>
                )}
              </Tab.Panel>
            )}
          </Tab.Panels>
        </Tab.Group>
      </div>
      <ModalProduct
        isOpen={modalOpen}
        setIsOpen={setOpenModal}
        product={produto}
      />
      <ModalSelectCard
        isOpen={openModalSelectCart}
        setIsOpen={setOpenModalSelectCart}
      />
    </TabSearchWraper>
  );
}
