/* eslint-disable @typescript-eslint/no-empty-function */
import * as crypto from 'crypto-js';
export class EncrDecrService {
  constructor() {}

  //The set method is use for encrypt the value.
  set(value: string) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return crypto.AES.encrypt(value, process.env.REACT_APP_AES!).toString();
  }

  //The get method is use for decrypt the value.
  get(value: string) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return crypto.AES.decrypt(value, process.env.REACT_APP_AES!).toString(
      crypto.enc.Utf8
    );
  }
}
