import { UploadIcon } from '@heroicons/react/outline';
import { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useApp } from '../../app.hook';
import { ROUTES_MAP } from '../../app.map.routes';
import { IBanner, IDestaque } from '../../app.models';
import { Destaque as CompDestaque } from '../../components/destaques';
import Button from '../../components/layout/button';
import NukaCaroucel from '../../components/NukaCaroucel';
import { Ofertas } from '../../components/ofertas';
import { getBanners, getDestaques } from './promotions.service';
import { Img } from 'react-image';
import { Transition } from '@headlessui/react';
import { CaroucelMarcas } from '../../components/caroucelMarcas';
import { useProduct } from '../product/product.hook';
import { EnumOrigens } from '../../utils/origens';
import TabSearchWraper from '../../components/TabSearchWraper';

export default function Promotions() {
  const { isPerfilManager, empresa } = useApp();
  const { setOrigem } = useProduct();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [banners, setBanners] = useState<IBanner[]>([]);
  const [destaques, setDestaques] = useState<IDestaque[]>([]);

  const init = useCallback(async () => {
    setLoading(true);
    const banners = await getBanners();
    const destaques = await getDestaques();
    setBanners(banners);
    setDestaques(destaques);
    setLoading(false);
  }, []);

  useEffect(() => {
    init();
  }, [init]);

  if (loading || !banners || !destaques)
    return (
      <div className="flex flex-col justify-center items-center h-[88vh] animate-pulse">
        <span className="mx-auto"></span>Aguarde ...
      </div>
    );

  return (
    <div className="mt-28 md:mt-0 flex w-full bg-slate-100">
      <TabSearchWraper className="flex flex-col space-y-6 bg-slate-100 h-[88vh] overflow-hidden overflow-y-auto">
        {/* marcas */}
        {(empresa?.exibeMarcas ?? true) && (
          <div className="bg-slate-100 space-y-0">
            <CaroucelMarcas classNames={['w-full']} />
          </div>
        )}
        {/* banner */}
        <Transition
          className={`w-full`}
          as={'div'}
          show={banners.length > 0}
          enter="transition-opacity duration-[1000ms]"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-[1000ms]"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          {banners && (
            <div className="px-12 py-2">
              {banners.length > 0 ? (
                <NukaCaroucel slidesToShow={1} autoplay={true}>
                  {banners
                    .filter((el) => el.isVisible)
                    .map((banner) => (
                      <Link
                        key={banner.id}
                        onClick={() => {
                          setOrigem(EnumOrigens.BANNER);
                        }}
                        to={banner.urlDestino}
                        className="w-full h-full"
                      >
                        {/* <LazyLoadingImage
                    src={banner.images.medium}
                    classNames={['w-full h-full']}
                    alt={banner.id?.toString() || ''}
                    efect={'blur'}
                  /> */}
                        <Img
                          src={[
                            banner.images.medium,
                            banner.images.medium.replace('api', 'api-teste'),
                            '/no-image-1200x200.png',
                          ]}
                          loading={'lazy'}
                          sizes={
                            '(max-width: 768px) 100vw, (max-width: 992px) 50vw, (max-width: 1200px) 33.33vw, (max-width: 1600px) 25vw, (max-width: 1920px) 20vw, 100vw'
                          }
                          className="w-full h-full"
                        />
                      </Link>
                    ))}
                </NukaCaroucel>
              ) : (
                <NukaCaroucel slidesToShow={1} autoplay={true}>
                  <Img
                    src={['/no-image-1200x200.png']}
                    loading={'lazy'}
                    sizes={
                      '(max-width: 768px) 100vw, (max-width: 992px) 50vw, (max-width: 1200px) 33.33vw, (max-width: 1600px) 25vw, (max-width: 1920px) 20vw, 100vw'
                    }
                    className="w-full h-full"
                  />
                </NukaCaroucel>
              )}
              {isPerfilManager && (
                <div className="flex justify-center my-4 w-full">
                  <Button
                    title="Upload de Banner"
                    icon={<UploadIcon className="w-4 h-4" />}
                    // color="bg-gray-500 text-white hover:bg-gray-300 hover:text-gray-700"
                    onClick={() => navigate(ROUTES_MAP.uploadBannerList)}
                  />
                </div>
              )}
            </div>
          )}
        </Transition>

        {/* destaques */}
        <div className="bg-slate-100 space-y-0">
          {destaques.map((dest) => (
            <CompDestaque
              key={dest.id}
              classNames={['w-full']}
              destaque={dest}
            />
          ))}
        </div>
        {/* ofertas */}
        <div className="my-1 w-full flex justify-center">
          <Ofertas classNames={'px-6 py-2 rounded-lg bg-white w-full'} />
        </div>
      </TabSearchWraper>
    </div>
  );
}
