import { Disclosure, Transition } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/outline';
import React, { useCallback, useEffect, useState } from 'react';

type Props = {
  title: string;
  items: {
    label: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any;
  }[];
  selecteds?:
    | {
        label: string;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        value: any;
      }[]
    | undefined;
  onChange: (selecteds: string[]) => void;
  selectedsLabels?: (labels: string[]) => void;
};

export default function Filter(props: Props) {
  const defaultState = useCallback(
    () => new Array(props.items.length).fill(false),
    [props.items.length]
  );
  const [checkedState, setCheckedState] = useState(defaultState);

  useEffect(() => {
    setCheckedState(defaultState);
  }, [defaultState]);

  const handleOnChange = useCallback(
    (position: number) => {
      const updatedCheckedState = checkedState.map((item, index) =>
        index === position ? !item : item
      );

      setCheckedState(updatedCheckedState);

      const selectedsLabels = updatedCheckedState.reduce(
        (acc: string[], cur: boolean, index: number) => {
          if (cur) {
            acc.push(props.items[index].label);
          }
          return acc;
        },
        []
      );
      const selectedsValues = updatedCheckedState.reduce(
        (acc: string[], cur: boolean, index: number) => {
          if (cur) {
            acc.push(props.items[index].value);
          }
          return acc;
        },
        []
      );

      props.selectedsLabels && props.selectedsLabels(selectedsLabels);
      props.onChange(selectedsValues);
    },
    [checkedState, props]
  );

  useEffect(() => {
    if (props.selecteds) {
      props.selecteds.map((e) => {
        const position = props.items.indexOf(e);
        handleOnChange(position);
      });
    }
  }, [handleOnChange, props.items, props.selecteds]);

  if (!props.items || props.items.length === 0) return <></>;

  return (
    <Disclosure as="div" defaultOpen={true} className="my-3">
      {({ open }) => (
        <>
          <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-gray-900 bg-gray-100 rounded-lg hover:bg-gray-200 focus:outline-none focus-visible:ring focus-visible:ring-gray-300 focus-visible:ring-opacity-75 mb-3">
            <span>{props.title.toUpperCase()}</span>
            <ChevronUpIcon
              className={`${
                open ? 'transform rotate-180' : ''
              } w-5 h-5 text-primary1`}
            />
          </Disclosure.Button>
          <Transition
            show={open}
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <Disclosure.Panel className="px-2">
              {props.items
                // .sort((a, b) => a.label.localeCompare(b.label))
                .map(
                  (it, index) =>
                    it && (
                      <div key={index} className="flex items-start">
                        <div className="flex items-center h-5">
                          <input
                            type="checkbox"
                            id={`custom-checkbox-${index}`}
                            className="focus:ring-primary1 h-4 w-4 text-primary2 border-red-300 rounded"
                            value={it.value}
                            checked={checkedState[index]}
                            onChange={() => handleOnChange(index)}
                          />
                        </div>
                        <div className="ml-3 text-sm">
                          <label
                            htmlFor={`custom-checkbox-${index}`}
                            className="text-xs text-gray-700"
                          >
                            {it.label}
                          </label>
                        </div>
                      </div>
                    )
                )}
            </Disclosure.Panel>
          </Transition>
        </>
      )}
    </Disclosure>
  );
}
