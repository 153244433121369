// import { OrderProvider } from '../order.hook';
import Switch from './switch';

export default function Transmission() {
  return (
    // <OrderProvider>
    <div className="mt-24 px-1 py-2 md:mt-0 z-0">
      <Switch />
    </div>
    // </OrderProvider>
  );
}
