import { Dialog, Disclosure, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { Fragment, useEffect, useRef, useState } from 'react';
import { useApp } from '../app.hook';
import { Marca, Modelo } from '../app.models';
import { arrayGroupBy, classNames } from '../utils/functions';
// import useMedia from 'use-media';
// import AppPaginator from './layout/pagination';

function matchWords(subject: string, words: string[]): boolean {
  const regexMetachars = /[(){[*+?.\\^$|]/g;

  for (let i = 0; i < words.length; i++) {
    words[i] = words[i].replace(regexMetachars, '\\$&');
  }

  const regex = new RegExp('\\b(?:' + words.join('|') + ')\\b', 'gi');

  const ret = subject.match(regex);
  if (!ret) return false;
  return true;
}

interface MarcasModelos {
  marca: string;
  modelos: { label: string; value: string }[];
}

interface Props {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  items: { label: string; value: string }[];
  onSelect: (selected: number | string) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  checkedState: any[];
}

export default function ModalFilterObjectModelos(props: Props) {
  // const isSmall = useMedia({ maxWidth: 480 });
  const { marcas, modelos } = useApp();
  function closeModal() {
    props.setIsOpen(false);
  }

  const [filteredMarcasModelos, setFilteredMarcasModelos] = useState<
    MarcasModelos[]
  >([]);

  const [searchFor, setSearchFor] = useState('');
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (props.isOpen) {
      new Promise((t) => setTimeout(t, 100)).then(
        () => ref.current && ref.current.focus()
      );
    }
  }, [props.isOpen]);

  useEffect(() => {
    const mapItems: number[] = props.items
      .filter((el) => el.label)
      .filter((el) =>
        el.label
          .replace(/[-.?]/, '')
          .toLowerCase()
          .includes(searchFor.toLowerCase())
      )
      // .filter((el) => matchWords(el.label, searchFor.split(' ')))
      .reduce((acc: number[], cur: any) => {
        acc.push(cur.value);
        return acc;
      }, []);
    const formatedItems = modelos.filter((m) => mapItems.includes(m.id));
    const group = arrayGroupBy(formatedItems, 'marcaId');
    const obj = Object.entries(group);

    const formated = obj.map(([key, array]) => {
      const _marca = marcas.find((m: Marca) => m.id === parseInt(key));
      return {
        marca: _marca ? `${_marca.marcaDesc}` : key,
        modelos: (array as Modelo[]).map((a) => ({
          label: `${a.modeloDesc} <span class="text-primary1/50 px-1">ano: ${a.anoI}-${a.anoF}</span>`,
          value: String(a.id),
        })),
      };
    });

    setFilteredMarcasModelos(formated);
  }, [marcas, modelos, props.items, searchFor]);

  return (
    <div>
      <Transition appear show={props.isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={closeModal}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black opacity-20" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-lg p-0 my-1 overflow-hidden text-left align-middle transition-all transform bg-gradient-to-tr to-white from-slate-200 shadow-xl rounded-2xl">
                <Dialog.Title
                  as="h3"
                  className="flex justify-between mb-2 bg-primary2 text-default-white px-4 py-2"
                >
                  <span className="text-lg font-bold leading-6 font-display">
                    Selecionar Modelo
                  </span>
                  <button onClick={closeModal} className="hover:text-gray-400">
                    <XIcon className="w-5 h-5" />
                  </button>
                </Dialog.Title>
                <div className="flex flex-col font-display p-2">
                  <div className="flex justify-between space-x-1">
                    <label className="relative block w-full">
                      <span className="sr-only">Busca</span>
                      <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-5 w-5 stroke-gray-400"
                          viewBox="0 0 20 20"
                          fill="none"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                          />
                        </svg>
                      </span>
                      <input
                        className="placeholder:italic placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-gray-300 focus:ring-gray-300 focus:ring-1 sm:text-sm"
                        placeholder="Buscar por nome..."
                        type="search"
                        value={searchFor}
                        onChange={(e) => setSearchFor(e.target.value)}
                        ref={ref}
                      />
                    </label>
                  </div>

                  <div className="h-[45vh] mt-2 overflow-hidden overflow-y-auto">
                    <div className="flex flex-col space-y-2">
                      {filteredMarcasModelos.map((e, i) => (
                        <div key={i} className="flex flex-col space-y-2">
                          <Disclosure defaultOpen={true}>
                            {({ open }) => (
                              <>
                                <Disclosure.Button
                                  className={classNames(
                                    'px-2 py-1 sticky -top-1 z-[1]'
                                  )}
                                >
                                  <div className="flex space-x-1 p-2 text-gray-100 text-md font-bold bg-slate-500">
                                    <span className={`p-0 `}>
                                      {open ? '-' : '+'}
                                    </span>
                                    <span className="">{e.marca}</span>
                                  </div>
                                </Disclosure.Button>
                                <Disclosure.Panel>
                                  <div className="flex flex-col ml-4">
                                    {e.modelos &&
                                      e.modelos.length > 0 &&
                                      e.modelos.map(
                                        (it, index) =>
                                          it && (
                                            <div
                                              key={index}
                                              className="flex items-start hover:font-semibold"
                                            >
                                              <div className="flex items-center h-5">
                                                <input
                                                  type="checkbox"
                                                  // id={`custom-checkbox-${index}`}
                                                  className="hover:bg-primary1 h-4 w-4 text-primary2 border-red-300 rounded cursor-pointer"
                                                  value={it.value}
                                                  checked={
                                                    props.checkedState[index]
                                                  }
                                                  onChange={() =>
                                                    props.onSelect(it.value)
                                                  }
                                                />
                                              </div>
                                              <div className="ml-3 text-sm">
                                                <label
                                                  dangerouslySetInnerHTML={{
                                                    __html: it.label,
                                                  }}
                                                  // htmlFor={`custom-checkbox-${index}`}
                                                  className="text-xs text-gray-700"
                                                />
                                              </div>
                                            </div>
                                          )
                                      )}
                                  </div>
                                </Disclosure.Panel>
                              </>
                            )}
                          </Disclosure>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}
