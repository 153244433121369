/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import MenuLeftMin from '../../components/layout/menuLeftMin';
import MenuLeftBottom from '../../components/layout/menuLeftBottom';
import { ReactComponent as LogoNome } from '../../assets/svg/logo-full-name.min.svg';
import WhatsappMenu from '../../components/whatsappMenu';
import MainSearchOrder from '../../components/mainSearchOrder';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';
import {
  Cart,
  ErrorTransmission,
  Produto,
  ProdutoCart,
  TabelaPreco,
  VeiculosByProduct,
} from '../../app.models';
import { columns, columnsManager } from './columns';
import {
  getProduct,
  getVeiculosByProduct,
  productPedidoSearchFor,
  productSearchCateg,
  productSearchPorModelo,
} from '../../app.service';
import LazyLoadingImage from '../../components/lazyLadingImage';
import {
  camelCaseToNormal,
  classNames,
  createSlug,
  truncateStringWithEllipsis,
} from '../../utils/functions';
import { useApp } from '../../app.hook';
import { useCart } from '../cart/cart.hook';
import ProdutoItem from './produtoItem';
import ModalProductCard from './modalProductCard';
import { LoadingSVG } from '../../components/loadingSVG';
import { parseCurrency } from '../../utils/numberHandler';
import { toast } from 'react-toastify';
import { IPedido, addPedido } from '../myOrders/pedido.service';
import {
  getCart,
  newCart as nCart,
  updCarrinhoCliente,
} from '../cart/cart.service';
import { useNavigate } from 'react-router-dom';
import { ROUTES_MAP } from '../../app.map.routes';
import ModalSendOrder from '../cart/modalSendOrder';
import Button from '../../components/layout/button';
import ModalConfirmation from '../../components/modalConfirmation';
import ModalProductAdd from './modalProductAdd';
import ModalClienteSelect from '../../components/modalClienteSelect';
import {
  ChevronDownIcon,
  InformationCircleIcon,
  SaveIcon,
  ShoppingCartIcon,
  ViewGridIcon,
  ViewListIcon,
} from '@heroicons/react/outline';
import ComboTabelaPreco from '../../components/comboTabelaPrecos';
import ShowVeiculoCompativelCard from './showVeiculoCompativelCard';
import ShowVeiculoCompativelLine from './showVeiculoCompativelLine';
import ModalBuscaPorVeiculo from './modalBuscaPorVeiculo';
import ModalBuscaPorMarcaModelo from './modalBuscaPorMarcaModelo';
import ModalBuscaPorCategorias from './modalBuscaPorCategorias';
import clsx from 'clsx';
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';
import { Tab } from '@headlessui/react';
import LogoNameBd from '../../components/LogoNameBd';
import { PrintCartClientImageBase64 } from '../../components/pdf';
import ExibirPrecoConsumidorFinal from '../../components/ExibirPrecoConsumidorFinal';
import getMask, { formatarTelefone } from '../../utils/masks';
import { useForm, Controller } from 'react-hook-form';
import MaskedInput from 'react-text-mask';

const _classNamesViewCompativel = (active: boolean) =>
  classNames(
    active &&
      'text-gray-500 shadow border-[1px] border-gray-200 border-solid bg-gray-50',
    'hover:text-gray-500 hover:bg-gray-50 rounded-md p-1 mx-1'
  );

const tabClass = (selected: boolean) =>
  clsx(
    'w-full h-8 rounded-lg text-sm font-medium leading-5',
    'ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
    selected
      ? 'bg-primary1 shadow text-white'
      : 'text-primary1 hover:bg-white/[0.12] hover:text-primary2'
  );

const tabPanelClass = () =>
  clsx(
    'rounded-xl bg-white h-[81vh] flex-1',
    'ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
    'shadow-md',
    'overflow-y-auto overflow-hidden'
  );

// const rowStyle = ({ data }: { data: Produto }) => {
//   return {
//     color: data.saldo.saldo === 0 ? '#ff4500' : 'inherit',
//     padding: 0,
//     margin: 0,
//     height: 20,
//   };
// };

export type SearchType = 'veiculo' | 'texto' | 'categoria' | 'marcaModelo';
interface FormBalcaoComprador {
  contatoNome: string;
  contatoFone: string;
}

export default function OrderCounter() {
  const navigate = useNavigate();
  const {
    empresa,
    showPrecoConsumidorFinal,
    innerShowPrecoConsumidorFinal,
    currentClientAtivo,
  } = useApp();

  const showPesquisaVeiculo = empresa?.exibirPesquisaVeiculos || false;
  const showPesquisaCategoria = empresa?.exibirPesquisaCategorias || false;

  // carrinho de compras
  const {
    loading: loadingCart,
    currentCart,
    upsetItemCart,
    deleteItemCart,
    loadCart,
    cleanCart,
    updBalcaoComprador,
  } = useCart();

  const formBalcaoComprador = useForm<FormBalcaoComprador>({
    defaultValues: {
      contatoNome: currentCart.contatoNome,
      contatoFone: currentCart.contatoFone,
    },
    mode: 'onBlur',
    criteriaMode: 'all',
  });

  const [loading, setLoading] = useState(false);
  const [loadingRelacionados, setLoadingRelacionados] = useState(false);
  const [menuMin, setMenuMin] = useState(true);
  // const [selected, setSelected] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [selectedRelacionadoIndex, setSelectedRelacionadoIndex] = useState<
    number | null
  >(null);
  const {
    marcas,
    modelos,
    currentUser,
    isPerfilManager,
    updateDefaultCli,
    updateDefaultTab,
  } = useApp();

  const [searchType, setSearchType] = useState<SearchType>('texto');

  const [gridRef, setGridRef] = useState<any>(null);
  const [gridRefRelacionados, setGridRefRelacionados] = useState<any>(null);

  const [heightGrid, setHeightGrid] = useState<number[]>([51, 30]);
  const [heightPanelInfo, setHeightPanelInfo] = useState<number[]>([50, 50]);

  const setColumnSizesToFit = useCallback(() => {
    if (gridRef && gridRef.current.setColumnSizesToFit) {
      gridRef.current.setColumnSizesToFit();
    }
    if (
      gridRefRelacionados &&
      gridRefRelacionados.current.setColumnSizesToFit
    ) {
      gridRefRelacionados.current.setColumnSizesToFit();
    }
  }, [gridRef, gridRefRelacionados]);
  // const [gridRefRelacionados, setGridRefRelacionados] = useState<any>(null);
  const refSearch = useRef<any>(null);
  const refSearchVeiculo = useRef<any>(null);
  const refSearchMarcaModelo = useRef<any>(null);
  const refSearchCategoria = useRef<any>(null);

  const [data, setData] = useState<Produto[]>([]);

  const [searchText, setSearchText] = useState('');
  const [categSearch, setCategSearch] = useState<number | null>(null);
  const [marcaModeloSearch, setMarcaModeloSearch] = useState<number | null>(
    null
  );
  const [veiculoSearch, setVeiculoSearch] = useState<number | null>(null);

  const [currentImage, setCurrentImage] = useState<any>(null);

  const [produto, setProduto] = useState<Produto>();

  const [relacionados, setRelacionados] = useState<Produto[]>([]);

  const [veiculosCompativeis, setVeiculosCompativeis] = useState<
    VeiculosByProduct[]
  >([]);

  const [veiculosCompativeisView, setVeiculosCompativeisView] = useState<
    'line' | 'card'
  >('line');

  const [tabCarrinhoInfo, setTabCarrinhoInfo] = useState<number>(0);

  const produtoCard = useRef<ProdutoCart | null>(null);
  const [modalOpenBuy, setOpenModalBuy] = useState(false);
  const [modalOpenAlterar, setOpenModalAlterar] = useState(false);
  const [verifing, setVerifing] = useState<boolean>(false);
  const modalErrorData = useRef<ErrorTransmission[]>([]);
  const [modalIsOpenErros, setModalIsOpenErros] = useState<boolean>(false);
  const [modalOpenSendOrder, setOpenModalSendOrder] = useState<boolean>(false);
  const [modalOpenConfirm, setOpenModalConfirm] = useState<boolean>(false);
  const [modalOpenSelectCliente, setOpenModalSelectCliente] =
    useState<boolean>(false);

  const transmission = async (
    obs: string,
    fpagto: number,
    cpagto: number,
    transportadora: number,
    vendedor?: number | undefined
  ) => {
    if (currentCart.produtos.length === 0) {
      toast.error('Sem produtos, verifique');
      return;
    }

    if (!fpagto) {
      toast.error('Sem forma de pagamento, verifique');
      return;
    }
    if (!cpagto) {
      toast.error('Sem condições de pagamento, verifique');
      return;
    }
    if (!transportadora) {
      toast.error('Sem transportadora, verifique');
      return;
    }
    if (!vendedor) {
      toast.error('Verifique o vendedor, verifique');
      return;
    }

    setLoading(true);
    const pedido: IPedido = {
      cotacoes: [
        { cotacaoId: currentCart.id, updated_at: currentCart.updated_at },
      ],
      produtos: currentCart.produtos.map((produto) => ({
        produtoId: produto.produto.id,
        qtde: produto.qtde,
        unitario: produto.unitario,
        desc1: produto.desc1,
        desc2: produto.desc2,
        desc3: produto.desc3,
        descGT: produto.descGT,
        descMaxClasse: produto.produto.descMaxClasse,
        unitarioLiquido: produto.unitarioLiquido,
        isPromocao: produto.isPromocao,
      })),
      totais: {
        qtdeTotal: currentCart.qtdeTotal,
        produtos: currentCart.totalProdutos,
        frete: currentCart.frete,
        total: currentCart.total,
      },
      obs:
        obs +
        `
        Usuário: ${currentUser.nome}
        Email: ${currentUser.email}
        Telefone: ${formatarTelefone(currentUser.telefone)}
      `,
      cliente: currentCart.cliente.id,
      tabelaPrecos: currentCart.tabelaPrecos.id,
      fPagto: fpagto,
      cPagto: cpagto,
      transportadora,
      vendedor,
    };

    addPedido({ pedido })
      .then((data) => {
        console.log('🚀 ~ file: index.tsx:239 ~ .then ~ data:', data);
        afterTransmissionSuccess(data);
      })
      .catch((err) => {
        // setLoading(false);
        toast.error(err.response.message || err.message);
      })
      .finally(() => setLoading(false));
  };

  const saveCart = useCallback(async () => {
    if (currentCart.produtos.length === 0) {
      toast.error('Sem produtos, verifique');
      return;
    }
    const newcart = await nCart();
    loadCart(newcart);
    // window.location.reload();

    // await loadCart();
  }, [currentCart.produtos, loadCart]);

  const afterTransmissionSuccess = async (newOrder: any) => {
    const cart = await nCart();
    loadCart(cart);
    // mostar tela de sucesso
    navigate(ROUTES_MAP.orderSuccess, { state: { newOrder } });
  };

  const validateTransmission = useCallback(async () => {
    const errors: ErrorTransmission[] = [];
    const cartId = currentCart.id;
    const cart = await getCart(cartId);
    const produtos = cart.produtos;
    if (produtos.length === 0) {
      return errors;
    }

    produtos.forEach((produto: any, index: number) => {
      if (produto.isPromocao === 1) {
        if (
          produto?.produto?.promocao &&
          produto?.produto?.promocao?.qtdeDisponivel === 0
        ) {
          errors.push({
            image: produto?.produto?.images[0].small,
            produto: produto.produto.descricaoSite,
            qtde: produto.qtde,
            promocionalPrice: produto.produto.precoPromo,
            price: produto.unitarioLiquido,
            qtdeDisponivel: produto.produto.promocao?.qtdeDisponivel || 0,
            itemId: produto.id,
            error: `:(  Quantidade esgotada para promoção`,
            index,
            actions: [
              {
                title: `Manter com valor ${parseCurrency(
                  produto.produto.precoPromo
                )}`,
                action: 'replace',
              },
              {
                title: 'Remover item',
                action: 'remove',
              },
            ],
          });
          return;
        }
        if (
          produto?.produto?.promocao &&
          produto.qtde > produto?.produto?.promocao?.qtdeDisponivel
        ) {
          errors.push({
            image: produto?.produto?.images[0].small,
            produto: produto.produto.descricaoSite,
            qtde: produto.qtde,
            qtdeDisponivel: produto.produto.promocao?.qtdeDisponivel || 0,
            promocionalPrice: produto.produto.precoPromo,
            price: produto.unitarioLiquido,
            itemId: produto.id,
            error: `Quantidade promocional excedida, restante: ${
              produto.produto.promocao?.qtdeDisponivel || 0
            } und(s)`,
            index,
            actions: [
              {
                title: `Atualizar quantidade (${produto.produto.promocao?.qtdeDisponivel})`,
                action: 'update',
              },
              {
                title: 'Remover item',
                action: 'remove',
              },
            ],
          });
          return;
        }
        if (!produto.produto?.promocao) {
          errors.push({
            image: produto?.produto?.images[0].small,
            produto: produto.produto.descricaoSite,
            qtde: produto.qtde,
            qtdeDisponivel: 0,
            promocionalPrice: produto.produto.precoPromo,
            price: produto.unitarioLiquido,
            itemId: produto.id,
            error:
              ':( Promoção acabou... Você quer manter o item no valor normal?',
            index,
            actions: [
              {
                title: `Manter com valor ${parseCurrency(
                  produto.produto.precoPromo
                )}`,
                action: 'replace',
              },
              {
                title: 'Remover item',
                action: 'remove',
              },
            ],
          });
          return;
        }
      }
    });

    return errors;
  }, [currentCart.id]);

  const verifyTransmission = useCallback(async () => {
    setVerifing(true);
    modalErrorData.current = [];
    const errors = await validateTransmission();

    if (errors.length > 0) {
      modalErrorData.current = errors;
      setModalIsOpenErros(true);
      setVerifing(false);
      return;
    }

    setVerifing(false);
    setOpenModalSendOrder(true);
  }, [validateTransmission]);

  const saveCliente = async (_clienteId: number) => {
    if (currentCart.cliente?.id === _clienteId || !_clienteId) return;

    // salvar cliente
    const newCart = await updCarrinhoCliente({
      id: currentCart.id,
      usuarioId: currentUser.id,
      updated_at: currentCart.updated_at,
    });
    loadCart(newCart);
  };

  const saveTabelaPrecos = useCallback(
    async (tabelaPrecosId: number) => {
      if (currentCart.tabelaPrecos?.id === tabelaPrecosId || !tabelaPrecosId)
        return;
      updateDefaultTab(tabelaPrecosId);
      await new Promise((t) => setTimeout(t, 500));

      const newCart = await updCarrinhoCliente({
        id: currentCart.id,
        usuarioId: currentUser.id,
        updated_at: currentCart.updated_at,
      });

      loadCart(newCart);
      setProduto(undefined);
      setData([]);
    },
    [
      currentCart.id,
      currentCart.tabelaPrecos?.id,
      currentCart.updated_at,
      currentUser.id,
      loadCart,
      updateDefaultTab,
    ]
  );

  // const processErrors = async (actions: Record<string, string>[]) => {
  //   for await (const { index, action } of actions) {
  //     switch (action) {
  //       case 'update': {
  //         const upd = currentCart.produtos[Number(index)];
  //         if (upd.produto.promocao?.qtdeDisponivel)
  //           upsetItemCart(upd.produto.id, upd.produto.promocao?.qtdeDisponivel);
  //         break;
  //       }

  //       case 'remove': {
  //         console.log('remove', index);
  //         const rem = currentCart.produtos[Number(index)];
  //         await delItemCart(currentCart.id, rem.id, currentCart.updated_at);
  //         break;
  //       }

  //       case 'replace': {
  //         const rep = currentCart.produtos[Number(index)];
  //         const cart = await delItemCart(
  //           currentCart.id,
  //           rep.id,
  //           currentCart.updated_at
  //         );
  //         await addItemCart(cart.id, rep.produto.id, rep.qtde, cart.updated_at);
  //         break;
  //       }

  //       default:
  //         console.log('none', index);
  //     }
  //   }

  //   loadCart();
  // };

  const getProductByIndex = useCallback(
    (index: number) => {
      return data[index];
    },
    [data]
  );

  const getRelacionadoByIndex = useCallback(
    (index: number) => {
      return relacionados[index] || null;
    },
    [relacionados]
  );

  const [laodingInfo, setLoadingInfo] = useState(false);

  const [fab, setFab] = useState<Record<string, any>[]>([]);
  const [attr, setAttr] = useState<Record<string, any>[]>([]);

  const loadProdutoInfo = useCallback(async (produtoId: number) => {
    setLoadingInfo(true);
    await getProduct(produtoId)
      .then((res) => {
        setFab(res.produto.codigoFabricante);
        const atributos = JSON.parse(res.produto.atributosPesquisa);
        if (atributos) {
          setAttr(
            atributos.schema.map((at: Record<string, any>) => ({
              ...at,
              key: camelCaseToNormal(at.key),
              formatedText: atributos.dados[at.key],
            }))
          );
        }
      })
      .finally(() => setLoadingInfo(false));
  }, []);

  // const windowWith = typeof window !== 'undefined' ? window.innerWidth : 0;
  // const windowHeigth = typeof window !== 'undefined' ? window.innerHeight : 0;

  const onActiveIndexChange = useCallback((activeIndex: number | null) => {
    if (activeIndex === -1) {
      return;
    }
    setSelectedRelacionadoIndex(null);
    setSelectedIndex(activeIndex);
  }, []);

  const onActiveIndexRelacionadoChange = useCallback(
    (activeIndex: number | null) => {
      if (activeIndex === -1) {
        return;
      }
      setSelectedIndex(null);
      setSelectedRelacionadoIndex(activeIndex);
    },
    []
  );

  const loadCompativeis = useCallback(async () => {
    if (!produto) {
      setVeiculosCompativeis([]);
    }
    const veiculos = await getVeiculosByProduct(Number(produto?.id));
    setVeiculosCompativeis(veiculos);
    // const fabs = await getProductIdFabricante(Number(id));
    // setFabricantes(fabs);
  }, [produto]);

  const buy = async () => {
    setOpenModalBuy(true);
  };

  const handleDowloadCartPdf = useCallback(async () => {
    const cart = currentCart;
    if (!cart.contatoNome) {
      toast.warning('Informe o nome e telefone do comprador');
      formBalcaoComprador.setFocus('contatoNome');
      return;
    }

    if (!cart.contatoFone) {
      toast.warning('Informe o nome e telefone do comprador');
      formBalcaoComprador.setFocus('contatoNome');
      return;
    }

    const cartModificado: Cart = {
      ...cart,
      subTotalProdutos: showPrecoConsumidorFinal
        ? cart.subTotalProdutosConsumidorFinal ?? 0
        : cart.subTotalProdutos ?? 0,
      totalProdutos: showPrecoConsumidorFinal
        ? cart.totalProdutosConsumidorFinal ?? 0
        : cart.totalProdutos ?? 0,
      total: showPrecoConsumidorFinal
        ? cart.totalConsumidorFinal ?? 0
        : cart.total ?? 0,
      produtos: cart.produtos.map((produto) => ({
        ...produto,
        unitarioLiquido: showPrecoConsumidorFinal
          ? produto.unitarioLiquidoConsumidorFinal
          : produto.unitario,
        totalItem: showPrecoConsumidorFinal
          ? produto.totalItemConsumidorFinal
          : produto.totalItem,
      })),
    };
    const base64 = await PrintCartClientImageBase64(cartModificado);
    const linkSource = `data:application/pdf;base64,${base64}`;
    const downloadLink = document.createElement('a');
    const fileName = `Orcamento-${cart.id}-${createSlug(
      cart.contatoNome || 'ok'
    )}.pdf`;

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }, [currentCart, formBalcaoComprador, showPrecoConsumidorFinal]);

  // modifacao de porcentagem de consumidor final
  const formataDataProdutos = useCallback(
    (data: Produto[]) => {
      return data.map((produto) => ({
        ...produto,
        precoPromo: showPrecoConsumidorFinal
          ? produto.precoPromoConsumidorFinal
          : produto.precoPromo,
        precoPromoConsumidorFinal: 0,
        preco: showPrecoConsumidorFinal
          ? produto.precoConsumidorFinal
          : produto.preco,
        precoConsumidorFinal: 0,
      }));
    },
    [showPrecoConsumidorFinal]
  );

  const search = useCallback(
    async (text: string) => {
      if (!text) return;
      setLoading(true);
      const res = await productPedidoSearchFor(text);
      setData(formataDataProdutos(res.produtos));
      setProduto(res.produtos?.[0] || null);
      setCurrentImage(res.produtos?.[0]?.images?.[0] || null);
      await new Promise((resolve) => setTimeout(resolve, 100));
      gridRef.current?.focus();
      gridRef.current?.scrollToIndex(0);
      await new Promise((resolve) => setTimeout(resolve, 100));
      gridRef.current?.setActiveIndex(0);
      setColumnSizesToFit();
      setLoading(false);
    },
    [formataDataProdutos, gridRef, setColumnSizesToFit]
  );

  const searchByModelo = useCallback(
    async (modelo: number) => {
      setLoading(true);
      const res = await productSearchPorModelo(
        modelo,
        0,
        9999,
        '',
        `{"modelos":[${modelo}]}`,
        'marca_moodelo'
      );
      setData(res.produtos);
      setProduto(res.produtos?.[0] || null);
      setCurrentImage(res.produtos?.[0]?.images?.[0] || null);
      await new Promise((resolve) => setTimeout(resolve, 100));
      gridRef.current?.focus();
      gridRef.current?.scrollToIndex(0);
      await new Promise((resolve) => setTimeout(resolve, 100));
      gridRef.current?.setActiveIndex(0);
      setColumnSizesToFit();
      setLoading(false);
    },
    [gridRef, setColumnSizesToFit]
  );

  const searchByCategoria = useCallback(
    async (categoria: number) => {
      setLoading(true);
      const res = await productSearchCateg(
        categoria,
        0,
        9999,
        '',
        `{"categorias":[${categoria}]}`,
        'categoria'
      );
      setData(res.produtos);
      setProduto(res.produtos?.[0] || null);
      setCurrentImage(res.produtos?.[0]?.images?.[0] || null);
      await new Promise((resolve) => setTimeout(resolve, 100));
      gridRef.current?.focus();
      gridRef.current?.scrollToIndex(0);
      await new Promise((resolve) => setTimeout(resolve, 100));
      gridRef.current?.setActiveIndex(0);
      setColumnSizesToFit();
      setLoading(false);
    },
    [gridRef, setColumnSizesToFit]
  );

  const colorToolbar = useMemo(
    () =>
      innerShowPrecoConsumidorFinal && showPrecoConsumidorFinal
        ? currentClientAtivo?.corAlternativaPrecoConsumidor || '#BF360C'
        : '#ff0008',
    [
      currentClientAtivo,
      innerShowPrecoConsumidorFinal,
      showPrecoConsumidorFinal,
    ]
  );

  useEffect(() => {
    if (selectedIndex !== null) {
      const produto = getProductByIndex(selectedIndex);
      if (produto?.id) {
        setProduto(produto);
        setLoadingRelacionados(true);
        getProduct(produto.id)
          .then((res) => {
            setRelacionados(res.relacionados);
          })
          .finally(() => {
            setLoadingRelacionados(false);
          });
      }
      if (produto?.images) {
        setCurrentImage(produto.images[0]);
      } else {
        setCurrentImage(null);
        setRelacionados([]);
        setProduto(undefined);
      }
    }
  }, [getProductByIndex, selectedIndex]);

  useEffect(() => {
    if (selectedRelacionadoIndex !== null) {
      const produtoRelacionado = getRelacionadoByIndex(
        selectedRelacionadoIndex
      );
      setProduto(produtoRelacionado);
      if (produtoRelacionado?.images) {
        setCurrentImage(produtoRelacionado.images[0]);
      } else {
        setCurrentImage(null);
      }
    }
  }, [getRelacionadoByIndex, selectedRelacionadoIndex]);

  useEffect(() => {
    if (produto) {
      loadCompativeis();
      loadProdutoInfo(produto.id);
    }
  }, [loadCompativeis, loadProdutoInfo, produto]);

  const refWraperGrids = useRef<HTMLDivElement>(null);

  const reloadSearch = useCallback(async () => {
    refWraperGrids.current?.focus();
    gridRef.current.focus();
    await new Promise((resolve) => setTimeout(resolve, 100));
    gridRef.current.activeRowRef.current?.node?.click();
    // console.log('reloadSearch', gridRef.current.activeRowRef.current);
  }, [gridRef]);

  const refButtonSendOrder = useRef<HTMLButtonElement>(null);

  // usar o evento de teclado
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'F4') {
        refSearch.current?.focus();
        refSearch.current?.select();
      }
      if (e.key === 'F6') {
        refSearchVeiculo.current?.focus();
        refSearchVeiculo.current?.select();
      }
      if (e.key === 'F8') {
        refSearchMarcaModelo.current?.focus();
        refSearchMarcaModelo.current?.click();
      }
      if (e.key === 'F9') {
        refSearchCategoria.current?.focus();
        refSearchCategoria.current?.click();
      }
      if (e.key === 'F10') {
        refButtonSendOrder.current?.click();
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [refSearch]);

  const modalProduto = useMemo(() => {
    return (
      <ModalProductAdd
        isOpen={modalOpenBuy}
        setIsOpen={setOpenModalBuy}
        product={produto}
        onClose={async () => {
          reloadSearch();
          setTabCarrinhoInfo(0);
        }}
      />
    );
  }, [modalOpenBuy, produto, reloadSearch]);

  const modalProdutoCart = useMemo(() => {
    return (
      <ModalProductCard
        isOpen={modalOpenAlterar}
        setIsOpen={setOpenModalAlterar}
        product={produtoCard.current || undefined}
        alterarQtde={(produto, qtde) => {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          upsetItemCart(produto.id, qtde);
        }}
        removeItem={(produto) => {
          deleteItemCart(produto.id);
        }}
        onClose={() => {
          reloadSearch();
        }}
      />
    );
  }, [deleteItemCart, modalOpenAlterar, reloadSearch, upsetItemCart]);

  const gridProdutos = useMemo(() => {
    console.log('data', data);
    return (
      <ReactDataGrid
        onReady={setGridRef}
        columns={isPerfilManager ? columnsManager || [] : columns || []}
        dataSource={data}
        style={{
          height: `calc(${heightGrid[0]}vh - 26%)`,
          with: '100%',
          fontSize: 12,
        }}
        onActiveIndexChange={onActiveIndexChange}
        activeIndex={selectedIndex || 0}
        onRowDoubleClick={() => buy()}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            buy();
          }
        }}
        enableSelection={true}
        // defaultActiveIndex={0}
        loading={loading}
        nativeScroll={true}
        idProperty="id"
        // rowStyle={isPerfilManager ? rowStyle : undefined}
        scrollProps={{
          autoHide: false,
          alwaysShowTrack: true,
        }}
        enableColumnAutosize={true}
      />
    );
  }, [
    data,
    isPerfilManager,
    loading,
    onActiveIndexChange,
    selectedIndex,
    heightGrid,
  ]);

  const gridRelacionados = useMemo(() => {
    return (
      <ReactDataGrid
        onReady={setGridRefRelacionados}
        columns={isPerfilManager ? columnsManager || [] : columns || []}
        dataSource={relacionados ?? []}
        style={{
          height: `calc(${heightGrid[1]}vh - 30%)`,
          with: '100%',
          fontSize: 12,
        }}
        // selected={selected}
        onActiveIndexChange={onActiveIndexRelacionadoChange}
        onRowDoubleClick={() => buy()}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            buy();
          }
        }}
        loading={loadingRelacionados}
        nativeScroll={true}
        idProperty="id"
        // rowStyle={isPerfilManager ? rowStyle : undefined}
        scrollProps={{
          autoHide: true,
          alwaysShowTrack: true,
        }}
        enableColumnAutosize={true}
      />
    );
  }, [
    isPerfilManager,
    loadingRelacionados,
    onActiveIndexRelacionadoChange,
    relacionados,
    heightGrid,
  ]);

  const salvarBalcaoComprador = useCallback(
    (data: FormBalcaoComprador) => {
      const { contatoNome, contatoFone } = data;
      updBalcaoComprador({
        contatoNome,
        contatoFone,
      });
    },
    [updBalcaoComprador]
  );

  const carrinho = useMemo(() => {
    if (loadingCart)
      return (
        <div className="h-[79vh] w-full flex justify-center items-center">
          <svg
            className="animate-spin h-10 w-10 text-slate-400"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        </div>
      );

    return (
      <div className="h-[79vh] bg-slate-300">
        <div className="flex flex-col items-center gap-1 mb-1 bg-slate-300 w-full">
          {/* isPerfilManager */}
          {currentCart.situacaoCarrinho === 0 && isPerfilManager && (
            <div className="flex space-x-2 w-full justify-center">
              {currentCart.produtos?.length > 0 &&
              currentUser.cliente.id !== currentCart.cliente?.id ? (
                <span
                  className={`
                              text-opacity-90 h-full group inline-flex
                              items-center justify-between
                              rounded-md bg-gray-300 px-3 py-2
                              text-sm font-medium text-white
                              hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
                >
                  <div className="line-clamp-1">
                    {truncateStringWithEllipsis(
                      currentCart?.cliente?.nome || '',
                      25
                    )}
                  </div>
                </span>
              ) : (
                <button
                  onClick={() => {
                    setOpenModalSelectCliente(true);
                  }}
                  className={`
                            text-opacity-90 h-full group inline-flex items-center justify-between
                            rounded-md bg-primary1 px-3 py-2
                            text-sm font-medium text-white hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
                >
                  <div className="line-clamp-1">
                    {truncateStringWithEllipsis(
                      currentCart?.cliente?.nome || '',
                      25
                    )}
                  </div>
                  <ChevronDownIcon
                    className={`text-opacity-70 ml-2 h-5 w-5 text-orange-300 transition duration-150 ease-in-out group-hover:text-opacity-80`}
                    aria-hidden="true"
                  />
                </button>
              )}
              <ComboTabelaPreco
                action={(tabelaPreco: TabelaPreco) => {
                  if (tabelaPreco.id === currentCart?.tabelaPrecos?.id) return;
                  saveTabelaPrecos(tabelaPreco.id);
                }}
                tabelaPreco={currentCart?.tabelaPrecos?.id}
              />
            </div>
          )}
          <div className="flex flex-col gap-1 border border-white border-solid w-full">
            <div className="text-xs text-white bg-slate-700 px-2 py-1">
              Carrinho #{currentCart?.id}
            </div>
            {/* produtos */}
            <div
              className={clsx(
                'space-y-2 overflow-hidden overflow-y-auto bg-white',
                isPerfilManager ? 'h-[38vh]' : 'h-[43vh]'
              )}
            >
              {currentCart?.produtos?.map((prod, index) => (
                <ProdutoItem
                  key={index}
                  product={prod}
                  onClick={(produto) => {
                    produtoCard.current = produto;
                    setOpenModalAlterar(true);
                  }}
                />
              ))}
            </div>
            {/* totais */}
            <div className="text-xs text-white bg-slate-700 px-2">Total</div>
            <div className="space-y-2 h-[30vh] overflow-hidden bg-slate-300">
              <div className="mt-2 px-2 text-gray-900 flex justify-between">
                <span className="text-md">Peças</span>
                <span className="font-bold text-md">
                  {/* {showPrecoConsumidorFinal
                    ? parseCurrency(
                        currentCart?.totalProdutosConsumidorFinal || 0
                      )
                    : parseCurrency(currentCart?.totalProdutos || 0)} */}
                  <ExibirPrecoConsumidorFinal
                    item={currentCart}
                    field="totalProdutos"
                    fieldConsumidorFinal="totalProdutosConsumidorFinal"
                    isTotal
                    className="!font-bold"
                  />
                </span>
              </div>
              <div className="px-2 text-gray-900 flex justify-between ">
                <span className="text-md">Frete</span>
                <span className="font-bold text-md">
                  {parseCurrency(currentCart?.frete || 0)}
                </span>
              </div>
              <div className="px-2 text-gray-900 flex justify-between text-lg">
                <span className="">Total</span>
                <span className="font-bold text-blue-700">
                  {/* {showPrecoConsumidorFinal
                    ? parseCurrency(currentCart?.totalConsumidorFinal || 0)
                    : parseCurrency(currentCart?.total || 0)} */}
                  <ExibirPrecoConsumidorFinal
                    item={currentCart}
                    field="total"
                    fieldConsumidorFinal="totalConsumidorFinal"
                    isTotal
                    className="!font-bold"
                  />
                </span>
              </div>
              <div className="py-2 bg-white h-full w-full">
                <div className="flex justify-center items-center px-4">
                  {verifing ? (
                    <div className="flex justify-center items-center text-gray-800 bg-gray-300 rounded-full px-2 py-1 w-full">
                      <LoadingSVG className={classNames('fill-current py-1')} />{' '}
                      <span>Analizando pedido</span>
                    </div>
                  ) : (
                    <div className="w-full space-y-2">
                      <div className="grid grid-cols-4 gap-1 h-6">
                        <button
                          ref={refButtonSendOrder}
                          title="Enviar (F10)"
                          onClick={verifyTransmission}
                          disabled={
                            currentCart.situacaoCarrinho !== 0 ||
                            currentCart.produtos.length === 0
                          }
                          className="text-xs bg-green-500 text-white text-center rounded-sm py-[2px] hover:bg-green-600"
                        >
                          Enviar (F10)
                        </button>
                        <button
                          ref={refButtonSendOrder}
                          title="Baixar"
                          onClick={handleDowloadCartPdf}
                          disabled={
                            currentCart.situacaoCarrinho !== 0 ||
                            currentCart.produtos.length === 0
                          }
                          className="text-xs bg-green-500 text-white text-center rounded-sm py-[2px] hover:bg-green-600"
                        >
                          Baixar PDF
                        </button>
                        <button
                          title="Guardar em meus carrinhos"
                          onClick={saveCart}
                          disabled={
                            currentCart.situacaoCarrinho !== 0 ||
                            currentCart.produtos.length === 0
                          }
                          className="text-xs bg-yellow-200 text-yellow-600 text-center rounded-sm py-[2px] hover:bg-yellow-300"
                        >
                          Guardar
                        </button>
                        <button
                          title="Esvaziar Carrinho"
                          onClick={() => setOpenModalConfirm(true)}
                          disabled={
                            currentCart.situacaoCarrinho !== 0 ||
                            currentCart.produtos.length === 0
                          }
                          className="text-xs bg-slate-200 text-slate-600 text-center rounded-sm py-[2px] hover:bg-slate-300"
                        >
                          Esvaziar
                        </button>
                      </div>
                      <form
                        onSubmit={formBalcaoComprador.handleSubmit(
                          salvarBalcaoComprador
                        )}
                      >
                        {/* <h1 className="text-sm mb-1">Comprador/Balcão</h1> */}
                        <div className="flex flex-col gap-2">
                          <label className="block text-[0.7em]">
                            <span className="text-gray-700">
                              Nome Balcão/Comprador
                            </span>
                            <input
                              type="text"
                              className="form-input text-xs py-[5px]"
                              placeholder=""
                              {...formBalcaoComprador.register('contatoNome')}
                            />
                          </label>
                          {/* telefone */}
                          <div className="grid grid-cols-2 gap-2">
                            <label className="block text-[0.7em]">
                              <span className="text-gray-700">Telefone</span>
                              <Controller
                                name="contatoFone"
                                control={formBalcaoComprador.control}
                                render={({ field }) => (
                                  <MaskedInput
                                    mask={getMask('telefone-11')}
                                    type="text"
                                    className="form-input text-xs py-[5px]"
                                    placeholder=""
                                    {...field}
                                  />
                                )}
                              />
                            </label>
                            <div className="text-right w-full pt-4">
                              <button
                                title="Alterar dados do comprador"
                                type="submit"
                                className="flex justify-center items-center gap-1 text-xs rounded-md w-full py-1 bg-slate-50 border border-slate-300 text-primary2 hover:shadow-lg hover:text-primary1"
                                disabled={currentCart.situacaoCarrinho !== 0}
                              >
                                <SaveIcon className="w-5 h-5" />
                                <span>Salvar</span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                      {/* <div className="flex flex-col gap-1"> */}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }, [
    loadingCart,
    currentCart,
    isPerfilManager,
    currentUser.cliente.id,
    verifing,
    formBalcaoComprador,
    verifyTransmission,
    handleDowloadCartPdf,
    saveCart,
    saveTabelaPrecos,
    salvarBalcaoComprador,
  ]);

  const wraperClassName =
    'text-xs odd:bg-gray-100 odd:rounded-lg px-2 py-1 flex justify-between gap-2 hover:bg-gray-200';

  const informacoes = useMemo(() => {
    if (laodingInfo)
      return (
        <div className="h-[79vh] flex justify-center items-center">
          <svg
            className="animate-spin h-10 w-10 text-slate-400"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        </div>
      );

    return (
      <div className="h-[79vh] flex-1 bg-slate-300">
        <PanelGroup
          className="flex-1 flex flex-col gap-1 border border-white border-solid w-full"
          direction="vertical"
          onLayout={(sizes: number[]) => {
            setHeightPanelInfo([sizes[0], sizes[1]]);
          }}
        >
          <div className="text-xs text-white bg-slate-700 px-2 py-1 flex-1">
            Informações
          </div>
          <Panel
            minSize={10}
            defaultSize={heightPanelInfo[0]}
            className="bg-white"
          >
            <div className="space-y-2 overflow-hidden overflow-y-auto bg-white h-full">
              {attr
                .filter((att: any) => att.formatedText)
                .map((att: any, i: number) => (
                  <div key={i} className={wraperClassName}>
                    <span className="font-bold">{att.key}</span>
                    <span>{att.formatedText}</span>
                  </div>
                ))}
            </div>
          </Panel>
          <PanelResizeHandle className="text-xs text-white bg-slate-700 px-2 py-1 grid grid-cols-3">
            <span>Código/Fabricante</span>
            <div className="flex justify-center">
              <svg
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3 7.5 7.5 3m0 0L12 7.5M7.5 3v13.5m13.5 0L16.5 21m0 0L12 16.5m4.5 4.5V7.5"
                />
              </svg>
            </div>
          </PanelResizeHandle>
          <Panel
            minSize={10}
            defaultSize={heightPanelInfo[1]}
            className="bg-white"
          >
            <div className="space-y-2 overflow-hidden overflow-y-auto bg-white h-full">
              {fab.map((fab: any, i: number) => (
                <div key={i} className={wraperClassName}>
                  <span className="font-bold">{fab.idFabricante}</span>
                  <span>{fab.fabricante.fabricanteDesc}</span>
                </div>
              ))}
            </div>
          </Panel>
        </PanelGroup>
      </div>
    );
  }, [attr, fab, heightPanelInfo, laodingInfo]);

  return (
    <div className="flex flex-no-wrap relative h-screen bg-slate-300">
      {/* menu left */}
      <div
        className={`${
          menuMin ? 'w-16' : 'w-64'
        } transition-all duration-500 ease-in-out absolute sm:relative bg-gray-500 shadow md:h-screen flex-col justify-between hidden sm:flex z-10`}
      >
        <>
          <MenuLeftMin menuMin={menuMin} setMenuMin={setMenuMin} />
          <MenuLeftBottom orientation="column" />
        </>
      </div>
      {/* munu top */}
      <div className="w-full">
        <div
          style={{ backgroundColor: colorToolbar }}
          className={clsx(
            'h-[4.5em] w-full md:grid md:grid-cols-3 drop-shadow-md hidden relative z-50'
          )}
        >
          <span className="px-2 h-[4.4em]">
            <LogoNameBd className="fill-white w-full h-[4.4em]" />
            {/* <LogoNome className="fill-white w-[15vw]" /> */}
          </span>
          <span className="flex-[6] flex justify-center h-[4.4em] gap-1 items-center">
            {/* <PopoverCategoria position="left" /> */}
            <MainSearchOrder
              ref={refSearch}
              onSearch={(text) => {
                setSearchText(text);
                setSearchType('texto');
                search(text);
              }}
            />
            <span className="text-[0.8em] text-white/50">(F4)</span>
          </span>
          <span className="flex-[2] px-2 flex justify-center gap-8 items-center">
            <WhatsappMenu />
          </span>
        </div>
        {/* content */}
        <div className="p-1 h-[89vh] overflow-hidden">
          <div className="grid grid-cols-[3fr_1fr] overflow-hidden">
            {/* produtos */}
            <div className="bg-slate-300">
              <div className="flex gap-1 items-center bg-white mx-1 h-10">
                {/* ModalBuscaPorCategorias */}
                {showPesquisaCategoria ? (
                  <div className="h-9 flex items-center gap-1">
                    <ModalBuscaPorCategorias
                      ref={refSearchCategoria}
                      searchTipo={searchType}
                      onSearch={(categ) => {
                        searchByCategoria(categ.id);
                        setSearchType('categoria');
                        setCategSearch(categ.id);
                      }}
                    />
                    <span className="text-xs opacity-50">(F9)</span>
                    <div className="w-[1px] bg-slate-200 h-10 ml-2" />
                  </div>
                ) : null}

                {/* ModalBuscaPorMarcaModelo */}
                {showPesquisaVeiculo ? (
                  <div className="h-9 flex items-center gap-1">
                    <ModalBuscaPorMarcaModelo
                      ref={refSearchMarcaModelo}
                      searchTipo={searchType}
                      onSearch={(modelo) => {
                        searchByModelo(modelo.id);
                        setSearchType('marcaModelo');
                        setMarcaModeloSearch(modelo.id);
                      }}
                    />
                    <span className="text-xs opacity-50">(F8)</span>
                    <div className="w-[1px] bg-slate-200 h-10 mx-2" />
                    <ModalBuscaPorVeiculo
                      ref={refSearchVeiculo}
                      searchTipo={searchType}
                      onSearch={(modelo) => {
                        searchByModelo(modelo.id);
                        setSearchType('veiculo');
                        setVeiculoSearch(modelo.id);
                      }}
                    />
                    <span className="text-xs opacity-50">(F6)</span>
                  </div>
                ) : null}
              </div>
              {/* grid produtos  */}
              <div
                ref={refWraperGrids}
                className="grid grid-cols-[5fr_1fr] gap-1 overflow-hidden bg-slate-300 px-1 py-2"
              >
                {/* grid de produtos */}
                <PanelGroup
                  className="flex-1"
                  direction="vertical"
                  onLayout={(sizes: number[]) => {
                    if (sizes[0] && sizes[1]) {
                      setHeightGrid(sizes);
                    }
                    // gridRef.current?.setHeight(`${grid1}vh`);
                    // gridRefRelacionados.current?.setHeight(`${grid2}vh`);
                  }}
                >
                  <Panel defaultSize={heightGrid[0]} minSize={35}>
                    {gridProdutos}
                  </Panel>
                  <PanelResizeHandle>
                    <div className="grid grid-cols-2 text-xs text-white bg-slate-700 px-2">
                      <span>Produtos Relacionados</span>
                      <svg
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="w-4 h-4"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M3 7.5 7.5 3m0 0L12 7.5M7.5 3v13.5m13.5 0L16.5 21m0 0L12 16.5m4.5 4.5V7.5"
                        />
                      </svg>
                    </div>
                  </PanelResizeHandle>
                  <Panel defaultSize={heightGrid[1]} minSize={35}>
                    {/* grid de produtos relacionados */}
                    {gridRelacionados}
                  </Panel>
                </PanelGroup>
                {/* imagem do produto e veiculos compativeis */}
                <div className="space-y-1">
                  <div className="flex space-x-4 border border-white border-solid bg-white">
                    <div className="border-4 border-solid border-white w-[265px]">
                      {/* imagem */}
                      <div className="relative z-0 h-[200px]">
                        {currentImage ? (
                          <div className="w-full h-full bg-white">
                            <LazyLoadingImage
                              alt={'imagem do produto'}
                              height={255}
                              width={255}
                              src={currentImage.big}
                              efect="blur"
                            />
                          </div>
                        ) : (
                          <div className="w-[255px] h-[200px] flex justify-center items-center bg-white">
                            <span className="text-2xl text-gray-400">
                              Sem Imagem
                            </span>
                          </div>
                        )}
                      </div>
                      <div className="relative z-10 text-xs p-1 h-[60px] text-black bg-white font-bold">
                        {produto?.descricaoSite || 'Selecione um produto'}
                      </div>
                      {/* mais informações do produto */}
                      {/* <button
                        onClick={() => {
                          produto?.id && loadProdutoInfo(produto.id);
                        }}
                        className="
                                    flex gap-1 items-center justify-center
                                    h-[20px] px-2 py-1 my-1
                                    rounded-md
                                    bg-white text-blue-600
                                    hover:bg-blue-100
                                    focus:outline-none
                                    focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-opacity-75
                                  "
                      >
                        {laodingInfo ? (
                          <svg
                            className="animate-spin h-5 w-5 mr-3"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              className="opacity-25 bg-white"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              fill="transparent"
                              strokeWidth="4"
                            ></circle>
                            <path
                              className="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                          </svg>
                        ) : (
                          <InformationCircleIcon className="h-5 w-5" />
                        )}
                        <span className="text-xs ">Mais informações</span>
                      </button> */}
                    </div>
                    <div className="w-[50px] h-[250px] bg-slate-300 flex flex-col items-center gap-1 p-1 overflow-hidden overflow-y-auto">
                      {produto?.images.length
                        ? produto?.images?.map((img, i) => (
                            <button
                              key={i}
                              className="hover:border-[1px] hover:border-slate-500"
                              onClick={() => {
                                setCurrentImage(img as any);
                              }}
                            >
                              <img src={img.small} className="w-16" />
                            </button>
                          ))
                        : null}
                    </div>
                  </div>
                  <div className="text-xs text-white bg-slate-700 px-2 flex justify-between items-center border-white border-solid">
                    <span>Veículos Compatíveis</span>
                    <div>
                      <button
                        className={_classNamesViewCompativel(
                          veiculosCompativeisView === 'line'
                        )}
                        type="button"
                        onClick={() => setVeiculosCompativeisView('line')}
                        title="Visão Lista"
                      >
                        <ViewListIcon className="h-4 w-4" />
                      </button>
                      <button
                        className={_classNamesViewCompativel(
                          veiculosCompativeisView === 'card'
                        )}
                        type="button"
                        onClick={() => setVeiculosCompativeisView('card')}
                        title="Visão Cartão"
                      >
                        <ViewGridIcon className="h-4 w-4 rotate-90 py-[1px]" />
                      </button>
                    </div>
                  </div>
                  <div
                    className={`${
                      veiculosCompativeisView === 'line'
                        ? 'space-y-3'
                        : 'grid grid-cols-2 gap-3'
                    } h-[calc(81vh-300px)] py-1 overflow-hidden overflow-y-auto px-2 border border-white border-solid`}
                  >
                    {veiculosCompativeis.map((veiculo, index) =>
                      veiculosCompativeisView === 'line' ? (
                        <ShowVeiculoCompativelLine
                          key={index}
                          veiculo={veiculo}
                          marcas={marcas}
                          onClick={(veiculo) => {
                            searchByModelo(veiculo.modeloId);
                          }}
                        />
                      ) : (
                        <ShowVeiculoCompativelCard
                          key={index}
                          veiculo={veiculo}
                          marcas={marcas}
                          modelos={modelos}
                          onClick={(veiculo) => {
                            searchByModelo(veiculo.modeloId);
                          }}
                        />
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* carrinho de compras e informações */}
            <div>
              <Tab.Group
                selectedIndex={tabCarrinhoInfo}
                onChange={setTabCarrinhoInfo}
              >
                <Tab.List className="flex space-x-1 rounded-xl bg-gray-100 p-1 h-10">
                  <Tab className={({ selected }) => tabClass(selected)}>
                    <div className="flex gap-1 items-center px-4">
                      <span className="flex items-center justify-center rounded-full p-1 bg-white border border-primary1 border-solid">
                        <ShoppingCartIcon
                          className={`w-5 h-5 text-primary1 `}
                        />
                      </span>
                      <span>Carrinho</span>
                      <span className="flex-1" />
                      <span className="text-xs text-slate-100">
                        ({currentCart.produtos?.length || 0})
                      </span>
                    </div>
                  </Tab>
                  <Tab className={({ selected }) => tabClass(selected)}>
                    <div className="flex gap-1 items-center px-4">
                      <span className="flex items-center justify-center rounded-full p-1 bg-white border border-primary1 border-solid">
                        <InformationCircleIcon
                          className={`w-5 h-5 text-primary1 `}
                        />
                      </span>
                      <span>Informações</span>
                      <span className="flex-1" />
                      <span className="text-xs text-slate-100"></span>
                    </div>
                  </Tab>
                </Tab.List>
                <Tab.Panels className="mt-2">
                  <Tab.Panel className={() => tabPanelClass()}>
                    {carrinho}
                  </Tab.Panel>
                  <Tab.Panel className={() => tabPanelClass()}>
                    {informacoes}
                  </Tab.Panel>
                </Tab.Panels>
              </Tab.Group>
            </div>
          </div>
        </div>
      </div>
      {modalProduto}
      {modalProdutoCart}
      <ModalSendOrder
        isOpen={modalOpenSendOrder}
        setIsOpen={setOpenModalSendOrder}
        vendedor={currentCart.vendedor?.id ?? 0}
        resume={{
          total: currentCart.total,
          frete: currentCart.frete,
          qtde: currentCart.qtdeTotal,
        }}
        action={transmission}
      />
      <ModalConfirmation
        isOpen={modalOpenConfirm}
        setIsOpen={setOpenModalConfirm}
        title="Confirmação"
        question="Remover todos os itens do carrinho?"
        answer={() => {
          if (currentCart.produtos.length === 0) {
            toast.error('Sem produtos, verifique');
            return;
          }
          cleanCart();
        }}
      />
      <ModalClienteSelect
        isOpen={modalOpenSelectCliente}
        setIsOpen={setOpenModalSelectCliente}
        updCard={(cliente) => {
          updateDefaultCli(cliente.id);
          updateDefaultTab(cliente.tabelaId);
          new Promise((t) => setTimeout(t, 500)).then(() => {
            saveCliente(cliente.id);
          });
        }}
        clienteId={currentCart.cliente?.id}
      />
    </div>
  );
}
