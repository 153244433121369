/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useRef, useState } from 'react';
import { Popover, Switch } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/outline';

import { classNames } from '../utils/functions';
import Badge from './badge';
import { useSearchParams } from 'react-router-dom';
import { usePopper } from 'react-popper';

export interface Filter {
  display: string;
  value: any;
}

interface SelectFilterProps {
  filters: Filter[];
  placeholder: string;
  action: (filters: Filter[]) => void;
}

export default function SelectFilter(props: SelectFilterProps) {
  const sf = Array(props.filters.length).fill(false);
  const selectedFilters = useRef<boolean[]>(sf);
  const [searchParams] = useSearchParams();

  const [referenceElement, setReferenceElement] = useState({} as any);
  const [popperElement, setPopperElement] = useState({} as any);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
    strategy: 'fixed' ? 'fixed' : 'absolute',
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setRender] = useState(0);

  useEffect(() => {
    const params = searchParams;
    if (params.get('situacao')) {
      const sit = params.get('situacao');
      if (sit) {
        const split = sit?.split(',');
        const sels = selectedFilters.current;
        split?.forEach((s) => {
          for (let index = 0; index < props.filters.length; index++) {
            const el = props.filters[index];
            if (el.value === parseInt(s)) {
              sels[index] = true;
            }
          }
        });
        selectedFilters.current = sels;
      }
    }
  }, [props.filters, searchParams, sf]);

  const setSelected = (index: number) => {
    const array = selectedFilters.current;
    array[index] = !array[index];
    selectedFilters.current = array;
    const vals: any[] = [];
    props.filters.forEach((e, i) => {
      if (selectedFilters.current[i]) vals.push(e.value);
    });
    setTimeout(() => {
      props.action(vals);
      setRender((state) => state + 1);
    }, 100);
  };

  return (
    <Popover className="">
      {({ open }) => {
        return (
          <>
            <Popover.Button
              className={classNames(
                open ? '' : 'text-opacity-90',
                'text-sm text-gray-600 group bg-gray-200 px-3 py-2 rounded-md inline-flex items-center font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75'
              )}
              ref={setReferenceElement}
            >
              {selectedFilters.current.reduce<boolean>((acc, cur) => {
                if (cur) {
                  acc = cur;
                }
                return acc;
              }, false) ? (
                <div className="line-clamp-2">
                  {props.filters.map((filter, i) => {
                    if (selectedFilters.current[i] === true)
                      return (
                        <Badge
                          key={i}
                          label={filter.display}
                          id={filter.value}
                        />
                      );
                  })}
                </div>
              ) : (
                <div>{props.placeholder}</div>
              )}
              <ChevronDownIcon
                className={`${open ? '' : 'text-opacity-70'}
                  ml-2 h-5 w-5 text-gray-400 group-hover:text-opacity-80 transition ease-in-out duration-150`}
                aria-hidden="true"
              />
            </Popover.Button>

            <Popover.Panel
              className={classNames([
                'p-4 z-10 bg-white shadow rounded-xl flex flex-col space-y-1 text-gray-600',
              ])}
              ref={setPopperElement}
              style={styles.popper}
              {...attributes.popper}
            >
              {props.filters.map((it, index) => (
                <Switch.Group key={index}>
                  <div className="flex items-center justify-between">
                    <Switch.Label className="mr-4">{it.display}</Switch.Label>
                    <Switch
                      checked={selectedFilters.current[index]}
                      onChange={() => setSelected(index)}
                      className={`${
                        selectedFilters.current[index]
                          ? 'bg-blue-600'
                          : 'bg-gray-200'
                      } relative inline-flex items-center h-6 rounded-full w-11 transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                    >
                      <span
                        className={`${
                          selectedFilters.current[index]
                            ? 'translate-x-6'
                            : 'translate-x-1'
                        } inline-block w-4 h-4 transform bg-white rounded-full transition-transform`}
                      />
                    </Switch>
                  </div>
                </Switch.Group>
              ))}
            </Popover.Panel>
          </>
        );
      }}
    </Popover>
  );
}
