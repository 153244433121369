/* eslint-disable @typescript-eslint/no-explicit-any */
import { Popover } from '@headlessui/react';
import {
  ChevronDownIcon,
  InformationCircleIcon,
  XIcon,
} from '@heroicons/react/outline';
import { useEffect, useState } from 'react';
import { usePopper } from 'react-popper';
import { CondicoesPgto, FormasPgto, Transportadora } from '../app.models';
import {
  condicoesPgto,
  formasPgto,
  transportadorasFrete,
} from '../app.service';
import { classNames } from '../utils/functions';

type Props = {
  valorTotal: number;
  direction: 'row' | 'column';
  condicao: number | undefined;
  selectedCondicao: (condicao: number | undefined) => void;
  forma: number | undefined;
  selectedForma: (forma: number | undefined) => void;
  transportadora: number | undefined;
  selectedTransportadora: (transportadora: number | undefined) => void;
  error?: 'forma' | 'cond' | 'transp' | undefined;
};

export default function CondFormaTransp(props: Props) {
  const [condicao, setCondicao] = useState<CondicoesPgto>();
  const [condicoes, setCondicoes] = useState<CondicoesPgto[]>([]);
  const [refEleCond, setRefEleCond] = useState({} as any);
  const [popperEleCond, setPopperEleCond] = useState({} as any);
  const { styles: stylesCond, attributes: attribCond } = usePopper(
    refEleCond,
    popperEleCond,
    {
      placement: 'right',
      modifiers: [{ name: 'preventOverflow', enabled: true }],
    }
  );

  const [forma, setForma] = useState<FormasPgto>();
  const [formas, setFormas] = useState<FormasPgto[]>([]);
  const [refEleForma, setRefEleForma] = useState({} as any);
  const [popperEleForma, setPopperEleForma] = useState({} as any);
  const { styles: stylesForma, attributes: attribForma } = usePopper(
    refEleForma,
    popperEleForma,
    {
      placement: 'right',
      modifiers: [
        { name: 'preventOverflow', enabled: true },
        { name: 'offset', options: { offset: [0, -150] } },
      ],
    }
  );

  const [transportadora, setTransportadora] = useState<Transportadora>();
  const [transportadoras, setTransportadoras] = useState<Transportadora[]>([]);
  const [refEleTransp, setRefEleTransp] = useState({} as any);
  const [popperEleTransp, setPopperEleTransp] = useState({} as any);
  const { styles: stylesTransp, attributes: attribTransp } = usePopper(
    refEleTransp,
    popperEleTransp,
    {
      placement: 'left',
      modifiers: [{ name: 'preventOverflow', enabled: true }],
    }
  );

  const [error, setError] = useState<'forma' | 'cond' | 'transp' | undefined>();
  const [info, setInfo] = useState<string>();

  useEffect(() => {
    if (props.forma) {
      const f = formas.find((f) => f.id === props.forma);
      setForma(f);
    }
    if (props.condicao) {
      const c = condicoes.find((c) => c.id === props.condicao);
      setCondicao(c);
    }
    if (props.transportadora) {
      const t = transportadoras.find((t) => t.id === props.transportadora);
      setTransportadora(t);
    }
  }, [
    condicoes,
    formas,
    props.condicao,
    props.forma,
    props.transportadora,
    transportadoras,
  ]);

  useEffect(() => {
    condicoesPgto().then((data) => {
      const filtered = data
        .sort((a, b) => b.valorMinimo - a.valorMinimo)
        .sort((a, b) => b.descricao.localeCompare(a.descricao))
        .filter(
          (condicao) =>
            condicao.isVisible && condicao.valorMinimo <= props.valorTotal
        );
      setCondicoes(filtered);
    });
    formasPgto().then((data) => {
      const filtered = data.filter((forma) => forma.isVisible);
      setFormas(filtered);
    });
    transportadorasFrete().then((data) => {
      const filtered = data.filter(
        (transportadora) => transportadora.isVisible
      );
      setTransportadoras(filtered);
    });
  }, [props.valorTotal]);

  useEffect(() => {
    if (props?.error) {
      switch (props.error) {
        case 'cond':
          setError('cond');
          setInfo('Selecione uma condição de pagamento');
          break;

        case 'forma':
          setError('forma');
          setInfo('Selecione uma forma de pagamento');
          break;

        case 'transp':
          setError('transp');
          setInfo('Selecione uma transportadora');
          break;
      }
      new Promise((resolve) => setTimeout(resolve, 3000)).then(() => {
        setError(undefined);
        setInfo(undefined);
      });
    }
  }, [props?.error]);

  useEffect(() => {
    if (condicao) {
      props.selectedCondicao(condicao.id);
    }
    if (forma) {
      props.selectedForma(forma.id);
    }

    if (transportadora) {
      props.selectedTransportadora(transportadora.id);
    }
  }, [condicao, forma, props, transportadora]);

  return (
    <>
      <div
        className={classNames(
          'grid gap-2',
          props.direction === 'row' ? 'grid-cols-3' : 'grid-rows-3'
        )}
      >
        {/* forma de pagamento */}
        <Popover className="relative">
          {({ open, close }) => (
            <>
              <Popover.Button
                ref={setRefEleCond}
                className={`
                ${error === 'forma' && 'animate-bounce'}
                ${open ? '' : 'text-opacity-90'}
                h-full w-full group inline-flex items-center justify-between rounded-md bg-primary1 px-3 py-2 text-base font-medium text-white hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
              >
                <div className="line-clamp-2">
                  {forma?.descricao || 'Forma de Pagamento'}
                </div>
                <ChevronDownIcon
                  className={`${open ? '' : 'text-opacity-70'}
                  ml-2 h-5 w-5 text-orange-300 transition duration-150 ease-in-out group-hover:text-opacity-80`}
                  aria-hidden="true"
                />
              </Popover.Button>

              <Popover.Panel
                className="absolute z-10"
                ref={setPopperEleCond}
                style={stylesCond.popper}
                {...attribCond.popper}
              >
                <div className="rounded-lg p-2 shadow-lg ring-1 ring-black ring-opacity-5 max-w-lg bg-white h-[40vh] w-[20vw]">
                  <div className="flex justify-between my-2 text-primary1 font-bold">
                    <span className="">Formas de Pagamento</span>
                    <button
                      className="group hover:bg-primary1 hover:text-white p-1 rounded-full"
                      onClick={() => close()}
                    >
                      <XIcon className="w-5 h-5" />
                    </button>
                  </div>
                  <div className="relative grid gap-1 grid-cols-1 overflow-hidden overflow-y-auto">
                    {formas.map((item) => (
                      <button
                        key={item.id}
                        className={classNames(
                          'flex rounded-lg p-2  ',
                          'transition duration-150 ease-in-out',
                          'focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50',
                          forma?.id === item.id
                            ? 'bg-primary1 text-white'
                            : 'text-gray-900 bg-white hover:bg-gray-100'
                        )}
                        onClick={() => {
                          setForma((state) =>
                            state?.id === item.id ? undefined : item
                          );
                          close();
                        }}
                      >
                        <div className="ml-4">
                          <p className="text-sm font-medium">
                            {item.descricao}
                          </p>
                        </div>
                      </button>
                    ))}
                  </div>
                </div>
              </Popover.Panel>
            </>
          )}
        </Popover>

        {/* condição de pagamento */}
        <Popover className="relative">
          {({ open, close }) => (
            <>
              <Popover.Button
                ref={setRefEleForma}
                className={`
                ${error === 'cond' && 'animate-bounce'}
                ${open ? '' : 'text-opacity-90'}
                h-full w-full group inline-flex items-center justify-between rounded-md bg-primary1 px-3 py-2 text-base font-medium text-white hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
              >
                <div className="line-clamp-2">
                  {condicao?.descricao || 'Condição de Pagamento'}
                </div>
                <ChevronDownIcon
                  className={`${open ? '' : 'text-opacity-70'}
                  ml-2 h-5 w-5 text-orange-300 transition duration-150 ease-in-out group-hover:text-opacity-80`}
                  aria-hidden="true"
                />
              </Popover.Button>

              <Popover.Panel
                className="absolute z-10"
                ref={setPopperEleForma}
                style={stylesForma.popper}
                {...attribForma.popper}
              >
                <div className="rounded-lg p-2 shadow-lg ring-1 ring-black ring-opacity-5 w-[20vw] bg-white">
                  <div className="flex justify-between my-2 text-primary1 font-bold">
                    <span className="">Condições de Pagamento</span>
                    <button
                      className="group hover:bg-primary1 hover:text-white p-1 rounded-full"
                      onClick={() => close()}
                    >
                      <XIcon className="w-5 h-5" />
                    </button>
                  </div>
                  <div className="relative grid gap-1 grid-cols-1 h-[40vh] overflow-hidden overflow-y-auto">
                    {condicoes.map((item) => (
                      <button
                        key={item.id}
                        className={classNames(
                          'flex rounded-lg p-2 text-left',
                          'transition duration-150 ease-in-out',
                          'focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50',
                          condicao?.id === item.id
                            ? 'bg-primary1 text-white'
                            : 'text-gray-900 bg-white hover:bg-gray-100'
                        )}
                        onClick={() => {
                          setCondicao((state) =>
                            state?.id === item.id ? undefined : item
                          );
                          close();
                        }}
                      >
                        <div className="ml-4">
                          <p className="text-sm font-medium">
                            {item.descricao}
                          </p>
                        </div>
                      </button>
                    ))}
                  </div>
                </div>
              </Popover.Panel>
            </>
          )}
        </Popover>

        {/* transportadoras */}
        <Popover className="relative">
          {({ open, close }) => (
            <>
              <Popover.Button
                ref={setRefEleTransp}
                className={`
                ${error === 'transp' && 'animate-bounce'}
                ${open ? '' : 'text-opacity-90'}
                h-full w-full group inline-flex items-center justify-between rounded-md bg-primary1 px-3 py-2 text-base font-medium text-white hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
              >
                <div className="line-clamp-2">
                  {transportadora?.transpDesc || 'Transportadora'}
                </div>
                <ChevronDownIcon
                  className={`${open ? '' : 'text-opacity-70'}
                  ml-2 h-5 w-5 text-orange-300 transition duration-150 ease-in-out group-hover:text-opacity-80`}
                  aria-hidden="true"
                />
              </Popover.Button>

              <Popover.Panel
                className="absolute z-10"
                ref={setPopperEleTransp}
                style={stylesTransp.popper}
                {...attribTransp.popper}
              >
                <div className="rounded-lg p-2 shadow-lg ring-1 ring-black ring-opacity-5 bg-white w-[25vw]">
                  <div className="flex justify-between my-2 text-primary1 font-bold">
                    <span className="">Transportadoras</span>
                    <button
                      className="group hover:bg-primary1 hover:text-white p-1 rounded-full"
                      onClick={() => close()}
                    >
                      <XIcon className="w-5 h-5" />
                    </button>
                  </div>
                  <div className="relative grid gap-1 grid-cols-1 h-[40vh] overflow-hidden overflow-y-auto">
                    {transportadoras.map((item) => (
                      <button
                        key={item.id}
                        className={classNames(
                          'flex rounded-lg p-2 text-left',
                          'transition duration-150 ease-in-out',
                          'focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50',
                          transportadora?.id === item.id
                            ? 'bg-primary1 text-white'
                            : 'text-gray-900 bg-white hover:bg-gray-100'
                        )}
                        onClick={() => {
                          setTransportadora((state) =>
                            state?.id === item.id ? undefined : item
                          );
                          close();
                        }}
                      >
                        <div className="ml-4">
                          <p className="text-sm font-medium text-left">
                            {item.transpDesc}
                          </p>
                        </div>
                      </button>
                    ))}
                  </div>
                </div>
              </Popover.Panel>
            </>
          )}
        </Popover>
      </div>
      {info && (
        <div className="bg-amber-300 text-amber-900 flex justify-center items-center space-x-2 rounded-lg border-2 border-amber-900 border-dashed my-2 py-2">
          <InformationCircleIcon className="w-8 h-8" /> <span>{info}</span>
        </div>
      )}
    </>
  );
}
