/* eslint-disable @typescript-eslint/no-explicit-any */
import { Tab } from '@headlessui/react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useMedia from 'use-media';
import { ROUTES_MAP } from '../../app.map.routes';
import { Modelo } from '../../app.models';
import { classNames } from '../../utils/functions';
import Display from './display';
// import FiltersMarcaModelo from './filtersMarcaModelo';
import FiltersText from './filtersText';
import { useProduct } from './product.hook';
import { getTake } from '../../utils/functions';
import { EnumOrigens } from '../../utils/origens';
// import TabSerach from '../../components/tabSerach';
// import MultiSearch from '../../components/MultiSearch';
import MultiSearchSimple from '../../components/MultiSearchSimple';

interface SelectTipoProps {
  refPai: HTMLDivElement | null;
}

export default function SelectTipo({ refPai }: SelectTipoProps) {
  const isSmall = useMedia({ maxWidth: 480 });
  const navigate = useNavigate();
  const _take = getTake();
  const {
    filterTipo,
    filterModelo,
    filterModeloMarca,
    selectedMarca,
    selectedModelo,
    selectedLastModelo,
    // setSelectedModelo,
    selectedTabTipo,
    setSelectedTabTipo,
    selectedTabTipoPosition,
    setSelectedTabTipoPosition,
    setOrigem,
  } = useProduct();

  const [filter, setFilter] = useState<string>('');

  const filterLocalModelo = (mod: Modelo) => {
    return filter.length > 0
      ? mod.modeloDesc
          .replace(/[^a-zA-Z0-9]/g, '')
          .toLowerCase()
          .match(
            new RegExp(
              `${filter.replace(/[^a-zA-Z0-9]/g, '').toLowerCase()}`,
              'igm'
            )
          )
      : true;
  };

  useEffect(() => {
    setTimeout(() => {
      refPai &&
        refPai?.scrollTo({
          top: selectedTabTipoPosition || 0,
          behavior: 'smooth',
        });
    }, 300);
  }, [refPai, selectedModelo, selectedTabTipoPosition]);

  const defaultTabClass = (selected: boolean) =>
    classNames(
      'w-full  leading-5 font-medium text-primary1',
      'focus:outline-none bg-gray-100',
      selected
        ? 'text-black text-lg border-b-2 border-b-primary1 border-b-solid font-bold'
        : 'text-black text-sm border-b-2 border-b-gray-100 border-b-solid hover:bg-slate-400 hover:text-white'
    );

  if (selectedModelo?.id) return <FiltersText />;
  // if (selectedModelo?.id) return <FiltersMarcaModelo />;
  else
    return (
      <>
        {/* <MultiSearch /> */}
        <MultiSearchSimple />
        {/* <TabSerach /> */}
        <div className="px-2 flex justify-center items-center gap-3 py-1 bg-white">
          <h1>
            <span className="font-bold">Selecione um Modelo</span> ou procure
            pela descrição:
          </h1>

          <input
            type="search"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            // onKeyPress={(e) => {
            //   const regex = new RegExp('^[a-zA-Z0-9 -]+$');
            //   const key = String.fromCharCode(
            //     !e.charCode ? e.which : e.charCode
            //   );
            //   if (!regex.test(key)) {
            //     e.preventDefault();
            //     return false;
            //   }
            // }}
            placeholder="descrição do modelo"
            className="w-full md:max-w-xs px-1 rounded-lg border-2 border-black focus:outline-none focus:border-primary1 focus:border-solid focus:border-2 focus:bg-white"
          />
        </div>
        {/* <h1 className="flex justify-center items-center gap-3 text-xs">
          (Clique no modelo para ver os produtos)
        </h1> */}
        <Tab.Group
          defaultIndex={0}
          selectedIndex={selectedTabTipo || 0}
          onChange={(index) => setSelectedTabTipo(index)}
        >
          <Tab.List className="flex p-1 bg-gray-100 rounded-xl">
            <Tab className={({ selected }) => defaultTabClass(selected)}>
              Todos
            </Tab>
            {filterTipo &&
              filterTipo.length > 1 &&
              !isSmall &&
              filterTipo.map((tp) => (
                <Tab
                  key={tp.id}
                  className={({ selected }) => defaultTabClass(selected)}
                >
                  {tp.tipoVeiculoDesc}
                </Tab>
              ))}
          </Tab.List>
          <Tab.Panels className={`h-[67vh] overflow-hidden overflow-y-auto`}>
            {/* todos */}
            <Tab.Panel className="flex flex-wrap">
              {selectedMarca?.id &&
                filterModeloMarca(selectedMarca?.id)
                  .filter(filterLocalModelo)
                  .map((m) => (
                    <Display
                      key={m.id}
                      id={m.id}
                      selected={selectedLastModelo?.id}
                      title={m.modeloDesc}
                      subTitle={`Ano: ${m.anoI} - ${m.anoF}`}
                      img={m.images[0].img}
                      onClick={() => {
                        setOrigem(EnumOrigens.MARCA_MODELO);
                        setSelectedTabTipoPosition(refPai?.scrollTop || 0);
                        // setSelectedModelo(m);
                        navigate(
                          ROUTES_MAP.product +
                            '/?marca=' +
                            selectedMarca?.id +
                            '&tipo=' +
                            m.id +
                            '&take=' +
                            _take.toString() +
                            `&skip=0&tags={"modelos":[${m.id}]}&filtros=`
                        );
                      }}
                    />
                  ))}
            </Tab.Panel>
            {/* se o tipo for maior que 1 */}
            {filterTipo &&
              filterTipo.length > 1 &&
              !isSmall &&
              filterTipo.map((tp) => (
                <Tab.Panel className="flex flex-wrap" key={tp.id}>
                  {filterModelo(tp.id)
                    .filter(filterLocalModelo)
                    .map((m) => (
                      <Display
                        key={m.id}
                        id={m.id}
                        selected={selectedLastModelo?.id}
                        title={m.modeloDesc}
                        subTitle={`Ano: ${m.anoI} - ${m.anoF}`}
                        img={m.images[0].img}
                        onClick={() => {
                          setOrigem(EnumOrigens.MARCA_MODELO);
                          setSelectedTabTipoPosition(refPai?.scrollTop || 0);
                          // setSelectedModelo(m);
                          navigate(
                            ROUTES_MAP.product +
                              '/?marca=' +
                              selectedMarca?.id +
                              '&tipo=' +
                              m.id +
                              '&take=' +
                              _take.toString() +
                              `&skip=0&tags={"modelos":[${m.id}]}&filtros=`
                          );
                        }}
                      />
                    ))}
                </Tab.Panel>
              ))}
          </Tab.Panels>
        </Tab.Group>
      </>
    );
}
