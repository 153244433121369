import { Dialog, Transition } from '@headlessui/react';
import { ThumbDownIcon, ThumbUpIcon, XIcon } from '@heroicons/react/outline';
import { Fragment, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { IBanner } from '../../app.models';
import Button from '../../components/layout/button';

interface uploadBannerFormModalProps {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  data: IBanner;
  setData: (data: IBanner) => void;
}

export default function UploadBannerFormModal(
  props: uploadBannerFormModalProps
) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<IBanner>();

  useEffect(() => {
    reset(props.data);
  }, [props.data, reset]);

  function closeModal() {
    props.setIsOpen(false);
  }

  const confirm = (data: unknown) => {
    props.setData(data as IBanner);
    reset();
    closeModal();
  };

  return (
    <div>
      <Transition appear show={props.isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={closeModal}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black opacity-20" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-xl p-0 my-1 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <Dialog.Title
                  as="h3"
                  className="flex justify-between mb-2 bg-primary2 text-default-white px-4 py-2"
                >
                  <span className="text-lg font-bold leading-6 font-display">
                    Cadastro de Banner
                  </span>
                  <button onClick={closeModal} className="hover:text-gray-400">
                    <XIcon className="w-5 h-5" />
                  </button>
                </Dialog.Title>
                <form
                  onSubmit={handleSubmit(confirm)}
                  className="flex flex-col font-display py-4 px-6"
                >
                  <div className="text-md font-display mb-8 space-y-4">
                    <input type={'hidden'} {...register('id')} />
                    <label className="relative block">
                      <span className="">Descrição do Banner *</span>
                      <input
                        className="placeholder:italic placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md py-2 pl-3 pr-3 shadow-sm focus:outline-none focus:border-gray-300 focus:ring-gray-300 focus:ring-1 sm:text-sm"
                        placeholder="Descrição do banner"
                        type="text"
                        {...register('descricao', { required: true })}
                      />
                      {errors && errors.descricao && (
                        <div className="text-sm text-red-500">
                          (*) Campo obrigatório
                        </div>
                      )}
                    </label>
                    <label className="relative block">
                      <span className="">Link de Destino *</span>
                      <input
                        className="placeholder:italic placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md py-2 pl-3 pr-3 shadow-sm focus:outline-none focus:border-gray-300 focus:ring-gray-300 focus:ring-1 sm:text-sm"
                        placeholder="Link de Destino"
                        type="text"
                        {...register('urlDestino', { required: true })}
                      />
                      {errors && errors.urlDestino && (
                        <div className="text-sm text-red-500">
                          (*) Campo obrigatório
                        </div>
                      )}
                    </label>
                  </div>
                  <div className="flex justify-between">
                    <Button
                      title="Fechar"
                      type="button"
                      icon={<ThumbDownIcon className="w-4 h-4" />}
                      onClick={closeModal}
                      color="bg-red-500 text-white hover:bg-red-400 hover:text-gray-100"
                    />
                    <Button
                      title="Salvar"
                      type="submit"
                      icon={<ThumbUpIcon className="w-4 h-4" />}
                      color="bg-green-500 text-white hover:bg-green-400 hover:text-gray-100"
                    />
                  </div>
                </form>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}
