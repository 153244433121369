/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useApp } from '../../../app.hook';
import { getCryptoLocalStorage } from '../../../app.service';
import { LOCAL_STORAGE_DEFAUT_CLI } from '../../../app.settings';
import { getCart } from '../../cart/cart.service';
import { addPedido, getCotacoes, ICotacao, IPedido } from '../pedido.service';
import Finalization from './finalization';
import Resume from './resume';
import Selection from './selection';
import { formatarTelefone } from '../../../utils/masks';

export default function Switch() {
  const clienteId = getCryptoLocalStorage(LOCAL_STORAGE_DEFAUT_CLI)?.clientId;
  const tabelaId = getCryptoLocalStorage(LOCAL_STORAGE_DEFAUT_CLI)?.tabelaId;
  const navigate = useNavigate();

  // const [searchParams, setSearchParams] = useSearchParams();
  const [tabelaPreco, setTabelaPreco] = useState<string>('');
  const [currentStep, setCurrentStep] = useState<number>(0);
  const { currentUser } = useApp();

  const [sending, setSending] = useState<boolean>(false);
  const [products, setProducts] = useState<any[]>([]);
  const [qtde, setQtde] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const selectedsCarts = useRef<ICotacao[]>([]);
  const [selectedsRows, setSelectedsRows] = useState<Record<number, boolean>[]>(
    []
  );
  const [selectedsCotacoes, setSelectedsCotacoes] = useState<number[]>([]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setRender] = useState<number>(0);

  const itemHandler = (qtde: number, idx: number) => {
    const newProducts = products as any;
    if (qtde > 0) {
      newProducts[idx].qtdeDisponivel = qtde;
    } else {
      newProducts[idx].qtdeDisponivel = 1;
    }
    setProducts(newProducts);
    calc(newProducts);
    setRender((s) => s + 1);
  };

  const removeItem = (idx: number) => {
    const newProducts = products as any;
    newProducts.splice(idx, 1);
    setProducts(newProducts);
    calc(newProducts);
    setRender((s) => s + 1);
  };

  const removePromotion = (idx: number) => {
    const newProducts = products as any;
    newProducts[idx].precoPromo = newProducts[idx].preco;
    newProducts[idx].isPromocao = false;
    delete newProducts[idx].promocao;
    setProducts(newProducts);
    calc(newProducts);
    setRender((s) => s + 1);
  };

  const calc = (data: any[]) => {
    setQtde(0);
    setTotal(0);
    setQtde(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      data.reduce((acc: number, cur: any) => {
        acc = acc + cur.qtdeDisponivel;
        return acc;
      }, 0)
    );
    setTotal(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      data.reduce((acc: number, cur: any) => {
        const sum = cur.qtdeDisponivel * cur.precoPromo;
        acc = acc + sum;
        return acc;
      }, 0)
    );
  };

  const checkTransmission = async (): Promise<boolean> => {
    const cotacoesIds = selectedsCotacoes.toString(); // searchParams.get('cotacoesIds');
    const tabelaId = tabelaPreco; //searchParams.get('tabelaPreco');
    if (!cotacoesIds || !tabelaId) {
      toast.error('Verifique se todos os campos foram selecionados');
      // const params = searchParams;
      // params.set('step', '0');
      // setSearchParams(params);
      setCurrentStep(0);
      return false;
    }

    const filterPromocoesProdutosPedido = products.filter((p) => p.isPromocao);
    if (filterPromocoesProdutosPedido.length === 0) {
      return true;
    }

    const produtosAtualizados = await getCotacoes(cotacoesIds, tabelaId);
    const filterPromocoesProdutosAtualizados = produtosAtualizados.filter(
      (produto) => produto.isPromocao
    );

    // comparação de produtos
    for await (const produtoPedido of filterPromocoesProdutosPedido) {
      const produtoAtualizado = filterPromocoesProdutosAtualizados.find(
        (produto) => produto.id === produtoPedido.id
      );
      if (produtoAtualizado) {
        if (produtoPedido.isPromocao && !produtoAtualizado.isPromocao) {
          setProducts(produtosAtualizados);
          toast.error('Alguns produtos estão fora da oferta');
          // const params = searchParams;
          // params.set('step', '1');
          // setSearchParams(params);
          setCurrentStep(1);
          setSending(false);
          return false;
        }

        if (
          produtoPedido.qtdeDisponivel >
          produtoAtualizado?.promocao.qtdeDisponivel
        ) {
          setProducts(produtosAtualizados);
          toast.error(
            'Alguns produtos exederam a quantidade disponível na oferta'
          );
          // const params = searchParams;
          // params.set('step', '1');
          // setSearchParams(params);
          setCurrentStep(1);
          return false;
        }

        if (produtoPedido.isPromocao && !produtoAtualizado?.promocao) {
          setProducts(produtosAtualizados);
          toast.error('Verifique os produtos fora de oferta');
          // const params = searchParams;
          // params.set('step', '1');
          // setSearchParams(params);
          setCurrentStep(1);
          return false;
        }

        if (produtoPedido.precoPromo !== produtoAtualizado?.precoPromo) {
          setProducts(produtosAtualizados);
          toast.error('Verifique os preços dos produtos');
          // const params = searchParams;
          // params.set('step', '1');
          // setSearchParams(params);
          setCurrentStep(1);
          return false;
        }
      }
    }

    return true;
  };

  const transmission = async (
    obs: string,
    fpagto: number,
    cpagto: number,
    transportadora: number
  ) => {
    if (products.length === 0) {
      toast.error('Sem produtos, verifique');
      return;
    }

    if (selectedsCarts.current.length === 0) {
      toast.error('Sem carrinhos, verifique');
      return;
    }

    if (!fpagto) {
      toast.error('Sem forma de pagamento, verifique');
      return;
    }
    if (!cpagto) {
      toast.error('Sem condições de pagamento, verifique');
      return;
    }
    if (!transportadora) {
      toast.error('Sem transportadora, verifique');
      return;
    }

    const check = await checkTransmission();
    if (!check) {
      toast.error('Erro ...');
      return;
    }

    setSending(true);
    const pedido: IPedido = {
      cotacoes: selectedsCarts.current,
      produtos: products.map((produto) => {
        // console.log(
        //   '🚀 ~ file: switch.tsx ~ line 99 ~ produtos:products.map ~ produto',
        //   produto
        // );
        return {
          produtoId: produto.id,
          qtde: produto.qtdeDisponivel,
          unitario: produto.preco,
          desc1: produto.desc1,
          desc2: produto.desc2,
          desc3: produto.desc3,
          descGT: produto.descGT,
          descMaxClasse: produto.descMaxClasse,
          unitarioLiquido: produto.precoPromo,
        };
      }),

      totais: {
        qtdeTotal: qtde,
        produtos: total,
        frete: 0,
        total: 0 + total,
      },
      obs:
        obs +
        `
        Usuário: ${currentUser.nome}
        Email: ${currentUser.email}
        Telefone: ${formatarTelefone(currentUser.telefone)}
      `,
      fPagto: fpagto,
      cPagto: cpagto,
      transportadora,
      cliente: clienteId,
      tabelaPrecos: tabelaId,
    };

    // console.log({ pedido });

    addPedido({ pedido })
      .then(() => {
        navigate('/pedidos?situacao=&take=12&skip=0');
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response?.data?.message || 'Erro ao criar pedido');
      })
      .finally(() => setSending(false));
  };

  useEffect(() => {
    switch (currentStep) {
      case 0:
        setProducts([]);
        break;
      case 1:
        const cotacoesIds = selectedsCotacoes.toString();
        const tabelaId = tabelaPreco;

        if (cotacoesIds && tabelaId) {
          selectedsCarts.current = [];
          cotacoesIds.split(',').forEach((idCart: string) => {
            getCart(parseInt(idCart)).then((data) => {
              const cotacao = {
                cotacaoId: data.id,
                updated_at: data.updated_at,
              };
              selectedsCarts.current.push(cotacao);
            });
          });
          // setSelecteds(cotacoesIds.split(',').map((el) => parseInt(el)));
          getCotacoes(cotacoesIds, tabelaId).then((data) => {
            // console.log({ data });
            setProducts(data);
            calc(data);
          });
        }

        break;
    }
  }, [currentStep, selectedsCotacoes, tabelaPreco]);

  // useEffect(() => {
  //   // STEP +++++++++++++++++++++++++++
  //   if (searchParams.has('step')) {
  //     const step = searchParams.get('step');
  //     switch (step) {
  //       case '0':
  //         setCurrentStep(0);
  //         setProducts([]);
  //         if (searchParams.has('cotacoesIds')) {
  //           const params = searchParams;
  //           params.delete('cotacoesIds');
  //           setSearchParams(params);
  //         }
  //         break;

  //       case '1':
  //         setCurrentStep(1);
  //         if (
  //           searchParams.has('cotacoesIds') &&
  //           searchParams.has('tabelaPreco')
  //         ) {
  //           const cotacoesIds = searchParams.get('cotacoesIds');
  //           const tabelaId = searchParams.get('tabelaPreco');

  //           if (cotacoesIds && tabelaId) {
  //             cotacoesIds.split(',').forEach((idCart) => {
  //               getCart(parseInt(idCart)).then((data) => {
  //                 const cotacao = {
  //                   cotacaoId: data.id,
  //                   updated_at: data.updated_at,
  //                 };
  //                 selectedsCarts.current.push(cotacao);
  //               });
  //             });
  //             // setSelecteds(cotacoesIds.split(',').map((el) => parseInt(el)));
  //             getCotacoes(cotacoesIds, tabelaId).then((data) => {
  //               // console.log({ data });
  //               setProducts(data);
  //               calc(data);
  //             });
  //           }
  //         } else {
  //           const params = searchParams;
  //           params.set('step', '0');
  //           setSearchParams(params);
  //         }
  //         break;

  //       case '2':
  //         setCurrentStep(2);
  //         // if (selectedsCarts.current?.length === 0) setCurrentStep(0);
  //         break;
  //     }
  //   }
  // }, [searchParams, setSearchParams]);

  // console.log('selectedsRows', selectedsRows.current);
  // console.log('selectedsCarts', selectedsCarts.current);

  switch (currentStep) {
    case 0:
      return (
        <Selection
          rowSelecteds={selectedsRows}
          setRowSelecteds={setSelectedsRows}
          setStep={setCurrentStep}
          tabelaPreco={tabelaPreco}
          setTabelaPreco={setTabelaPreco}
          setSelectedsCotacoes={setSelectedsCotacoes}
        />
      );
    case 1:
      return (
        <Resume
          products={products}
          setStep={setCurrentStep}
          resume={{ qtde, frete: 0, total }}
          itemHandler={itemHandler}
          removeItem={removeItem}
          removePromotion={removePromotion}
          selectedsCotacoes={selectedsCotacoes}
          tabelaPreco={tabelaPreco}
        />
      );
    case 2:
      return (
        <Finalization
          resume={{ qtde, frete: 0, total }}
          sending={sending}
          action={transmission}
          setStep={setCurrentStep}
        />
      );

    default:
      return null;
  }
}
