/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useState, useRef } from 'react';
import { useProduct } from './product.hook';
import { Produto } from '../../app.models';
import DisplayProductCard from './displayProductCard';
// import { arrayGroupBy } from '../../utils/functions';
// import Badge from '../../components/badge';
import DisplayProductList from './displayProductList';
import CardType from './cardType';
import TableProduct from './tableProduct';
import { useSearchParams } from 'react-router-dom';
import useMedia from 'use-media';
import FilterObject from '../../components/filterObject';
import { useApp } from '../../app.hook';
import { EnumOrigens } from '../../utils/origens';
import TabSearchWraper from '../../components/TabSearchWraper';
import clsx from 'clsx';

interface FiltersTextProps {
  handlerPage?: (page: number) => void;
}

const ProcessFilterSelected = (filtros: string | null) => {
  if (!filtros) return [];
  const selected = filtros ? JSON.parse(filtros) : {};
  return Object.entries(selected).map(([label, value]) => ({
    label,
    value,
  })) as unknown as {
    label: string;
    value: any;
  }[];
};

export default function FiltersText(props: FiltersTextProps) {
  const isSmall = useMedia({ maxWidth: 480 });
  const { setOrigem } = useProduct();
  const [searchParams, setSearchParams] = useSearchParams();
  const { marcas, modelos, categoriasRaw: categorias, eixos } = useApp();
  const { listProdutos, loading, cardType, orderType } = useProduct();
  const [list, setList] = useState<Produto[]>([]);
  const [selectedFilters, setSelectedFilters] = useState<
    {
      label: string;
      value: any;
    }[]
  >([]);

  const [selectedTags, setSelectedTags] = useState<
    {
      label: string;
      value: any;
    }[]
  >([]);

  const [filters, setFilters] = useState<Record<string, any>>({});
  const [tags, setTags] = useState<Record<string, any>>({});

  const getTagsLabel = useCallback(
    (id: number | string, table: string): string | undefined => {
      const _id = Number(id);
      switch (table) {
        case 'marcas':
          return marcas.find((m) => m.id === _id)?.marcaDesc;
        case 'modelos':
          const modelo = modelos.find((m) => Number(m.id) === _id);
          if (!modelo) return undefined; //String(_id);
          return `${modelo.modeloDesc} <span class="text-primary1/50 px-1">ano: ${modelo.anoI}-${modelo.anoF}</span>`;
        case 'categorias':
          return categorias.find((m) => m.id === _id)?.descricao;
        case 'eixos':
          return eixos.find((m) => m.id === _id)?.eixoDesc;
        default:
          return undefined;
      }
    },
    [categorias, eixos, marcas, modelos]
  );

  const ref = useRef<HTMLDivElement>(null);
  const toTop = () => {
    ref.current && ref.current.scroll(0, 0);
  };

  // listagem
  useEffect(() => {
    if (listProdutos) {
      if (listProdutos.produtos) {
        setList(
          listProdutos.produtos.sort((a, b) =>
            orderType === 'alfa'
              ? a.descricao.localeCompare(b.descricao)
              : parseInt(a.codigoSgi) - parseInt(b.codigoSgi)
          )
        );
      }
      if (listProdutos.filtros) setFilters(listProdutos.filtros);
      if (listProdutos.tags) {
        const {
          marcas: _marcas,
          modelos: _modelos,
          eixos: _eixos,
          categorias: _categorias,
        } = listProdutos.tags;
        const formatedMarcas = _marcas
          ? _marcas.map((el: unknown) => ({
              label: getTagsLabel(Number(el), 'marcas'),
              value: Number(el),
            }))
          : [];
        const formatedModelos = _modelos
          ? _modelos.map((el: unknown) => {
              const lb = getTagsLabel(Number(el), 'modelos');
              return {
                label: lb,
                value: Number(el),
              };
            })
          : [];
        const formatedEixos = _eixos
          ? _eixos.map((el: unknown) => ({
              label: getTagsLabel(Number(el), 'eixos'),
              value: Number(el),
            }))
          : [];
        const formatedCategorias = _categorias
          ? _categorias.map((el: unknown) => ({
              label: getTagsLabel(Number(el), 'categorias'),
              value: Number(el),
            }))
          : [];
        setTags({
          marcas: formatedMarcas,
          modelos: formatedModelos,
          eixos: formatedEixos,
          categorias: formatedCategorias,
        });
      }
    }
  }, [getTagsLabel, listProdutos, orderType]);

  useEffect(() => {
    const selectedsFilter = ProcessFilterSelected(searchParams.get('filtros'));
    setSelectedFilters(selectedsFilter);
    const selectedsTags = ProcessFilterSelected(searchParams.get('tags'));
    setSelectedTags(selectedsTags);
  }, [searchParams, setSearchParams]);

  const clearFiltros = () => {
    const allParams = searchParams;
    allParams.set('filtros', '');
    setSearchParams(allParams);
  };

  const clearTags = () => {
    const allParams = searchParams;
    allParams.set('tags', '');
    setSearchParams(allParams);
  };

  useEffect(() => {
    toTop();
  }, [selectedFilters, selectedTags]);

  if (loading) {
    return (
      <div className="flex flex-col md:flex-row h-full">
        <div className="w-full md:w-5/6 flex flex-col justify-center items-center h-full animate-pulse">
          <span className="mx-auto"></span>Aguarde ...
        </div>
      </div>
    );
  }

  if (!list) return <></>;

  // caso não encontre produtos
  if (list && list.length === 0) {
    return (
      <div className="flex flex-col md:flex-row md:h-full">
        <TabSearchWraper className="flex flex-col w-full h-full">
          <div className="flex flex-col justify-center items-center h-[60vh]">
            {/* <span className="mx-auto">Produto(s) não encontrado(s).</span> */}
            <span className="mx-auto">Pesquise um produto.</span>
          </div>
          {/* <div className="flex flex-col justify-center items-center">
            <button
              onClick={() => history.back()}
              className="mx-auto bg-primary1 text-white rounded-lg px-4 py-2 hover:bg-primary3 hover:text-white/70"
            >
              Voltar
            </button>
          </div> */}
        </TabSearchWraper>
      </div>
    );
  }

  // console.log({ produtoCategoriasFilter });
  // if (isSmall)
  //   return (
  //     <div className="flex flex-col md:flex-row md:h-full">
  //       <div className="w-full md:w-1/6 md:shadow-lg md:h-full md:overflow-hidden md:overflow-y-auto p-2">
  //         {/* <p className="h-full">Sem Filtros</p> */}
  //         {/* {produtoCategoriasFilter.length > 1 && (
  //           <Filter
  //             title="Filtro"
  //             // eslint-disable-next-line @typescript-eslint/no-explicit-any
  //             items={produtoCategoriasFilter.map((el: any) => ({
  //               label: el.label,
  //               value: el.value,
  //             }))}
  //             onChange={setSelectedsCategorias}
  //           />
  //         )} */}
  //       </div>
  //       <div className="flex flex-col w-full md:w-5/6 h-full">
  //         {listProdutos.info && (
  //           <CardType
  //             // cardType={cardType}
  //             // setCardType={setCardType}
  //             qtdeReg={listProdutos.info.qtdeTotal}
  //             handlerPage={props.handlerPage}
  //             curPage={parseInt(listProdutos.info.pagina)}
  //             perPage={parseInt(listProdutos.info.qtdePorPagina)}
  //           />
  //         )}

  //         <div className="p-4 overflow-hidden overflow-y-auto grid gap-x-4 gap-y-6 grid-cols-none md:grid-cols-2 lg:grid-cols-4">
  //           {list.map((it) => (
  //             <DisplayProductCard key={it.id} product={it} />
  //           ))}
  //         </div>
  //       </div>
  //     </div>
  //   );

  return (
    <div className="flex flex-col md:flex-row md:h-full">
      <div
        ref={ref}
        className="w-full md:w-1/6 md:shadow-lg md:h-full md:overflow-hidden md:overflow-y-auto p-2"
      >
        <div>
          {/* tags ********************************************* */}
          {/* <p className="flex space-x-2">
            {selectedTags.map((sel, idx) => (
              <Badge
                id={sel.label}
                key={idx}
                label={sel.label
                  .concat(': ', getTagsLabel(sel.value[0], sel.label))
                  .toUpperCase()}
                deleteAction={(key) => {
                  const newSelected = selectedTags.filter(
                    (el) => el.label !== key
                  );
                  setSelectedTags(newSelected);
                  if (newSelected.length === 0) {
                    clearTags();
                    return;
                  }
                  const allParams = searchParams;
                  const tags = allParams.get('tags');
                  const params = tags ? JSON.parse(tags) : {};
                  delete params[key];

                  allParams.set('tags', JSON.stringify(params));
                  setSearchParams(allParams);
                }}
              />
            ))}
          </p> */}
          {/* filtros ********************************************** */}
          {/* <p className="flex space-x-2">
            {selectedFilters.map((sel, idx) => (
              <Badge
                id={sel.label}
                key={idx}
                label={sel.label.concat(': ', sel.value[0]).toUpperCase()}
                deleteAction={(key) => {
                  const newSelected = selectedFilters.filter(
                    (el) => el.label !== key
                  );
                  setSelectedFilters(newSelected);
                  if (newSelected.length === 0) {
                    clearFiltros();
                    return;
                  }
                  const allParams = searchParams;
                  const filtros = allParams.get('filtros');
                  const params = filtros ? JSON.parse(filtros) : {};
                  delete params[key];

                  allParams.set('filtros', JSON.stringify(params));
                  setSearchParams(allParams);
                }}
              />
            ))}
          </p> */}
          {(Object.keys(selectedTags).length > 0 ||
            Object.keys(selectedFilters).length > 0) && (
            <div className="px-2 py-2 flex justify-center">
              <button
                className="text-xs text-slate-400 hover:underline"
                onClick={() => {
                  setOrigem(EnumOrigens.FILTRO + 'Limpar_Filtros');
                  const allParams = searchParams;
                  allParams.set('filtros', '');
                  allParams.set('tags', '');
                  setSearchParams(allParams);
                  toTop();
                }}
              >
                Limpar filtros
              </button>
            </div>
          )}
        </div>
        <>
          {/* categorias ********************************************* */}

          <FilterObject
            title={'Categorias'}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            items={tags.categorias?.sort(
              (a: { label: any }, b: { label: any }) =>
                String(a.label).localeCompare(String(b.label))
            )}
            onChange={(d) => {
              const allParams = searchParams;
              const tags = allParams.get('tags');
              const params = tags ? JSON.parse(tags) : {};
              const obj = { ['categorias']: d };
              Object.assign(params, obj);
              allParams.set('tags', JSON.stringify(params));
              allParams.set('skip', '0');
              setSearchParams(allParams);
              const selecteds = ProcessFilterSelected(searchParams.get('tags'));
              setSelectedTags(selecteds);
            }}
            onUncheck={(del) => {
              const _key = del.toLocaleLowerCase();
              const newSelected = selectedTags.filter(
                (el) => el.label !== _key
              );
              setSelectedTags(newSelected);
              if (newSelected.length === 0) {
                clearTags();
                return;
              }
              const allParams = searchParams;
              const tags = allParams.get('tags');
              const params = tags ? JSON.parse(tags) : {};
              delete params[_key];

              allParams.set('tags', JSON.stringify(params));
              allParams.set('filtros', '');
              setSearchParams(allParams);
            }}
            selecteds={selectedTags.reduce((acc: string[], cur) => {
              acc.push(cur.label);
              return acc;
            }, [])}
            highlight={true}
          />

          {/* marcas ********************************************* */}
          <FilterObject
            title={'Marcas'}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            items={tags.marcas?.sort((a: { label: any }, b: { label: any }) =>
              String(a.label).localeCompare(String(b.label))
            )}
            onChange={(d) => {
              const allParams = searchParams;
              const tags = allParams.get('tags');
              const params = tags ? JSON.parse(tags) : {};
              const obj = { ['marcas']: d };
              Object.assign(params, obj);
              allParams.set('tags', JSON.stringify(params));
              allParams.set('skip', '0');
              setSearchParams(allParams);
              const selecteds = ProcessFilterSelected(searchParams.get('tags'));
              setSelectedTags(selecteds);
            }}
            onUncheck={(del) => {
              const _key = del.toLocaleLowerCase();
              const newSelected = selectedTags.filter(
                (el) => el.label !== _key
              );
              setSelectedTags(newSelected);
              if (newSelected.length === 0) {
                clearTags();
                return;
              }
              const allParams = searchParams;
              const tags = allParams.get('tags');
              const params = tags ? JSON.parse(tags) : {};
              delete params[_key];

              allParams.set('tags', JSON.stringify(params));
              allParams.set('filtros', '');
              setSearchParams(allParams);
            }}
            selecteds={selectedTags.reduce((acc: string[], cur) => {
              acc.push(cur.label);
              return acc;
            }, [])}
            highlight={true}
          />

          {/* modelos ********************************************* */}
          <FilterObject
            title={'Modelos'}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            items={tags.modelos?.sort((a: { label: any }, b: { label: any }) =>
              String(a.label).localeCompare(String(b.label))
            )}
            onChange={(d) => {
              const allParams = searchParams;
              const tags = allParams.get('tags');
              const params = tags ? JSON.parse(tags) : {};
              const obj = { ['modelos']: d };
              Object.assign(params, obj);
              allParams.set('tags', JSON.stringify(params));
              allParams.set('skip', '0');
              setSearchParams(allParams);
              const selecteds = ProcessFilterSelected(searchParams.get('tags'));
              setSelectedTags(selecteds);
            }}
            onUncheck={(del) => {
              const _key = del.toLocaleLowerCase();
              const newSelected = selectedTags.filter(
                (el) => el.label !== _key
              );
              setSelectedTags(newSelected);
              if (newSelected.length === 0) {
                clearTags();
                return;
              }
              const allParams = searchParams;
              const tags = allParams.get('tags');
              const params = tags ? JSON.parse(tags) : {};
              delete params[_key];

              allParams.set('tags', JSON.stringify(params));
              allParams.set('filtros', '');
              setSearchParams(allParams);
            }}
            selecteds={selectedTags.reduce((acc: string[], cur) => {
              acc.push(cur.label);
              return acc;
            }, [])}
            highlight={true}
          />

          {/* eixos ********************************************* */}
          <FilterObject
            title={'Eixos'}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            items={tags.eixos?.sort((a: { label: any }, b: { label: any }) =>
              String(a.label).localeCompare(String(b.label))
            )}
            onChange={(d) => {
              const allParams = searchParams;
              const tags = allParams.get('tags');
              const params = tags ? JSON.parse(tags) : {};
              const obj = { ['eixos']: d };
              Object.assign(params, obj);
              allParams.set('tags', JSON.stringify(params));
              allParams.set('skip', '0');
              setSearchParams(allParams);
              const selecteds = ProcessFilterSelected(searchParams.get('tags'));
              setSelectedTags(selecteds);
            }}
            onUncheck={(del) => {
              const _key = del.toLocaleLowerCase();
              const newSelected = selectedTags.filter(
                (el) => el.label !== _key
              );
              setSelectedTags(newSelected);
              if (newSelected.length === 0) {
                clearTags();
                return;
              }
              const allParams = searchParams;
              const tags = allParams.get('tags');
              const params = tags ? JSON.parse(tags) : {};
              delete params[_key];

              allParams.set('tags', JSON.stringify(params));
              allParams.set('filtros', '');
              setSearchParams(allParams);
            }}
            selecteds={selectedTags.reduce((acc: string[], cur) => {
              acc.push(cur.label);
              return acc;
            }, [])}
            highlight={true}
          />

          {/* filtros ********************************************** */}
          {Object.keys(selectedTags).length > 0 && (
            <>
              {Object.entries(filters).map(([key, value], idx) => (
                <FilterObject
                  key={idx}
                  title={key}
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  items={value.map(
                    (val: any) =>
                      val && {
                        label: val,
                        value: val,
                      }
                  )}
                  onChange={(d) => {
                    const allParams = searchParams;
                    const filtros = allParams.get('filtros');
                    const params = filtros ? JSON.parse(filtros) : {};
                    const obj = { [key]: d };
                    Object.assign(params, obj);
                    allParams.set('filtros', JSON.stringify(params));
                    allParams.set('skip', '0');
                    setSearchParams(allParams);
                    const selecteds = ProcessFilterSelected(
                      searchParams.get('filtros')
                    );
                    setSelectedFilters(selecteds);
                  }}
                  onUncheck={(del) => {
                    const _key = del.toLocaleLowerCase();
                    const newSelected = selectedFilters.filter(
                      (el) => el.label !== _key
                    );
                    setSelectedFilters(newSelected);
                    if (newSelected.length === 0) {
                      clearFiltros();
                      return;
                    }
                    const allParams = searchParams;
                    const filters = allParams.get('filters');
                    const params = filters ? JSON.parse(filters) : {};
                    delete params[_key];

                    allParams.set('filters', JSON.stringify(params));
                    setSearchParams(allParams);
                  }}
                  selecteds={selectedFilters.reduce((acc: string[], cur) => {
                    acc.push(cur.label);
                    return acc;
                  }, [])}
                />
              ))}
            </>
          )}
        </>
      </div>
      <div className="flex flex-col w-full md:w-5/6 h-full">
        {listProdutos.info && (
          <CardType
            // cardType={cardType}
            // setCardType={setCardType}
            qtdeReg={listProdutos.info.qtdeTotal}
            handlerPage={props.handlerPage}
            curPage={parseInt(listProdutos.info.pagina)}
            perPage={parseInt(listProdutos.info.qtdePorPagina)}
          />
        )}

        {/* {activedsFilters.length > 0 && (
          <div className="px-1 py-1 text-sm font-medium text-left text-gray-900 bg-gray-100 hover:bg-gray-200 focus:outline-none focus-visible:ring focus-visible:ring-gray-300 focus-visible:ring-opacity-75 mb-3">
            {activedsFilters.map((el, i) => (
              <Badge
                label={el}
                id={i}
                // deleteAction={(key) => console.log(key)}
              />
            ))}
          </div>
        )} */}

        {cardType === 'card' && (
          <TabSearchWraper
            className="p-4 overflow-hidden overflow-y-auto"
            classNameWraper={clsx(
              'grid gap-x-4 gap-y-6 grid-cols-2 lg:grid-cols-3 xl:grid-cols-4',
              'mx-auto lg:max-w-7xl'
            )}
          >
            {list.map((it) => (
              <DisplayProductCard key={it.id} product={it} />
            ))}
          </TabSearchWraper>
        )}
        {cardType === 'list' && (
          <TabSearchWraper
            className="p-4 overflow-hidden overflow-y-auto"
            classNameWraper={clsx(
              'flex flex-col space-y-2',
              'mx-auto lg:max-w-7xl'
            )}
          >
            {list.map((it) => (
              <DisplayProductList key={it.id} product={it} />
            ))}
          </TabSearchWraper>
        )}
        {cardType === 'table' && (
          <TabSearchWraper
            className="overflow-hidden overflow-y-auto "
            classNameWraper={clsx(
              'flex flex-col space-y-2'
              // 'mx-auto lg:max-w-7xl'
            )}
          >
            <TableProduct items={list} />
          </TabSearchWraper>
        )}
      </div>
    </div>
  );
}
