import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';
import Carousel from 'nuka-carousel';
import { Children, ReactNode } from 'react';

type Props = {
  children: ReactNode;
  slidesToShow: number;
  autoplay: boolean;
  autoplayInterval?: number;
  infinite?: boolean;
  pauseOnHover?: boolean;
  slidesToScroll?: number;
  cellSpacing?: number;
};

export default function NukaCaroucel({
  children,
  slidesToShow,
  autoplay,
  autoplayInterval,
  infinite,
  pauseOnHover,
  slidesToScroll,
  cellSpacing,
}: Props) {
  return (
    <div className="">
      <Carousel
        slidesToShow={slidesToShow}
        adaptiveHeight={false}
        cellSpacing={cellSpacing || 0}
        slidesToScroll={slidesToScroll || 1}
        autoplay={autoplay}
        autoplayInterval={autoplayInterval || 5000}
        wrapAround={infinite || true}
        pauseOnHover={pauseOnHover || true}
        defaultControlsConfig={{
          containerClassName: 'relative',
          nextButtonClassName: '',
          nextButtonStyle: {},
          nextButtonText: '',
          pagingDotsClassName: '',
          pagingDotsContainerClassName: '',
          pagingDotsStyle: {},
          prevButtonClassName: '',
          prevButtonStyle: {},
          prevButtonText: '',
        }}
        renderCenterLeftControls={({ previousSlide }) => (
          <button onClick={previousSlide} className="bg-slate-50 opacity-50">
            <ChevronLeftIcon className="w-8 h-8 stroke-slate-600 hover:stroke-slate-800" />
          </button>
        )}
        renderCenterRightControls={({ nextSlide }) => (
          <button onClick={nextSlide} className="bg-slate-50 opacity-50">
            <ChevronRightIcon className="w-8 h-8 stroke-slate-600 hover:stroke-slate-800" />
          </button>
        )}
      >
        {Children.map(children, (slide, index) => {
          return <div key={index}>{slide}</div>;
        })}
      </Carousel>
    </div>
  );
}
