import { Marca } from '../app.models';
import { classNames as setClassNames } from '../utils/functions';
import {
  CarouselProvider,
  Slide,
  Slider,
  // DotGroup,
  ButtonBack,
  ButtonNext,
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';
import { Link, useNavigate } from 'react-router-dom';
// import Rating from 'react-rating-stars-component';
import { ROUTES_MAP } from '../app.map.routes';
import useMedia from 'use-media';
import { useApp } from '../app.hook';
import Display from '../pages/product/display';
import { useRef } from 'react';
import { useProduct } from '../pages/product/product.hook';

interface Props {
  classNames: string[];
}

export function CaroucelMarcas({ classNames }: Props) {
  const { marcas, empresa } = useApp();
  const { take, skip } = useProduct();
  const navigate = useNavigate();
  const isExtraSmall = useMedia({ maxWidth: 320 });
  const isSmall = useMedia({ minWidth: 321, maxWidth: 480 });
  const isMedium = useMedia({ minWidth: 481, maxWidth: 768 });
  const isBig = useMedia({ minWidth: 769, maxWidth: 1440 });
  const isExtraBig = useMedia({ minWidth: 1441, maxWidth: 1920 });
  const isExtraExtraBig = useMedia({ minWidth: 1921 });

  const wrapeRef = useRef<HTMLDivElement>(null);

  const getVisibleSlides = () => {
    if (isExtraSmall) {
      return 1;
    }

    if (isSmall) {
      return 1;
    }

    if (isMedium) {
      return 3;
    }

    if (isBig) {
      return 4;
    }

    if (isExtraBig) {
      return 5;
    }

    if (isExtraExtraBig) {
      return 6;
    }

    return 6;
  };

  const isPlaying = () => {
    return marcas.length > getVisibleSlides();
  };

  const getNaturalProp = () => {
    if (isExtraSmall) {
      return {
        naturalSlideWidth: 1,
        naturalSlideHeight: 1.4,
      };
    }

    if (isSmall) {
      return {
        naturalSlideWidth: 1,
        naturalSlideHeight: 1,
      };
    }

    if (isMedium) {
      return {
        naturalSlideWidth: 1,
        naturalSlideHeight: 1.2,
      };
    }

    if (isBig) {
      return {
        naturalSlideWidth: 1,
        naturalSlideHeight: 1,
      };
    }

    if (isExtraBig) {
      return {
        naturalSlideWidth: 1,
        naturalSlideHeight: 0.8,
      };
    }

    if (isExtraExtraBig) {
      return {
        naturalSlideWidth: 1,
        naturalSlideHeight: 0.7,
      };
    }

    return {
      naturalSlideWidth: 1,
      naturalSlideHeight: 0.7,
    };
  };

  return (
    <div
      ref={wrapeRef}
      className="p-6 flex flex-col justify-center items-center"
    >
      <CarouselProvider
        naturalSlideWidth={getNaturalProp().naturalSlideWidth}
        naturalSlideHeight={getNaturalProp().naturalSlideHeight}
        totalSlides={marcas.length}
        infinite={true}
        orientation="horizontal"
        interval={6000}
        isPlaying={isPlaying()}
        className={setClassNames(classNames)}
        visibleSlides={getVisibleSlides()}
        step={1}
      >
        <div className="flex flex-col bg-white py-4 px-6 rounded-lg w-full 2xl:w-[70%] mx-auto">
          <div className="text-lg font-semibold grid grid-cols-12">
            <div className="col-span-12 flex flex-col">
              <div className="flex justify-between text-2xl w-full">
                <strong className="text-primary1">Compre pela marca</strong>

                <Link
                  to={ROUTES_MAP.product + '?marca='}
                  className="text-sm opacity-70 hover:underline min-w-max inline-flex"
                >
                  Ver todas! <ChevronRightIcon className="w-5 h-5" />
                </Link>
              </div>
            </div>
          </div>
          <div className="flex flex-rol">
            {isPlaying() && (
              <div className="flex flex-col group hover:bg-slate-200">
                <ButtonBack className="h-full">
                  <ChevronLeftIcon className="w-8 h-8 stroke-slate-600 group-hover:stroke-slate-800" />
                </ButtonBack>
              </div>
            )}
            <Slider className="w-full">
              {marcas.map((m: Marca, index) => {
                return (
                  <Slide index={index} key={index}>
                    <div className="flex flex-col justify-center items-center h-full">
                      <Display
                        key={m.id}
                        id={m.id}
                        selected={0}
                        title={m.marcaDesc}
                        img={m.images[0].img}
                        onClick={() => {
                          if (empresa.pesquisarSomenteMarca) {
                            navigate(
                              encodeURI(
                                `${ROUTES_MAP.product}?take=${take}&skip=${skip}&tags={"marca":[${m.id}]}`
                              )
                            );
                          } else {
                            navigate(ROUTES_MAP.product + '/?marca=' + m.id);
                          }
                        }}
                      />
                    </div>
                  </Slide>
                );
              })}
            </Slider>
            {isPlaying() && (
              <div className="flex flex-col group hover:bg-slate-200">
                <ButtonNext className="h-full">
                  <ChevronRightIcon className="w-8 h-8 stroke-slate-600 group-hover:stroke-slate-800" />
                </ButtonNext>
              </div>
            )}
          </div>
          {/* <DotGroup dotNumbers className="space-x-1" /> */}
        </div>
      </CarouselProvider>
    </div>
  );
}
