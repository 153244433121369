/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Info } from '../../app.models';
import { genericGet } from '../../app.service';
import { useApp } from '../../app.hook';

interface IProvider {
  children: React.ReactNode;
}

interface IContext {
  loading: boolean;
  info: Info | undefined;
  skip: number;
  setSkip: (skip: number) => void;
  take: number;
  setTake: (take: number) => void;
  paths: IPath[];
  data: any[];
  genericPath: number;
  setGenericPath: (genericPath: number) => void;
  reloadData: () => void;
}

interface IPath {
  id: number;
  title: string;
  path: string;
  fieldDesc: string;
}

const Context = createContext<IContext>({} as IContext);

const UploadProvider: React.FC<IProvider> = ({ children }: IProvider) => {
  const { empresa } = useApp();
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [info, setInfo] = useState<Info | undefined>();
  const [reload, setReload] = useState<number>(0);
  const [take, setTake] = useState<number>(12);
  const lastTake = useRef<number>();
  const [skip, setSkip] = useState<number>(0);
  const lastSkip = useRef<number>();
  const [genericPath, setGenericPath] = useState<number>(-1);
  const lastGenericPath = useRef<number>();

  const reloadData = () => {
    setReload(reload + 1);
  };

  const paths: IPath[] = useMemo(() => {
    if (empresa.pesquisarSomenteMarca) {
      return [
        {
          id: 0,
          title: 'Categorias',
          path: 'categorias',
          fieldDesc: 'descricao',
        },
        {
          id: 1,
          title: 'Marcas',
          path: 'marcas',
          fieldDesc: 'marcaDesc',
        },
      ];
    } else {
      return [
        {
          id: 0,
          title: 'Categorias',
          path: 'categorias',
          fieldDesc: 'descricao',
        },
        {
          id: 1,
          title: 'Marcas',
          path: 'marcas',
          fieldDesc: 'marcaDesc',
        },
        {
          id: 2,
          title: 'Modelos',
          path: 'modelos',
          fieldDesc: 'modeloDesc',
        },
        {
          id: 3,
          title: 'Tipos de Veículos',
          path: 'veiculotipos',
          fieldDesc: 'tipoVeiculoDesc',
        },
      ];
    }
  }, [empresa.pesquisarSomenteMarca]);

  // se nao for produto
  useEffect(() => {
    const init = async () => {
      if (genericPath === lastGenericPath.current) return;
      if (genericPath < 0) {
        setData([]);
        return;
      }

      setLoading(true);
      lastGenericPath.current = genericPath;

      const _data = await genericGet(paths[genericPath].path);

      _data &&
        setData(
          _data.map((el) => ({
            ...el,
            descricao: el[paths[genericPath].fieldDesc],
          }))
        );
      // console.log('upload-resp-data', _data);

      setLoading(false);
    };
    init();
  }, [genericPath, paths, reload]);

  // se for produto
  useEffect(() => {
    const init = async () => {
      if (take === lastTake.current && lastSkip.current === take) return;
      lastTake.current = take;
      if (skip === lastSkip.current && lastTake.current === take) return;
      lastSkip.current = skip;

      setLoading(true);

      // const _data = await getProduct();

      // _data &&
      //   setData(
      //     _data.map((el) => ({
      //       ...el,
      //       descricao: el[paths[genericPath].fieldDesc],
      //     }))
      //   );
      // console.log('upload-resp-data', _data);

      setLoading(false);
    };
    init();
  }, [skip, take, reload]);

  return (
    <Context.Provider
      value={{
        loading,
        data,
        info,
        skip,
        setSkip,
        take,
        setTake,
        paths,
        setGenericPath,
        genericPath,
        reloadData,
      }}
    >
      {children}
    </Context.Provider>
  );
};

const useUpload = (): IContext => {
  const context = useContext(Context);
  return context;
};

export { UploadProvider, useUpload };
