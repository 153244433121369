import { ProdutoCart } from '../../app.models';
import ExibirPrecoConsumidorFinal from '../../components/ExibirPrecoConsumidorFinal';

interface ProdutoItemProps {
  product: ProdutoCart;
  onClick: (produto: ProdutoCart) => void;
}

export default function ProdutoItem({ product, onClick }: ProdutoItemProps) {
  return (
    <div
      className="odd:bg-slate-200 even:bg-slate-50 px-1 py-2 hover:bg-slate-300 cursor-pointer space-y-1"
      onClick={() => onClick(product)}
    >
      <div className="flex gap-1 text-xs justify-between items-center">
        <div className="flex gap-1">
          <span>Cod:</span>{' '}
          <span className="font-bold">{product?.produto?.codigoSgi}</span>
        </div>
        <div className="flex gap-1">
          <span>Ref:</span>{' '}
          <span className="font-bold">
            {product?.produto?.referencia ?? ' - '}
          </span>
        </div>
      </div>
      <div className="text-blue-500 text-sm">
        {product.produto.descricaoSite}
      </div>
      <div className="flex gap-1 text-xs justify-between items-center">
        <div className="flex gap-2">
          <span>Qtde:</span>
          <span className="text-red-500 font-bold">{product.qtde}</span>
        </div>
        <div className="flex gap-2 items-center">
          <span>Unit:</span>
          <span className="text-yellow-700 font-bold">
            {/* {Number(product.unitarioLiquido).toLocaleString('pt-br', {
              style: 'decimal',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })} */}
            <ExibirPrecoConsumidorFinal
              item={product}
              field="unitarioLiquido"
              fieldConsumidorFinal="unitarioLiquidoConsumidorFinal"
              isColumn
            />
          </span>
        </div>
        <div className="flex gap-2 items-center">
          <span>Valor:</span>
          <span className="text-green-700 font-bold text-sm">
            {/* {Number(product.totalItem).toLocaleString('pt-br', {
              style: 'decimal',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })} */}
            <ExibirPrecoConsumidorFinal
              item={product}
              field="totalItem"
              fieldConsumidorFinal="totalItemConsumidorFinal"
              isColumn
            />
          </span>
        </div>
      </div>
    </div>
  );
}
