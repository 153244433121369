import { Cart, Info, Pedido, Produto } from '../../app.models';
import { getCryptoLocalStorage } from '../../app.service';
import { LOCAL_STORAGE_DEFAUT_CLI } from '../../app.settings';
import api from '../../axios';
import { convertImages } from '../../utils/functions';

const NO_IMAGES = {
  small: 'no-image-100x100.png',
  medium: 'no-image-180x135.png',
  big: 'no-image-180x135.png',
};

interface IResponsePainelPesquisas {
  searchFor: number;
  categoria: number;
  veiculo: number;
}
interface IQueryPainel {
  dataI?: string;
  dataF?: string;
  clienteId?: number;
  produtoId?: number;
  ano?: string | number;
  mes?: string | number;
}

export async function painelPesquisas({
  dataI,
  dataF,
  ano,
  mes,
  clienteId,
  produtoId,
}: IQueryPainel) {
  const params = new URLSearchParams();
  if (dataI) params.append('dataI', dataI);
  if (dataF) params.append('dataF', dataF);
  if (ano) params.append('ano', ano.toString());
  if (mes) params.append('mes', mes.toString());
  if (clienteId) params.append('clienteId', clienteId.toString());
  if (produtoId) params.append('produtoId', produtoId.toString());
  const url = `painel/pesquisas/?${params.toString()}`;
  return (await api.get(url)).data.pesquisas as IResponsePainelPesquisas;
}

interface IResponsePainelCotacoes {
  visualizados: number;
  cotados: {
    qtde: number;
    total: number;
  };
  vendidos: {
    qtde: number;
    total: number;
  };
}

export async function painelCotacoes({
  dataI,
  dataF,
  ano,
  mes,
  clienteId,
  produtoId,
}: IQueryPainel) {
  const params = new URLSearchParams();
  if (dataI) params.append('dataI', dataI);
  if (dataF) params.append('dataF', dataF);
  if (ano) params.append('ano', ano.toString());
  if (mes) params.append('mes', mes.toString());
  if (clienteId) params.append('clienteId', clienteId.toString());
  if (produtoId) params.append('produtoId', produtoId.toString());
  const url = `painel/cotacoes/?${params.toString()}`;
  return (await api.get(url)).data as IResponsePainelCotacoes;
}

export interface IResponsePainelPedidos {
  ano: number;
  mes: string;
  qtdeCotacao: number;
  totalCotacao: number;
  qtdePedido: number;
  totalPedido: number;
}

export async function cotacoesPedidosResumo({
  dataI,
  dataF,
  clienteId,
  ano,
}: {
  dataI?: string;
  dataF?: string;
  clienteId?: number;
  ano?: string | number;
}) {
  const params = new URLSearchParams();
  if (dataI) params.append('dataI', dataI);
  if (dataF) params.append('dataF', dataF);
  // if (ano) params.append('ano', ano.toString());
  if (clienteId) params.append('clienteId', clienteId.toString());

  const url = `painel/cotacoesPedidos/resumo/?${params.toString()}`;
  return (await api.get(url)).data.movimentacao as IResponsePainelPedidos[];
}

export interface IPeriodo {
  ano: string;
  mes: string;
}

export interface IResponseCotacoesPorCliente {
  clienteId: number;
  nome: string;
  apelido: string;
  cpf_cnpj: string;
  total: number;
  qtde?: number;
}

export interface IResponseCotacoesPorProduto {
  produtoId: number;
  codigoSgi: string;
  descricaoSite: string;
  qtde: number;
  total: number;
}

export async function pedidosPorCliente({
  dataI,
  dataF,
  clienteId,
  ano,
  mes,
}: IQueryPainel) {
  const params = new URLSearchParams();
  if (dataI) params.append('dataI', dataI);
  if (dataF) params.append('dataF', dataF);
  if (ano) params.append('ano', ano.toString());
  if (mes) params.append('mes', mes.toString());
  if (clienteId) params.append('clienteId', clienteId.toString());
  const url = `painel/pedidosPorCliente/?${params.toString()}`;
  return (await api.get(url)).data as IResponseCotacoesPorCliente[];
}

export async function pedidosPorProduto({
  dataI,
  dataF,
  clienteId,
  ano,
  mes,
}: IQueryPainel) {
  const params = new URLSearchParams();
  if (dataI) params.append('dataI', dataI);
  if (dataF) params.append('dataF', dataF);
  if (ano) params.append('ano', ano.toString());
  if (mes) params.append('mes', mes.toString());
  if (clienteId) params.append('clienteId', clienteId.toString());
  const url = `painel/pedidosPorProduto/?${params.toString()}`;
  return (await api.get(url)).data as IResponseCotacoesPorProduto[];
}

export async function pedidosPorClienteProdutos({
  dataI,
  dataF,
  clienteId,
  ano,
  mes,
}: IQueryPainel) {
  const params = new URLSearchParams();
  if (dataI) params.append('dataI', dataI);
  if (dataF) params.append('dataF', dataF);
  if (ano) params.append('ano', ano.toString());
  if (mes) params.append('mes', mes.toString());
  if (clienteId) params.append('clienteId', clienteId.toString());
  const url = `painel/pedidosPorClienteProdutos/?${params.toString()}`;
  return (await api.get(url)).data;
}

export async function pedidosPorProdutoClientes({
  dataI,
  dataF,
  produtoId,
  ano,
  mes,
}: IQueryPainel) {
  const params = new URLSearchParams();
  if (dataI) params.append('dataI', dataI);
  if (dataF) params.append('dataF', dataF);
  if (ano) params.append('ano', ano.toString());
  if (mes) params.append('mes', mes.toString());
  if (produtoId) params.append('produtoId', produtoId.toString());
  const url = `painel/pedidosPorProdutoClientes/?${params.toString()}`;
  return (await api.get(url)).data;
}

export async function cotacoesPorProduto({
  dataI,
  dataF,
  clienteId,
  ano,
  mes,
}: IQueryPainel) {
  const params = new URLSearchParams();
  if (dataI) params.append('dataI', dataI);
  if (dataF) params.append('dataF', dataF);
  if (ano) params.append('ano', ano.toString());
  if (mes) params.append('mes', mes.toString());
  if (clienteId) params.append('clienteId', clienteId.toString());
  const url = `painel/cotacoesPorProduto/?${params.toString()}`;
  return (await api.get(url)).data as IResponseCotacoesPorProduto[];
}

export async function cotacoesPorCliente({
  dataI,
  dataF,
  clienteId,
  ano,
  mes,
}: IQueryPainel) {
  const params = new URLSearchParams();
  if (dataI) params.append('dataI', dataI);
  if (dataF) params.append('dataF', dataF);
  if (ano) params.append('ano', ano.toString());
  if (mes) params.append('mes', mes.toString());
  if (clienteId) params.append('clienteId', clienteId.toString());
  const url = `painel/cotacoesPorCliente/?${params.toString()}`;
  return (await api.get(url)).data as IResponseCotacoesPorCliente[];
}

export async function getProductById(
  productId: number | string
): Promise<{ produto: Produto }> {
  const tabelaId = getCryptoLocalStorage(LOCAL_STORAGE_DEFAUT_CLI)?.tabelaId;
  const clienteId = getCryptoLocalStorage(LOCAL_STORAGE_DEFAUT_CLI)?.clienteId;
  const url =
    '/produtos/' +
    productId +
    '?clienteId=' +
    clienteId +
    '&tabelaId=' +
    tabelaId;
  const prod = (await api.get(url)).data.produto;
  return {
    produto: {
      ...prod,
      images:
        prod.images.length > 0
          ? convertImages(prod.images, prod.id, 'produtos')
          : [NO_IMAGES],
    },
  };
}

// meus carrinhos
interface SearchCarts {
  info: Info;
  carts: Cart[];
}
type CartParams = {
  searchFor?: string;
  situacao?: string;
  compradorId?: number;
  dataI?: Date;
  dataF?: Date;
  take?: number;
  skip?: number;
  sortBy?: string;
  ord?: number;
  defaultCli?: string;
  exibirZerados?: boolean;
  ano?: string;
  mes?: string;
};

export async function myCarts(
  params: CartParams,
  apenasAprovados = false
): Promise<SearchCarts> {
  const urlBase = '/carrinhos/?';
  const urlWithParams = [urlBase];

  if (urlWithParams.length === 0) return {} as SearchCarts;

  urlWithParams[0] = urlWithParams[0].concat(`cliente=${params.defaultCli}`);

  if (params.ano)
    urlWithParams[0] = urlWithParams[0].concat(`&ano=${params.ano.toString()}`);

  if (params.mes)
    urlWithParams[0] = urlWithParams[0].concat(`&mes=${params.mes.toString()}`);

  if (params.searchFor)
    urlWithParams[0] = urlWithParams[0].concat(
      `&searchFor=${params.searchFor || ''}`
    );

  if (params.dataI)
    urlWithParams[0] = urlWithParams[0].concat(
      `&dataI=${new Date(params.dataI)
        .toISOString()
        .slice(0, 10)
        .replace('T', ' ')}`
    );

  if (params.dataF)
    urlWithParams[0] = urlWithParams[0].concat(
      `&dataF=${new Date(params.dataF)
        .toISOString()
        .slice(0, 10)
        .replace('T', ' ')}`
    );

  if (apenasAprovados === false) {
    if (params.situacao)
      urlWithParams[0] = urlWithParams[0].concat(
        `&situacao=${params.situacao}`
      );
    if (params.compradorId)
      urlWithParams[0] = urlWithParams[0].concat(
        `&comprador=${params.compradorId}`
      );
    if (params.take)
      urlWithParams[0] = urlWithParams[0].concat(`&take=${params.take}`);
  } else {
    urlWithParams[0] = urlWithParams[0].concat(`&situacao=1`);
    urlWithParams[0].concat(`&take=${-1}`);
  }

  if (params.skip)
    urlWithParams[0] = urlWithParams[0].concat(`&skip=${params.skip}`);

  if (params.sortBy)
    urlWithParams[0] = urlWithParams[0].concat(`&sortBy=${params.sortBy}`);

  if (params.ord)
    urlWithParams[0] = urlWithParams[0].concat(`&ord=${params.ord}`);

  urlWithParams[0] = urlWithParams[0].concat(
    `&exibirZerados=${params.exibirZerados ?? false}`
  );

  if (!params.take)
    return {
      info: {
        pagina: '0',
        qtdePorPagina: '',
        ref: '',
        qtdeTotal: 0,
        ord: '',
        viewType: '',
        viewCol: '',
      },
      carts: [],
    };

  return (await api.get(urlWithParams[0])).data;
}

// meus pedidos
type OrderParams = {
  searchFor?: string;
  situacao?: string;
  compradorId?: number;
  dataI?: Date;
  dataF?: Date;
  take?: number;
  skip?: number;
  sortBy?: string;
  ord?: number;
  defaultCli?: string;
  ano?: string;
  mes?: string;
};

interface SearchPedidos {
  info: Info;
  pedidos: Pedido[];
}

export async function myOrders(
  params: OrderParams,
  apenasAprovados = false
): Promise<SearchPedidos> {
  const urlBase = '/pedidos/?';
  const urlWithParams = [urlBase];
  // const defaultCli = getCryptoLocalStorage(LOCAL_STORAGE_DEFAUT_CLI);

  urlWithParams[0] = urlWithParams[0].concat(`cliente=${params.defaultCli}`);

  if (params.searchFor) {
    urlWithParams[0] = urlWithParams[0].concat(
      `&searchFor=${params.searchFor}`
    );
  }

  if (params.ano) {
    urlWithParams[0] = urlWithParams[0].concat(`&ano=${params.ano}`);
  }

  if (params.mes) {
    urlWithParams[0] = urlWithParams[0].concat(`&mes=${params.mes}`);
  }

  if (params.dataI)
    urlWithParams[0] = urlWithParams[0].concat(
      `&dataI=${new Date(params.dataI)
        .toISOString()
        .slice(0, 10)
        .replace('T', ' ')}`
    );

  if (params.dataF)
    urlWithParams[0] = urlWithParams[0].concat(
      `&dataF=${new Date(params.dataF)
        .toISOString()
        .slice(0, 10)
        .replace('T', ' ')}`
    );

  if (apenasAprovados === false) {
    if (params.situacao)
      urlWithParams[0] = urlWithParams[0].concat(
        `&situacao=${params.situacao}`
      );
    if (params.compradorId)
      urlWithParams[0] = urlWithParams[0].concat(
        `&comprador=${params.compradorId}`
      );
    if (params.take)
      urlWithParams[0] = urlWithParams[0].concat(`&take=${params.take}`);
  } else {
    urlWithParams[0] = urlWithParams[0].concat(`&situacao=1`);
    urlWithParams[0].concat(`&take=${-1}`);
  }

  if (params.skip)
    urlWithParams[0] = urlWithParams[0].concat(`&skip=${params.skip}`);

  if (params.sortBy)
    urlWithParams[0] = urlWithParams[0].concat(`&sortBy=${params.sortBy}`);

  if (params.ord)
    urlWithParams[0] = urlWithParams[0].concat(`&ord=${params.ord}`);

  urlWithParams[0] = urlWithParams[0].concat(`&take=12`);

  return (await api.get(urlWithParams[0])).data;
}
