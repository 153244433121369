import axios, { AxiosError } from 'axios';

const SUA_INSTANCIA = process.env.REACT_APP_ZAPI_INSTANCE;
const SEU_TOKEN = process.env.REACT_APP_ZAPI_TOKEN;
const CLIENT_TOKEN = process.env.REACT_APP_ZAPI_CLIENT_TOKEN as string;
const zapiUrl = `https://api.z-api.io/instances/${SUA_INSTANCIA}/token/${SEU_TOKEN}`;

const _axios = axios.create({
  baseURL: zapiUrl,
});

export async function sendZapiDocument({
  document,
  phone,
  extension,
  fileName,
}: {
  document: string;
  phone: string;
  extension: string;
  fileName: string;
}): Promise<boolean> {
  const url = `/send-document/${extension}`;
  return await _axios
    .post(
      url,
      {
        phone,
        document,
        fileName,
      },
      {
        headers: {
          'Client-Token': CLIENT_TOKEN,
        },
      }
    )
    .then(() => {
      return true;
    })
    .catch((error: AxiosError) => {
      console.log(error);
      return false;
    });
}
