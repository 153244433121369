/* eslint-disable @typescript-eslint/no-explicit-any */
import { Tab } from '@headlessui/react';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useMedia from 'use-media';
import { ROUTES_MAP } from '../../app.map.routes';
import { classNames } from '../../utils/functions';
import Display from './display';
import { useProduct } from './product.hook';
import { EnumOrigens } from '../../utils/origens';
import TabSerach from '../../components/tabSerach';
import clsx from 'clsx';
import MultiSearch from '../../components/MultiSearch';
import MultiSearchSimple from '../../components/MultiSearchSimple';

interface SelectMarcaTipoProps {
  refPai?: HTMLDivElement | null;
}

export default function SelectMarcaTipo({ refPai }: SelectMarcaTipoProps) {
  const isSmall = useMedia({ maxWidth: 480 });
  // console.log('marca tipo:', { isSmall });
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const {
    marcas,
    marcasFilterTipo,
    selectedLastMarca,
    selectedModelo,
    selectedTabMarca,
    setSelectedTabMarca,
    selectedTabMarcaPosition,
    setSelectedTabMarcaPosition,
    setOrigem,
  } = useProduct();

  useEffect(() => {
    setTimeout(() => {
      refPai &&
        searchParams.has('marca') &&
        refPai?.scrollTo({
          top: selectedTabMarcaPosition || 0,
          behavior: 'smooth',
        });
    }, 300);
    return setSelectedTabMarcaPosition(refPai?.scrollTop || 0);
  }, [
    refPai,
    selectedModelo,
    selectedTabMarcaPosition,
    searchParams,
    setSelectedTabMarcaPosition,
  ]);

  const defaultTabClass = (selected: boolean) =>
    classNames(
      'w-full leading-5 font-medium text-primary1',
      'focus:outline-none bg-gray-100',
      selected
        ? 'text-black text-sm border-b-2 border-b-primary1 border-b-solid font-bold'
        : 'text-black text-sm border-b-2 border-b-gray-100 border-b-solid hover:bg-slate-400 hover:text-white'
    );

  return (
    <>
      {/* <MultiSearch /> */}
      <MultiSearchSimple />
      {/* <TabSerach /> */}
      <Tab.Group
        defaultIndex={0}
        selectedIndex={selectedTabMarca || 0}
        onChange={(index) => setSelectedTabMarca(index)}
      >
        <Tab.List className="flex p-1 bg-gray-100 rounded-xl">
          <Tab className={({ selected }) => defaultTabClass(selected)}>
            Todas
          </Tab>
          {marcasFilterTipo &&
            marcasFilterTipo.length > 1 &&
            !isSmall &&
            marcasFilterTipo.map((tp) => (
              <Tab
                key={tp.id}
                className={({ selected }) => defaultTabClass(selected)}
              >
                {tp.tipoVeiculoDesc}
              </Tab>
            ))}
        </Tab.List>
        <Tab.Panels
          className={clsx('h-[71vh] overflow-hidden overflow-y-auto')}
        >
          {/* todos */}
          <Tab.Panel className="flex flex-wrap">
            {marcas.map((m) => (
              <Display
                key={m.id}
                id={m.id}
                selected={selectedLastMarca?.id}
                title={m.marcaDesc}
                img={m.images[0].img}
                onClick={() => {
                  setOrigem(EnumOrigens.MARCA_MODELO);
                  setSelectedTabMarcaPosition(refPai?.scrollTop || 0);
                  navigate(ROUTES_MAP.product + '/?marca=' + m.id);
                }}
              />
            ))}
          </Tab.Panel>
          {/* se o tipo for maior que 1 */}
          {marcasFilterTipo &&
            marcasFilterTipo.length > 1 &&
            !isSmall &&
            marcasFilterTipo.map((tp) => (
              <Tab.Panel className="flex flex-wrap" key={tp.id}>
                {tp.marcas.map(
                  (m) =>
                    m && (
                      <Display
                        key={m.id}
                        id={m.id}
                        selected={selectedLastMarca?.id}
                        title={m.marcaDesc}
                        img={m.images[0].img}
                        onClick={() => {
                          setOrigem(EnumOrigens.MARCA_MODELO);
                          setSelectedTabMarcaPosition(refPai?.scrollTop || 0);
                          navigate(ROUTES_MAP.product + '/?marca=' + m.id);
                        }}
                      />
                    )
                )}
              </Tab.Panel>
            ))}
        </Tab.Panels>
      </Tab.Group>
    </>
  );
}
