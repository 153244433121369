/* eslint-disable prettier/prettier */
import { IBanner, IDestaque, Produto, Search } from '../../app.models';
import { getCryptoLocalStorage } from '../../app.service';
import { LOCAL_STORAGE_DEFAUT_CLI } from '../../app.settings';
import api from '../../axios';
import { convertImages, filterImageSize } from '../../utils/functions';

export const productSearchPromotions = async (
  take = 10,
  skip = 0
): Promise<Search> => {
  const url = `/produtos/promocoes/?&take=${take}&skip=${skip}`;
  const data = (await api.get(url)).data;
  return {
    info: data.info,
    produtos: data.produtos.map((p: Produto) => ({
      ...p,
      images:
        p.images.length > 0
          ? convertImages(p.images, p.id, 'produtos')
          : [
            {
              small: 'https://via.placeholder.com/100?text=Sem Imagem',
              medium: 'https://via.placeholder.com/300?text=Sem Imagem',
              big: 'https://via.placeholder.com/600?text=Sem Imagem',
            },
          ],
    })),
  };
};

export async function getBanners(): Promise<IBanner[]> {
  const res = await api.get<IBanner[]>('/banners');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formated = res.data.map((b: any) => ({
    ...b,
    images: {
      small: filterImageSize(b.images, 'small'),
      medium: filterImageSize(b.images, 'medium'),
    },
  }));
  return formated.filter((b: IBanner) => b.isVisible);
}

export const getDestaque = async (id: string | number): Promise<Search> => {
  const tabelaId = getCryptoLocalStorage(LOCAL_STORAGE_DEFAUT_CLI)?.tabelaId;
  const clienteId = getCryptoLocalStorage(LOCAL_STORAGE_DEFAUT_CLI)?.clienteId;
  const url = `/produtos/destaques/${id}?clienteId=${clienteId}&tabelaId=${tabelaId}`;
  const data = (await api.get(url)).data;
  return {
    info: data.info,
    produtos: data.produtos.map((p: Produto) => ({
      ...p,
      images:
        p.images.length > 0
          ? convertImages(p.images, p.id, 'produtos')
          : [
            {
              small: 'https://via.placeholder.com/100?text=Sem Imagem',
              medium: 'https://via.placeholder.com/300?text=Sem Imagem',
              big: 'https://via.placeholder.com/600?text=Sem Imagem',
            },
          ],
    })),
  };
};

export const getDestaques = async (): Promise<IDestaque[]> => {
  const tabelaId = getCryptoLocalStorage(LOCAL_STORAGE_DEFAUT_CLI)?.tabelaId;
  const url = `/destaques/produtos/${tabelaId}`;
  const data = (await api.get(url)).data;
  const formatedData = data.map((d: IDestaque) => ({
    ...d,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    produtos: d.produtos.map((p: any) => ({
      ...p.produto,
      images:
        p.produto.images.length > 0
          ? convertImages(p.produto.images, p.produto.id, 'produtos')
          : [
            {
              small: 'https://via.placeholder.com/100?text=Sem Imagem',
              medium: 'https://via.placeholder.com/300?text=Sem Imagem',
              big: 'https://via.placeholder.com/600?text=Sem Imagem',
            },
          ],
    })),
  }));
  return formatedData.filter((d: IDestaque) => d.isVisible);
};
