import api from '../../axios';
import { Info, Cart } from '../../app.models';
// import { format } from 'date-fns';

interface SearchCarts {
  info: Info;
  carts: Cart[];
}

type CartParams = {
  searchFor?: string;
  situacao?: string;
  compradorId?: number;
  dataI?: Date;
  dataF?: Date;
  take?: number;
  skip?: number;
  sortBy?: string;
  ord?: number;
  defaultCli?: string;
  exibirZerados?: boolean;
};

export async function getCarts(
  params: CartParams,
  apenasAprovados = false
): Promise<SearchCarts> {
  const urlBase = '/carrinhos/?';
  const urlWithParams = [urlBase];

  if (urlWithParams.length === 0) return {} as SearchCarts;

  urlWithParams[0] = urlWithParams[0].concat(`cliente=${params.defaultCli}`);

  if (params.searchFor)
    urlWithParams[0] = urlWithParams[0].concat(
      `&searchFor=${params.searchFor || ''}`
    );

  if (params.dataI)
    urlWithParams[0] = urlWithParams[0].concat(
      `&dataI=${new Date(params.dataI)
        .toISOString()
        .slice(0, 10)
        .replace('T', ' ')}`
    );

  if (params.dataF)
    urlWithParams[0] = urlWithParams[0].concat(
      `&dataF=${new Date(params.dataF)
        .toISOString()
        .slice(0, 10)
        .replace('T', ' ')}`
    );

  if (apenasAprovados === false) {
    if (params.situacao)
      urlWithParams[0] = urlWithParams[0].concat(
        `&situacao=${params.situacao}`
      );
    if (params.compradorId)
      urlWithParams[0] = urlWithParams[0].concat(
        `&comprador=${params.compradorId}`
      );
    if (params.take)
      urlWithParams[0] = urlWithParams[0].concat(`&take=${params.take}`);
  } else {
    urlWithParams[0] = urlWithParams[0].concat(`&situacao=1`);
    urlWithParams[0].concat(`&take=${-1}`);
  }

  if (params.skip)
    urlWithParams[0] = urlWithParams[0].concat(`&skip=${params.skip}`);

  if (params.sortBy)
    urlWithParams[0] = urlWithParams[0].concat(`&sortBy=${params.sortBy}`);

  if (params.ord)
    urlWithParams[0] = urlWithParams[0].concat(`&ord=${params.ord}`);

  urlWithParams[0] = urlWithParams[0].concat(
    `&exibirZerados=${params.exibirZerados ?? false}`
  );

  if (!params.take)
    return {
      info: {
        pagina: '0',
        qtdePorPagina: '',
        ref: '',
        qtdeTotal: 0,
        ord: '',
        viewType: '',
        viewCol: '',
      },
      carts: [],
    };

  return (await api.get(urlWithParams[0])).data;
}
