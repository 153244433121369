interface PerPageProps {
  listPerPage: number[];
  setTake: (take: number) => void;
  take: number;
}

export default function AppPerPage(props: PerPageProps) {
  // if (listPerPage.includes(take))
  return (
    <select
      className="py-[6px] px-2 rounded-md text-xs shadow ring-1 ring-gray-300"
      value={props.take ?? props.listPerPage[0]}
      onChange={(e) => {
        props.setTake(Number(e.target.value));
      }}
    >
      {props.listPerPage.map((e, i) => (
        <option key={i} value={e}>
          {e} por página
        </option>
      ))}
      {/* <option value={200}>200 por página</option> */}
    </select>
  );
  // else return null;
}
