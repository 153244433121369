import { AnnotationIcon } from '@heroicons/react/outline';
import { useEffect, useState } from 'react';
import AppStep from '../../../components/appStep';
import CondFormaTransp from '../../../components/CondFormaTransp';
import Button from '../../../components/layout/button';
import { parseCurrencyBRL } from '../../../utils/numberHandler';
import { useOrder } from '../order.hook';

interface FinalizationProps {
  resume: {
    qtde: number;
    total: number;
    frete: number;
  };
  action: (
    obs: string,
    fpagto: number,
    cpagto: number,
    transportadora: number
  ) => void;
  sending: boolean;
  setStep: (step: number) => void;
}

export default function Finalization(props: FinalizationProps) {
  const { steps } = useOrder();
  // const [searchParams, setSearchParams] = useSearchParams();
  const [obs, setObs] = useState<string>('');
  const [condicao, setCondicao] = useState<number>();
  const [forma, setForma] = useState<number>();
  const [transportadora, setTransportadora] = useState<number>();
  const [error, setError] = useState<'forma' | 'cond' | 'transp' | undefined>();

  const previous = () => {
    // const params = searchParams;
    // params.set('step', '1');
    // setSearchParams(params);
    props.setStep(1);
  };

  useEffect(() => {
    setForma(undefined);
    setCondicao(undefined);
    setTransportadora(undefined);
  }, []);

  const next = () => {
    if (!forma) {
      setError('forma');
      new Promise((t) => setTimeout(t, 3000)).then(() => setError(undefined));
      return;
    }

    if (!condicao) {
      setError('cond');
      new Promise((t) => setTimeout(t, 3000)).then(() => setError(undefined));
      return;
    }

    if (!transportadora) {
      setError('transp');
      new Promise((t) => setTimeout(t, 3000)).then(() => setError(undefined));
      return;
    }

    props.action(obs, forma, condicao, transportadora);
    setForma(undefined);
    setCondicao(undefined);
    setTransportadora(undefined);
  };

  return (
    <div className="">
      <AppStep steps={steps} current={2} />
      <div className="flex justify-between px-4 py-2 mb-5">
        <span>
          <Button
            title="Anterior"
            color="bg-primary2 text-default-white hover:bg-primary1 hover:text-gray-100"
            onClick={previous}
          />
        </span>

        <span>
          <Button
            title="Enviar Pedido"
            color="bg-green-700 text-default-white hover:bg-green-400 hover:text-gray-100"
            onClick={next}
            disabled={props.sending}
          />
        </span>
      </div>
      <div className="px-6 flex flex-col md:flex-row">
        <div className="w-full md:1/2">
          <CondFormaTransp
            direction="row"
            error={error}
            valorTotal={props.resume.total}
            condicao={condicao}
            selectedCondicao={(condicao: number | undefined): void => {
              setCondicao(condicao);
            }}
            forma={forma}
            selectedForma={(forma: number | undefined): void => {
              setForma(forma);
            }}
            transportadora={transportadora}
            selectedTransportadora={(
              transportadora: number | undefined
            ): void => {
              setTransportadora(transportadora);
            }}
          />
          <div className="mt-3">
            <label className="text-sm font-medium text-gray-700 flex space-x-2 items-center">
              <AnnotationIcon className="w-6 h-6" /> <span>Observações:</span>
            </label>
            <div className="mt-1 relative rounded-md shadow-sm">
              <textarea
                className="bg-gray-200 focus:ring-red-500 focus:border-red-500 block w-full p-3 sm:text-sm border-gray-300 rounded-md"
                rows={6}
                value={obs}
                onChange={(e) => setObs(e.target.value)}
              ></textarea>
            </div>
          </div>
        </div>
        <div className="w-full md:1/2 flex justify-end">
          <div className="w-full md:w-1/2 bg-gray-100">
            <div className="px-2 text-gray-500 flex justify-between">
              <span className="text-xl">Quantidade</span>
              <span className="font-bold text-xl">{props.resume.qtde}</span>
            </div>
            <div className="px-2 text-gray-500 flex justify-between">
              <span className="text-xl">Produtos</span>
              <span className="font-bold text-xl">
                {parseCurrencyBRL(props.resume.total)}
              </span>
            </div>
            <div className="px-2 text-gray-500 flex justify-between ">
              <span className="text-xl">Frete</span>
              <span className="font-bold text-xl">
                {parseCurrencyBRL(props.resume.frete)}
              </span>
            </div>
            <div className="border-b-[1px] border-solid border-gray-300"></div>
            <div className="px-2 text-gray-500 flex justify-between ">
              <span className="text-xl">Total</span>
              <span className="font-bold text-xl">
                {parseCurrencyBRL(props.resume.frete + props.resume.total)}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
