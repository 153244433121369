import { SearchIcon } from '@heroicons/react/outline';
import { forwardRef, useState } from 'react';

interface MainSearchOrderProps {
  onSearch?: (text: string) => void;
}

const MainSearchOrder = forwardRef(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ({ onSearch }: MainSearchOrderProps, ref: any) => {
    const [simpleText, setSimpleText] = useState('');

    function search() {
      if (onSearch) {
        onSearch(simpleText);
      }
    }

    // teste
    return (
      <div className="flex flex-col w-[30vw]">
        {/* <span className="text-[0.8em] ml-4 text-white">Pesquisar produtos</span> */}
        <div className="flex items-center bg-white rounded-md text-gray-600 pr-1 w-full">
          <label htmlFor="search" className="text-[0.7em] px-[10px] text-black">
            Pesquisar Produto
          </label>
          <input
            ref={ref}
            className="bg-white w-full border-0 outline-none
                      focus:outline-hidden focus:shadow-none
                      active:outline-none ring-0 active:ring-0"
            type="search"
            placeholder="Pesquisar por produto..."
            value={simpleText}
            onChange={(e) => setSimpleText(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                search();
              }
            }}
          />
          <span className="w-1" />
          <button onClick={search} className="hover:text-gray-400">
            <SearchIcon className="w-8 h-8 p-1" />
          </button>
        </div>
      </div>
    );
  }
);

export default MainSearchOrder;
