import { MinusIcon, PlusIcon } from '@heroicons/react/outline';
import { InputHTMLAttributes, useRef, useEffect, useCallback } from 'react';
import { DebounceInput } from 'react-debounce-input';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  value: number;
  changeValue: (qtde: number) => void;
  keyEnter?: () => void;
}

export default function InputQtde(props: InputProps) {
  const ref = useRef<HTMLInputElement>(null);

  const setFocus = () => {
    if (ref.current) {
      ref.current.focus();
      // ref.current.select();
    }
  };

  useEffect(() => {
    setFocus();
  }, []);

  if (!props.value) return null;

  return (
    <div className="flex space-x-1 items-center">
      {/* <span className="">Qtde: </span> */}
      <div className="flex">
        <button
          type="button"
          className="rounded-l-full py-1 px-2 bg-slate-200 text-slate-700 hover:bg-slate-700 hover:text-slate-50 outline-none"
          onClick={() => {
            const qtde = props.value - 1;
            if (qtde > 0) props.changeValue(qtde);
            setFocus();
          }}
        >
          <MinusIcon className="w-4 h-4 " />
        </button>
        <DebounceInput
          inputRef={ref}
          type="text"
          pattern="[0-9]*"
          debounceTimeout={500}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onKeyPress={(event: any) => {
            switch (event.key) {
              default:
                const regex = new RegExp('^[0-9]*$');
                if (!regex.test(event.key)) {
                  event.preventDefault();
                }
            }
          }}
          className="w-16 px-2 py-1 bg-gray-300 text-center text-xs"
          value={props.value}
          onChange={(e) =>
            e.target.value && props.changeValue(parseInt(e.target.value))
          }
        />
        <button
          type="button"
          className="rounded-r-full py-1 px-2 bg-blue-400 text-default-white hover:bg-blue-700 hover:text-gray-50"
          onClick={() => {
            props.changeValue(props.value + 1);
            setFocus();
          }}
        >
          <PlusIcon className="w-4 h-4" />
        </button>
      </div>
    </div>
  );
}
