/* eslint-disable @typescript-eslint/no-explicit-any */
import AppStep from '../../../components/appStep';
import { useOrder } from '../order.hook';
import Table from '../../../components/SelectedTable';
import { useEffect, useRef, useState } from 'react';
import Button from '../../../components/layout/button';
import { toast } from 'react-toastify';
import useMedia from 'use-media';
import { getFormatedDateTime } from '../../../utils/datetimeHandler';
import { parseCurrencyBRL } from '../../../utils/numberHandler';
import { getCarts } from '../../myCarts/carts.service';
import ComboTabelaPreco from '../../../components/comboTabelaPrecos';
import { TabelaPreco } from '../../../app.models';
import { classNames } from '../../../utils/functions';
import { useApp } from '../../../app.hook';

interface SelectionProps {
  rowSelecteds: any;
  setRowSelecteds: (rows: any[]) => void;
  setSelectedsCotacoes: (rows: number[]) => void;
  setStep: (step: number) => void;
  tabelaPreco: string;
  setTabelaPreco: (tabelaPreco: string) => void;
}

export default function Selection(props: SelectionProps) {
  const { isPerfilManager } = useApp();
  const [carts, setCarts] = useState<unknown[]>([]);
  const isSmall = useMedia({ maxWidth: 480 });
  const { steps } = useOrder();
  // const [searchParams, setSearchParams] = useSearchParams();
  const selecteds = useRef<number[]>([]);
  const rowSelecteds = useRef<any>(props.rowSelecteds);
  const { defCliente } = useOrder();

  useEffect(() => {
    getCarts(
      {
        take: 999,
        defaultCli: defCliente,
      },
      true
    ).then((data) => {
      setCarts(
        data.carts.map((el) => ({
          ...el,
          created_at: getFormatedDateTime(el.created_at.toString()),
          total: parseCurrencyBRL(el.total),
        }))
      );
    });
  }, [defCliente]);

  const next = () => {
    if (selecteds.current?.length === 0) {
      toast.info('Selecione os carrinhos');
      return;
    }
    if (!props.tabelaPreco) {
      toast.info('Selecione uma tabela de preço');
      return;
    }

    // const params = searchParams;
    // params.set('step', '1');
    // params.set('cotacoesIds', selecteds.current?.toString() || '');
    // params.set('tabelaPreco', selectedTabelaId);
    // setSearchParams(params);
    props.setSelectedsCotacoes(selecteds.current);
    props.setRowSelecteds(rowSelecteds.current);
    props.setStep(1);
  };

  if (isSmall)
    return (
      <>
        <div className="">
          <AppStep steps={steps} current={0} />
        </div>
        <div
          className={classNames(
            'flex  px-4 py-2',
            isPerfilManager ? 'justify-between' : 'justify-end'
          )}
        >
          <ComboTabelaPreco
            action={(tabelaPreco: TabelaPreco) => {
              if (tabelaPreco.id.toString() === props.tabelaPreco) return;
              props.setTabelaPreco(tabelaPreco.id.toString());
            }}
            tabelaPreco={Number(props.tabelaPreco)}
          />
          <Button
            title="Próximo"
            color="bg-blue-700 text-default-white hover:bg-blue-400 hover:text-gray-100"
            onClick={next}
          />
        </div>
        <div className="px-4">
          <Table
            columns={[
              {
                Header: '#',
                accessor: 'id',
              },
              {
                Header: 'Dt',
                accessor: 'created_at',
              },
              {
                Header: 'Qt',
                accessor: 'qtdeTotal',
              },
              {
                Header: 'Total',
                accessor: 'total',
              },
            ]}
            data={carts}
            selectedRows={props.rowSelecteds}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            callback={(data: any) => {
              rowSelecteds.current = data.selectedRowIds;
              selecteds.current = data.selected.reduce(
                (acc: number[], cur: any) => {
                  acc.push(cur.id);
                  return acc;
                },
                []
              );
            }}
          />
        </div>
      </>
    );

  return (
    <>
      <div className="">
        <AppStep steps={steps} current={0} />
      </div>
      <div
        className={classNames(
          'flex  px-4 py-2',
          isPerfilManager ? 'justify-between' : 'justify-end'
        )}
      >
        <ComboTabelaPreco
          action={(tabelaPreco: TabelaPreco) => {
            if (tabelaPreco.id.toString() === props.tabelaPreco) return;
            props.setTabelaPreco(tabelaPreco.id.toString());
          }}
          tabelaPreco={Number(props.tabelaPreco)}
        />
        <Button
          title="Próximo"
          color="bg-blue-700 text-default-white hover:bg-blue-400 hover:text-gray-100"
          onClick={next}
        />
      </div>
      <div className="px-4">
        <Table
          columns={[
            {
              Header: '#',
              accessor: 'id',
            },
            {
              Header: 'Data',
              accessor: 'created_at',
            },
            {
              Header: 'Cliente',
              accessor: 'cliente.nome',
            },
            {
              Header: 'Vendedor',
              accessor: 'usuario.nome',
            },
            {
              Header: 'Comprador',
              accessor: 'comprador.nome',
            },
            {
              Header: 'Qtde',
              accessor: 'qtdeTotal',
            },
            {
              Header: 'Total',
              accessor: 'total',
            },
          ]}
          data={carts}
          selectedRows={props.rowSelecteds}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          callback={(data: any) => {
            rowSelecteds.current = data.selectedRowIds;
            selecteds.current = data.selected.reduce(
              (acc: number[], cur: any) => {
                acc.push(cur.id);
                return acc;
              },
              []
            );
          }}
        />
      </div>
    </>
  );
}
