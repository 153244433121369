import { useApp } from '../app.hook';
import { ReactComponent as WhatsappLogo } from '../assets/svg/whatsapp.svg';

export default function WhatsappMenu() {
  const { empresa } = useApp();
  if (!(empresa?.exibirBotaoWhatsApp ?? true)) return null;
  if (!empresa?.whatsappNro) return null;

  return (
    <a
      //---->https://api.whatsapp.com/send?phone=558533931468&text=Ol%C3%A1,%20vi%20um%20an%C3%BAncio%20e%20tenho%20interesse%20em%20fazer%20um%20pedido
      href={`https://api.whatsapp.com/send?phone=${empresa.whatsappNro}&text=${
        empresa.whatsappTexto ??
        'Olá, vi um anúncio e tenho interesse em fazer um pedido.'
      }`}
      target="_blank"
      className="cursor-pointer group flex items-center gap-1"
    >
      <WhatsappLogo className="h-10 w-10 group-hover:scale-110" />
      <div className="flex flex-col justify-center">
        <div className="text-xs text-white group-hover:underline">Fale</div>
        <div className="text-xs text-white group-hover:underline">conosco</div>
      </div>
    </a>
  );
}
