import { BanIcon, CheckIcon } from '@heroicons/react/outline';
import Steps from 'rc-steps';
import 'rc-steps/assets/index.css';

const { Step } = Steps;

interface AppStepProps {
  current: number;
  steps: IStep[];
}

export interface IStep {
  title: string;
  subTitle?: string;
  // status?: 'finish' | 'process' | 'wait';
  description?: string;
}

export default function AppStep(props: AppStepProps) {
  const containerStyle = {
    border: '1px solid rgb(235, 237, 240)',
    marginBottom: 24,
  };

  const getStatus = (idx: number): 'finish' | 'process' | 'wait' => {
    if (idx === props.current) return 'process';
    if (idx < props.current) return 'finish';
    if (idx > props.current) return 'wait';
    return 'process';
  };

  if (!props.steps || props.steps.length === 0) return null;

  return (
    <div className="">
      <Steps
        style={containerStyle}
        type="navigation"
        current={props.current}
        icons={{
          finish: <CheckIcon className="h-6 w-6" />,
          error: <BanIcon className="h-6 w-6" />,
        }}
        onChange={() => null}
      >
        {props.steps.map((st, idx) => (
          <Step
            key={idx}
            title={st.title}
            status={getStatus(idx)}
            subTitle={st.subTitle}
            description={st.description}
          />
        ))}
      </Steps>
    </div>
  );
}
