import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { Fragment } from 'react';
import { ProdutoCart } from '../../app.models';
import Button from '../../components/layout/button';
import { parseCurrency } from '../../utils/numberHandler';
import { useCart } from './cart.hook';

interface ModalDeleteItemProps {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  item: ProdutoCart;
}

export default function ModalDeleteItem(props: ModalDeleteItemProps) {
  const { deleteItemCart } = useCart();
  function closeModal() {
    props.setIsOpen(false);
  }

  return (
    <div>
      <Transition appear show={props.isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={closeModal}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black opacity-20" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl border-[1px] border-solid border-gray-300">
                <Dialog.Title as="h3" className="flex justify-between">
                  <span className="text-lg font-bold leading-6 text-red-900 font-display">
                    Remover Item
                  </span>
                  <button
                    onClick={() => props.setIsOpen(false)}
                    className="hover:text-gray-400"
                  >
                    <XIcon className="w-5 h-5" />
                  </button>
                </Dialog.Title>
                {props.item?.produto && (
                  <div className="flex items-center font-display">
                    <div className="h-24 w-24 shrink-0">
                      <img
                        style={{
                          width: '120px',
                          height: '120px',
                          objectFit: 'contain',
                          borderRadius: '0.375rem',
                          margin: '0 auto',
                        }}
                        src={props.item.produto.images[0].small}
                        // src="https://via.placeholder.com/300"
                        alt={props.item.produto.descricaoSite}
                        className="w-full relative -top-6"
                        // onError={(e) =>
                        //   replaceImgWithError(
                        //     e,
                        //     'https://via.placeholder.com/160x120?text=Sem Imagem'
                        //   )
                        // }
                      />
                    </div>
                    <div className="ml-4">
                      <div className="text-sm font-medium text-gray-900">
                        {props.item.produto.descricaoSite}
                      </div>
                      {/* <div className="text-[0.7em] text-gray-500">
                      {props.item.produto.descricaoCompleta}
                    </div> */}
                      <div className="text-[0.7em] text-gray-500">
                        <span className="">Categoria: </span>
                        <span className="font-bold">
                          {props.item.produto.categoria.descricao}
                        </span>
                      </div>
                      <div className="text-[0.7em] text-gray-500">
                        <span className="">Referência: </span>
                        <span className="font-bold">
                          {props.item.produto.referencia}
                        </span>
                      </div>
                      <div className="text-[0.7em] text-gray-500">
                        <span className="">Código: </span>
                        <span className="font-bold">
                          {props.item.produto.codigoSgi}
                        </span>
                      </div>
                      <div className="text-[0.7em] text-gray-500">
                        <span className="">R$ </span>
                        <span className="font-bold text-lg">
                          {parseCurrency(props.item.unitarioLiquido)}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                <div className="font-display">
                  <Button
                    title="Remover Item"
                    full="true"
                    onClick={() => {
                      deleteItemCart(props.item.id);
                      props.setIsOpen(false);
                    }}
                  />
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}
