export const ROUTES_MAP = {
  promotions: '/',
  ofertas: '/ofertas',
  oferta: '/ofertas/:id',
  ofertaId: (id: string | number) => `/ofertas/${id}`,

  myProfile: '/meu-perfil',

  cart: '/carrinho-atual',
  myCarts: '/carrinhos',

  orderSuccess: '/pedido-sucesso',

  users: '/usuarios',
  usersForm: '/usuarios/formulario',
  usersFormID: (id: string) => '/usuarios/formulario/' + id,

  myOrders: '/pedidos',
  transmission: '/pedidos/transmissao',

  product: '/produtos',
  productId: '/produtos/exibir/:id',
  productById: (id: number) => '/produtos/exibir/' + id,

  upload: '/upload',
  uploadProductList: '/upload/produtos',
  uploadProductId: '/upload/produto/:id',
  uploadProductById: (id: number) => '/upload/produto/' + id,
  uploadBannerList: '/upload/banners',

  painel: '/painel',

  balcao: '/balcao',
};
