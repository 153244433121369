import clsx from 'clsx';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useApp } from '../../../app.hook';
import { Categoria } from '../../../app.models';
import LazyLoadingImage from '../../../components/lazyLadingImage';
import { IClienteMarkup } from '../markup.interface';
import {
  BanIcon,
  ChartPieIcon,
  SaveIcon,
  XIcon,
} from '@heroicons/react/outline';
import LoadingComponent from '../../../components/loadingComponent';
import { Dialog, Transition } from '@headlessui/react';
import { useForm } from 'react-hook-form';

interface Props {
  markups: IClienteMarkup[];
  setMarkup: (markup: IClienteMarkup, isEdit: boolean) => void;
  resetMarkup: (markupId: number, updated_at: string) => void;
}

type TClassName = React.HTMLAttributes<HTMLDivElement>['className'] | undefined;

// item
const ITEM_CLASS: TClassName = clsx(
  'aspect-w-9 aspect-h-4 bg-white-500',
  'flex flex-col transition ease-in-out delay-150 hover:scale-[1.1] rounded-md overflow-hidden group',
  'border-[2px] border-gray-500 border-solid'
);

const ITEM_DESC_CLASS: TClassName = clsx(
  'text-md w-full p-1 z-10 font-bold text-primary1 text-shadow',
  'h-[102px]',
  'p-1 text-shadow-white'
);

const ITEM_INFO_CLASS: TClassName = clsx(
  'w-full p-1 mt-[70%] font-bold text-white',
  'flex justify-center items-center gap-2',
  'h-12 bg-slate-500',
  'p-1 text-2xl '
);

export default function ListCategorias({
  markups,
  setMarkup,
  resetMarkup,
}: Props) {
  const { categoriasRaw } = useApp();
  const [filterCategorias, setFilterCategorias] = useState<Categoria[]>([]);
  const [loadingCategorias, setLoadingCategorias] = useState<boolean>(false);
  const refInner = useRef<HTMLDivElement | null>(null);

  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);
  const markupForm = useForm<IClienteMarkup>({
    defaultValues: {},
  });

  const idCurrentMarkup = markupForm.watch('markupId');
  const updatedAtCurrentMarkup = markupForm.watch('updated_at');

  const existMarkup = (id: number) => {
    return markups.find((m) => m.id === id);
  };

  const submit = (data: IClienteMarkup) => {
    setMarkup(data, existMarkup(data.id) ? true : false);
    closeModal();
  };

  useEffect(() => {
    setLoadingCategorias(true);
    const res = categoriasRaw;
    // ordena por descricao
    const list = res.filter(
      (item) =>
        item.temSubCategoria === 0 && item.ativo === 1 && item.isVisible === 1
    );
    const sort = list.sort((a, b) => a.descricao.localeCompare(b.descricao));
    setFilterCategorias(sort);
    setLoadingCategorias(false);
  }, [categoriasRaw]);

  // controle de movimentação do teclado, teclas de seta usando tabulação
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'ArrowLeft') {
        const items = refInner.current?.querySelectorAll('button');
        if (!items) return;
        const getSelectedTabIndex = (items: NodeListOf<HTMLButtonElement>) => {
          for (let i = 0; i < items.length; i++) {
            if (items[i] === document.activeElement) {
              return i;
            }
          }
          return -1;
        };
        const indexSelected = getSelectedTabIndex(items);
        items[indexSelected - 1]?.focus();
      }

      if (e.key === 'ArrowRight') {
        const items = refInner.current?.querySelectorAll('button');
        if (!items) return;
        const getSelectedTabIndex = (items: NodeListOf<HTMLButtonElement>) => {
          for (let i = 0; i < items.length; i++) {
            if (items[i] === document.activeElement) {
              return i;
            }
          }
          return -1;
        };
        const indexSelected = getSelectedTabIndex(items);
        items[indexSelected + 1]?.focus();
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [refInner]);

  if (loadingCategorias) {
    return (
      <div className="h-[65vh] flex justify-center items-center">
        <LoadingComponent />
      </div>
    );
  }

  return (
    <>
      <div
        ref={refInner}
        className="grid lg:grid-cols-3 xl:grid-cols-5 2xl:grid-cols-6 gap-x-1 gap-y-4 py-3 px-6 h-[65vh] overflow-hidden overflow-y-auto"
      >
        {filterCategorias.map((categ) => {
          const findMarkup = existMarkup(categ.id);
          return (
            <button
              onClick={() => {
                markupForm.reset({
                  ...categ,
                  ...findMarkup,
                });
                openModal();
              }}
              style={{ width: `150px`, height: `150px` }}
              className={clsx(
                ITEM_CLASS,
                !findMarkup && 'grayscale opacity-50'
              )}
            >
              <div className={clsx(ITEM_DESC_CLASS)}>{categ.descricao}</div>
              <LazyLoadingImage
                classNames={['mx-auto rounded-full blur-[1px]']}
                alt={categ.descricao}
                height={'100%'}
                width={'100%'}
                src={categ.images[0].img}
                efect="blur"
              />
              <div className={clsx(ITEM_INFO_CLASS)}>
                <ChartPieIcon className="h-6 w-6" />
                <span>{findMarkup?.markup?.toFixed(2) ?? '0'}%</span>
              </div>
            </button>
          );
        })}
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto font-display">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="flex justify-between mb-2 bg-primary2 text-default-white px-4 py-2"
                  >
                    <span className="text-lg font-bold leading-6 font-display">
                      {markupForm.getValues('descricao')}
                    </span>
                    <button
                      onClick={closeModal}
                      className="hover:text-gray-400"
                    >
                      <XIcon className="w-5 h-5" />
                    </button>
                  </Dialog.Title>
                  <div className="p-5">
                    <div className="mt-2">
                      <label className="block">
                        <span className="text-gray-700">Margen Lucro (%)</span>
                        <input
                          type="number"
                          className={clsx(
                            'form-input',
                            markupForm.formState.errors.markup
                              ? 'bg-red-100'
                              : ''
                          )}
                          min="100"
                          max="1000"
                          {...markupForm.register('markup', {
                            valueAsNumber: true,
                            required: true,
                            min: 0,
                            max: 1000,
                          })}
                        />
                      </label>
                    </div>

                    <div className="mt-4 flex gap-2">
                      {idCurrentMarkup && (
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={() => {
                            resetMarkup(
                              idCurrentMarkup as number,
                              updatedAtCurrentMarkup as unknown as string
                            );
                            closeModal();
                          }}
                        >
                          <BanIcon className="w-4 h-4" />
                          <span>Resetar</span>
                        </button>
                      )}
                      <button
                        onClick={markupForm.handleSubmit(submit)}
                        className="btn btn-success"
                        type="submit"
                      >
                        <SaveIcon className="w-4 h-4" />
                        <span>Salvar</span>
                      </button>
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
