import React, { useCallback, useEffect } from 'react';
import clsx from 'clsx';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';
import {
  ButtonBack,
  ButtonNext,
  CarouselProvider,
  Slide,
  Slider,
} from 'pure-react-carousel';
import { Categoria } from '../app.models';
import LazyLoadingImage from './lazyLadingImage';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ROUTES_MAP } from '../app.map.routes';
import { getTake } from '../utils/functions';
import { EnumOrigens } from '../utils/origens';
import { useApp } from '../app.hook';
import { useProduct } from '../pages/product/product.hook';

type TClassName = React.HTMLAttributes<HTMLDivElement>['className'] | undefined;
const PRIMARY = 'bg-primary text-white';

export default function SearchCategoria() {
  const navigate = useNavigate();
  const { isSm, isMd, isLg, isXl, is2xl, is3xl, empresa } = useApp();
  const { setOrigem } = useProduct();
  const [params] = useSearchParams();
  const { tabSearchCurrentSlider, setTabSearchCurrentSlider } = useApp();
  const [currentSlider, setCurrentSlider] = React.useState<number>(0);

  const _take = getTake();
  // const [showMenu, setShowMenu] = React.useState(false);

  // item
  const ITEM_CLASS: TClassName = clsx(
    'aspect-w-16 aspect-h-9',
    'flex flex-col transition ease-in-out delay-150 hover:scale-[1.1] rounded-md overflow-hidden min-h-full group'
  );

  const ITEM_DESC_CLASS: TClassName = clsx(
    'text-[0.55em] w-full bg-transparent p-1 mt-[70%] flex justify-center items-center group-hover:font-bold group-hover:text-black',
    'flex flex-col justify-center items-center h-full',
    'h-12 md:h-12 lg:h-12 xl:h-12 2xl:h-20',
    'lg:text-[0.6em] xl:text-[0.7em] 2xl:text-[0.8em]'
  );

  // configuracao da proporção de slides visiveis
  const [visibleSlides, setVisibleSlides] = React.useState(12);
  const [naturalSlideWidth, setNaturalSlideWidth] = React.useState(1);
  const [naturalSlideHeight, setNaturalSlideHeight] = React.useState(1);
  const [slideWidth, setSlideWidth] = React.useState(12);
  const refContentCategorias = React.useRef<HTMLDivElement>(null);
  const [contentWidth, setContentWidth] = React.useState<number>();

  useEffect(() => {
    function handleResize() {
      if (refContentCategorias && refContentCategorias.current) {
        if (isSm) {
          setVisibleSlides(4);
          setNaturalSlideWidth(1);
          setNaturalSlideHeight(10);
          setSlideWidth(0.5);
          setContentWidth(300);
        }
        if (isMd) {
          setVisibleSlides(6);
          setNaturalSlideWidth(1);
          setNaturalSlideHeight(1.1);
          setSlideWidth(6);
          setContentWidth(580);
        }
        if (isLg) {
          //lg:max-w-3xl
          setVisibleSlides(7);
          setNaturalSlideWidth(1);
          setNaturalSlideHeight(0.9);
          setSlideWidth(6.5);
          setContentWidth(680);
        }
        if (isXl) {
          //xl:max-w-4xl
          setVisibleSlides(9);
          setNaturalSlideWidth(0.6);
          setNaturalSlideHeight(0.64);
          setSlideWidth(7);
          setContentWidth(900);
        }
        if (is2xl) {
          //2xl:max-w-5xl
          setVisibleSlides(10);
          setNaturalSlideWidth(0.7);
          setNaturalSlideHeight(0.78);
          setSlideWidth(8);
          setContentWidth(1250);
        }
        if (is3xl) {
          //2xl:max-w-5xl
          setVisibleSlides(12);
          setNaturalSlideWidth(0.7);
          setNaturalSlideHeight(0.78);
          setSlideWidth(8);
          setContentWidth(1250);
        }
      }
    }

    handleResize();
    return window.removeEventListener('resize', handleResize);
  }, [isSm, isMd, isLg, isXl, is2xl, is3xl]);
  const [loadingCategorias, setLoadingCategorias] = React.useState(false);
  const { categoriasRaw } = useApp();
  const [categorias, setCategorias] = React.useState<Categoria[]>([]);

  useEffect(() => {
    setLoadingCategorias(true);
    const res = categoriasRaw;
    // console.log('🚀 ~ file: tabSerach.tsx:133 ~ useEffect ~ res:', res);
    // // remove Todas
    // const idx = res.findIndex((item) => item.descricao === 'Todas');
    // const list = [...res];
    // list.splice(idx, 1);
    // ordena por descricao
    const list = res.filter(
      (item) =>
        item.temSubCategoria === 0 && item.ativo === 1 && item.isVisible === 1
    );
    const sort = list.sort((a, b) => a.descricao.localeCompare(b.descricao));
    setCategorias(sort);
    setLoadingCategorias(false);
  }, [categoriasRaw]);

  const setSlide = useCallback(async (index: number) => {
    await new Promise((resolve) => setTimeout(resolve, 700));
    setCurrentSlider(index);
  }, []);

  useEffect(() => {
    setSlide(tabSearchCurrentSlider ?? 0);
  }, [tabSearchCurrentSlider, setSlide]);

  return (
    <div className="flex justify-center w-full">
      <div
        className={clsx(
          'flex flex-col w-full max-w-6xl',
          'z-0',
          'pb-2 pt-1',
          'bg-slate-200',
          'gap-2',
          PRIMARY
        )}
      >
        <div
          className={clsx(
            'transition-opacity transition-ease-in-out duration-600'
          )}
        >
          <div ref={refContentCategorias}>
            <CarouselProvider
              naturalSlideWidth={naturalSlideWidth}
              naturalSlideHeight={naturalSlideHeight}
              totalSlides={categorias.length}
              step={visibleSlides}
              infinite={true}
              orientation="horizontal"
              dragEnabled={true}
              currentSlide={currentSlider}
              // interval={6000}
              // isPlaying={categorias.length > visibleSlides}
              visibleSlides={visibleSlides}
              className={clsx('w-full h-full')}
            >
              <div className="flex flex-rol">
                <div className="flex flex-col group hover:bg-black/20">
                  <ButtonBack className="h-full">
                    <ChevronLeftIcon
                      className={clsx('w-8 h-8', PRIMARY.split(' ')[1])}
                    />
                  </ButtonBack>
                </div>

                {!loadingCategorias && (
                  <Slider className="w-full">
                    {categorias.map((categ: Categoria, index) => {
                      return (
                        <Slide index={index} key={index}>
                          <button
                            onClick={() => {
                              setTabSearchCurrentSlider(index);
                              setOrigem(EnumOrigens.CATEGORIA);
                              navigate(
                                ROUTES_MAP.product +
                                  '/?categoria=' +
                                  categ.id +
                                  '&take=' +
                                  _take.toString() +
                                  `&skip=0&tags={"categorias":[${categ.id}]}`
                              );
                            }}
                            style={{ width: `${slideWidth}em` }}
                            className={clsx(
                              ITEM_CLASS,
                              params.has('categoria') &&
                                params.get('categoria') === categ.id.toString()
                                ? 'bg-black/20 text-white'
                                : 'bg-white/60 text-black'
                            )}
                          >
                            <LazyLoadingImage
                              classNames={['mx-auto rounded-full']}
                              alt={categ.descricao}
                              height={'100%'}
                              width={'100%'}
                              src={categ.images[0].img}
                              efect="blur"
                            />
                            <div className={ITEM_DESC_CLASS}>
                              <span>{categ.descricao}</span>
                            </div>
                          </button>
                        </Slide>
                      );
                    })}
                  </Slider>
                )}

                <div className="flex flex-col group hover:bg-black/20">
                  <ButtonNext className="h-full">
                    <ChevronRightIcon
                      className={clsx('w-8 h-8', PRIMARY.split(' ')[1])}
                    />
                  </ButtonNext>
                </div>
              </div>
            </CarouselProvider>
          </div>

          {/* {tabSearchIndex === 1 && (
            <div id="contentVeiculo" className={CONTENT_CLASS}>
              <div className="w-[45em] mx-auto">
                <MenuMarcaModelo reverseColors={true} />
              </div>
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
}
