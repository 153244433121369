import {
  BanIcon,
  EyeIcon,
  EyeOffIcon,
  PencilAltIcon,
  RefreshIcon,
  ReplyIcon,
  UploadIcon,
} from '@heroicons/react/outline';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useApp } from '../../app.hook';
import { IBanner } from '../../app.models';
import { delBanner, getBanners, saveBanner } from '../../app.service';
import Button from '../../components/layout/button';
import ModalConfirmation from '../../components/modalConfirmation';
import ModalUpload from '../../components/modalUpload';
import Unautorized from '../unauthorized';
import UploadBannerFormModal from './uploadBannerFormModal';
import { Img } from 'react-image';

interface IModalUploadData {
  pathUrl: string;
  uuid: string;
  imageDelIndex: number;
  aspectRatio?: number;
}

export default function UploadBanner() {
  const { isPerfilManager } = useApp();
  const navigate = useNavigate();
  const [banners, setBanners] = useState<IBanner[]>([]);
  const [isOpenModalBannerForm, setIsOpenModalBannerForm] = useState(false);
  const [loading, setLoading] = useState<{ id: number; ative: boolean }>();
  const [modalOpenConfirm, setModalOpenConfirm] = useState(false);
  const [openModalUpload, setOpenModalUpload] = useState(false);
  const modalUploadData = useRef<IModalUploadData>({
    pathUrl: '',
    uuid: '',
    imageDelIndex: 0,
    aspectRatio: 1,
  });

  const data = useRef({} as IBanner);
  const init = async () => {
    const data = await getBanners();
    setBanners(data);
  };

  useEffect(() => {
    init();
  }, []);

  const save = async (data: IBanner) => {
    data.id && setLoading({ id: data.id, ative: true });
    await saveBanner(data);
    setLoading(undefined);
    init();
  };

  if (!isPerfilManager) return <Unautorized />;

  return (
    <div className="mt-[19vh] md:mt-0 h-full md:h-[88vh] md:overflow-hidden md:overflow-y-auto">
      <div className="h-16 md:h-12 flex items-center justify-between space-x-1 bg-gray-300 px-4">
        <Button
          title="Ofertas"
          icon={<ReplyIcon className="w-4 h-4" />}
          color="bg-defaultWhite text-primary2 hover:bg-gray-100 hover:text-gray-700"
          onClick={() => navigate('/')}
        />
        <div className="flex space-x-4">
          <Button
            title="Atualizar Banners"
            icon={<RefreshIcon className="w-4 h-4" />}
            color="bg-green-400 text-white hover:bg-green-500 hover:text-gray-100"
            onClick={init}
          />
          <Button
            title="Inserir Banner"
            icon={<UploadIcon className="w-4 h-4" />}
            color="bg-green-400 text-white hover:bg-green-500 hover:text-gray-100"
            onClick={() => {
              data.current = {} as IBanner;
              setIsOpenModalBannerForm(true);
            }}
          />
        </div>
      </div>
      <div className="md:h-[82.4vh] md:overflow-hidden md:overflow-y-auto">
        <div className="flex flex-col px-4 mt-4 space-y-4">
          {banners.map((el: IBanner) => (
            <div key={el.id} className="bg-gray-300 p-2 rounded-lg space-y-3">
              <div className="flex justify-between items-center text-gray-700">
                <div className="flex flex-col">
                  <strong className="text-lg">{el.descricao}</strong>
                  <p className="text-sm">{el.urlDestino}</p>
                </div>
                {loading?.id &&
                el.id &&
                loading.id === el.id &&
                loading.ative ? (
                  <div className="flex items-center">
                    <svg
                      className="animate-spin -ml-1 mr-3 h-5 w-5 text-gray-500"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                    <p className="text-sm text-gray-500">Salvando...</p>
                  </div>
                ) : (
                  <span className="flex">
                    {/* Upload de Banner */}
                    <button
                      onClick={() => {
                        modalUploadData.current.aspectRatio = 1200 / 200;
                        modalUploadData.current.pathUrl = `/banners/${el.id}/upload`;
                        setTimeout(() => {
                          setOpenModalUpload(true);
                        }, 100);
                      }}
                      className="p-2 rounded-full hover:bg-slate-50"
                      title="Upload de Banner"
                    >
                      <UploadIcon className="w-4 h-4" />
                    </button>

                    {/* Deixar Visível */}
                    <button
                      title="Deixar Visível"
                      onClick={() =>
                        save({
                          ...el,
                          isVisible: !el.isVisible,
                        })
                      }
                      className="p-2 rounded-full hover:bg-slate-50"
                    >
                      {el.isVisible ? (
                        <EyeIcon className="w-4 h-4" />
                      ) : (
                        <EyeOffIcon className="w-4 h-4" />
                      )}
                    </button>
                    {/* Editar Nome e Link */}
                    <button
                      title="Editar Nome e Link"
                      onClick={() => {
                        data.current = el;
                        setTimeout(() => {
                          setIsOpenModalBannerForm(true);
                        }, 100);
                      }}
                      className="p-2 rounded-full hover:bg-slate-50"
                    >
                      <PencilAltIcon className="w-4 h-4" />
                    </button>
                    {/* Excluir Banner */}
                    <button
                      title="Excluir Banner"
                      onClick={() => {
                        data.current = el;
                        setTimeout(() => {
                          setModalOpenConfirm(true);
                        }, 100);
                      }}
                      className="p-2 rounded-full hover:bg-slate-50"
                    >
                      <BanIcon className="w-4 h-4" />
                    </button>
                  </span>
                )}
              </div>
              <Img
                src={[
                  el.images.medium,
                  el.images.medium.replace('api', 'api-teste'),
                  '/no-image-1200x200.png',
                ]}
                loading={'lazy'}
                sizes={
                  '(max-width: 768px) 100vw, (max-width: 992px) 50vw, (max-width: 1200px) 33.33vw, (max-width: 1600px) 25vw, (max-width: 1920px) 20vw, 100vw'
                }
                className="w-full h-full"
              />

              {/* <img src={el.images.small} className="w-full h-full" /> */}
            </div>
          ))}
        </div>
      </div>
      <UploadBannerFormModal
        data={data.current}
        setData={save}
        isOpen={isOpenModalBannerForm}
        setIsOpen={setIsOpenModalBannerForm}
      />
      <ModalConfirmation
        isOpen={modalOpenConfirm}
        setIsOpen={setModalOpenConfirm}
        title="Confirmação"
        question={'Remover Banner ' + (data.current.descricao || '?') + '?'}
        answer={() => {
          if (!data.current.id) return;
          delBanner(data.current.id).then(init);
        }}
      />
      <ModalUpload
        isOpen={openModalUpload}
        setIsOpen={setOpenModalUpload}
        title={'Upload de Imagem'}
        uploadInfo={'PNG, JPG, GIF up to 1MB'}
        aspect={modalUploadData.current.aspectRatio || 1}
        pathUrl={modalUploadData.current.pathUrl}
        autoRelaod={false}
        afterUpload={init}
      />
    </div>
  );
}
