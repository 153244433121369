import api from '../../axios';
import { SearchUsuario, Usuario } from '../../app.models';
import { toast } from 'react-toastify';

const URL_BASE = '/auth/usuarios';

export async function getUsuarios(
  defaultCli: string,
  skip = 0,
  take = 10,
  searchFor = ''
): Promise<SearchUsuario> {
  const endpoint = `${URL_BASE}/?cliente=${defaultCli}&searchFor=${searchFor}&take=${take}&skip=${skip}`;
  const users = await api.get<SearchUsuario>(endpoint);
  return users.data;
}

export function addUser(user: Usuario): Promise<Usuario> | void {
  const usuario = {
    email: user.email,
    nome: user.nome,
    senha: user.password,
    telefone: user.telefone,
    clienteId: user.cliente,
  };

  return api.post(URL_BASE, usuario);
}

export async function updUsuario(user: Usuario) {
  try {
    if (!user.id) {
      toast.error('Usuário não encontrado');
      throw new Error('Usuario não possui id');
    }
    const usuario = {
      email: user.email,
      nome: user.nome,
      telefone: user.telefone,
      isAtivo: user.isAtivo,
      clienteId: user.cliente,
    };

    const users = await api.put(URL_BASE + '/' + user.id, usuario);
    toast.success('Usuário atualizado com sucesso');
    return users.data;
  } catch (err: any) {
    console.log(err);
  }
}

export function altSenha(userId: string, email: string, senha: string) {
  // console.log('alterarSenha$(user)', userId, email, senha);
  const usuario = {
    email,
    senha,
  };
  return api.put(URL_BASE + '/alterarsenha/' + userId, { usuario }).then(() => {
    toast.success('Senha alterada com sucesso');
  });
}

export function delUser(id: string) {
  return api.delete(`${URL_BASE}/${id}`);
}

export function getPerfil(id: string) {
  return api.get(`${URL_BASE}`);
}
