import { DotsCircleHorizontalIcon } from '@heroicons/react/outline';
import { useRef, useState } from 'react';
import { Pedido } from '../../app.models';
import ModalConfirmation from '../../components/modalConfirmation';
import { getFormatedDateTime } from '../../utils/datetimeHandler';
import { classNames } from '../../utils/functions';
import { parseCurrency, parseCurrencyBRL } from '../../utils/numberHandler';
import { useOrder } from './order.hook';

const INITIAL_MODAL_CONFIRMATION = {
  question: '',
  title: '',
  clienteId: 0,
  id: 0,
  situacaoPedido: 0,
  updated_at: new Date(),
  usuarioId: '',
};

interface CardMyOrdersProps {
  pedidos: Pedido[];
  afterAction: () => void;
}

interface PedidoStatusProps {
  situacaoPedido: number;
}
const PedidoStatus = ({ situacaoPedido }: PedidoStatusProps) => {
  const { pedidoStatus } = useOrder();
  const status = pedidoStatus.find((c) => c.id === situacaoPedido);
  const class_name = pedidoStatus.find(
    (c) => c.id === situacaoPedido
  )?.className;
  if (status)
    return (
      <div className={classNames('flex pedidos-center', class_name)}>
        <span>{status.icone}</span>
        <span>{status.situacao}</span>
      </div>
    );
  else
    return (
      <div className="flex pedidos-center">
        <span>
          <DotsCircleHorizontalIcon className="w-5 h-5" />
        </span>
        <span>Não Definida</span>
      </div>
    );
};

export default function CardMyOrders(props: CardMyOrdersProps) {
  const [modalConfirm, setModalConfirm] = useState<boolean>(false);
  const modalConfirmationRef = useRef(INITIAL_MODAL_CONFIRMATION);

  // console.log(props.pedidos);
  return (
    <>
      {props.pedidos &&
        props.pedidos.map((pedido) => {
          return (
            <div
              className="my-2 p-2 odd:bg-gray-200 even:bg-gray-50"
              key={pedido.id}
            >
              <div className="px-1 py-0 flex justify-between">
                <div className="text-sm text-gray-900 text-left font-bold">
                  Ped: #{pedido.id}
                </div>
                <div className="text-sm text-gray-900 text-left">
                  {getFormatedDateTime(pedido.created_at.toString())}
                </div>
              </div>
              <div className="px-1 py-0 flex justify-between">
                <span className="text-sm text-gray-900 text-left font-bold">
                  Situação:
                </span>
                <PedidoStatus situacaoPedido={pedido.situacaoPedido} />
              </div>

              <div className="px-1 py-0 flex justify-between">
                <span className="text-sm text-gray-900 text-left  font-bold">
                  Pedido:
                </span>
                <div className="text-sm text-gray-900 text-left">
                  {pedido.xRef}
                </div>
              </div>
              <div className="px-1 py-0 flex flex-col">
                <span className="text-sm text-gray-900 text-left font-bold">
                  Transmissão:
                </span>
                {pedido.transmissao ? (
                  <span className="bg-green-200 px-3 py-1 rounded-lg">
                    {getFormatedDateTime(pedido.transmissao?.toString())}
                  </span>
                ) : (
                  <span className="bg-yellow-200 px-3 py-1 my-1 rounded-lg">
                    Não Recebido
                  </span>
                )}
              </div>

              <div className="text-sm text-gray-900 flex justify-between px-1 font-bold">
                <span>Qtde: {pedido.qtdeTotal}</span>
                <span>{parseCurrencyBRL(pedido.total)}</span>
              </div>
              <div className="text-center"></div>
            </div>
          );
        })}
      <ModalConfirmation
        isOpen={modalConfirm}
        setIsOpen={setModalConfirm}
        title={modalConfirmationRef.current.title}
        question={modalConfirmationRef.current.question}
        answer={() => null}
      />
    </>
  );
}
