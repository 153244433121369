import { Transition, Dialog } from '@headlessui/react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import {
  Fragment,
  LegacyRef,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Categoria } from '../app.models';
import { useApp } from '../app.hook';
import { classNames } from '../utils/functions';
import LazyLoadingImage from '../components/lazyLadingImage';
import clsx from 'clsx';

interface ModalCategoriaSearchProps {
  reverseColors?: boolean;
  onSearch: (categ: Categoria, origem: string) => void;
}

type TClassName = React.HTMLAttributes<HTMLDivElement>['className'] | undefined;

// item
const ITEM_CLASS: TClassName = clsx(
  'aspect-w-16 aspect-h-9',
  'flex flex-col transition ease-in-out delay-150 hover:scale-[1.1] rounded-md overflow-hidden min-h-full group',
  'border-[2px] border-slate-400 border-solid'
);

const ITEM_DESC_CLASS: TClassName = clsx(
  'text-[0.55em] w-full p-1 mt-[70%] flex justify-center items-center group-hover:font-bold group-hover:text-white',
  'flex flex-col justify-center items-center',
  'h-12',
  'lg:text-[0.6em] xl:text-[0.7em] 2xl:text-[0.8em] p-1'
);

const ModalCategoriaSearch = forwardRef(
  (
    { onSearch, reverseColors = false }: ModalCategoriaSearchProps,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ref: LegacyRef<HTMLButtonElement>
  ) => {
    const { categoriasRaw } = useApp();
    const [loadingCategorias, setLoadingCategorias] = useState<boolean>(false);
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const [selectedCategoria, setSelectedCategoria] = useState<Categoria>();

    const [position, setPosition] = useState<number>(0);
    const refInner = useRef<HTMLDivElement | null>(null);

    const open = () => {
      setIsOpen(true);
    };

    const close = () => {
      setIsOpen(false);
    };

    const [filterCategorias, setFilterCategorias] = useState<Categoria[]>([]);

    // loading categorias
    useEffect(() => {
      setLoadingCategorias(true);
      const res = categoriasRaw;
      // ordena por descricao
      const list = res.filter(
        (item) =>
          item.temSubCategoria === 0 && item.ativo === 1 && item.isVisible === 1
      );
      const sort = list.sort((a, b) => a.descricao.localeCompare(b.descricao));
      setFilterCategorias(sort);
      setLoadingCategorias(false);
    }, [categoriasRaw]);

    useEffect(() => {
      const t = setTimeout(() => {
        if (isOpen) {
          if (selectedCategoria) {
            refInner.current?.scrollTo({
              top: position || 0,
              behavior: 'smooth',
            });

            // colocar o focus no primeiro item
            const buttons = refInner.current?.querySelectorAll('button');
            const selected = Array.from(buttons || []).find(
              (b) => b.getAttribute('data-selected') === 'true'
            );
            if (selected) {
              selected.focus();
            }
            // const first = refInner.current?.querySelector('button');
            // first?.focus();
          } else {
            refInner.current?.querySelector('button')?.focus();
          }
        }
      }, 100);

      return () => {
        clearTimeout(t);
      };
    }, [isOpen, position, selectedCategoria]);

    // controle de movimentação do teclado, teclas de seta usando tabulação
    useEffect(() => {
      const handleKeyDown = (e: KeyboardEvent) => {
        if (e.key === 'ArrowLeft') {
          const items = refInner.current?.querySelectorAll('button');
          if (!items) return;
          const getSelectedTabIndex = (
            items: NodeListOf<HTMLButtonElement>
          ) => {
            for (let i = 0; i < items.length; i++) {
              if (items[i] === document.activeElement) {
                return i;
              }
            }
            return -1;
          };
          const indexSelected = getSelectedTabIndex(items);
          items[indexSelected - 1]?.focus();
        }

        if (e.key === 'ArrowRight') {
          const items = refInner.current?.querySelectorAll('button');
          if (!items) return;
          const getSelectedTabIndex = (
            items: NodeListOf<HTMLButtonElement>
          ) => {
            for (let i = 0; i < items.length; i++) {
              if (items[i] === document.activeElement) {
                return i;
              }
            }
            return -1;
          };
          const indexSelected = getSelectedTabIndex(items);
          items[indexSelected + 1]?.focus();
        }
      };
      window.addEventListener('keydown', handleKeyDown);
      return () => {
        window.removeEventListener('keydown', handleKeyDown);
      };
    }, [refInner]);

    return (
      <>
        <div className="font-display">
          <div className="group flex-grow-0 flex flex-col md:flex-row space-x-2 md:space-y-0 items-center md:font-bold px-1">
            <button
              ref={ref}
              className={`flex flex-grow-0 items-center space-x-2 px-2 hover:bg-default-white py-1 rounded-full group`}
              onClick={() => {
                open();
              }}
            >
              <MenuIcon className="w-10 h-10 text-gray-400 bg-white p-[1px] rounded-full lg:flex" />
              <span
                className={classNames(
                  'font-bold text-gray-600 md:text-default-white group-hover:text-primary1',
                  reverseColors ? 'md:text-primary1' : 'md:text-default-white'
                )}
              >
                <div>
                  {String(
                    selectedCategoria?.descricao || 'Todos os produtos'
                  ).toLocaleUpperCase()}
                </div>
              </span>
            </button>
          </div>
        </div>
        <Transition appear show={isOpen} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-50 overflow-y-auto"
            onClose={close}
          >
            <div className="px-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-black opacity-20" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="inline-block h-screen align-middle"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="inline-block font-display w-full max-w-6xl overflow-hidden align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                  <Dialog.Title
                    as="h3"
                    className="flex justify-between mb-2 bg-primary2 text-default-white px-4 py-2"
                  >
                    <span className="text-lg flex-1 font-bold leading-6 font-display flex gap-5 items-center">
                      <span>
                        {selectedCategoria?.descricao || 'Todos os produtos'}
                      </span>
                    </span>
                    <button onClick={close} className="hover:text-gray-400">
                      <XIcon className="w-5 h-5" />
                    </button>
                  </Dialog.Title>
                  {/* content */}
                  <div
                    ref={refInner}
                    className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-x-1 gap-y-4 py-3 px-6 h-[85vh] overflow-hidden overflow-y-auto"
                  >
                    {filterCategorias.map((categ) => (
                      <button
                        key={categ.id}
                        onClick={() => {
                          setSelectedCategoria(categ);
                          setPosition(refInner.current?.scrollTop || 0);
                          onSearch(categ, 'categorias');
                          close();
                        }}
                        style={{ width: `150px`, height: `150px` }}
                        className={clsx(ITEM_CLASS)}
                        data-selected={selectedCategoria?.id === categ.id}
                      >
                        <LazyLoadingImage
                          classNames={['mx-auto rounded-full']}
                          alt={categ.descricao}
                          height={'100%'}
                          width={'100%'}
                          src={categ.images[0].img}
                          efect="blur"
                        />
                        <div
                          className={clsx(
                            ITEM_DESC_CLASS,
                            selectedCategoria?.id === categ.id
                              ? 'bg-primary2 text-white'
                              : 'bg-slate-400 text-white'
                          )}
                        >
                          <span>{categ.descricao}</span>
                        </div>
                      </button>
                    ))}
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition>
      </>
    );
  }
);

export default ModalCategoriaSearch;
