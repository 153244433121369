import { useEffect, useState } from 'react';
import { ProdutoCart } from '../../app.models';
import InputQtdeMin from '../../components/inputQtdeMin';
import { Progress } from '../../components/progress';
// import { replaceImgWithError } from '../../utils/functions';
import externalLink from '../../assets/svg/external-link.svg';
import { Link } from 'react-router-dom';
import { ROUTES_MAP } from '../../app.map.routes';
import { useProduct } from '../product/product.hook';
import { EnumOrigens } from '../../utils/origens';
import { getTake } from '../../utils/functions';
import ExibirPrecoPromocaoConsumidorCarrinho from './exibirPrecoPromocaoConsumidorCarrinho';
import ExibirPrecoConsumidorFinal from '../../components/ExibirPrecoConsumidorFinal';

interface TableListProductsProps {
  products: ProdutoCart[];
  qtdeAction: (itemId: number, qtde: number) => void;
  delAction: (item: ProdutoCart) => void;
  active: boolean;
}

export default function TableListProducts({
  products,
  qtdeAction,
  delAction,
  active,
}: TableListProductsProps) {
  const { setOrigem } = useProduct();
  const [items, setItems] = useState<ProdutoCart[]>([]);

  useEffect(() => {
    setItems(products);
  }, [products]);

  return (
    <table className="w-full divide-y divide-gray-200">
      <thead className="bg-gray-100 sticky top-0 z-[1]">
        <tr>
          <th
            scope="col"
            className="px-1 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Item
          </th>
          <th
            scope="col"
            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Produto
          </th>
          <th
            scope="col"
            className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Qtde
          </th>
          <th
            scope="col"
            className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Valor
          </th>
        </tr>
      </thead>
      <tbody className="">
        {items &&
          items.map((item, index) => (
            <tr
              key={item.id}
              className="border-b-[1px] border-solid border-gray-300"
            >
              {/* item */}
              <td className="px-1 py-4 whitespace-nowrap align-middle w-1/12">
                <div className="text-md font-bold text-gray-900 text-center">
                  {index + 1}
                </div>
              </td>
              {/* imagem/produto*/}
              <td className="px-6 py-4 align-middle w-8/12">
                <div className="flex items-center">
                  <div className="h-24 w-24 shrink-0">
                    <img
                      style={{
                        width: '120px',
                        height: '120px',
                        objectFit: 'contain',
                        borderRadius: '0.375rem',
                        margin: '0 auto',
                      }}
                      src={item.produto.images[0].medium}
                      alt={item.produto.descricaoSite}
                      className="w-full relative -top-6"
                    />
                  </div>
                  <div className="ml-4 w-full group">
                    <Link
                      onClick={() => setOrigem(EnumOrigens.CARRINHO)}
                      to={ROUTES_MAP.productById(item.produto.id)}
                      className="text-sm font-medium text-gray-900 hover:underline flex items-start"
                    >
                      <img className="w-3 h-3 mr-1 mt-1" src={externalLink} />
                      {item.produto.descricaoSite}
                    </Link>

                    <div className="text-[0.7em] text-gray-500">
                      <span className="">Categoria: </span>
                      <span className="font-bold">
                        <Link
                          onClick={() => setOrigem(EnumOrigens.CATEGORIA)}
                          className="text-blue-400 underline"
                          to={
                            '/produtos/?categoria=' +
                            item.produto.categoria.id +
                            '&take=' +
                            getTake() +
                            '&skip=0'
                          }
                        >
                          {item.produto?.categoria?.descricao}
                        </Link>
                        {/* {item.produto.categoria.descricao} */}
                      </span>
                    </div>

                    <div className="text-[0.7em] text-gray-500">
                      <span className="">Referência: </span>
                      <span className="font-bold">
                        {item.produto.referencia}
                      </span>
                    </div>

                    <div className="text-[0.7em] text-gray-500">
                      <span className="">Código: </span>
                      <span className="font-bold">
                        {item.produto.codigoSgi}
                      </span>
                    </div>

                    <div>
                      {item.isPromocao ? (
                        <div className="relative -top-4 flex flex-col my-1">
                          {item.produto?.promocao ? (
                            <div>
                              <div className="flex flex-col items-end">
                                <span className="flex space-x-2 md:text-sm lg:text-md line-through text-gray-400">
                                  {/* <span>
                                    {parseCurrency(item.produto.precoPromo)}
                                  </span> */}
                                  <ExibirPrecoPromocaoConsumidorCarrinho
                                    produto={item}
                                  />
                                </span>
                                <span className="flex space-x-2 text-md md:text-md lg:text-xl text-amber-500 font-bold">
                                  {/* <span>
                                    {parseCurrency(item.unitarioLiquido)}
                                  </span> */}
                                  <ExibirPrecoConsumidorFinal
                                    item={item}
                                    className="text-inherit"
                                    field="unitarioLiquido"
                                    fieldConsumidorFinal="unitarioLiquidoConsumidorFinal"
                                    isTotal
                                  />
                                </span>
                              </div>
                              {item.produto.promocao && (
                                <div className="flex justify-end">
                                  <div className="w-[50%]">
                                    <Progress
                                      qtdeTotal={
                                        item.produto.promocao.qtdeEmPromocao
                                      }
                                      qtdeMovimentada={
                                        item.produto.promocao.qtdeMovimentada
                                      }
                                      qtdeDisponivel={
                                        item.produto.promocao.qtdeDisponivel
                                      }
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                          ) : (
                            <div className="flex flex-col items-end space-y-1">
                              <div className="text-red-500">
                                :( Saiu de oferta
                              </div>
                              {/* <Button
                                type="button"
                                title={'Atualizar Qtde'}
                                onClick={() => {
                                  qtdeAction(
                                    item.produto.id,
                                    item.produto?.promocao?.qtdeEmPromocao || 0
                                  );
                                }}
                              /> */}
                            </div>
                          )}
                        </div>
                      ) : (
                        <div className="relative -top-6 flex flex-col justify-start my-0 py-2">
                          <div className="flex justify-end">
                            {/* <span className="flex space-x-2 font-bold text-xl">
                              <span>{parseCurrency(item.unitarioLiquido)}</span>
                            </span> */}

                            <ExibirPrecoConsumidorFinal
                              item={item}
                              className="flex space-x-2 text-lg font-bold text-gray-500"
                              field="unitarioLiquido"
                              fieldConsumidorFinal="unitarioLiquidoConsumidorFinal"
                              isTotal
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </td>
              {/* qtde */}
              <td className="px-6 py-4 whitespace-nowrap align-middle w-2/12">
                {active ? (
                  <InputQtdeMin
                    initialValue={item.qtde}
                    action={(qtde) => {
                      qtdeAction(item.produto.id, qtde);
                    }}
                    delAction={() => {
                      delAction(item);
                    }}
                  />
                ) : (
                  <div className="text-lg text-center w-full">{item.qtde}</div>
                )}
              </td>
              {/* valor */}
              <td className="text-right align-middle">
                <div
                  className="px-6 py-4 whitespace-nowrap text-lg font-bold
                            text-gray-700"
                >
                  {/* {parseCurrency(item.totalItem)} */}
                  <ExibirPrecoConsumidorFinal
                    item={item}
                    className="text-inherit font-bold"
                    field="totalItem"
                    fieldConsumidorFinal="totalItemConsumidorFinal"
                    isTotal
                  />
                </div>
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  );
}
