/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dialog, RadioGroup, Transition } from '@headlessui/react';
import { BanIcon, CheckIcon } from '@heroicons/react/outline';
import { Fragment, Key } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { ErrorTransmission } from '../../app.models';
import Button from '../../components/layout/button';

interface ModalErrorsProps {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  errors: ErrorTransmission[];
  afterClose: (actions: Record<string, string>[]) => void;
}

export default function ModalErrors(props: ModalErrorsProps) {
  const { control, handleSubmit, reset } = useForm();

  function closeModal() {
    props.setIsOpen(false);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const submit = (data: any) => {
    const resp: Record<string, string>[] = [];
    const entries = Object.entries(data.answers);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    entries.forEach((entry: any) => {
      const [index, action] = entry;
      if (index && action) {
        resp.push({
          // index: props.errors[index].index.toString(),
          index: props.errors[index].itemId.toString(),
          action: action.toString().replace(/,/g, '').trim(),
        });
      }
    });
    reset();
    closeModal();
    props.afterClose(resp);
  };

  return (
    <div>
      <Transition appear show={props.isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={closeModal}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black opacity-20" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <form
                onSubmit={handleSubmit(submit)}
                className="inline-block w-full max-w-xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl border-[1px] border-solid border-gray-300"
              >
                <div className="flex flex-col font-display">
                  <div className="flex text-red-600 space-x-2 my-2 items-center">
                    <div className="h-16 w-16">
                      <BanIcon />
                    </div>
                    <div>
                      <div className="text-lg">
                        Não foi possível enviar o pedido.
                      </div>
                      <div className="text-lg">Verifique os itens abaixo:</div>
                    </div>
                  </div>
                  <div className="max-h-[60vh] space-y-2 my-2 overflow-hidden overflow-y-auto px-1">
                    {props.errors.map((error: any, index: Key) => (
                      <div
                        key={index}
                        className="text-sm font-display flex flex-col p-1 bg-slate-100 rounded-lg"
                      >
                        <div className="flex space-x-2">
                          <div className="h-16 w-16">
                            <img src={error.image} className="rounded-lg" />
                          </div>
                          <div className="space-y-1">
                            <div className="font-medium">{error.produto}</div>
                            <div className="font-medium text-red-600">
                              {error.error}
                            </div>
                          </div>
                        </div>
                        <div className="text-red-700"></div>
                        <Controller
                          name={`answers.${index}.${error.index}`}
                          control={control}
                          rules={{ required: true }}
                          render={({ field }) => {
                            return (
                              <RadioGroup
                                className="flex justify-between"
                                {...field}
                              >
                                {error.actions.map(
                                  (ac: any, i: Key | null | undefined) => (
                                    <RadioGroup.Option
                                      key={i}
                                      value={ac.action}
                                      className={({ active, checked }) =>
                                        `${
                                          active
                                            ? 'ring-2 ring-white ring-opacity-60 ring-offset-2 ring-offset-sky-300'
                                            : ''
                                        }
                                 ${
                                   checked
                                     ? 'bg-sky-900 bg-opacity-75 text-white'
                                     : 'bg-white'
                                 }
                                   relative flex cursor-pointer rounded-lg px-5 py-4 shadow-md focus:outline-none`
                                      }
                                    >
                                      {({ active, checked }) => (
                                        <>
                                          <div className="flex w-full items-center justify-between">
                                            <div className="flex items-center">
                                              <div className="text-sm">
                                                <RadioGroup.Label
                                                  as="p"
                                                  className={`font-medium  ${
                                                    checked
                                                      ? 'text-white'
                                                      : 'text-gray-900'
                                                  }`}
                                                >
                                                  {ac.title}
                                                </RadioGroup.Label>
                                              </div>
                                            </div>
                                            {checked && (
                                              <div className="shrink-0 text-white">
                                                <CheckIcon className="h-6 w-6" />
                                              </div>
                                            )}
                                          </div>
                                        </>
                                      )}
                                    </RadioGroup.Option>
                                  )
                                )}
                              </RadioGroup>
                            );
                          }}
                        />
                      </div>
                    ))}
                  </div>
                </div>
                <div className="flex justify-between font-display my-2">
                  <Button
                    title="Fechar"
                    color="bg-gray-200 text-gray-900"
                    type="button"
                    onClick={() => {
                      reset();
                      props.setIsOpen(false);
                    }}
                  />
                  <Button title="Corrigir Pedido" type="submit" />
                </div>
              </form>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}
