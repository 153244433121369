/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createElement } from 'react';

const getHeader = (column: any) => {
  return (cellProps: any) => {
    const { computedWidth, computedSortInfo } = cellProps;
    const title = createElement(
      'span',
      {
        style: {
          display: 'inline-flex',
          fontSize: '0.8rem',
          fontWeight: 'bold',
          width: computedWidth - 40,
        },
      },
      column.charAt(0).toUpperCase() + column.slice(1)
    );

    const asc = createElement(
      'div',
      {
        style: {
          width: '0px',
          height: '0px',
          borderLeft: '5px solid transparent',
          borderRight: '5px solid transparent',
          borderBottom: '5px solid black',
          margin: 'auto',
        },
      },
      null
    );

    const desc = createElement(
      'div',
      {
        style: {
          width: '0px',
          height: '0px',
          borderLeft: '5px solid transparent',
          borderRight: '5px solid transparent',
          borderTop: '5px solid black',
          margin: 'auto',
        },
      },
      null
    );

    return createElement(
      'div',
      {
        style: {
          width: computedWidth,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: 'inline-flex',
          margin: 'auto',
          cursor: 'pointer',
        },
      },
      title,
      computedSortInfo ? computedSortInfo.dir === 1 ? asc : desc : null

    );
  };
};

export const columns: any = [
  {
    name: 'codigoSgi',
    header: getHeader('Código'),
    headerProps: { className: 'bg-slate-200' },
    defaultVisible: true,
    defaultWidth: 70,
    draggable: false,
    sortable: true,
    showColumnMenuTool: false,
  },
  {
    name: 'referencia',
    header: getHeader('Ref.'),
    headerProps: { className: 'bg-slate-200' },
    defaultWidth: 80,
    sortable: true,
    showColumnMenuTool: false,
  },
  // {
  //   name: 'unidade',
  //   header: 'Un.',
  //   defaultWidth: 50,
  //   headerAlign: 'center',
  //   textAlign: 'center',
  //   sortable: true,
  //   showColumnMenuTool: false,
  // },
  {
    name: 'descricaoSite',
    header: getHeader('Descrição'),
    headerProps: { className: 'bg-slate-200' },
    defaultWidth: 350,
    sortable: true,
    showColumnMenuTool: false,
  },
  {
    name: 'precoPromo',
    header: getHeader('Preço'),
    headerProps: { className: 'bg-slate-200' },
    defaultWidth: 120,
    showColumnMenuTool: false,
    headerAlign: 'left',
    textAlign: 'right',
    sortable: true,
    render(h: any) {
      return h.data.isPromocao ?
        // com promocao
        createElement('div', {
          className: 'flex flex-col gap-1'
        },
          createElement('span', {
            style: {
              color: 'red',
              textDecoration: 'line-through',
              fontSize: '0.8rem',
            },
          }, Number(h.data.preco).toLocaleString('pt-BR', {
            style: 'decimal',
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })),
          createElement('span', {
            style: {
              color: 'green',
              fontWeight: 'bold',
              fontSize: '0.9rem',
            },
          }, Number(h.data.precoPromo).toLocaleString('pt-BR', {
            style: 'decimal',
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })),
        ) :
        // sem promocao
        createElement('div', {
          className: 'flex justify-end'
        },
          createElement('span', {
            style: {
              color: 'black',
              fontSize: '0.8rem',
            },
          }, Number(h.data.precoPromo).toLocaleString('pt-BR', {
            style: 'decimal',
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })))
    },
  },
];

export const columnsManager = [
  {
    name: 'codigoSgi',
    header: getHeader('Código'),
    headerProps: { className: 'bg-slate-200' },
    defaultVisible: true,
    defaultWidth: 70,
    draggable: false,
    sortable: true,
    showColumnMenuTool: false,
  },
  {
    name: 'referencia',
    header: getHeader('Ref.'),
    headerProps: { className: 'bg-slate-200' },
    defaultWidth: 80,
    sortable: true,
    showColumnMenuTool: false,
  },
  // {
  //   name: 'unidade',
  //   header: 'Un.',
  //   defaultWidth: 50,
  //   headerAlign: 'center',
  //   textAlign: 'center',
  //   sortable: true,
  //   showColumnMenuTool: false,
  // },
  {
    name: 'descricaoSite',
    header: getHeader('Descrição'),
    headerProps: { className: 'bg-slate-200' },
    defaultWidth: 350,
    sortable: true,
    showColumnMenuTool: false,
  },
  {
    name: 'precoPromo',
    header: getHeader('Preço'),
    headerProps: { className: 'bg-slate-200' },
    defaultWidth: 120,
    showColumnMenuTool: false,
    headerAlign: 'left',
    textAlign: 'right',
    sortable: true,
    render(h: any) {
      return h.data.isPromocao ?
        // com promocao
        createElement('div', {
          className: 'flex flex-col gap-1'
        },
          createElement('span', {
            style: {
              color: 'red',
              textDecoration: 'line-through',
              fontSize: '0.8rem',
            },
          }, Number(h.data.preco).toLocaleString('pt-BR', {
            style: 'decimal',
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })),
          createElement('span', {
            style: {
              color: 'green',
              fontWeight: 'bold',
              fontSize: '0.9rem',
            },
          }, Number(h.data.precoPromo).toLocaleString('pt-BR', {
            style: 'decimal',
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })),
        ) :
        // sem promocao
        createElement('div', {
          className: 'flex justify-end'
        },
          createElement('span', {
            style: {
              color: 'black',
              fontSize: '0.8rem',
            },
          }, Number(h.data.precoPromo).toLocaleString('pt-BR', {
            style: 'decimal',
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })))
    },
  },
  {
    name: 'saldo',
    header: getHeader('Estq'),
    headerProps: { className: 'bg-slate-200' },
    defaultWidth: 60,
    showColumnMenuTool: false,
    headerAlign: 'left',
    textAlign: 'center',
    sortable: true,
    render: ({ value }: { value: any }) => {
      return createElement(
        'span',
        {
          style: {
            color: Number(value.saldo || 0) > 0 ? 'black' : '#ff0000',
            // fontWeight: Number(value.saldo || 0) > 0 ? 'bold' : 'normal',
          },
        },
        Number(value.saldo)
      );
    },
  },
  // {
  //   name: 'isPromocao',
  //   header: getHeader('Oferta'),
  //   defaultWidth: 60,
  //   headerAlign: 'left',
  //   textAlign: 'center',
  //   sortable: true,
  //   showColumnMenuTool: false,
  //   render({ value }: { value: boolean }) {
  //     return value
  //       ? createElement(etiqueta, {
  //         style: {
  //           width: '20px',
  //           height: '20px',
  //           fill: '#bf8a18',
  //           margin: 'auto',
  //         },
  //         className: 'animate-pulse',
  //       })
  //       : null;
  //   },
  // },
];
