import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useApp } from '../../app.hook';
import { genericDel } from '../../app.service';
import ModalUpload from '../../components/modalUpload';
import ModalUploadDropZone from '../../components/modalUploadDropZone';
import Unautorized from '../unauthorized';
import UploadDisplay from './upload.display';
import { useUpload } from './upload.hook';

export default function Upload() {
  // const navigate = useNavigate();
  const {
    setTake,
    setSkip,
    setGenericPath,
    genericPath,
    paths,
    data,
    loading,
    reloadData,
  } = useUpload();
  const [openModalUpload, setOpenModalUpload] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const pathUrl = useRef<string>('');

  useEffect(() => {
    const currentParams = Object.fromEntries(searchParams);

    // PATH +++++++++++++++++++++++++++
    if (searchParams.has('path')) {
      const path = currentParams.path;
      setGenericPath(parseInt(path));
    } else {
      const params = searchParams;
      params.set('path', '-1');
      setSearchParams(params);
    }
  }, [searchParams, setGenericPath, setSearchParams, setSkip, setTake]);

  const { isPerfilManager } = useApp();
  if (!isPerfilManager)
    return (
      <div className="md:h-[89.4vh]">
        <Unautorized />
      </div>
    );

  return (
    <div className="mt-[19vh] md:mt-0">
      <div className="h-12 flex items-center justify-between bg-gray-300 px-4">
        {paths && (
          <select
            className="py-[6px] px-2 rounded-md text-xs shadow ring-1 ring-gray-300"
            value={genericPath}
            onChange={(e) => {
              const value = e.target.value;
              const params = searchParams;
              params.set('path', value);
              setSearchParams(params);
            }}
          >
            <option value={-1}>Selecione ...</option>
            {paths.map((e, i) => (
              <option key={i} value={e.id}>
                {e.title}
              </option>
            ))}
            {/* <option value={200}>200 por página</option> */}
          </select>
        )}
        {/* <Button
          title="Produtos"
          icon={<UploadIcon className="w-4 h-4" />}
          color="bg-default-white text-primary2 hover:bg-gray-100 hover:text-gray-700"
          onClick={() => navigate(ROUTES_MAP.uploadProductList)}
        /> */}
      </div>
      <div className="md:h-[82.4vh] md:overflow-hidden md:overflow-y-auto">
        {loading ? (
          <div className="flex flex-col justify-center items-center h-[88vh] animate-pulse">
            <span className="mx-auto"></span>Aguarde ...
          </div>
        ) : (
          <div className="flex flex-wrap p-2">
            {data.map((el) => (
              <UploadDisplay
                key={el.id}
                title={el.descricao}
                img={el.images[0].img}
                onClick={() => {
                  if (!searchParams.has('path')) return;
                  pathUrl.current =
                    process.env.REACT_APP_API_URL +
                    '/' +
                    paths[parseInt(searchParams.get('path') || '')].path +
                    '/' +
                    el.id +
                    '/upload';
                  setTimeout(() => {
                    setOpenModalUpload(true);
                  }, 100);
                }}
              />
            ))}
          </div>
        )}
        <ModalUploadDropZone
          isOpen={openModalUpload}
          setIsOpen={setOpenModalUpload}
          title={'Upload de Imagem'}
          uploadInfo={'PNG, JPG, GIF up to 1MB'}
          pathUrl={pathUrl.current}
          afterUpload={() => reloadData()}
          deleteAction={() =>
            genericDel(pathUrl.current.replace('/upload', '/fotos'))
          }
          autoRelaod={true}
        />
        {/* <ModalUpload
          isOpen={openModalUpload}
          setIsOpen={setOpenModalUpload}
          title={'Upload de Imagem'}
          uploadInfo={'PNG, JPG, GIF up to 1MB'}
          aspect={1.533}
          pathUrl={pathUrl.current}
          deleteAction={() =>
            genericDel(pathUrl.current.replace('/upload', '/fotos'))
          }
        /> */}
      </div>
    </div>
  );
}
