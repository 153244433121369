import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import useMedia from 'use-media';
// import { LOCAL_STORAGE_PREFIX } from '../../app.settings';
import Pagination from '../../components/layout/pagination';
import { useCarts } from './carts.hook';
import ParamsCart from './paramsCarts';
import TableMyCarts from './tableMyCarts';
import { format, parse } from 'date-fns';
import { LOCAL_STORAGE_PREFIX } from '../../app.settings';

export default function Carts() {
  const isSmall = useMedia({ maxWidth: 480 });
  const {
    carts,
    info,
    loading,
    setTake,
    setSkip,
    dtIni,
    setDtIni,
    dtEnd,
    setDtEnd,
    setFilters,
    filters,
    loadCarts,
    setShowZeroCarts,
  } = useCarts();
  const [searchParams, setSearchParams] = useSearchParams();

  // const location = useLocation();

  useEffect(() => {
    const currentParams = Object.fromEntries(searchParams);

    // DATE INI +++++++++++++++++++++++++++
    if (searchParams.has('dataI')) {
      const dataI = currentParams.dataI;
      dataI
        ? setDtIni(parse(dataI, 'yyyy-MM-dd', new Date(dataI)))
        : setDtIni(undefined);
    }
    // DATE END +++++++++++++++++++++++++++
    if (searchParams.has('dataF')) {
      const dataF = currentParams.dataF;
      dataF
        ? setDtEnd(parse(dataF, 'yyyy-MM-dd', new Date(dataF)))
        : setDtEnd(undefined);
    }
    // SEARCH_FOR +++++++++++++++++++++++++++
    // if (searchParams.has('searchFor')) {
    //   const searchFor = currentParams.searchFor;
    //   searchFor !== searchOnPageCarts && setSearchOnPageCarts(searchFor);
    // } else {
    //   const params = searchParams;
    //   params.set('searchFor', searchOnPageCarts);
    //   setSearchParams(params);
    // }

    // FILTERS +++++++++++++++++++++++++++
    if (searchParams.has('situacao')) {
      const situacao = currentParams.situacao;
      setFilters(situacao);
    } else {
      const params = searchParams;
      params.set('situacao', filters || '');
      setSearchParams(params);
    }

    // TAKE +++++++++++++++++++++++++++
    if (searchParams.has('take')) {
      const take = parseInt(currentParams.take);
      setTake(take);
    } else {
      setTake(
        parseInt(
          localStorage.getItem(LOCAL_STORAGE_PREFIX + 'page-take-carts') || '0'
        )
      );
      const params = searchParams;
      params.set(
        'take',
        localStorage.getItem(LOCAL_STORAGE_PREFIX + 'page-take-carts') || '12'
      );
      setSearchParams(params);
    }

    // SKIP +++++++++++++++++++++++++++
    if (searchParams.has('skip')) {
      const skip = parseInt(currentParams.skip);
      setSkip(skip);
    } else {
      setSkip(0);
      const params = searchParams;
      params.set('skip', '0');
      setSearchParams(params);
    }

    // CARRINHOS ZERADOS +++++++++++++++++++++++++++
    if (searchParams.has('exibirZerados')) {
      const exibirZerados = currentParams.exibirZerados;
      exibirZerados === 'true'
        ? setShowZeroCarts(true)
        : setShowZeroCarts(false);
    } else {
      const params = searchParams;
      params.set(
        'exibirZerados',
        localStorage.getItem(
          LOCAL_STORAGE_PREFIX + 'page-exibirZerados-carts'
        ) ?? 'false'
      );
      setSearchParams(params);
    }

    const params = searchParams;
    // params.set('take', '12');
    setSearchParams(params);
  }, [
    filters,
    searchParams,
    setDtEnd,
    setDtIni,
    setFilters,
    setSearchParams,
    setSkip,
    setTake,
    setShowZeroCarts,
  ]);

  const handlePageClick = (selectedItem: { selected: number }) => {
    const params = searchParams;
    params.set('skip', String(selectedItem.selected));
    setSearchParams(params);
  };

  useEffect(() => {
    loadCarts();
  }, [loadCarts]);

  // if (isSmall)
  //   return (
  //     <div className="mt-24 p-2">
  //       <ParamsCart
  //         startDate={dtIni}
  //         endDate={dtEnd}
  //         qtdeRegs={info?.qtdeTotal || 0}
  //         showingRegs={carts && carts.length}
  //         dateRange={(startDate, endDate) => {
  //           const params = searchParams;
  //           if (startDate && endDate) {
  //             params.set('dataI', moment(startDate).format('YYYY-MM-DD'));
  //             params.set('dataF', moment(endDate).format('YYYY-MM-DD'));
  //           } else {
  //             params.set('dataI', '');
  //             params.set('dataF', '');
  //           }
  //           setSearchParams(params);
  //         }}
  //         info={info?.qtdePorPagina ? info : undefined}
  //       />

  //       <div className="">
  //         {loading ? (
  //           <div className="flex flex-col justify-center items-center animate-pulse">
  //             <span className="mx-auto"></span>Aguarde ...
  //           </div>
  //         ) : (
  //           <CardMyCarts
  //             carts={carts}
  //             afterAction={() => {
  //               loadCarts();
  //               // load();
  //               // self.location.replace(location.pathname + location.search);
  //             }}
  //           />
  //         )}
  //       </div>
  //       <div className="">
  //         <Pagination
  //           curPage={Number(searchParams.get('skip'))}
  //           qtdeReg={info?.qtdeTotal || 0}
  //           perPage={Number(searchParams.get('take'))}
  //           handlePageClick={handlePageClick}
  //         />
  //       </div>
  //     </div>
  //   );
  // else
  return (
    <>
      <div className={`mt-24 md:mt-0 p-2 h-[88vh]`}>
        <ParamsCart
          startDate={dtIni}
          endDate={dtEnd}
          qtdeRegs={info?.qtdeTotal || 0}
          showingRegs={carts && carts.length}
          dateRange={(startDate, endDate) => {
            const params = searchParams;
            if (startDate && endDate) {
              params.set('dataI', format(startDate, 'yyyy-MM-dd'));
              params.set('dataF', format(endDate, 'yyyy-MM-dd'));
            } else {
              params.set('dataI', '');
              params.set('dataF', '');
            }
            setSearchParams(params);
          }}
          info={info?.qtdePorPagina ? info : undefined}
        />

        <div className={`h-[75vh] overflow-hidden overflow-y-auto`}>
          {loading ? (
            <div className="flex flex-col justify-center items-center h-[88vh] animate-pulse">
              <span className="mx-auto"></span>Aguarde ...
            </div>
          ) : (
            <TableMyCarts
              carts={carts}
              afterAction={() => {
                loadCarts();
                // load();
                // self.location.replace(location.pathname + location.search);
              }}
            />
          )}
        </div>
        <div className="">
          <Pagination
            curPage={Number(searchParams.get('skip'))}
            qtdeReg={info?.qtdeTotal || 0}
            perPage={Number(searchParams.get('take'))}
            handlePageClick={handlePageClick}
          />
        </div>
      </div>
    </>
  );
}
