import { Fragment, useState, useMemo } from 'react';
import { Popover, Transition } from '@headlessui/react';
import MenuLeft from './menuLeft';
import MenuLeftBottom from './menuLeftBottom';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import logoSvg from '../../assets/svg/logo-name.svg';
import { ReactSVG } from 'react-svg';
import ShoppingCart from '../shoppingCart';
import MenuLeftMin from './menuLeftMin';
import { Outlet } from 'react-router-dom';
import MainSearch from '../mainSearch';
// import SelectMarcaModelo from '../selectMarcaModelo';
// import SelectCategoria from '../selectCategoria';
// import ModeloSearch from '../modeloSearch';
// import PopoverCategoria from '../popoverCategoria';
// import PopoverMarcaModelo from '../popoverMarcaModelo';
// import ModalAds from '../modalAds';
// import MenuMarcaModelo from '../menuMarcaModelo';

import WhatsappMenu from '../whatsappMenu';
import { useApp } from '../../app.hook';
import LogoNameBd from '../LogoNameBd';
import clsx from 'clsx';

export default function Layout() {
  // pesquisas na barra de cima
  const {
    pesquisasTopBar,
    innerShowPrecoConsumidorFinal,
    showPrecoConsumidorFinal,
    currentClientAtivo,
  } = useApp();
  const [menuMin, setMenuMin] = useState(true);

  const colorToolbar = useMemo(
    () =>
      innerShowPrecoConsumidorFinal && showPrecoConsumidorFinal
        ? currentClientAtivo?.corAlternativaPrecoConsumidor || '#BF360C'
        : '#ff0008',
    [
      currentClientAtivo,
      innerShowPrecoConsumidorFinal,
      showPrecoConsumidorFinal,
    ]
  );

  return (
    <div className="flex flex-no-wrap relative h-full">
      {/* menu mobile */}
      <Popover className="w-full absolute shadow z-[60]">
        <div className="bg-primary1">
          <div className="md:hidden p-2">
            <div className="pt-4 px-4">
              <div className="flex items-center justify-between">
                <div>
                  {/* logo */}
                  <LogoNameBd className="text-default-white fill-primary1 w-full h-16" />
                </div>
                <div className="-mr-2">
                  <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                    <span className="sr-only">Open Menu</span>
                    <MenuIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="mt-6"></div>
            </div>
          </div>
        </div>
        <Transition
          as={Fragment}
          enter="duration-200 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel
            focus
            className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
          >
            {({ close }) => (
              <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
                <div className="pt-5 px-5">
                  <div className="flex items-center justify-between">
                    <div></div>
                    <div className="-mr-2">
                      <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                        <span className="sr-only">Close menu</span>
                        <XIcon className="h-6 w-6" aria-hidden="true" />
                      </Popover.Button>
                    </div>
                  </div>
                  <div className="mt-6"></div>
                </div>
                <MenuLeft
                  onClick={close}
                  menuMin={menuMin}
                  setMenuMin={setMenuMin}
                />
                <MenuLeftBottom />
              </div>
            )}
          </Popover.Panel>
        </Transition>
      </Popover>
      {/* menu web */}
      <div
        className={`${
          menuMin ? 'w-16' : 'w-64'
        } transition-all duration-500 ease-in-out absolute sm:relative bg-gray-500 shadow md:h-screen flex-col justify-between hidden sm:flex z-10`}
      >
        <>
          <MenuLeftMin menuMin={menuMin} setMenuMin={setMenuMin} />
          <MenuLeftBottom orientation="column" />
        </>
        {/* {menuMin ? (
          <>
            <MenuLeftMin menuMin={menuMin} setMenuMin={setMenuMin} />
            <MenuLeftBottom orientation="column" />
          </>
        ) : (
          <>
            <MenuLeft menuMin={menuMin} setMenuMin={setMenuMin} />
            <MenuLeftBottom />
          </>
        )} */}
      </div>

      {/* Sidebar ends */}
      {/* Remove class [ h-64 ] when adding a card block */}
      <div className="w-full">
        {/* {pesquisasTopBar ? (
          <div
            style={{ backgroundColor: colorToolbar ?? '#ff0008' }}
            className={clsx(
              'h-14 w-full drop-shadow-md hidden md:flex md:items-center relative z-50'
            )}
          >
            <span className="flex flex-1 items-center w-full pl-3">
              <PopoverCategoria />
              <MenuMarcaModelo />
            </span>
            <span className="flex space-x-1 items-center">

              <MainSearch />

              <WhatsappMenu />
              <ShoppingCart />
            </span>
          </div>
        ) : ( */}
        <div
          style={{ backgroundColor: colorToolbar }}
          className={clsx(
            'h-[4.5em] w-full md:grid md:grid-cols-3 drop-shadow-md hidden relative z-50',
            colorToolbar
          )}
        >
          <span className="px-2 h-[4.4em]">
            <LogoNameBd className="fill-white w-full h-[4.4em] text-white" />
            {/* <LogoNome className="fill-white w-[15vw]" /> */}
          </span>
          <span className="w-full h-[4.4em] flex justify-center gap-1 items-center">
            {/* <PopoverCategoria position="left" /> */}
            <MainSearch />
          </span>
          <span className="w-full h-[4.4em] pr-4 flex justify-end gap-8 items-center">
            <WhatsappMenu />
            <ShoppingCart />
          </span>
        </div>
        {/* )} */}
        {/* descomentar para mostrar o switch de marca e modelo */}
        {/* {showTabSearch && (
          <div className="relative z-[3]">
            <TabSerach />
          </div>
        )} */}

        {/* <div
          className={clsx(
            showTabSearch
              ? 'mt-12 h-[85vh] overflow-hidden'
              : 'p-0 md:h-[89.9vh]'
          )}
        > */}
        {/* fim comentar para mostrar o switch de marca e modelo */}

        {/* comentat para mostrar o switch de marca e modelo */}
        <div className="p-0 md:h-[88vh] overflow-hidden">
          {/* fim comentat para mostrar o switch de marca e modelo   */}
          <Outlet />
        </div>
      </div>
      {/* desativado temporariamente para ativar descomentar o import e o componente */}
      {/* <ModalAds /> */}
    </div>
  );
}
