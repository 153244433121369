import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { classNames } from '../utils/functions';

interface LazyLoadingImageProps {
  src: string;
  alt: string;
  height?: string | number | undefined;
  width?: string | number | undefined;
  classNames?: unknown[];
  efect?: 'blur' | 'black-and-white' | 'opacity';
}

export default function LazyLoadingImage(props: LazyLoadingImageProps) {
  return (
    <div className={classNames(props.classNames)}>
      {props.efect ? (
        <LazyLoadImage
          alt={props.alt}
          height={props.height || '100%'}
          width={props.width || '100%'}
          src={props.src}
          // src={props.src + '?=' + new Date().getTime()}
          effect={props.efect}
          placeholderSrc="Sem Imagem"
        />
      ) : (
        <LazyLoadImage
          alt={props.alt}
          src={props.src} // use normal <img> attributes as props
          height={props.height || '100%'}
          width={props.width || '100%'}
          placeholderSrc="Sem Imagem"
        />
      )}
    </div>
  );
}
