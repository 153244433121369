import { useEffect, useState } from 'react';
import InputQtde from '../../../components/inputQtde';
import Button from '../../../components/layout/button';
import { Progress } from '../../../components/progress';
import { parseCurrency } from '../../../utils/numberHandler';

interface ListProductsProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  products: any[];
  itemHandler: (qtde: number, idx: number) => void;
}

export default function ListProducts({
  products,
  itemHandler,
}: ListProductsProps) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [items, setItems] = useState<any[]>([]);

  useEffect(() => {
    setItems(products);
  }, [products]);

  return (
    <table className="w-full divide-y divide-gray-200">
      <thead className="bg-gray-100 sticky top-0 z-[1]">
        <tr>
          <th
            scope="col"
            className="px-1 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Item
          </th>
          <th
            scope="col"
            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Produto
          </th>
          <th
            scope="col"
            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Qtde
          </th>
          <th
            scope="col"
            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Valor
          </th>
        </tr>
      </thead>
      <tbody className="">
        {items &&
          items.map((item, index) => (
            <tr
              key={item.id}
              className="border-b-[1px] border-solid border-gray-300"
            >
              {/* item */}
              <td className="px-1 py-4 whitespace-nowrap align-middle w-1/12">
                <div className="text-md font-bold text-gray-900 text-center">
                  {index + 1}
                </div>
              </td>
              {/* imagem/produto*/}
              <td className="px-6 py-4 align-middle w-8/12">
                <div className="flex items-center">
                  <div className="h-24 w-24 shrink-0">
                    <img
                      style={{
                        width: '120px',
                        height: '120px',
                        objectFit: 'contain',
                        borderRadius: '0.375rem',
                        margin: '0 auto',
                      }}
                      src={item.images[0].medium}
                      alt={item.descricao}
                      className="w-full relative -top-6 z-0"
                    />
                  </div>
                  <div className="ml-4 w-full">
                    <div className="text-sm font-medium text-gray-900">
                      {item.descricao}
                    </div>

                    <div className="text-[0.7em] text-gray-500">
                      <span className="">Categoria: </span>
                      <span className="font-bold">
                        {item.categoria.descricao}
                      </span>
                    </div>
                    <div className="text-[0.7em] text-gray-500">
                      <span className="">Referência: </span>
                      <span className="font-bold">{item.referencia}</span>
                    </div>
                    <div className="text-[0.7em] text-gray-500">
                      <span className="">Código: </span>
                      <span className="font-bold">{item.codigoSgi}</span>
                    </div>
                    <div>
                      {item.isPromocao ? (
                        <>
                          {item?.promocao?.qtdeDisponivel === 0 ? (
                            <div className="flex flex-col items-end space-y-1">
                              <span className="flex space-x-2 text-md md:text-xs lg:text-xl text-gray-800 font-bold">
                                <span>{parseCurrency(item.preco)}</span>
                              </span>
                              <span className="flex space-x-2 text-md md:text-xs lg:text-xl text-amber-500 font-bold">
                                <span>
                                  Oferta: {parseCurrency(item.precoPromo)}
                                </span>
                              </span>
                              <div className="text-red-500">
                                Produto fora da oferta
                              </div>
                            </div>
                          ) : (
                            <div className="relative -top-4 flex flex-col my-1">
                              {item?.promocao?.qtdeDisponivel >=
                              item.qtdeDisponivel ? (
                                <div>
                                  <div className="flex flex-col items-end">
                                    <span className="flex space-x-2 text-md md:text-md lg:text-xl text-amber-500 font-bold">
                                      <span>
                                        {parseCurrency(item.precoPromo)}
                                      </span>
                                    </span>
                                  </div>

                                  {item.promocao && (
                                    <div className="flex justify-end">
                                      <div className="w-[50%]">
                                        <Progress
                                          qtdeTotal={
                                            item.promocao.qtdeEmPromocao
                                          }
                                          qtdeMovimentada={
                                            item.promocao.qtdeMovimentada
                                          }
                                          qtdeDisponivel={
                                            item.promocao.qtdeDisponivel
                                          }
                                        />
                                      </div>
                                    </div>
                                  )}
                                </div>
                              ) : (
                                <div className="flex flex-col items-end space-y-1">
                                  <span className="flex space-x-2 text-md md:text-md lg:text-xl text-amber-500 font-bold">
                                    <span>
                                      Oferta: {parseCurrency(item.precoPromo)}
                                    </span>
                                  </span>
                                  <div className="text-red-500">
                                    {item?.promocao?.qtdeDisponivel} disponível
                                    na oferta
                                  </div>
                                  <Button
                                    type="button"
                                    title={'Atualizar Qtde'}
                                    onClick={() => {
                                      itemHandler(
                                        item?.promocao?.qtdeDisponivel,
                                        index
                                      );
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                          )}
                        </>
                      ) : (
                        <div className="relative -top-4 flex flex-col justify-start my-0 py-2">
                          <div className="flex my-3 justify-end">
                            <span className="flex space-x-2 font-bold text-xl">
                              <span>{parseCurrency(item.precoPromo)}</span>
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </td>
              {/* qtde */}
              <td className="px-6 py-4 whitespace-nowrap align-middle w-2/12">
                <InputQtde
                  value={item.qtdeDisponivel}
                  changeValue={(qtde: number) => itemHandler(qtde, index)}
                />
              </td>
              {/* valor */}
              <td className="px-6 py-4 whitespace-nowrap text-lg font-bold text-gray-500 align-middle w-1/12">
                {parseCurrency(item.precoPromo * item.qtdeDisponivel)}
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  );
}
