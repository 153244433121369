import {
  DotsCircleHorizontalIcon,
  EyeIcon,
  PaperAirplaneIcon,
} from '@heroicons/react/outline';
import { useRef, useState } from 'react';
import { useApp } from '../../app.hook';
import { Pedido } from '../../app.models';
import ModalConfirmation from '../../components/modalConfirmation';
import { getFormatedDateTime } from '../../utils/datetimeHandler';
import { classNames, truncateStringWithEllipsis } from '../../utils/functions';
import { parseCurrency } from '../../utils/numberHandler';
import { useOrder } from './order.hook';
import { retransmitirPedido } from './pedido.service';
import { ReactComponent as WhatsappLogo } from '../../assets/svg/whatsapp.svg';

const INITIAL_MODAL_CONFIRMATION = {
  question: '',
  title: '',
  clienteId: 0,
  id: 0,
  situacaoPedido: 0,
  updated_at: new Date(),
  usuarioId: '',
};

interface TableMyOrdersProps {
  pedidos: Pedido[];
  rowClickAction?: (pedido: Pedido) => void;
  rowClickWhatsapp?: (pedido: Pedido) => void;
}

interface PedidoStatusProps {
  situacaoPedido: number;
}
export const PedidoStatus = ({ situacaoPedido }: PedidoStatusProps) => {
  const { pedidoStatus } = useOrder();
  const status = pedidoStatus.find((c) => c.id === situacaoPedido);
  const class_name = pedidoStatus.find(
    (c) => c.id === situacaoPedido
  )?.className;
  if (status)
    return (
      <div className={classNames('flex items-center', class_name)}>
        <span>{status.icone}</span>
        <span>{status.situacao}</span>
      </div>
    );
  else
    return (
      <div className="flex pedidos-center">
        <span>
          <DotsCircleHorizontalIcon className="w-5 h-5" />
        </span>
        <span>Não Definida</span>
      </div>
    );
};

export default function TableMyOrders(props: TableMyOrdersProps) {
  const { isPerfilManager } = useApp();
  const [modalConfirm, setModalConfirm] = useState<boolean>(false);
  const modalConfirmationRef = useRef(INITIAL_MODAL_CONFIRMATION);

  const retransmitir = async (pedido: number) => {
    await retransmitirPedido(pedido);
    self.location.reload();
  };

  // console.log(props.pedidos);
  return (
    <>
      <table className="divide-y divide-gray-200 text-sm w-full">
        <thead className="bg-gray-100 sticky top-0">
          <tr className="">
            <th
              scope="col"
              className="pl-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              #
            </th>
            <th
              scope="col"
              className="py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Situação
            </th>
            <th
              scope="col"
              className="py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Data
            </th>
            {isPerfilManager && (
              <th
                scope="col"
                className="py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                CNPJ
              </th>
            )}
            {isPerfilManager && (
              <th
                scope="col"
                className="py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Cliente
              </th>
            )}
            <th
              scope="col"
              className="py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Referência
            </th>
            <th
              scope="col"
              className="py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Transmissão
            </th>
            <th
              scope="col"
              className="py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Qtde
            </th>
            <th
              scope="col"
              className="py-3 text-xs font-medium text-gray-500 uppercase tracking-wider text-right"
            >
              Valor
            </th>
            <th
              scope="col"
              className="py-3 text-xs font-medium text-gray-500 uppercase tracking-wider text-center"
            >
              Ações
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-400">
          {props.pedidos &&
            props.pedidos.map((pedido) => (
              <tr
                key={pedido.id}
                className="even:bg-gray-100 h-8 hover:bg-gray-300"
              >
                <td className="font-bold text-md align-middle">{pedido.id}</td>
                <td className="whitespace-nowrap align-middle">
                  <PedidoStatus situacaoPedido={pedido.situacaoPedido} />
                </td>
                <td className="whitespace-nowrap align-middle">
                  {getFormatedDateTime(pedido.created_at.toString())}
                </td>
                {isPerfilManager && (
                  <td
                    className="whitespace-nowrap align-middle"
                    title={pedido.cliente.cpf_cnpj}
                  >
                    {truncateStringWithEllipsis(pedido.cliente.cpf_cnpj, 20)}
                  </td>
                )}
                {isPerfilManager && (
                  <td
                    className="whitespace-nowrap align-middle"
                    title={pedido.cliente.nome}
                  >
                    {truncateStringWithEllipsis(pedido.cliente.nome, 20)}
                  </td>
                )}

                <td className="whitespace-nowrap align-middle">
                  {pedido.xRef}
                </td>
                <td className="whitespace-nowrap align-middle">
                  {pedido.transmissao ? (
                    <span className="bg-green-200 px-3 py-1 rounded-lg">
                      {getFormatedDateTime(pedido.transmissao?.toString())}
                    </span>
                  ) : (
                    <span className="bg-yellow-200 px-3 py-1 my-1 rounded-lg">
                      Não Recebido
                    </span>
                  )}
                </td>

                <td className="whitespace-nowrap text-center align-middle">
                  {pedido.qtdeTotal}
                </td>
                <td className="whitespace-nowrap text-right align-middle">
                  {parseCurrency(pedido.total)}
                </td>
                {/* ações */}
                <td className="whitespace-nowrap text-center align-middle">
                  {props.rowClickAction && (
                    <button
                      type="button"
                      title="Visualizar Pedido"
                      className="bg-blue-500 p-1 m-1 text-default-white rounded-full"
                      onClick={() =>
                        props.rowClickAction && props.rowClickAction(pedido)
                      }
                    >
                      <EyeIcon className="h-4 w-4" />
                    </button>
                  )}
                  {props.rowClickWhatsapp && (
                    <button
                      type="button"
                      title="Enviar pedido para meu WhatsApp"
                      className="bg-blue-500 p-1 m-1 text-default-white rounded-full"
                      onClick={() =>
                        props.rowClickWhatsapp && props.rowClickWhatsapp(pedido)
                      }
                    >
                      <WhatsappLogo className="h-4 w-4" />
                    </button>
                  )}

                  {!pedido.transmissao && (
                    <button
                      type="button"
                      title="Retransmitir"
                      className="bg-green-500 p-1 m-1 text-default-white rounded-full"
                      onClick={() => retransmitir(pedido.id)}
                    >
                      <PaperAirplaneIcon className="w-4 h-4" />
                    </button>
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <ModalConfirmation
        isOpen={modalConfirm}
        setIsOpen={setModalConfirm}
        title={modalConfirmationRef.current.title}
        question={modalConfirmationRef.current.question}
        answer={() => null}
      />
    </>
  );
}
