export enum EnumOrigens {
  'SEARCH_FOR' = 'search_for',
  'MARCA_MODELO' = 'marca_moodelo',
  'VEICULO' = 'veiculo',
  'PRODUTO' = 'produto',
  'RELACIONADOS' = 'relacionados',
  'DESTAQUE' = 'destaque',
  'OFERTA' = 'oferta',
  'CATEGORIA' = 'categoria',
  'FILTRO' = 'filtro_',
  'VEICULO_COMPATIVEL' = 'veiculo_compativel',
  'BANNER' = 'banner',
  'CARRINHO' = 'carrinho',
}
