import React, { useEffect } from 'react';
import clsx from 'clsx';
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  MenuIcon,
  TruckIcon,
} from '@heroicons/react/outline';
import {
  ButtonBack,
  ButtonNext,
  CarouselProvider,
  Slide,
  Slider,
} from 'pure-react-carousel';
import { Categoria } from '../app.models';
import LazyLoadingImage from './lazyLadingImage';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ROUTES_MAP } from '../app.map.routes';
import { getTake } from '../utils/functions';
import MenuMarcaModelo from './menuMarcaModelo';
import { EnumOrigens } from '../utils/origens';
import { useApp } from '../app.hook';
import { useProduct } from '../pages/product/product.hook';

type TClassName = React.HTMLAttributes<HTMLDivElement>['className'] | undefined;

export default function TabSerach() {
  const navigate = useNavigate();
  const { isSm, isMd, isLg, isXl, is2xl } = useApp();
  const { setOrigem } = useProduct();
  const [params] = useSearchParams();
  const {
    tabSearchIndex: tabIndex,
    setTabSearchIndex: setTabIndex,
    tabSearchCurrentSlider,
    setTabSearchCurrentSlider,
  } = useApp();
  const [currentSlider, setCurrentSlider] = React.useState<number>(0);

  const [tabSearchIndex, setTabSearchIndex] = React.useState(0);
  const _take = getTake();
  // const [showMenu, setShowMenu] = React.useState(false);

  const PRIMARY = 'bg-[#281e5d] text-white';
  const SECONDARY = 'bg-blue-200 text-[#281e5d]';
  const TAB_CLASS = clsx(
    'flex justify-center items-center',
    'px-4 py-2',
    'w-[49%]',
    'text-md'
  );
  const CONTENT_CLASS = clsx(
    'flex items-center py-2 rounded-b-lg h-32',
    'lg:h-32 xl:h-36 2xl:h-48',
    PRIMARY
  );

  // item
  const ITEM_CLASS: TClassName = clsx(
    'aspect-w-16 aspect-h-9',
    'flex flex-col transition ease-in-out delay-150 hover:scale-[1.1] rounded-md overflow-hidden min-h-full group'
  );

  const ITEM_DESC_CLASS: TClassName = clsx(
    'text-[0.55em] w-full bg-transparent p-1 mt-[70%] flex justify-center items-center group-hover:font-bold group-hover:text-white',
    'flex flex-col justify-center items-center h-full',
    'h-12 md:h-12 lg:h-12 xl:h-12 2xl:h-20',
    'lg:text-[0.6em] xl:text-[0.7em] 2xl:text-[0.8em]'
  );

  // configuracao da proporção de slides visiveis
  const [visibleSlides, setVisibleSlides] = React.useState(12);
  const [naturalSlideWidth, setNaturalSlideWidth] = React.useState(1);
  const [naturalSlideHeight, setNaturalSlideHeight] = React.useState(1);
  const [slideWidth, setSlideWidth] = React.useState(12);
  const refContentCategorias = React.useRef<HTMLDivElement>(null);
  const [contentWidth, setContentWidth] = React.useState<number>();

  useEffect(() => {
    function handleResize() {
      if (refContentCategorias && refContentCategorias.current) {
        if (isSm) {
          setVisibleSlides(2);
          setNaturalSlideWidth(1);
          setNaturalSlideHeight(10);
          setSlideWidth(0.5);
          setContentWidth(300);
        }
        if (isMd) {
          setVisibleSlides(5);
          setNaturalSlideWidth(1);
          setNaturalSlideHeight(1.1);
          setSlideWidth(6);
          setContentWidth(580);
        }
        if (isLg) {
          //lg:max-w-3xl
          setVisibleSlides(5);
          setNaturalSlideWidth(1);
          setNaturalSlideHeight(0.9);
          setSlideWidth(6.5);
          setContentWidth(680);
        }
        if (isXl) {
          //xl:max-w-4xl
          setVisibleSlides(7);
          setNaturalSlideWidth(0.6);
          setNaturalSlideHeight(0.64);
          setSlideWidth(7);
          setContentWidth(900);
        }
        if (is2xl) {
          //2xl:max-w-5xl
          setVisibleSlides(8);
          setNaturalSlideWidth(0.7);
          setNaturalSlideHeight(0.78);
          setSlideWidth(8);
          setContentWidth(1250);
        }
      }
    }

    handleResize();
  }, [isSm, isMd, isLg, isXl, is2xl]);
  const [loadingCategorias, setLoadingCategorias] = React.useState(false);
  const { categoriasRaw } = useApp();
  const [categorias, setCategorias] = React.useState<Categoria[]>([]);

  useEffect(() => {
    setLoadingCategorias(true);
    const res = categoriasRaw;
    // console.log('🚀 ~ file: tabSerach.tsx:133 ~ useEffect ~ res:', res);
    // // remove Todas
    // const idx = res.findIndex((item) => item.descricao === 'Todas');
    // const list = [...res];
    // list.splice(idx, 1);
    // ordena por descricao
    const list = res.filter(
      (item) =>
        item.temSubCategoria === 0 && item.ativo === 1 && item.isVisible === 1
    );
    const sort = list.sort((a, b) => a.descricao.localeCompare(b.descricao));
    setCategorias(sort);
    setLoadingCategorias(false);
  }, [categoriasRaw]);

  useEffect(() => {
    (async () => {
      await new Promise((resolve) => setTimeout(resolve, 100));
      setTabSearchIndex(tabIndex);
    })();
  }, [tabIndex]);

  useEffect(() => {
    (async () => {
      await new Promise((resolve) => setTimeout(resolve, 700));
      // if (tabSearchCurrentSlider !== currentSlider)
      setCurrentSlider(tabSearchCurrentSlider);
    })();
  }, [tabSearchCurrentSlider]);

  return (
    <div className="flex justify-center w-full">
      <div
        style={{ width: `${contentWidth ? contentWidth + 'px' : '100%'}` }}
        className={clsx('flex flex-col w-full', 'z-10')}
      >
        <div className={clsx('flex gap-2 justify-between')}>
          <button
            className={clsx(
              TAB_CLASS,
              tabSearchIndex === 0 ? PRIMARY : SECONDARY
            )}
            onClick={() => {
              setTabIndex(0);
            }}
            // onMouseOver={() => setTabIndex(0)}
          >
            <MenuIcon className="w-8 h-8 mr-2" />
            <span>Pesquise por categoria</span>
          </button>
          <button
            className={clsx(
              TAB_CLASS,
              tabSearchIndex === 1 ? PRIMARY : SECONDARY
            )}
            onClick={() => {
              setTabIndex(1);
            }}
            // onMouseOver={() => setTabIndex(1)}
          >
            <TruckIcon className="w-8 h-8 mr-2" />
            <span>Pesquise por veículo</span>
          </button>
        </div>
        <div
          className={clsx(
            'transition-opacity transition-ease-in-out duration-600'
          )}
        >
          {tabSearchIndex === 0 && (
            <div ref={refContentCategorias} className={clsx(CONTENT_CLASS)}>
              <CarouselProvider
                naturalSlideWidth={naturalSlideWidth}
                naturalSlideHeight={naturalSlideHeight}
                totalSlides={categorias.length}
                step={visibleSlides}
                infinite={true}
                orientation="horizontal"
                dragEnabled={true}
                currentSlide={currentSlider}
                // interval={6000}
                // isPlaying={categorias.length > visibleSlides}
                visibleSlides={visibleSlides}
                className={clsx('w-full h-full')}
              >
                <div className="flex flex-rol">
                  <div className="flex flex-col group hover:bg-black/20">
                    <ButtonBack className="h-full">
                      <ChevronLeftIcon
                        className={clsx('w-8 h-8', PRIMARY.split(' ')[1])}
                      />
                    </ButtonBack>
                  </div>

                  {!loadingCategorias && (
                    <Slider className="w-full">
                      {categorias.map((categ: Categoria, index) => {
                        return (
                          <Slide index={index} key={index}>
                            <button
                              onClick={() => {
                                setTabSearchCurrentSlider(index);
                                setOrigem(EnumOrigens.CATEGORIA);
                                navigate(
                                  ROUTES_MAP.product +
                                    '/?categoria=' +
                                    categ.id +
                                    '&take=' +
                                    _take.toString() +
                                    `&skip=0&tags={"categorias":[${categ.id}]}`
                                );
                              }}
                              style={{ width: `${slideWidth}em` }}
                              className={clsx(
                                ITEM_CLASS,
                                params.has('categoria') &&
                                  params.get('categoria') ===
                                    categ.id.toString()
                                  ? 'bg-red-500 text-white'
                                  : 'bg-white/60 text-white'
                              )}
                            >
                              <LazyLoadingImage
                                classNames={['mx-auto rounded-full']}
                                alt={categ.descricao}
                                height={'100%'}
                                width={'100%'}
                                src={categ.images[0].img}
                                efect="blur"
                              />
                              <div className={ITEM_DESC_CLASS}>
                                <span>{categ.descricao}</span>
                              </div>
                            </button>
                          </Slide>
                        );
                      })}
                    </Slider>
                  )}

                  <div className="flex flex-col group hover:bg-black/20">
                    <ButtonNext className="h-full">
                      <ChevronRightIcon
                        className={clsx('w-8 h-8', PRIMARY.split(' ')[1])}
                      />
                    </ButtonNext>
                  </div>
                </div>
              </CarouselProvider>
            </div>
          )}
          {tabSearchIndex === 1 && (
            <div id="contentVeiculo" className={CONTENT_CLASS}>
              <div className="w-[45em] mx-auto">
                <MenuMarcaModelo reverseColors={true} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
