import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { Produto, ProdutoCart } from '../../app.models';
import InputQtde from '../../components/inputQtde';
import Button from '../../components/layout/button';
import { replaceImgWithError } from '../../utils/functions';
import { parseCurrency } from '../../utils/numberHandler';

interface ModalProductCardProps {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  product: ProdutoCart | undefined;
  alterarQtde: (produto: Produto, qtde: number) => void;
  removeItem: (produto: ProdutoCart) => void;
  onClose: () => void;
}

export default function ModalProductCard(props: ModalProductCardProps) {
  const [innerQtde, setInnerQtde] = useState<number>(props.product?.qtde || 0);
  const closeModal = useCallback(() => {
    props.setIsOpen(false);
    props.onClose();
  }, [props]);

  const qtdeAlterada = innerQtde !== props.product?.qtde;

  const botaoRef = useRef<any>(null);

  useEffect(() => {
    setInnerQtde(props.product?.qtde || 0);
  }, [props.product]);

  // usar o evento de teclado
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        // props.alterarQtde(props.product!.produto, innerQtde);
        // closeModal();
        botaoRef?.current?.click();
      }

      if (e.key === 'ArrowDown') {
        if (innerQtde < 2) return;
        setInnerQtde(innerQtde - 1);
      }

      if (e.key === 'ArrowUp') {
        setInnerQtde(innerQtde + 1);
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [closeModal, innerQtde, props]);

  if (!props?.product) return <></>;

  return (
    <Transition appear show={props.isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={closeModal}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-20" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-[60vw] max-w-2xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl border-[1px] border-solid border-gray-300">
              <Dialog.Title
                as="h3"
                className="text-lg font-bold leading-6 text-gray-900 font-display line-clamp-2"
              >
                {props.product?.produto?.descricaoSite}
              </Dialog.Title>
              <div className="mt-2 flex font-display">
                <div className="w-2/12">
                  <img
                    style={{
                      width: '100px',
                      height: '60px',
                      objectFit: 'contain',
                      borderRadius: '0.375rem',
                      margin: '0 auto',
                    }}
                    src={props.product?.produto?.images[0]?.small}
                    // src="https://via.placeholder.com/300"
                    alt={props.product?.produto?.descricaoSite}
                    onError={(e) =>
                      replaceImgWithError(
                        e,
                        'https://via.placeholder.com/160x120?text=Sem Imagem'
                      )
                    }
                  />
                </div>
                <div className="flex justify-between w-6/12">
                  <div className="flex flex-col ">
                    <span className=" text-xs">
                      Ref:{' '}
                      <span className="font-bold">
                        {props.product?.produto?.referencia || 'N/D'}
                      </span>
                    </span>
                    <span className=" text-xs">
                      Código:{' '}
                      <span className="font-bold">
                        {props.product?.produto?.codigoSgi}
                      </span>
                    </span>
                  </div>
                </div>

                <div className="flex flex-col w-6/12">
                  <span className=" text-xs">Categoria: </span>
                  <span className="font-bold text-xs">
                    {props.product?.produto?.categoria?.descricao}
                  </span>
                </div>
              </div>
              <div className="flex justify-between items-end mt-5 font-display">
                <div className="">
                  <h1 className="text-xs h-0">Preço</h1>
                  <div className="flex flex-col justify-center my-0">
                    <div className="flex my-3 justify-end">
                      <span className="flex space-x-2 font-bold text-xl">
                        <span>{parseCurrency(props.product.unitario)}</span>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex space-x-3">
                  <Button
                    title="Remover"
                    color="bg-yellow-500 text-white"
                    onClick={() => {
                      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                      props.removeItem(props.product!);
                      closeModal();
                    }}
                  />
                  <InputQtde
                    value={innerQtde}
                    changeValue={setInnerQtde}
                    keyEnter={() => {
                      console.log('enter');
                      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                      props.alterarQtde(props.product!.produto, innerQtde);
                      closeModal();
                    }}
                  />
                  <Button
                    ref={botaoRef}
                    title="Alterar Quantidade"
                    disabled={!qtdeAlterada}
                    color="bg-green-500 text-white"
                    onClick={() => {
                      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                      props.alterarQtde(props.product!.produto, innerQtde);
                      closeModal();
                    }}
                  />
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}
