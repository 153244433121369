/* eslint-disable prettier/prettier */
import { ca } from 'date-fns/locale';
import { LOCAL_STORAGE_PREFIX } from '../app.settings';

/* eslint-disable @typescript-eslint/no-explicit-any */
export const arrayGroupBy = (array: any[], key: string): any[] => {
  const keys = key.split('.');
  const count = keys.length;
  try {
    switch (count) {
      case 1: {
        return array.reduce((acc, cur) => {
          (acc[cur[keys[0]]] = acc[cur[keys[0]]] || []).push(cur);
          return acc;
        }, []);
      }
      case 2:
        return array.reduce((acc, cur) => {
          (acc[cur[keys[0]][keys[1]]] = acc[cur[keys[0]][keys[1]]] || []).push(
            cur
          );
          return acc;
        }, []);
      case 3:
        return array.reduce((acc, cur) => {
          (acc[cur[keys[0]][keys[1]][keys[2]]] =
            acc[cur[keys[0]][keys[1]][keys[2]]] || []).push(cur);
          return acc;
        }, []);
      case 4:
        return array.reduce((acc, cur) => {
          (acc[cur[keys[0]][keys[1]][keys[2]][keys[3]]] =
            acc[cur[keys[0]][keys[1]][keys[2]][keys[3]]] || []).push(cur);
          return acc;
        }, []);
      case 5:
        return array.reduce((acc, cur) => {
          (acc[cur[keys[0]][keys[1]][keys[2]][keys[3]][keys[4]]] =
            acc[cur[keys[0]][keys[1]][keys[2]][keys[3]][keys[4]]] || []).push(
              cur
            );
          return acc;
        }, []);
      default:
        return [];
    }
  } catch {
    return [];
  }
};

export function truncateString(str: string, num: number) {
  if (str.length <= num) {
    return str;
  }
  return str.slice(0, num) + '...';
}

export const replaceImgWithError = (e: any, imgHref?: string) => {
  e.target.onerror = null;
  e.target.src = imgHref || 'https://via.placeholder.com/100?text=Not Found';
};

export function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(' ');
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function convertImages(array: any[], id: any, folder: string) {
  // groupBy ARRAY por uuid
  // foi usado reverse para pegar a ultima imagem
  const result = array.reduce(
    (r, v, i, a, k = v.uuid) => ((r[k] || (r[k] = [])).push(v), r),
    {}
  );

  const values = Object.values(result);

  const images = values.map((r: any) => ({
    small: r[0]?.filename
      ? `${process.env.REACT_APP_API_IMG_URL}/images/${folder}/${id}/${r[0].filename}`
      : 'https://via.placeholder.com/100?text=Sem Imagem',
    medium: r[1]?.filename
      ? `${process.env.REACT_APP_API_IMG_URL}/images/${folder}/${id}/${r[1].filename}`
      : 'https://via.placeholder.com/300?text=Sem Imagem',
    big: r[2]?.filename
      ? `${process.env.REACT_APP_API_IMG_URL}/images/${folder}/${id}/${r[2].filename}`
      : 'https://via.placeholder.com/600?text=Sem Imagem',
  }));

  return images.reverse();
}

export function convertOneImage(array: any[], id: any, folder: any) {
  // groupBy ARRAY por uuid
  const result = array.reduce(
    (r, v, i, a, k = v.uuid) => ((r[k] || (r[k] = [])).push(v), r),
    {}
  );

  const values = Object.values(result);

  const images = values.map((r: any) => ({
    img: `${process.env.REACT_APP_API_IMG_URL}/images/${folder}/${id}/${r[0].filename}`,
    // img: constroiPath(r[0]),
  }));

  return images;
}

export const getTake = () =>
  localStorage.getItem(LOCAL_STORAGE_PREFIX + 'page-take') || 50;

export function isMenuActive(link: string, locationPathname: string) {
  if (link === '/' && locationPathname === '/') return true;
  else if (locationPathname === '/' && link !== '/') return false;
  else {
    const location = locationPathname.split('/');
    const compare = location.reduce((acc: string[], cur: string) => {
      if (cur) acc.push(cur);
      return acc;
    }, []);
    return link.trim().split('/').includes(compare[0]);
  }
}

export const truncateStringWithEllipsis = (str: string, num: number) => {
  if (str.length <= num) {
    return str;
  }
  return str.slice(0, num) + '...';
};

export function filterImageSize(images: any[], size: string) {
  // if (!images || images.length === 0)
  const image = images.filter((i: any) => i.size === size)[0] as any;
  return image
    ? process.env.REACT_APP_API_IMG_URL?.concat(
      '/',
      image.path,
      '/',
      image.filename
    ).replace('upload/', '')
    : size === 'small'
      ? '/no-image-1200x60.png'
      : '/no-image-1200x200.png';
}

export function urlDecode(str: string) {
  str = (str + '').toString();

  // Tilde should be allowed unescaped in future versions of PHP (as reflected below), but if you want to reflect current
  // PHP behavior, you would need to add ".replace(/~/g, '%7E');" to the following.
  return decodeURIComponent(str)
    .replace('%21', '!')
    .replace('%27', "'")
    .replace('%28', '(')
    .replace('%29', ')')
    .replace('%2A', '*')
    .replace('+', '%20');
}

export function camelCaseToNormal(camelCase: string): string {
  return (
    camelCase
      .replace(/([A-Z])/g, ' $1')
      .replace(/([\_])/g, ' ')
      // uppercase the first character
      .replace(/^./, function (str) {
        return str.toUpperCase();
      })
  );
}

export function getImageUrl(
  item: any,
  index: number,
  tam: 'small' | 'medium' | 'big'
): string {
  if (item?.produto?.images && item?.produto?.images.length > 0) {
    const images = convertImages(
      item.produto.images,
      item.produto.id,
      'produtos'
    );

    return images[index][tam];
  }
  return '';
  // return 'https://via.placeholder.com/60?text=Sem+Imagem';
}

export async function getBase64ImageFromURL(url: string) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.setAttribute("crossOrigin", "anonymous");
    img.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext("2d");
      // if (!ctx) return reject("Não foi possível converter a imagem");
      if (!ctx) {
        resolve('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAIAAAD/gAIDAAADHklEQVR4nO3bXU/qShiG4WHsxFbSVqwkhIKaCGrQ//8rPDPGqIlBKopaS2mRtkjbdbDO9nInPrvq2ibPdcrkfYebj3BC7fT0VNDHyL99gZ+EsQCMBWAsAGMBGAvAWADGAjAWgLEAjAVgLABjARgLwFgAxgIwFoCxAIwFYCwAYwEYC8BYAMYCMBaAsQCMBWAsAGMBGAvAWADGAjAWgLEAjAVgLABjARgLwFgAxgIwFoCxAIwFYCwAYwEYC6BVHzGZTIIgyPPcMAzXdQ3DQCfkeX55eTkYDKpf5ktVjZUkSRiGvV5PKRVF0Wg0Ojw8/JSb/Q9VjaVpWlmWWZZpmmbbtm3bZVl6njebzZRS3W63Xq9Pp9P5fB7HcVmWu7u7d3d3q9VqZ2fHsqx/TPu3k6ZpjsfjMAyllJ1Ox7KsoiiGw+F8Pt/a2np9fT04OPjPS4EnW62VUErt7e35vn9/fy+lbLVaaZoKIY6Pj5fL5XA4PDo6EkIsFot+vx+Goed5vV4vjuMgCN6997sn8zzP83wwGCRJMh6PLct6fn5WSp2cnERRNJvNfN+vsvSDPuE7yzCMbrcrhFgul1dXV7ZtB0EQBMHvR9/e3oQQpmkqpXRdr9frSinDMGaz2bvT3j1pWdZisbi5ucmyTEophEjT1HEcKeXm5ubDw0OaplWWflDVWL7vx3Hsuq5SqigKKaWu69vb261WS9M+4ZX4bTKZ5HnebreTJHl8fBRC6Lo+nU43NjaiKCqK4iuW/qnqaMdxsiy7vr5erVbr6+uu65qm6XnexcWFEKLRaHQ6neq3tCxrNBpFUdRsNqWURVE0m83b29vz83PHcWq1muM4n770T7Uf+u973/fTNG2322EYvry89Pv9b1j6U3+UNhqNLMvOzs6enp5c1/2epV/4Cf9Sa2tr+/v737z0p76z/grGAjAWgLEAjAVgLABjARgLwFgAxgIwFoCxAIwFYCwAYwEYC8BYAMYCMBaAsQCMBWAsAGMBGAvAWADGAjAWgLEAjAVgLABjARgLwFgAxgIwFoCxAIwFYCwAYwEYC8BYAMYCMBbgF3nBY0Bj0BfyAAAAAElFTkSuQmCC');
      } else {
        ctx.drawImage(img, 0, 0);
        const dataURL = canvas.toDataURL("image/png");
        resolve(dataURL);
      }
    };
    img.onerror = error => {
      // reject(error);
      resolve('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAIAAAD/gAIDAAADHklEQVR4nO3bXU/qShiG4WHsxFbSVqwkhIKaCGrQ//8rPDPGqIlBKopaS2mRtkjbdbDO9nInPrvq2ibPdcrkfYebj3BC7fT0VNDHyL99gZ+EsQCMBWAsAGMBGAvAWADGAjAWgLEAjAVgLABjARgLwFgAxgIwFoCxAIwFYCwAYwEYC8BYAMYCMBaAsQCMBWAsAGMBGAvAWADGAjAWgLEAjAVgLABjARgLwFgAxgIwFoCxAIwFYCwAYwEYC6BVHzGZTIIgyPPcMAzXdQ3DQCfkeX55eTkYDKpf5ktVjZUkSRiGvV5PKRVF0Wg0Ojw8/JSb/Q9VjaVpWlmWWZZpmmbbtm3bZVl6njebzZRS3W63Xq9Pp9P5fB7HcVmWu7u7d3d3q9VqZ2fHsqx/TPu3k6ZpjsfjMAyllJ1Ox7KsoiiGw+F8Pt/a2np9fT04OPjPS4EnW62VUErt7e35vn9/fy+lbLVaaZoKIY6Pj5fL5XA4PDo6EkIsFot+vx+Goed5vV4vjuMgCN6997sn8zzP83wwGCRJMh6PLct6fn5WSp2cnERRNJvNfN+vsvSDPuE7yzCMbrcrhFgul1dXV7ZtB0EQBMHvR9/e3oQQpmkqpXRdr9frSinDMGaz2bvT3j1pWdZisbi5ucmyTEophEjT1HEcKeXm5ubDw0OaplWWflDVWL7vx3Hsuq5SqigKKaWu69vb261WS9M+4ZX4bTKZ5HnebreTJHl8fBRC6Lo+nU43NjaiKCqK4iuW/qnqaMdxsiy7vr5erVbr6+uu65qm6XnexcWFEKLRaHQ6neq3tCxrNBpFUdRsNqWURVE0m83b29vz83PHcWq1muM4n770T7Uf+u973/fTNG2322EYvry89Pv9b1j6U3+UNhqNLMvOzs6enp5c1/2epV/4Cf9Sa2tr+/v737z0p76z/grGAjAWgLEAjAVgLABjARgLwFgAxgIwFoCxAIwFYCwAYwEYC8BYAMYCMBaAsQCMBWAsAGMBGAvAWADGAjAWgLEAjAVgLABjARgLwFgAxgIwFoCxAIwFYCwAYwEYC8BYAMYCMBbgF3nBY0Bj0BfyAAAAAElFTkSuQmCC');
    };
    img.src = url;
  });
}

// radomize color for chart js
export function randomRGBA() {
  const o = Math.round;
  const r = Math.random;
  const s = 255;
  return `rgba(${o(r() * s)},${o(r() * s)},${o(r() * s)},0.5)`;
}

// calcular o width do container com base no componente pai
export function getWidthContainer(seletor: string, defaultWidth: number) {
  const container = document.getElementById(seletor);
  const width = container?.clientWidth;
  return width ? width : defaultWidth;
}

export function getMonthName(month: number) {
  switch (month) {
    case 0:
      return 'Janeiro';
    case 1:
      return 'Fevereiro';
    case 2:
      return 'Março';
    case 3:
      return 'Abril';
    case 4:
      return 'Maio';
    case 5:
      return 'Junho';
    case 6:
      return 'Julho';
    case 7:
      return 'Agosto';
    case 8:
      return 'Setembro';
    case 9:
      return 'Outubro';
    case 10:
      return 'Novembro';
    case 11:
      return 'Dezembro';
    default:
      return '';
  }
}

export function getMonthNameAbv(month: number) {
  switch (month) {
    case 0:
      return 'Jan';
    case 1:
      return 'Fev';
    case 2:
      return 'Mar';
    case 3:
      return 'Abr';
    case 4:
      return 'Mai';
    case 5:
      return 'Jun';
    case 6:
      return 'Jul';
    case 7:
      return 'Ago';
    case 8:
      return 'Set';
    case 9:
      return 'Out';
    case 10:
      return 'Nov';
    case 11:
      return 'Dez';
    default:
      return '???';
  }
}

export function getMonthNumber(month: string) {
  switch (month) {
    case 'Janeiro':
      return 0;
    case 'Fevereiro':
      return 1;
    case 'Março':
      return 2;
    case 'Abril':
      return 3;
    case 'Maio':
      return 4;
    case 'Junho':
      return 5;
    case 'Julho':
      return 6;
    case 'Agosto':
      return 7;
    case 'Setembro':
      return 8;
    case 'Outubro':
      return 9;
    case 'Novembro':
      return 10;
    case 'Dezembro':
      return 11;
    default:
      return 0;
  }
}

export function getMonthNumberByShortName(month: string) {
  switch (month) {
    case 'Jan':
      return 0;
    case 'Fev':
      return 1;
    case 'Mar':
      return 2;
    case 'Abr':
      return 3;
    case 'Mai':
      return 4;
    case 'Jun':
      return 5;
    case 'Jul':
      return 6;
    case 'Ago':
      return 7;
    case 'Set':
      return 8;
    case 'Out':
      return 9;
    case 'Nov':
      return 10;
    case 'Dez':
      return 11;
    default:
      return 0;
  }
}

export function getMonthNumberByShortName2(month: string) {
  switch (month) {
    case 'Jan':
      return '01';
    case 'Fev':
      return '02';
    case 'Mar':
      return '03';
    case 'Abr':
      return '04';
    case 'Mai':
      return '05';
    case 'Jun':
      return '06';
    case 'Jul':
      return '07';
    case 'Ago':
      return '08';
    case 'Set':

      return '09';
    case 'Out':
      return '10';
    case 'Nov':
      return '11';
    case 'Dez':
      return '12';
    default:
      return;
  }
}

// selecao de cores 1
export const colors = [
  '#0088FE',
  '#00C49F',
  '#FFBB28',
  '#FF8042',
  '#FF0000',
  '#0000FF',
  '#00FF00',
  '#FF00FF',
  '#00FFFF',
  '#FF0000',
  '#0000FF',
  '#00FF00',
];

// selecao de cores 2 random
export const colors2 = [
  '#0F0000',
  '#FF0000',
  '#0000FF',
  '#FFCC00',
  '#00FF00',
  '#FF00FF',
  '#00FFFF',
  '#FFA000',
  '#CCC000',
  '#00FF00',
  '#FFCCCC',
  '#00CCFF',
];


export function createSlug(text: string) {
  return text
    .toLowerCase()                     // Converte para minúsculas
    .trim()                            // Remove espaços no início e no final
    .replace(/[^\w\s-]/g, '')          // Remove caracteres especiais
    .replace(/\s+/g, '-')              // Substitui espaços por hifens
    .replace(/-+/g, '-');              // Remove hifens repetidos
}
