/* eslint-disable react-hooks/exhaustive-deps */
import {
  ChevronDownIcon,
  SaveIcon,
  TableIcon,
  ViewBoardsIcon,
  ViewListIcon,
} from '@heroicons/react/outline';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useApp } from '../../app.hook';
import { ROUTES_MAP } from '../../app.map.routes';
import {
  Cart,
  Comprador,
  ErrorTransmission,
  ProdutoCart,
  TabelaPreco,
} from '../../app.models';
import {
  LOCAL_STORAGE_DEFAUT_CLI,
  LOCAL_STORAGE_PREFIX,
} from '../../app.settings';
import ComboTabelaPreco from '../../components/comboTabelaPrecos';
import Button from '../../components/layout/button';
import { LoadingSVG } from '../../components/loadingSVG';
import ModalClienteSelect from '../../components/modalClienteSelect';
import ModalConfirmation from '../../components/modalConfirmation';
import {
  classNames,
  createSlug,
  truncateStringWithEllipsis,
} from '../../utils/functions';
import { parseCurrency } from '../../utils/numberHandler';
import { addPedido, IPedido } from '../myOrders/pedido.service';
import { useCart } from './cart.hook';
import {
  addComprador,
  addItemCart,
  delItemCart,
  getCart,
  updCarrinhoCliente,
  updComprador,
  newCart as nCart,
  updateCart,
} from './cart.service';
import LineProducts from './lineProducts';
import ListProducts from './listProducts';

import ModalCompradorInsert from './modalCompradorInsert';
import ModalCompradorSelect from './modalCompradorSelect';
import ModalDeleteItem from './modalDeleteItem';
import ModalErrors from './modalErrors';
import ModalSendOrder from './modalSendOrder';
import TableListProducts from './tableListProducts';

import { ReactComponent as WhatsappLogo } from '../../assets/svg/whatsapp.svg';
import {
  PrintCartClientImageBase64,
  PrintCartImageBase64,
} from '../../components/pdf';
import ModalConfirmationNumberWhatsapp from '../../components/modalConfirmationNumberWhatsapp';
import { sendZapiDocument } from '../../zapi.service';
import ExibirPrecoConsumidorFinal from '../../components/ExibirPrecoConsumidorFinal';
import getMask, { formatarTelefone } from '../../utils/masks';
import { Controller, useForm } from 'react-hook-form';
import MaskedInput from 'react-text-mask';
import { getCryptoLocalStorage } from '../../app.service';

const initialComprador: Comprador = {
  id: null,
  tipoPessoa: 'F',
  nome: '',
  apelido: '',
  cpf_cnpj: '',
  foneNumero: '',
};

interface FormBalcaoComprador {
  contatoNome: string;
  contatoFone: string;
}

const LOCAL_STOGAGE_CART_TYPE = LOCAL_STORAGE_PREFIX + 'cart-card-type';

export default function PageCart() {
  // const isSmall = useMedia({ maxWidth: 480 });
  const navigate = useNavigate();

  const {
    currentUser,
    isPerfilManager,
    updateDefaultCli,
    updateDefaultTab,
    currentClientAtivo,
    showPrecoConsumidorFinal,
  } = useApp();

  const {
    loading,
    setLoading,
    currentCart,
    upsetItemCart,
    updCart,
    updBalcaoComprador,
    loadCart,
    newCart,
    cleanCart,
  } = useCart();

  const formBalcaoComprador = useForm<FormBalcaoComprador>({
    defaultValues: {
      contatoNome: currentCart.contatoNome,
      contatoFone: currentCart.contatoFone,
    },
  });

  const [modalOpenSelectComprador, setOpenModalSelectComprador] =
    useState<boolean>(false);
  const [modalOpenInsertComprador, setOpenModalInsertComprador] =
    useState<boolean>(false);
  const [modalOpenDeleteItem, setOpenModalDeleteItem] =
    useState<boolean>(false);
  const [modalOpenSendOrder, setOpenModalSendOrder] = useState<boolean>(false);
  const [modalOpenConfirm, setOpenModalConfirm] = useState<boolean>(false);
  const delItem = useRef<ProdutoCart>({} as ProdutoCart);
  const comprador = useRef<Comprador>(initialComprador);
  const [verifing, setVerifing] = useState<boolean>(false);

  const [modalOpenSelectCliente, setOpenModalSelectCliente] =
    useState<boolean>(false);

  const modalErrorData = useRef<ErrorTransmission[]>([]);

  const [modalIsOpenErros, setModalIsOpenErros] = useState<boolean>(false);

  // card type handler
  const [cardType, _setCardType] = useState<string>(
    localStorage.getItem(LOCAL_STOGAGE_CART_TYPE) || 'card'
  );

  const setCardType = (card: string) => {
    _setCardType(card);
    localStorage.setItem(LOCAL_STOGAGE_CART_TYPE, card);
  };

  const [orderType, setOrderType] = useState<string>('alfa');
  const _classNames = (active: boolean) =>
    classNames(
      active &&
        'text-gray-500 shadow border-[1px] border-gray-200 border-solid',
      'hover:text-gray-500 hover:bg-gray-50 rounded-md p-1 mx-1'
    );

  const saveComprador = async (comprador: Comprador) => {
    // salvar comprador
    if (comprador.id) {
      await updComprador(comprador);
    } else {
      await addComprador(comprador);
    }
  };

  const saveCliente = useCallback(async (_clienteId: number) => {
    if (!currentCart.cliente) {
      newCart();
      return;
    }

    if (currentCart.cliente?.id === _clienteId || !_clienteId) {
      return;
    }
    // salvar cliente
    await updCarrinhoCliente({
      id: currentCart.id,
      usuarioId: currentUser.id,
      updated_at: currentCart.updated_at,
    })
      .then(() => {
        loadCart();
      })
      .finally(() => {
        // window.location.reload();
      });
  }, []);

  // se mudar o cliente, atualiza o carrinho
  useEffect(() => {
    if (!currentClientAtivo || !currentCart?.cliente?.id) return;
    if (currentClientAtivo.id !== currentCart?.cliente?.id) {
      // atualizou carrinho
      console.log('atualizou carrinho');
      loadCart();
    }
  }, [currentClientAtivo, currentCart?.cliente?.id]);

  const saveTabelaPrecos = async (tabelaPrecosId: number) => {
    if (currentCart.tabelaPrecos?.id === tabelaPrecosId || !tabelaPrecosId)
      return;
    updateDefaultTab(tabelaPrecosId);
    await new Promise((t) => setTimeout(t, 500));

    // const newCart = await updCarrinhoCliente({
    //   id: currentCart.id,
    //   usuarioId: currentUser.id,
    //   updated_at: currentCart.updated_at,
    // });
    const cart = await getCart(currentCart.id);
    const newCart = await updateCart(currentCart.id, {
      carrinho: {
        id: cart.id,
        updated_at: cart.updated_at,
        tabelaPrecos: tabelaPrecosId,
      },
    } as unknown as Cart);

    loadCart(newCart);
  };

  const saveCart = async () => {
    if (currentCart.produtos.length === 0) {
      toast.error('Sem produtos, verifique');
      return;
    }
    await newCart();
    // window.location.reload();
    await new Promise((t) => setTimeout(t, 500));

    await loadCart();
  };

  const transmission = async (
    obs: string,
    fpagto: number,
    cpagto: number,
    transportadora: number,
    vendedor?: number | undefined
  ) => {
    if (currentCart.produtos.length === 0) {
      toast.error('Sem produtos, verifique');
      return;
    }

    if (!fpagto) {
      toast.error('Sem forma de pagamento, verifique');
      return;
    }
    if (!cpagto) {
      toast.error('Sem condições de pagamento, verifique');
      return;
    }
    if (!transportadora) {
      toast.error('Sem transportadora, verifique');
      return;
    }
    if (!vendedor) {
      toast.error('Verifique o vendedor, verifique');
      return;
    }

    setLoading(true);
    const pedido: IPedido = {
      cotacoes: [
        { cotacaoId: currentCart.id, updated_at: currentCart.updated_at },
      ],
      produtos: currentCart.produtos.map((produto) => ({
        produtoId: produto.produto.id,
        qtde: produto.qtde,
        unitario: produto.unitario,
        desc1: produto.desc1,
        desc2: produto.desc2,
        desc3: produto.desc3,
        descGT: produto.descGT,
        descMaxClasse: produto.produto.descMaxClasse,
        unitarioLiquido: produto.unitarioLiquido,
        isPromocao: produto.isPromocao,
      })),
      totais: {
        qtdeTotal: currentCart.qtdeTotal,
        produtos: currentCart.totalProdutos,
        frete: currentCart.frete,
        total: currentCart.total,
      },
      obs:
        obs +
        `
        Usuário: ${currentUser?.nome ?? ''}
        Email: ${currentUser?.email ?? ''}
        Telefone: ${
          currentUser?.telefone ? formatarTelefone(currentUser.telefone) : ''
        }
      `,
      cliente: currentCart.cliente.id,
      tabelaPrecos: currentCart.tabelaPrecos.id,
      fPagto: fpagto,
      cPagto: cpagto,
      transportadora,
      vendedor,
    };

    addPedido({ pedido })
      .then((data) => {
        console.log('🚀 ~ file: index.tsx:239 ~ .then ~ data:', data);
        afterTransmissionSuccess(data);
      })
      .catch((err) => {
        // setLoading(false);
        toast.error(err.response.message || err.message);
      })
      .finally(() => setLoading(false));
  };

  const afterTransmissionSuccess = async (newOrder: any) => {
    const cart = await nCart();
    loadCart(cart);
    // mostar tela de sucesso
    navigate(ROUTES_MAP.orderSuccess, { state: { newOrder } });
  };

  const validateTransmission = async () => {
    const errors: ErrorTransmission[] = [];
    const cartId = currentCart.id;
    const cart = await getCart(cartId);
    const produtos = cart.produtos;
    if (produtos.length === 0) {
      return errors;
    }

    produtos.forEach((produto, index) => {
      if (produto.isPromocao === 1) {
        if (
          produto?.produto?.promocao &&
          produto?.produto?.promocao?.qtdeDisponivel === 0
        ) {
          errors.push({
            image: produto?.produto?.images[0].small,
            produto: produto.produto.descricaoSite,
            qtde: produto.qtde,
            promocionalPrice: produto.produto.precoPromo,
            price: produto.unitarioLiquido,
            qtdeDisponivel: produto.produto.promocao?.qtdeDisponivel || 0,
            itemId: produto.id,
            error: `:(  Quantidade esgotada para promoção`,
            index,
            actions: [
              {
                title: `Manter com valor ${parseCurrency(
                  produto.produto.precoPromo
                )}`,
                action: 'replace',
              },
              {
                title: 'Remover item',
                action: 'remove',
              },
            ],
          });
          return;
        }
        if (
          produto?.produto?.promocao &&
          produto.qtde > produto?.produto?.promocao?.qtdeDisponivel
        ) {
          errors.push({
            image: produto?.produto?.images[0].small,
            produto: produto.produto.descricaoSite,
            qtde: produto.qtde,
            qtdeDisponivel: produto.produto.promocao?.qtdeDisponivel || 0,
            promocionalPrice: produto.produto.precoPromo,
            price: produto.unitarioLiquido,
            itemId: produto.id,
            error: `Quantidade promocional excedida, restante: ${
              produto.produto.promocao?.qtdeDisponivel || 0
            } und(s)`,
            index,
            actions: [
              {
                title: `Atualizar quantidade (${produto.produto.promocao?.qtdeDisponivel})`,
                action: 'update',
              },
              {
                title: 'Remover item',
                action: 'remove',
              },
            ],
          });
          return;
        }
        if (!produto.produto?.promocao) {
          errors.push({
            image: produto?.produto?.images[0].small,
            produto: produto.produto.descricaoSite,
            qtde: produto.qtde,
            qtdeDisponivel: 0,
            promocionalPrice: produto.produto.precoPromo,
            price: produto.unitarioLiquido,
            itemId: produto.id,
            error:
              ':( Promoção acabou... Você quer manter o item no valor normal?',
            index,
            actions: [
              {
                title: `Manter com valor ${parseCurrency(
                  produto.produto.precoPromo
                )}`,
                action: 'replace',
              },
              {
                title: 'Remover item',
                action: 'remove',
              },
            ],
          });
          return;
        }
      }
    });

    return errors;
  };

  const verifyTransmission = async () => {
    setVerifing(true);
    modalErrorData.current = [];
    const errors = await validateTransmission();

    if (errors.length > 0) {
      modalErrorData.current = errors;
      setModalIsOpenErros(true);
      setVerifing(false);
      return;
    }

    setVerifing(false);
    setOpenModalSendOrder(true);
  };

  const processErrors = async (actions: Record<string, string>[]) => {
    for await (const { index, action } of actions) {
      switch (action) {
        case 'update': {
          // console.log('update', index);
          // const upd = currentCart.produtos[Number(index)];
          const upd = currentCart.produtos.find(
            (p) => p.id.toString() === index
          );
          // console.log('upd', upd);
          if (!upd) {
            toast.error('Erro ao atualizar item, tente fazer manualmente');
            return;
          }
          if (upd.produto.promocao?.qtdeDisponivel)
            upsetItemCart(upd.produto.id, upd.produto.promocao?.qtdeDisponivel);
          break;
        }

        case 'remove': {
          // console.log('remove', index);
          const rem = currentCart.produtos.find(
            (p) => p.id.toString() === index
          );
          if (!rem) {
            toast.error('Erro ao remover item, tente fazer manualmente');
            return;
          }
          // console.log('rem', rem);
          await delItemCart(currentCart.id, rem.id, currentCart.updated_at);
          break;
        }

        case 'replace': {
          // console.log('replace', index);
          // const rep = currentCart.produtos[Number(index)];
          const rep = currentCart.produtos.find(
            (p) => p.id.toString() === index
          );
          if (!rep) {
            toast.error('Erro ao substituir item, tente fazer manualmente');
            return;
          }
          const cart = await delItemCart(
            currentCart.id,
            rep.id,
            currentCart.updated_at
          );
          await addItemCart(cart.id, rep.produto.id, rep.qtde, cart.updated_at);
          break;
        }

        default:
          console.log('none', index);
      }
    }

    loadCart();
  };

  // whatsapp modal send
  const [modalIsOpenWhatsapp, setModalIsOpenWhatsapp] =
    useState<boolean>(false);

  const handleWhatsapp = async (number: string) => {
    if (!number) return;
    const cart = currentCart;
    // await PrintCartImagePDF(cart);
    // return;
    const base64 = await PrintCartImageBase64(cart);
    const result = await sendZapiDocument({
      document: `data:application/pdf;base64,${base64}`,
      phone: number,
      extension: 'pdf',
      fileName: `Orcamento-${cart.id}`,
    });
    if (result) {
      toast.success('Pedido enviado com sucesso!');
    } else {
      toast.error('Erro ao enviar pedido!');
    }
  };

  const handleDowloadCartPdf = async () => {
    if (!currentCart.contatoNome) {
      toast.warning('Informe o nome e telefone do comprador');
      formBalcaoComprador.setFocus('contatoNome');
      return;
    }

    if (!currentCart.contatoFone) {
      toast.warning('Informe o nome e telefone do comprador');
      formBalcaoComprador.setFocus('contatoNome');
      return;
    }

    const cart = currentCart;
    const cartModificado: Cart = {
      ...cart,
      subTotalProdutos: showPrecoConsumidorFinal
        ? cart.subTotalProdutosConsumidorFinal ?? 0
        : cart.subTotalProdutos ?? 0,
      totalProdutos: showPrecoConsumidorFinal
        ? cart.totalProdutosConsumidorFinal ?? 0
        : cart.totalProdutos ?? 0,
      total: showPrecoConsumidorFinal
        ? cart.totalConsumidorFinal ?? 0
        : cart.total ?? 0,
      produtos: cart.produtos.map((produto) => ({
        ...produto,
        unitarioLiquido: showPrecoConsumidorFinal
          ? produto.unitarioLiquidoConsumidorFinal
          : produto.unitario,
        totalItem: showPrecoConsumidorFinal
          ? produto.totalItemConsumidorFinal
          : produto.totalItem,
      })),
    };
    const base64 = await PrintCartClientImageBase64(cartModificado);
    const linkSource = `data:application/pdf;base64,${base64}`;
    const downloadLink = document.createElement('a');
    const fileName = `Orcamento-${cart.id}-${createSlug(
      cart.contatoNome || 'ok'
    )}.pdf`;

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  const salvarBalcaoComprador = (data: FormBalcaoComprador) => {
    const { contatoNome, contatoFone } = data;
    updBalcaoComprador({
      contatoNome,
      contatoFone,
    });
  };

  const tabelaId = useMemo(
    () => currentCart.tabelaPrecos?.id,
    [currentCart.tabelaPrecos]
  );

  if (loading)
    return (
      <div className="flex flex-col justify-center items-center h-[88vh] animate-pulse">
        <span className="mx-auto"></span>Aguarde ...
      </div>
    );

  // if (isSmall)
  //   return (
  //     <>
  //       <div className="mt-24 p-2 flex flex-col space-y-3">
  //         <div className="flex flex-col">
  //           <h1 className="text-xl font-semibold">
  //             Carrinho #{currentCart.id}{' '}
  //             {currentCart.comprador ? ' - ' + currentCart.comprador.nome : ''}
  //           </h1>
  //           {/* <ComboTabelaPreco
  //             action={(tabelaPreco: TabelaPreco) => {
  //               console.log('tabelaPreco', tabelaPreco);
  //             }}
  //             tabelaPreco={null}
  //           /> */}
  //         </div>

  //         <div className="">
  //           {currentCart.produtos && currentCart.produtos.length > 0 ? (
  //             <CardListProducts
  //               products={currentCart.produtos}
  //               qtdeAction={upsetItemCart}
  //               delAction={(item) => {
  //                 delItem.current = item;
  //                 setOpenModalDeleteItem(true);
  //               }}
  //               active={currentCart.situacaoCarrinho === 0}
  //             />
  //           ) : (
  //             <div className="relative top-[50%] text-center my-20">
  //               <span className="animate-pulse">Carrinho Vazio!</span>
  //             </div>
  //           )}
  //         </div>
  //         <div className="h-[30vh]">
  //           <div className="px-2 mt-1 text-gray-500 flex justify-between space-x-2">
  //             <span className="text-xl">Cliente</span>

  //             {currentCart.comprador ? (
  //               <Button
  //                 title="Selecionar"
  //                 icon={<PencilAltIcon className="w-4 h-4" />}
  //                 color="bg-green-500 text-white hover:bg-green-400 hover:text-gray-100"
  //                 onClick={() => setOpenModalSelectComprador(true)}
  //                 disabled={currentCart.situacaoCarrinho !== 0}
  //               />
  //             ) : (
  //               <Button
  //                 title="Selecionar"
  //                 icon={<PlusCircleIcon className="w-4 h-4" />}
  //                 color="bg-green-500 text-white hover:bg-green-400 hover:text-gray-100"
  //                 onClick={() => setOpenModalSelectComprador(true)}
  //                 disabled={currentCart.situacaoCarrinho !== 0}
  //               />
  //             )}
  //           </div>
  //           <div className="mx-2 text-sm">
  //             {currentCart.comprador ? (
  //               <div className="flex flex-col space-y-1 mt-3">
  //                 <div className="px-2 text-sm text-gray-500 flex justify-between">
  //                   <span className="">Nome</span>
  //                   <span className="font-bold">
  //                     {currentCart.comprador.nome}
  //                   </span>
  //                 </div>
  //                 <div className="px-2 text-sm text-gray-500 flex justify-between">
  //                   <span className="">Apelido</span>
  //                   <span className="font-bold">
  //                     {currentCart.comprador.apelido || 'N/D'}
  //                   </span>
  //                 </div>
  //                 <div className="px-2 text-sm text-gray-500 flex justify-between">
  //                   <span className="">CPF/CNPJ</span>
  //                   <span className="font-bold">
  //                     {currentCart.comprador.cpf_cnpj || 'N/D'}
  //                   </span>
  //                 </div>
  //                 <div className="px-2 text-sm text-gray-500 flex justify-between">
  //                   <span className="">Contato</span>
  //                   <span className="font-bold">
  //                     {currentCart.comprador.foneNumero || 'N/D'}
  //                   </span>
  //                 </div>
  //                 <div className="flex justify-between">
  //                   <Button
  //                     title="Alterar"
  //                     icon={<PencilAltIcon className="w-4 h-4" />}
  //                     color="bg-default-white text-primary2 hover:bg-gray-100 hover:text-gray-700"
  //                     onClick={() => {
  //                       comprador.current = initialComprador;
  //                       setTimeout(() => {
  //                         if (currentCart.comprador) {
  //                           comprador.current = currentCart.comprador;
  //                           setOpenModalInsertComprador(true);
  //                         }
  //                       }, 100);
  //                     }}
  //                     disabled={currentCart.situacaoCarrinho !== 0}
  //                   />
  //                   <Button
  //                     title="Remover"
  //                     icon={<TrashIcon className="w-4 h-4" />}
  //                     color="bg-default-white text-primary2 hover:bg-gray-100 hover:text-gray-700"
  //                     onClick={() => updCart(null)}
  //                     disabled={currentCart.situacaoCarrinho !== 0}
  //                   />
  //                 </div>
  //               </div>
  //             ) : (
  //               <div className="flex flex-col space-y-3 h-40">
  //                 <div className="px-2 text-sm text-gray-500 flex justify-between">
  //                   <span className="">Nome</span>
  //                   <span className="font-bold"></span>
  //                 </div>
  //                 <div className="px-2 text-sm text-gray-500 flex justify-between">
  //                   <span className="">Apelido</span>
  //                   <span className="font-bold"></span>
  //                 </div>
  //                 <div className="px-2 text-sm text-gray-500 flex justify-between">
  //                   <span className="">CPF/CNPJ</span>
  //                   <span className="font-bold"></span>
  //                 </div>
  //                 <div className="px-2 text-sm text-gray-500 flex justify-between">
  //                   <span className="">Contato</span>
  //                   <span className="font-bold"></span>
  //                 </div>
  //                 <div className="flex justify-between">
  //                   <Button
  //                     title="Inserir Cliente"
  //                     icon={<PlusCircleIcon className="w-4 h-4" />}
  //                     color="bg-default-white text-primary2 hover:bg-gray-100 hover:text-gray-700"
  //                     onClick={() => {
  //                       comprador.current = initialComprador;
  //                       setTimeout(() => {
  //                         setOpenModalInsertComprador(true);
  //                       }, 100);
  //                     }}
  //                     disabled={currentCart.situacaoCarrinho !== 0}
  //                   />
  //                 </div>
  //               </div>
  //             )}
  //           </div>
  //         </div>
  //         <div className="h-5" />
  //         <div className="w-full bg-gray-100">
  //           <div className="px-2 mt-4 text-gray-500 flex justify-between">
  //             <span className="text-xl">Peças</span>
  //             <span className="font-bold text-xl">
  //               {parseCurrency(currentCart.totalProdutos)}
  //             </span>
  //           </div>
  //           <div className="px-2 text-gray-500 flex justify-between ">
  //             <span className="text-xl">Frete</span>
  //             <span className="font-bold text-xl">
  //               {parseCurrency(currentCart.frete)}
  //             </span>
  //           </div>
  //           <div className="border-b-[1px] border-solid border-gray-300"></div>
  //           <div className="px-2 text-gray-500 flex justify-between ">
  //             <span className="text-xl">Total</span>
  //             <span className="font-bold text-xl">
  //               {parseCurrency(currentCart.total)}
  //             </span>
  //           </div>
  //           <div className="flex justify-center items-center py-2 px-4">
  //             <Link
  //               to={ROUTES_MAP.product}
  //               className="
  //               rounded-2xl bg-primary2 text-default-white
  //               py-2
  //               drop-shadow-md
  //               hover:bg-primary1 hover:text-gray-100
  //               w-full text-center"
  //             >
  //               Continuar Comprando
  //             </Link>
  //           </div>
  //           <div className="flex justify-center items-center py-2 px-4">
  //             <Button
  //               title="Enviar Pedido"
  //               color="bg-green-500 text-white hover:bg-green-400 hover:text-gray-100"
  //               full="true"
  //               onClick={() => setOpenModalSendOrder(true)}
  //               disabled={currentCart.situacaoCarrinho !== 0}
  //             />
  //           </div>
  //           <div className="flex justify-center items-center py-2 px-4">
  //             <Button
  //               title="Guardar em meus carrinhos"
  //               color="bg-default-white text-primary2 hover:bg-gray-100 hover:text-gray-700"
  //               full="true"
  //               onClick={saveCart}
  //               disabled={currentCart.situacaoCarrinho !== 0}
  //             />
  //           </div>
  //           <div className="flex justify-center items-center py-2 px-4">
  //             <Button
  //               title="Esvaziar Carrinho"
  //               color="bg-default-white text-primary2 hover:bg-gray-100 hover:text-gray-700"
  //               full="true"
  //               onClick={() => setOpenModalConfirm(true)}
  //               disabled={currentCart.situacaoCarrinho !== 0}
  //             />
  //           </div>
  //           <div className="border-b-[1px] mx-1 border-solid border-gray-300"></div>
  //         </div>
  //       </div>
  //       <ModalCompradorSelect
  //         isOpen={modalOpenSelectComprador}
  //         setIsOpen={setOpenModalSelectComprador}
  //         updCard={updCart}
  //       />
  //       <ModalClienteSelect
  //         isOpen={modalOpenSelectCliente}
  //         setIsOpen={setOpenModalSelectCliente}
  //         updCard={updCart}
  //         clienteId={currentCart.cliente?.id}
  //       />
  //       <ModalCompradorInsert
  //         isOpen={modalOpenInsertComprador}
  //         setIsOpen={setOpenModalInsertComprador}
  //         comprador={comprador.current}
  //         save={saveComprador}
  //       />
  //       <ModalDeleteItem
  //         isOpen={modalOpenDeleteItem}
  //         setIsOpen={setOpenModalDeleteItem}
  //         item={delItem.current}
  //       />
  //       <ModalSendOrder
  //         isOpen={modalOpenSendOrder}
  //         vendedor={currentCart.vendedor?.id ?? 0}
  //         setIsOpen={setOpenModalSendOrder}
  //         resume={{
  //           total: currentCart.total,
  //           frete: currentCart.frete,
  //           qtde: currentCart.qtdeTotal,
  //         }}
  //         action={transmission}
  //       />
  //       <ModalConfirmation
  //         isOpen={modalOpenConfirm}
  //         setIsOpen={setOpenModalConfirm}
  //         title="Confirmação"
  //         question="Remover todos os itens do carrinho?"
  //         answer={() => {
  //           if (currentCart.produtos.length === 0) {
  //             toast.error('Sem produtos, verifique');
  //             return;
  //           }
  //           cleanCart();
  //         }}
  //       />
  //     </>
  //   );

  return (
    <div className="mt-24 md:mt-0 grid grid-cols-1 grid-rows-1 h-full">
      <div className="flex flex-col justify-between h-full">
        <div className="flex justify-between items-center py-2 px-1 bg-gray-50">
          {currentCart.situacaoCarrinho === 0 && isPerfilManager && (
            <div className="flex space-x-2">
              {currentCart.produtos?.length > 0 &&
              currentUser.cliente.id !== currentCart.cliente?.id ? (
                <span
                  className={`
                text-opacity-90 h-full group inline-flex items-center justify-between rounded-md bg-gray-300 px-3 py-2 text-sm font-medium text-white hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
                >
                  <div className="line-clamp-1">
                    {truncateStringWithEllipsis(
                      currentCart?.cliente?.nome || '',
                      25
                    )}
                  </div>
                </span>
              ) : (
                <button
                  onClick={() => {
                    setOpenModalSelectCliente(true);
                  }}
                  className={`
                text-opacity-90 h-full group inline-flex items-center justify-between rounded-md bg-primary1 px-3 py-2 text-sm font-medium text-white hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
                >
                  <div className="line-clamp-1">
                    {truncateStringWithEllipsis(
                      currentCart?.cliente?.nome || '',
                      25
                    )}
                  </div>
                  <ChevronDownIcon
                    className={`text-opacity-70 ml-2 h-5 w-5 text-orange-300 transition duration-150 ease-in-out group-hover:text-opacity-80`}
                    aria-hidden="true"
                  />
                </button>
              )}
              <ComboTabelaPreco
                action={(tabelaPreco: TabelaPreco) => {
                  if (tabelaPreco.id === currentCart?.tabelaPrecos?.id) return;
                  saveTabelaPrecos(tabelaPreco.id);
                }}
                tabelaPreco={tabelaId}
              />
            </div>
          )}
          <h1 className="text-xl font-semibold flex justify-between">
            <span>
              Carrinho #{currentCart.id}{' '}
              {currentCart.comprador ? ' - ' + currentCart.comprador.nome : ''}
            </span>
          </h1>
          <span className="p-0 rounded-md hidden md:inline text-gray-500">
            <button
              className={_classNames(orderType === 'alfa')}
              type="button"
              onClick={() => setOrderType && setOrderType('alfa')}
              title="Ordem Alfabética"
            >
              <img
                className="h-6 w-6 opacity-50"
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAAA8UlEQVRIid2UMQ4BURCGv2XPQCki0W5F4Q4aBRINJ6BT2mtxABfYqLiAREuDXcVO8Wze27fvhYI/mWJm/5l/Znby4J+xADJgbuFlYs7YSeL2GwIN4C6JD6DpK1AzxCdACCRAHRi7dmjDHkiBHvAU3wTnFbWkeKZYCrR9BHQrmgFBIRYAU5cuy5BIR5H4kfgHA9/7TKvC64o+hq8LhJpY2T6LP98KlwmOrsWrYEU+0QXoGjjeVzQkf4duwKCE5yXQB67kz8TIwnUW6ABnSVoaOGvenxLVNjaBU0myiljzPa4ygam4bg3qJNbOfRFTsfPfxAuUolP9esbLDwAAAABJRU5ErkJggg=="
              />
            </button>
            <button
              className={_classNames(orderType === 'num')}
              type="button"
              onClick={() => setOrderType && setOrderType('num')}
              title="Ordem Numérica"
            >
              <img
                className="h-6 w-6 opacity-60"
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAABmJLR0QA/wD/AP+gvaeTAAABNklEQVRIie3Wu0rEUBDG8Z+XysZCDF6wstM3UNBOEK0EGxEfQXwU8RV8DMFiG1G0UUGwUbTQykIQrxiLnEB2ybrJ6qbZ/cMQMjkz30xykgk9upUh7OEp2FzJ+GMclRWdxDXijC2UzJHGNaU/xzeFEWziq6TgnxgPBp861PFgju+hpEhb5N3qSug+4bxnnOUGA3iroJZ/oa33uBJ6wimLqOEZj9jHRKeLmcErvnGIO8lGKTNtWm6uPHYko3A3nE9nEg13UriRrZDkFn1N1tRwiihHOArXSs3nFbxLptTSL+tOgtBFEEqFo+CLw5pCrEm+WB9Yb7E2K5Ae4wZf1DQ6w4akyxcsFyx0FOfq/1xiXGGsSIJVyY6OcY+DjM22iI1wqb7jQp3Cdk7Vqc0XiI9wpn6z9ehCfgCYCVwSivT9bQAAAABJRU5ErkJggg=="
              />
            </button>

            <button
              className={_classNames(cardType === 'card')}
              type="button"
              onClick={() => setCardType('card')}
              title="Visão Cartão"
            >
              <ViewBoardsIcon className="h-6 w-6 rotate-90 py-[1px]" />
            </button>
            <button
              className={_classNames(cardType === 'list')}
              type="button"
              onClick={() => setCardType('list')}
              title="Visão Lista"
            >
              <ViewListIcon className="h-6 w-6" />
            </button>
            <button
              className={_classNames(cardType === 'table')}
              type="button"
              onClick={() => setCardType('table')}
              title="Visão Tabela"
            >
              <TableIcon className="h-6 w-6" />
            </button>
          </span>
        </div>
        <div className="flex flex-col md:grid md:grid-cols-12 md:grid-rows-1 md:gap-0 md:h-full">
          <div className="w-full md:col-span-9 h-[81.5vh] overflow-hidden overflow-y-scroll">
            {currentCart.produtos && currentCart.produtos.length > 0 ? (
              <>
                {cardType === 'list' && (
                  <ListProducts
                    products={currentCart.produtos.sort((a, b) => {
                      if (orderType === 'alfa') {
                        return a.produto.descricaoSite.localeCompare(
                          b.produto.descricaoSite
                        );
                      } else {
                        return (
                          Number(a.produto.codigoSgi) -
                          Number(b.produto.codigoSgi)
                        );
                      }
                    })}
                    qtdeAction={upsetItemCart}
                    delAction={(item) => {
                      delItem.current = item;
                      setOpenModalDeleteItem(true);
                    }}
                    active={currentCart.situacaoCarrinho === 0}
                  />
                )}
                {cardType === 'table' && (
                  <LineProducts
                    products={currentCart.produtos.sort((a, b) => {
                      if (orderType === 'alfa') {
                        return a.produto.descricaoSite.localeCompare(
                          b.produto.descricaoSite
                        );
                      } else {
                        return (
                          Number(a.produto.codigoSgi) -
                          Number(b.produto.codigoSgi)
                        );
                      }
                    })}
                    qtdeAction={upsetItemCart}
                    delAction={(item) => {
                      delItem.current = item;
                      setOpenModalDeleteItem(true);
                    }}
                    active={currentCart.situacaoCarrinho === 0}
                  />
                )}
                {cardType === 'card' && (
                  <TableListProducts
                    products={currentCart.produtos.sort((a, b) => {
                      if (orderType === 'alfa') {
                        return a.produto.descricaoSite.localeCompare(
                          b.produto.descricaoSite
                        );
                      } else {
                        return (
                          Number(a.produto.codigoSgi) -
                          Number(b.produto.codigoSgi)
                        );
                      }
                    })}
                    qtdeAction={upsetItemCart}
                    delAction={(item) => {
                      delItem.current = item;
                      setOpenModalDeleteItem(true);
                    }}
                    active={currentCart.situacaoCarrinho === 0}
                  />
                )}
              </>
            ) : (
              <div className="relative top-[50%] text-center">
                <span className="animate-pulse">Carrinho Vazio!</span>
              </div>
            )}
          </div>
          <div className="w-full md:col-span-3 h-full flex flex-col justify-between bg-slate-200 pt-5">
            <div>
              <div className="px-2 text-gray-500 flex justify-between">
                <span className="text-xl">Peças</span>
                {/* <span className="font-bold text-xl">
                  {showPrecoConsumidorFinal
                    ? parseCurrency(
                        currentCart?.totalProdutosConsumidorFinal || 0
                      )
                    : parseCurrency(currentCart?.totalProdutos || 0)}
                </span> */}
                <ExibirPrecoConsumidorFinal
                  item={currentCart}
                  className="font-bold text-xl"
                  field="totalProdutos"
                  fieldConsumidorFinal="totalProdutosConsumidorFinal"
                  isTotal
                />
              </div>
              <div className="px-2 text-gray-500 flex justify-between ">
                <span className="text-xl">Frete</span>
                <span className="font-bold text-xl">
                  {parseCurrency(currentCart?.frete || 0)}
                </span>
              </div>
              <div className="border-b-[1px] border-solid border-gray-300"></div>
              <div className="px-2 text-gray-500 flex justify-between ">
                <span className="text-xl">Total</span>
                {/* <span className="font-bold text-xl">
                  {showPrecoConsumidorFinal
                    ? parseCurrency(currentCart?.totalConsumidorFinal || 0)
                    : parseCurrency(currentCart?.total || 0)}
                </span> */}
                <ExibirPrecoConsumidorFinal
                  item={currentCart}
                  className="font-bold text-xl"
                  field="total"
                  fieldConsumidorFinal="totalConsumidorFinal"
                  isTotal
                />
              </div>
            </div>
            <div>
              {/* <div className="flex justify-center items-center py-2 px-4">
                <Link
                  to={ROUTES_MAP.product}
                  className="
                    rounded-2xl bg-primary2 text-default-white
                    py-2
                    drop-shadow-md
                    hover:bg-primary1 hover:text-gray-100
                    w-full text-center"
                >
                  Continuar Comprando
                </Link>
              </div> */}

              <div className="flex justify-center items-center py-1 px-4">
                {verifing ? (
                  <div className="flex justify-center items-center text-gray-800 bg-gray-300 rounded-full px-2 py-1 w-full">
                    <LoadingSVG className={classNames('fill-current py-1')} />{' '}
                    <span>Analizando pedido</span>
                  </div>
                ) : (
                  <div className="flex gap-2">
                    <Button
                      title="Enviar Pedido"
                      color="bg-green-500 text-white hover:bg-green-400 hover:text-gray-100"
                      full="true"
                      onClick={verifyTransmission}
                      disabled={
                        currentCart.situacaoCarrinho !== 0 ||
                        currentCart.produtos.length === 0
                      }
                    />
                    <Button
                      title="Download PDF"
                      color="bg-green-500 text-white hover:bg-green-400 hover:text-gray-100"
                      full="true"
                      onClick={handleDowloadCartPdf}
                      disabled={
                        currentCart.situacaoCarrinho !== 0 ||
                        currentCart.produtos.length === 0
                      }
                    />
                  </div>
                )}
              </div>

              <div className="flex justify-center items-center py-1 px-4">
                <Button
                  title="Guardar em meus carrinhos"
                  color="bg-default-white text-primary2 hover:bg-gray-100 hover:text-gray-700"
                  full="true"
                  onClick={saveCart}
                  disabled={
                    currentCart.situacaoCarrinho !== 0 ||
                    currentCart.produtos.length === 0
                  }
                />
              </div>

              <div className="flex justify-center items-center py-1 px-4">
                <Button
                  title="Esvaziar Carrinho"
                  color="bg-default-white text-primary2 hover:bg-gray-100 hover:text-gray-700"
                  full="true"
                  onClick={() => setOpenModalConfirm(true)}
                  disabled={
                    currentCart.situacaoCarrinho !== 0 ||
                    currentCart.produtos.length === 0
                  }
                />
              </div>

              {isPerfilManager && (
                <div className="flex justify-center items-center py-1 px-4">
                  <Button
                    title="Enviar para um Whatsapp"
                    color="bg-green-400 text-white hover:bg-gray-100 hover:text-green-400"
                    full="true"
                    icon={<WhatsappLogo />}
                    onClick={() => setModalIsOpenWhatsapp(true)}
                    disabled={
                      currentCart.situacaoCarrinho !== 0 ||
                      currentCart.produtos.length === 0
                    }
                  />
                </div>
              )}
            </div>
            <div className="border-b-[1px] border-solid border-gray-300" />
            <div className="h-[30vh] px-2">
              {/* <div className="px-2 mt-1 text-gray-500 flex justify-between space-x-2"> */}
              <h1 className="text-sm mb-1">Comprador/Balcão</h1>
              <div className="flex flex-col gap-2 text-sm">
                <label className="block">
                  <span className="text-gray-700">Nome</span>
                  <input
                    type="text"
                    className="form-input"
                    placeholder=""
                    {...formBalcaoComprador.register('contatoNome')}
                  />
                </label>
                {/* telefone */}
                <label className="block">
                  <span className="text-gray-700">Telefone</span>
                  <Controller
                    name="contatoFone"
                    control={formBalcaoComprador.control}
                    render={({ field }) => (
                      <MaskedInput
                        mask={getMask('telefone-11')}
                        type="text"
                        className="form-input"
                        placeholder=""
                        {...field}
                      />
                    )}
                  />
                </label>
                <button
                  title="Alterar dados do comprador"
                  className="flex justify-center items-center py-1 gap-1 rounded-full bg-default-white text-primary2 hover:shadow-lg hover:text-primary2/60"
                  onClick={() => {
                    formBalcaoComprador.handleSubmit(salvarBalcaoComprador)();
                  }}
                  disabled={currentCart.situacaoCarrinho !== 0}
                >
                  <SaveIcon className="w-6 h-6" />
                  <span>Salvar</span>
                </button>
              </div>

              {/* {currentCart.comprador ? (
                  <div className="flex space-x-1">
                    <button
                      title="Remover do Carrinho"
                      className="p-1 rounded-full bg-default-white text-primary2 hover:bg-gray-100 hover:text-gray-700"
                      onClick={() => updCart(null)}
                      disabled={currentCart.situacaoCarrinho !== 0}
                    >
                      <BanIcon className="w-4 h-4" />
                    </button>
                    <button
                      title="Alterar dados do comprador"
                      className="p-1 rounded-full bg-default-white text-primary2 hover:bg-gray-100 hover:text-gray-700"
                      onClick={() => {
                        comprador.current = initialComprador;
                        setTimeout(() => {
                          if (currentCart.comprador) {
                            comprador.current = currentCart.comprador;
                            setOpenModalInsertComprador(true);
                          }
                        }, 100);
                      }}
                      disabled={currentCart.situacaoCarrinho !== 0}
                    >
                      <PencilAltIcon className="w-4 h-4" />
                    </button>
                    <button
                      title="Selecionar comprador"
                      className="bg-green-500 text-white hover:bg-green-400 hover:text-gray-100 p-1 rounded-full"
                      onClick={() => setOpenModalSelectComprador(true)}
                      disabled={currentCart.situacaoCarrinho !== 0}
                    >
                      <SearchCircleIcon className="w-4 h-4" />
                    </button>
                  </div>
                ) : (
                  <button
                    title="Selecionar Comprador"
                    className="bg-green-500 text-white hover:bg-green-400 hover:text-gray-100 p-1 rounded-full"
                    onClick={() => setOpenModalSelectComprador(true)}
                    disabled={currentCart.situacaoCarrinho !== 0}
                  >
                    <SearchCircleIcon className="w-4 h-4" />
                  </button>
                )} */}
            </div>
            {/* <div className="mx-2 text-sm">
                {currentCart.comprador ? (
                  <div className="flex flex-col space-y-1 mt-3">
                    <div className="px-2 text-sm text-gray-500 flex justify-between">
                      <span className="">Nome</span>
                      <span className="font-bold">
                        {currentCart.comprador.nome}
                      </span>
                    </div>
                    <div className="px-2 text-sm text-gray-500 flex justify-between">
                      <span className="">Apelido</span>
                      <span className="font-bold">
                        {currentCart.comprador.apelido || 'N/D'}
                      </span>
                    </div>
                    <div className="px-2 text-sm text-gray-500 flex justify-between">
                      <span className="">CPF/CNPJ</span>
                      <span className="font-bold">
                        {currentCart.comprador.cpf_cnpj || 'N/D'}
                      </span>
                    </div>
                    <div className="px-2 text-sm text-gray-500 flex justify-between">
                      <span className="">Contato</span>
                      <span className="font-bold">
                        {currentCart.comprador.foneNumero || 'N/D'}
                      </span>
                    </div>
                  </div>
                ) : (
                  <div className="flex flex-col space-y-3 h-40">
                    <div className="px-2 text-sm text-gray-500 flex justify-between">
                      <span className="">Nome</span>
                      <span className="font-bold"></span>
                    </div>
                    <div className="px-2 text-sm text-gray-500 flex justify-between">
                      <span className="">Apelido</span>
                      <span className="font-bold"></span>
                    </div>
                    <div className="px-2 text-sm text-gray-500 flex justify-between">
                      <span className="">CPF/CNPJ</span>
                      <span className="font-bold"></span>
                    </div>
                    <div className="px-2 text-sm text-gray-500 flex justify-between">
                      <span className="">Contato</span>
                      <span className="font-bold"></span>
                    </div>
                  </div>
                )}
              </div> */}
            {/* </div> */}
          </div>
        </div>
      </div>
      <ModalCompradorSelect
        isOpen={modalOpenSelectComprador}
        setIsOpen={setOpenModalSelectComprador}
        updCard={updCart}
        newCliente={() => {
          {
            setOpenModalSelectComprador(false);
            comprador.current = initialComprador;
            setOpenModalInsertComprador(true);
          }
        }}
      />
      <ModalClienteSelect
        isOpen={modalOpenSelectCliente}
        setIsOpen={setOpenModalSelectCliente}
        updCard={async (cliente) => {
          updateDefaultCli(cliente.id);
          updateDefaultTab(cliente.tabelaId);
          loadCart();
          // new Promise((t) => setTimeout(t, 500)).then(() => {
          //   saveCliente(cliente.id);
          // });
        }}
        clienteId={currentCart.cliente?.id}
      />
      <ModalCompradorInsert
        isOpen={modalOpenInsertComprador}
        setIsOpen={setOpenModalInsertComprador}
        comprador={comprador.current}
        save={saveComprador}
      />
      <ModalDeleteItem
        isOpen={modalOpenDeleteItem}
        setIsOpen={setOpenModalDeleteItem}
        item={delItem.current}
      />
      <ModalSendOrder
        isOpen={modalOpenSendOrder}
        setIsOpen={setOpenModalSendOrder}
        vendedor={currentCart.vendedor?.id ?? 0}
        resume={{
          total: currentCart.total,
          frete: currentCart.frete,
          qtde: currentCart.qtdeTotal,
        }}
        action={transmission}
      />
      <ModalConfirmation
        isOpen={modalOpenConfirm}
        setIsOpen={setOpenModalConfirm}
        title="Confirmação"
        question="Remover todos os itens do carrinho?"
        answer={() => {
          if (currentCart.produtos.length === 0) {
            toast.error('Sem produtos, verifique');
            return;
          }
          cleanCart();
        }}
      />
      <ModalErrors
        isOpen={modalIsOpenErros}
        setIsOpen={setModalIsOpenErros}
        errors={modalErrorData.current}
        afterClose={processErrors}
      />
      {/* <ModalConfirmationWhatsapp
        isOpen={modalIsOpenWhatsapp}
        setIsOpen={setModalIsOpenWhatsapp}
        action={(cliente, cnpj, cel) => {
          PrintOrcamento(currentCart, cliente, cnpj, cel);
        }}
        currentUser={currentUser}
      /> */}
      <ModalConfirmationNumberWhatsapp
        isOpen={modalIsOpenWhatsapp}
        setIsOpen={setModalIsOpenWhatsapp}
        action={handleWhatsapp}
        currentUser={{} as any}
      />
    </div>
  );
}
