import TableUsers from './tableUsers';
import { useUsers } from './users.hook';
import { useApp } from '../../app.hook';
import { Controller, useForm } from 'react-hook-form';
import { Usuario } from '../../app.models';
import { KeyIcon, SaveIcon } from '@heroicons/react/outline';
import { useCallback, useEffect, useState } from 'react';
import { altSenha, getPerfil, updUsuario } from './users.service';
import ModalPassword from './modalPassword';
import MaskedInput from 'react-text-mask';
import getMask from '../../utils/masks';

export default function Users() {
  // const isSmall = useMedia({ maxWidth: 480 });
  const {
    register,
    reset,
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<Usuario>();
  const { isPerfilManager, isPerfilAdmin, currentUser } = useApp();
  const [modalPassword, setModalPassword] = useState<boolean>(false);
  const [loadingUser, setLoadingUser] = useState<boolean>(false);

  const { loading, filtered } = useUsers();

  const loadUser = useCallback(async () => {
    if (isPerfilAdmin || isPerfilManager) return;
    setLoadingUser(true);
    const user = await getPerfil(currentUser.id);
    if (!user) return;
    reset(user.data[0]);
    setLoadingUser(false);
  }, [currentUser, isPerfilAdmin, isPerfilManager, reset]);

  const submit = async (data: Record<string, any>) => {
    updUsuario(data as Usuario).then(loadUser);
  };

  useEffect(() => {
    loadUser();
  }, [loadUser]);

  if (loading) {
    return (
      <div className="flex flex-col justify-center items-center h-[88vh] animate-pulse">
        <span className="mx-auto"></span>Aguarde ...
      </div>
    );
  }

  if (!isPerfilManager && !isPerfilAdmin) {
    return (
      <fieldset className="mt-12">
        <legend className="text-xl text-center">Meus Dados</legend>
        <form
          autoComplete="off"
          onSubmit={handleSubmit(submit)}
          className="flex flex-col justify-center items-center h-full max-w-xl mx-auto"
        >
          <input type="hidden" {...register('id')} />
          <input type="hidden" {...register('cliente')} />
          <div className="flex flex-col w-full">
            <label
              htmlFor="nome"
              className="block text-sm font-medium text-gray-700"
            >
              Nome
            </label>
            <div className="mt-1 relative shadow-sm">
              <input
                type="text"
                id="nome"
                className="form-input px-3 py-2 w-full rounded-md bg-gray-200 text-gray-700 border-gray-200 border-2 focus:outline-none focus:bg-white focus:border-gray-500"
                placeholder="Nome Completo"
                {...register('nome', { required: true })}
                autoComplete="off"
              />
            </div>
            <span className="text-xs text-red-500">
              {errors.nome && '* Campo requerido'}
            </span>
          </div>
          <div className="flex flex-col w-full">
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              E-mail
            </label>
            <div className="mt-1 relative shadow-sm">
              <input
                type="text"
                id="email"
                className="form-input px-3 py-2 w-full rounded-md bg-gray-200 text-gray-700 border-gray-200 border-2 focus:outline-none focus:bg-white focus:border-gray-500 disabled:bg-gray-400 disabled:text-white"
                placeholder="Email Válido"
                {...register('email', {
                  required: true,
                })}
                autoComplete="off"
              />
            </div>
            <span className="text-xs text-red-500">
              {errors.email && '* Campo requerido'}
            </span>
          </div>
          <div className="flex flex-col w-full">
            <label
              htmlFor="telefone"
              className="block text-sm font-medium text-gray-700"
            >
              Telefone
            </label>
            <div className="mt-1 relative shadow-sm">
              <Controller
                name="telefone"
                control={control}
                render={({ field }) => (
                  <MaskedInput
                    mask={getMask('telefone-11')}
                    type="text"
                    className="form-input px-3 py-2 w-full rounded-md bg-gray-200 text-gray-700 border-gray-200 border-2 focus:outline-none focus:bg-white focus:border-gray-500"
                    placeholder=""
                    {...field}
                  />
                )}
              />
              {/* <input
                type="text"
                id="telefone"
                className="form-input px-3 py-2 w-full rounded-md bg-gray-200 text-gray-700 border-gray-200 border-2 focus:outline-none focus:bg-white focus:border-gray-500"
                placeholder="Número Atual"
                {...register('telefone', { required: false })}
                autoComplete="off"
              /> */}
            </div>
            <span className="text-xs text-red-500">
              {errors.telefone && '* Campo requerido'}
            </span>
          </div>

          <div className="flex w-full justify-between my-2">
            <button
              type="button"
              onClick={() => setModalPassword(true)}
              title="Alterar Senha"
              className="bg-yellow-500 hover:opacity-70 text-default-white flex items-center rounded-lg px-3 py-2"
            >
              <KeyIcon className="h-6 w-6" /> Alterar Senha
            </button>
            <button
              type="submit"
              title="Salvar"
              className="bg-primary1 hover:opacity-70 text-default-white flex items-center rounded-lg px-3 py-2"
            >
              <SaveIcon className="h-6 w-6" /> Salvar
            </button>
          </div>
        </form>
        <ModalPassword
          isOpen={modalPassword}
          setIsOpen={setModalPassword}
          user={currentUser}
          afterAltSenha={loadUser}
        />
      </fieldset>
    );
  } else
    return (
      <div className="mt-24 md:mt-0 p-2 h-[88vh]">
        <div className="h-[88vh] overflow-hidden overflow-y-auto">
          <TableUsers
            users={filtered}
            afterAction={function (): void {
              //throw new Error('Function not implemented.');
            }}
          />
        </div>
      </div>
    );
}
