import { CalendarIcon } from '@heroicons/react/outline';
import clsx from 'clsx';
import moment from 'moment';
import React from 'react';

interface RootProps {
  title: string;
  icon?: JSX.Element;
  children?: React.ReactNode;
  color?: string;
  onClick?: () => void;
}

const Root = ({
  title,
  icon,
  children,
  color = 'bg-primary1',
  onClick,
}: RootProps) => {
  return (
    <div
      onClick={onClick && onClick}
      className={clsx(
        // 'bg-gradient-to-b from-gray-100 to-white',
        'bg-[#fff]',
        'p-1 rounded-xl',
        'shadow-md border border-solid border-gray-100',
        !icon ? 'pb-4' : 'pb-0',
        color.replace('text-', 'bg-') + '/70',
        'group',
        onClick &&
          'cursor-pointer hover:bg-gradient-to-b hover:from-white hover:to-slate-200'
      )}
    >
      <div className="flex gap-2 relative z-10">
        {title && (
          <span
            className={`flex-1 truncate text-xs px-2 py-1 font-bold ${color}`}
          >
            {title}
          </span>
        )}
        {icon && (
          <span
            className={clsx(
              color.replace('text-', 'bg-'),
              'text-default-white p-2 rounded-full',
              'group-hover:scale-110 transition-all duration-300'
            )}
          >
            {React.cloneElement(icon, { className: 'h-7 w-7' })}
          </span>
        )}
      </div>
      <div className={clsx('relative z-0', icon && '-top-4')}>{children}</div>
    </div>
  );
};

interface ValueProps {
  value: string | number;
  size?: '2' | '3' | '4' | '5' | '6';
}

// se o valor for um número, formata como moeda senão, exibe o valor
const Value = ({ value, size }: ValueProps) => {
  return (
    <div className="px-2">
      <span className={clsx('font-medium text-inherit', `text-${size}xl`)}>
        {typeof value === 'number'
          ? Number(value).toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            })
          : value}
      </span>
    </div>
  );
};

interface ProgressBarProps {
  caption?: string;
  value: number;
  color?: string;
}

const ProgressBar = ({
  caption,
  value,
  color = 'bg-primary1',
}: ProgressBarProps) => {
  return (
    <div className="flex flex-col gap-1">
      {caption && (
        <span className="text-slate-500 text-sm italic">{caption}</span>
      )}
      <div className="h-2 bg-gray-300 rounded-full">
        <div
          className={clsx('h-full rounded-full', color)}
          style={{ width: value + '%' }}
        />
      </div>
    </div>
  );
};

interface PeriodViewProps {
  period: [string, string];
}

const PeriodView = ({ period }: PeriodViewProps) => {
  return (
    <div className="flex justify-between gap-1 text-[0.6em]">
      <span className="text-slate-500 italic">Período</span>
      <span className="flex items-center gap-1">
        <CalendarIcon className="h-3 w-3 text-slate-500" />
        <span className="text-slate-700 flex gap-1">
          <span>{moment(period[0]).format('DD/MM/yyyy')}</span>
        </span>
        <span> - </span>
        <span className="text-slate-700 flex gap-1">
          <span>{moment(period[1]).format('DD/MM/yyyy')}</span>
        </span>
      </span>
    </div>
  );
};
interface TitleViewProps {
  title: string;
  description?: string;
}
const TitleView = ({ title, description }: TitleViewProps) => {
  return (
    <div className="flex justify-between gap-1 text-[0.6em]">
      <span className="text-slate-500 italic">{title}</span>
      <span className="text-slate-700 flex gap-1">{description}</span>
    </div>
  );
};

interface LabelViewProps {
  label: string;
  classColor?: string;
}
const LabelView = ({ label, classColor = 'text-primary1' }: LabelViewProps) => {
  return (
    <div className="flex justify-between gap-1 text-[0.6em] my-1">
      <span className={clsx(classColor, 'px-2 rounded-full')}>{label}</span>
    </div>
  );
};

export {
  Root as CardView,
  Value as CardViewValue,
  ProgressBar as CardViewProgressBar,
  PeriodView as CardViewPeriod,
  TitleView as CardViewTitle,
  LabelView as CardViewLabel,
};
