/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  BanIcon,
  CheckCircleIcon,
  ShoppingCartIcon,
  ThumbDownIcon,
  ThumbUpIcon,
} from '@heroicons/react/outline';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Info, Cart } from '../../app.models';
import { getCryptoLocalStorage } from '../../app.service';
import { LOCAL_STORAGE_DEFAUT_CLI } from '../../app.settings';
// import { getAuthUser } from '../../app.service';
import { getCarts } from './carts.service';

interface ICartStatus {
  situacao: string;
  id: number;
  icone: React.ReactElement;
  className: string;
}

export const cartStatus: ICartStatus[] = [
  {
    situacao: 'Cotando',
    id: 0,
    icone: <ShoppingCartIcon className="w-5 h-5" />,
    className: 'text-blue-500',
  },
  {
    situacao: 'Aprovada',
    id: 1,
    icone: <ThumbUpIcon className="w-5 h-5" />,
    className: 'text-green-500',
  },
  {
    situacao: 'Arquivada',
    id: 20,
    icone: <ThumbDownIcon className="w-5 h-5" />,
    className: 'text-gray-500',
  },
  {
    situacao: 'Cancelada',
    id: 70,
    icone: <BanIcon className="w-5 h-5" />,
    className: 'text-red-500',
  },
  {
    situacao: 'Pedido OK',
    id: 90,
    icone: <CheckCircleIcon className="w-5 h-5" />,
    className: 'text-purple-500',
  },
];

interface IProvider {
  children: React.ReactNode;
}

interface IContext {
  loading: boolean;
  cartStatus: ICartStatus[];
  dtIni: Date | undefined;
  setDtIni: (dtIni: Date | undefined) => void;
  dtEnd: Date | undefined;
  setDtEnd: (dtEnd: Date | undefined) => void;
  carts: Cart[];
  info: Info | undefined;
  skip: number | undefined;
  setSkip: (skip: number | undefined) => void;
  take: number | undefined;
  setTake: (take: number | undefined) => void;
  filters: string | undefined;
  setFilters: (filters: string) => void;
  defCliente: string;
  setDefCliente: (defCliente: string) => void;
  loadCarts: () => void;
  showZeroCarts: boolean;
  setShowZeroCarts: (showZeroCarts: boolean) => void;
}
const Context = createContext<IContext>({} as IContext);

const CartsProvider: React.FC<IProvider> = ({ children }: IProvider) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [carts, setCarts] = useState<Cart[]>([]);
  const [info, setInfo] = useState<Info | undefined>();
  const [dtIni, setDtIni] = useState<Date | undefined>();
  const lastDtIni = useRef<Date>();
  const [dtEnd, setDtEnd] = useState<Date | undefined>();
  const lastDtEnd = useRef<Date>();
  const [take, setTake] = useState<number | undefined>();
  const lastTake = useRef<number>();
  const [skip, setSkip] = useState<number | undefined>();
  const lastSkip = useRef<number>();
  const [filters, setFilters] = useState<string>();
  const lastFilters = useRef<string>();

  const [defCliente, setDefCliente] = useState(
    getCryptoLocalStorage(LOCAL_STORAGE_DEFAUT_CLI)?.clienteId.toString()
  );
  const lastDefCliente = useRef<string>(
    getCryptoLocalStorage(LOCAL_STORAGE_DEFAUT_CLI)?.clienteId.toString()
  );
  const [showZeroCarts, setShowZeroCarts] = useState<boolean>(false);
  const lastShowZeroCarts = useRef<boolean>(false);

  const loadCarts = useCallback(() => {
    setLoading(true);
    getCarts({
      dataI: dtIni,
      dataF: dtEnd,
      take,
      skip,
      situacao: filters,
      defaultCli: defCliente,
      exibirZerados: showZeroCarts,
    })
      .then((data) => {
        if (!data) {
          setCarts([]);
          setInfo(undefined);
        }
        setInfo(data.info);
        setCarts(data.carts);
      })
      .finally(() => setLoading(false));
  }, [dtIni, dtEnd, take, skip, filters, defCliente, showZeroCarts]);

  useEffect(() => {
    // if (take === lastTake.current && lastSkip.current === take) return;
    if (
      skip === lastSkip.current &&
      take === lastTake.current &&
      dtIni === lastDtIni.current &&
      dtEnd === lastDtEnd.current &&
      filters === lastFilters.current &&
      defCliente === lastDefCliente.current &&
      showZeroCarts === lastShowZeroCarts.current
    )
      return;

    lastTake.current = take;
    lastSkip.current = skip;
    lastDtIni.current = dtIni;
    lastDtEnd.current = dtEnd;
    lastDefCliente.current = defCliente;
    lastFilters.current = filters;
    lastShowZeroCarts.current = showZeroCarts;

    loadCarts();
  }, [defCliente, dtEnd, dtIni, filters, loadCarts, showZeroCarts, skip, take]);

  return (
    <Context.Provider
      value={{
        loading,
        cartStatus,
        dtIni,
        setDtIni,
        dtEnd,
        setDtEnd,
        info,
        carts,
        skip,
        setSkip,
        take,
        setTake,
        filters,
        setFilters,
        defCliente,
        setDefCliente,
        loadCarts,
        showZeroCarts,
        setShowZeroCarts,
      }}
    >
      {children}
    </Context.Provider>
  );
};

const useCarts = (): IContext => {
  const context = useContext(Context);
  return context;
};

export { CartsProvider, useCarts };
