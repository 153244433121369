import {
  DotsCircleHorizontalIcon,
  EyeIcon,
  PaperAirplaneIcon,
} from '@heroicons/react/outline';
import { useRef, useState, useEffect } from 'react';
import { Pedido } from '../../../app.models';
import ModalConfirmation from '../../../components/modalConfirmation';
import { getFormatedDateTime } from '../../../utils/datetimeHandler';
import { classNames } from '../../../utils/functions';
import { parseCurrency } from '../../../utils/numberHandler';
import { retransmitirPedido } from '../../myOrders/pedido.service';
import { useOrder } from '../../myOrders/order.hook';
import { ReactComponent as WhatsappLogo } from '../../../assets/svg/whatsapp.svg';
import AppPerPage from '../../../components/layout/appPerPage';
import AppPaginator from '../../../components/layout/pagination';
import clsx from 'clsx';

const INITIAL_MODAL_CONFIRMATION = {
  question: '',
  title: '',
  clienteId: 0,
  id: 0,
  situacaoPedido: 0,
  updated_at: new Date(),
  usuarioId: '',
};

interface InnerGridMyOrdersProps {
  pedidos: Pedido[];
  rowClickAction?: (pedido: Pedido) => void;
  rowClickWhatsapp?: (pedido: Pedido) => void;
  limit?: number;
  expandido?: boolean;
  verTudo?: () => void;
  close?: () => void;
}

interface PedidoStatusProps {
  situacaoPedido: number;
  expandido?: boolean;
}
export const PedidoStatus = ({
  situacaoPedido,
  expandido,
}: PedidoStatusProps) => {
  const { pedidoStatus } = useOrder();
  const status = pedidoStatus.find((c) => c.id === situacaoPedido);
  const class_name = pedidoStatus.find(
    (c) => c.id === situacaoPedido
  )?.className;
  if (status)
    return (
      <div className={classNames('flex items-center w-full', class_name)}>
        <span className="w-full">{status.icone}</span>
        {expandido && <span>{status.situacao}</span>}
      </div>
    );
  else
    return (
      <div className="flex pedidos-center">
        <span>
          <DotsCircleHorizontalIcon className="w-5 h-5" />
        </span>
        <span>Não Definida</span>
      </div>
    );
};

export default function InnerGridMyOrders(props: InnerGridMyOrdersProps) {
  const expandido = props.expandido ?? false;
  const limit = props.limit ?? false;
  const [modalConfirm, setModalConfirm] = useState<boolean>(false);
  const modalConfirmationRef = useRef(INITIAL_MODAL_CONFIRMATION);

  const retransmitir = async (pedido: number) => {
    await retransmitirPedido(pedido);
    self.location.reload();
  };

  const [rows, setRows] = useState<Pedido[]>([]);
  const [curPage, setCurPage] = useState<number>(0);
  const [perPage, setPerPage] = useState<number>(15);
  const [totalRows, setTotalRows] = useState<number>(0);

  useEffect(() => {
    const filtrado = props.pedidos;
    setTotalRows(filtrado.length);

    if (props.limit) setRows(filtrado.slice(0, props.limit));
    else setRows(filtrado);
  }, [props.pedidos, props.limit]);

  const rowsToDisplay = rows.slice(
    curPage * perPage,
    curPage * perPage + perPage
  );

  return (
    <div className={clsx(props.expandido === true ? 'h-[80vh]' : 'h-[17rem]')}>
      {/* content */}
      <div
        className={clsx(
          props.expandido === true ? 'h-[93.2%]' : 'h-[91%]',
          'overflow-hidden overflow-y-auto'
        )}
      >
        <table className="divide-y divide-gray-200 text-sm w-full">
          <thead className="bg-gray-100 sticky top-0">
            <tr className="">
              <th
                scope="col"
                className="pl-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                #
              </th>
              <th
                scope="col"
                className="py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Situação
              </th>
              <th
                scope="col"
                className="py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Data
              </th>
              <th
                scope="col"
                className="py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Referência
              </th>
              <th
                scope="col"
                className="py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Transmissão
              </th>
              <th
                scope="col"
                className="py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Qtde
              </th>
              <th
                scope="col"
                className="py-3 text-xs font-medium text-gray-500 uppercase tracking-wider text-right"
              >
                Valor
              </th>
              <th
                scope="col"
                className="py-3 text-xs font-medium text-gray-500 uppercase tracking-wider text-center"
              >
                Ações
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-400">
            {rowsToDisplay.map((pedido) => (
              <tr
                key={pedido.id}
                className="even:bg-gray-100 h-8 hover:bg-gray-300"
              >
                <td className="font-bold text-md align-middle">{pedido.id}</td>
                <td className="whitespace-nowrap align-middle">
                  <PedidoStatus
                    situacaoPedido={pedido.situacaoPedido}
                    expandido={expandido}
                  />
                </td>
                <td className="whitespace-nowrap align-middle">
                  {getFormatedDateTime(pedido.created_at.toString())}
                </td>
                <td className="whitespace-nowrap align-middle">
                  {pedido.xRef}
                </td>
                <td className="whitespace-nowrap align-middle">
                  {pedido.transmissao ? (
                    <span className="bg-green-200 px-3 py-1 rounded-lg">
                      {getFormatedDateTime(pedido.transmissao?.toString())}
                    </span>
                  ) : (
                    <span className="bg-yellow-200 px-3 py-1 my-1 rounded-lg">
                      Não Recebido
                    </span>
                  )}
                </td>

                <td className="whitespace-nowrap text-center align-middle">
                  {pedido.qtdeTotal}
                </td>
                <td className="whitespace-nowrap text-right align-middle">
                  {parseCurrency(pedido.total)}
                </td>
                {/* ações */}
                <td className="whitespace-nowrap text-center align-middle">
                  {props.rowClickAction && (
                    <button
                      type="button"
                      title="Visualizar Pedido"
                      className="bg-blue-500 p-1 m-1 text-default-white rounded-full"
                      onClick={() =>
                        props.rowClickAction && props.rowClickAction(pedido)
                      }
                    >
                      <EyeIcon className="h-4 w-4" />
                    </button>
                  )}
                  {props.rowClickWhatsapp && (
                    <button
                      type="button"
                      title="Enviar pedido para meu WhatsApp"
                      className="bg-blue-500 p-1 m-1 text-default-white rounded-full"
                      onClick={() =>
                        props.rowClickWhatsapp && props.rowClickWhatsapp(pedido)
                      }
                    >
                      <WhatsappLogo className="h-4 w-4" />
                    </button>
                  )}

                  {!pedido.transmissao && (
                    <button
                      type="button"
                      title="Retransmitir"
                      className="bg-green-500 p-1 m-1 text-default-white rounded-full"
                      onClick={() => retransmitir(pedido.id)}
                    >
                      <PaperAirplaneIcon className="w-4 h-4" />
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {/* footer */}
      <div className="grid grid-cols-[1fr_6fr_1fr] content-center gap-2 bg-slate-100 h-10 px-1">
        {/* ver todos ou fechar */}
        <div>
          {expandido === false && limit && totalRows > limit && (
            <button
              className="hover:underline text-xs text-red-700 ml-auto h-full"
              onClick={() => props.verTudo?.()}
            >
              Ver todos
            </button>
          )}

          {expandido === true && (
            <button
              className="hover:underline text-xs text-red-700 ml-auto h-full"
              onClick={props?.close}
            >
              Fechar
            </button>
          )}
        </div>
        {/* paginacao */}
        {expandido && totalRows > perPage ? (
          <div className="flex gap-1">
            <AppPerPage
              take={perPage}
              setTake={(perPage) => {
                setCurPage(0);
                setPerPage(perPage);
              }}
              listPerPage={[15, 25, 50, 100]}
            />
            <AppPaginator
              qtdeReg={totalRows}
              perPage={perPage}
              curPage={curPage}
              handlePageClick={(page) => setCurPage(page.selected)}
            />
            <div className="ml-auto h-full">
              <span className="text-xs">Qtde: {totalRows}</span>
            </div>
          </div>
        ) : (
          <div className="ml-auto h-full">
            <span className="text-xs">Qtde: {totalRows}</span>
          </div>
        )}
      </div>
      <ModalConfirmation
        isOpen={modalConfirm}
        setIsOpen={setModalConfirm}
        title={modalConfirmationRef.current.title}
        question={modalConfirmationRef.current.question}
        answer={() => null}
      />
    </div>
  );
}
