import { Dialog, Transition } from '@headlessui/react';
import { SaveIcon, XIcon } from '@heroicons/react/outline';
import { Fragment, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import MaskedInput from 'react-text-mask';
import { Comprador } from '../../app.models';
import Button from '../../components/layout/button';
import getMask from '../../utils/masks';

interface ModalCompradorInsertProps {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  comprador?: Comprador;
  save: (comprador: Comprador) => void;
}

export default function ModalCompradorInsert(props: ModalCompradorInsertProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    reset,
    watch,
  } = useForm();
  const tipoPessoa = watch('tipoPessoa', 'F');

  function closeModal() {
    props.setIsOpen(false);
  }

  const submit = (data: any) => {
    props.save(data as Comprador);
    closeModal();
  };

  useEffect(() => {
    setTimeout(() => {
      if (props.comprador) {
        const comps = Object.entries(props.comprador);
        comps.forEach((c) => {
          setValue(c[0], c[1]);
        });
      }
    }, 100);
  }, [props.comprador, setValue]);

  return (
    <div>
      <Transition appear show={props.isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={closeModal}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black opacity-20" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-xl p-0 my-1 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <Dialog.Title
                  as="h3"
                  className="flex justify-between mb-2 bg-primary2 text-default-white px-4 py-2"
                >
                  <span className="text-lg font-bold leading-6 font-display">
                    {props.comprador?.id
                      ? 'Alterar Cliente'
                      : 'Inserir Cliente'}
                  </span>
                  <button onClick={closeModal} className="hover:text-gray-400">
                    <XIcon className="w-5 h-5" />
                  </button>
                </Dialog.Title>
                <div className="flex flex-col font-display py-4 px-6">
                  <form
                    className="flex flex-col space-y-4"
                    onSubmit={handleSubmit(submit)}
                  >
                    <input type="hidden" {...register('id')} />
                    <div className="flex justify-start space-x-5">
                      <div className="flex items-center">
                        <input
                          type="radio"
                          {...register('tipoPessoa', { required: true })}
                          className="appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-primary1 checked:border-primary1 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                          value="F"
                          defaultChecked={
                            props.comprador?.tipoPessoa &&
                            props.comprador.tipoPessoa === 'F'
                              ? true
                              : false
                          }
                        />
                        <label className="inline-block" htmlFor="inlineRadio10">
                          Pessoa Física
                        </label>
                      </div>
                      <div className="flex items-center">
                        <input
                          type="radio"
                          {...register('tipoPessoa', { required: true })}
                          className="appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-primary1 checked:border-primary1 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                          value="J"
                          defaultChecked={
                            props.comprador?.tipoPessoa &&
                            props.comprador.tipoPessoa === 'J'
                              ? true
                              : false
                          }
                        />
                        <label
                          className="form-check-label inline-block"
                          htmlFor="inlineRadio20"
                        >
                          Pessoa Jurídica
                        </label>
                      </div>
                      {errors && errors.nome && (
                        <div className="text-sm text-red-500">
                          (*) Campo obrigatório
                        </div>
                      )}
                    </div>
                    {/* <label className="relative block">
                      <span className="text-xs">Tipo de Pessoa:</span>{' '}
                      <select
                        className="bg-gray-200 px-2 py-1 text-xs"
                        {...register('tipoPessoa', { required: true })}
                      >
                        <option value={'F'}>Pessoa Física</option>
                        <option value={'J'}>Pessoa Jurídica</option>
                      </select>
                      {errors && errors.tipoPessoa && (
                        <div className="text-sm text-red-500">
                          (*) Campo obrigatório
                        </div>
                      )}
                    </label> */}
                    <label className="relative block">
                      <span className="sr-only">
                        {tipoPessoa && tipoPessoa === 'F'
                          ? 'Nome *'
                          : 'Razão Social *'}
                      </span>
                      <input
                        className="placeholder:italic placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md py-2 pl-3 pr-3 shadow-sm focus:outline-none focus:border-gray-300 focus:ring-gray-300 focus:ring-1 sm:text-sm"
                        placeholder={
                          tipoPessoa && tipoPessoa === 'F'
                            ? 'Nome *'
                            : 'Razão Social *'
                        }
                        type="text"
                        {...register('nome', { required: true })}
                      />
                      {errors && errors.nome && (
                        <div className="text-sm text-red-500">
                          (*) Campo obrigatório
                        </div>
                      )}
                    </label>
                    <label className="relative block">
                      <span className="sr-only">Apelido *</span>
                      <input
                        className="placeholder:italic placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md py-2 pl-3 pr-3 shadow-sm focus:outline-none focus:border-gray-300 focus:ring-gray-300 focus:ring-1 sm:text-sm"
                        placeholder="Apelido"
                        type="text"
                        {...register('apelido', { required: false })}
                      />
                      {errors && errors.apelido && (
                        <div className="text-sm text-red-500">
                          (*) Campo obrigatório
                        </div>
                      )}
                    </label>
                    <label className="relative block">
                      <span className="sr-only">CPF</span>
                      <Controller
                        name="cpf_cnpj"
                        control={control}
                        rules={{ required: false }}
                        render={({ field }) => (
                          <MaskedInput
                            mask={
                              tipoPessoa === 'F'
                                ? getMask('cpf')
                                : getMask('cnpj')
                            }
                            type="text"
                            placeholder={tipoPessoa === 'F' ? 'CPF' : 'CNPJ'}
                            className="placeholder:italic placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md py-2 pl-3 pr-3 shadow-sm focus:outline-none focus:border-gray-300 focus:ring-gray-300 focus:ring-1 sm:text-sm"
                            {...field}
                          />
                        )}
                      />
                      {/* <input
                        className="placeholder:italic placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md py-2 pl-3 pr-3 shadow-sm focus:outline-none focus:border-gray-300 focus:ring-gray-300 focus:ring-1 sm:text-sm"
                        placeholder="CPF"
                        type="text"
                        {...register('cpf_cnpj', { required: false })}
                      /> */}
                      {errors && errors.cpf_cnpj && (
                        <div className="text-sm text-red-500">
                          (*) Campo obrigatório
                        </div>
                      )}
                    </label>
                    <label className="relative block">
                      <span className="sr-only">Contato</span>
                      <Controller
                        name="foneNumero"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <MaskedInput
                            mask={getMask('celular')}
                            type="text"
                            placeholder="Contato *"
                            className="placeholder:italic placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md py-2 pl-3 pr-3 shadow-sm focus:outline-none focus:border-gray-300 focus:ring-gray-300 focus:ring-1 sm:text-sm"
                            {...field}
                          />
                        )}
                      />
                      {/* <input
                        className="placeholder:italic placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md py-2 pl-3 pr-3 shadow-sm focus:outline-none focus:border-gray-300 focus:ring-gray-300 focus:ring-1 sm:text-sm"
                        placeholder="Contato *"
                        type="text"
                        {...register('foneNumero', { required: true })}
                      /> */}
                      {errors && errors.foneNumero && (
                        <div className="text-sm text-red-500">
                          (*) Campo obrigatório
                        </div>
                      )}
                    </label>
                    <div className="flex justify-end">
                      <Button
                        title="Salvar"
                        type="submit"
                        icon={<SaveIcon className="w-4 h-4" />}
                        color="bg-green-500 text-white hover:bg-green-400 hover:text-gray-100"
                      />
                    </div>
                  </form>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}
