import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { Fragment, useEffect, useRef, useState } from 'react';
import useMedia from 'use-media';
import { Cliente } from '../app.models';
import AppPaginator from './layout/pagination';
import { getClientes } from '../pages/cart/cart.service';

interface ModalFindClienteProps {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  onSelect: (cliente: Cliente) => void;
  onClear?(): void;
  cliente?: Cliente;
}

export default function ModalFindCliente(props: ModalFindClienteProps) {
  const isSmall = useMedia({ maxWidth: 480 });
  function closeModal() {
    props.setIsOpen(false);
  }

  const [currentCliente, setCurrentCliente] = useState<Cliente | undefined>();
  const [searchFor, setSearchFor] = useState('');
  const [clientes, setClientes] = useState<Cliente[]>([]);
  const [loading, setLoading] = useState(false);
  const [skip, setSkip] = useState(0);
  const [qtdeReg, setQtdeReg] = useState(0);
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (props.isOpen) {
      new Promise((t) => setTimeout(t, 100)).then(
        () => ref.current && ref.current.focus()
      );
      console.log('props.cliente', props.cliente);
      setCurrentCliente(props.cliente);
    }
  }, [props.cliente, props.isOpen]);

  useEffect(() => {
    if (searchFor.length > 2) {
      setLoading(true);
      getClientes(searchFor, 10, skip).then((data) => {
        setLoading(false);
        setQtdeReg(data.info.qtdeTotal);
        setClientes(data.clientes);
      });
    } else {
      setClientes([]);
      setSkip(0);
      setQtdeReg(0);
    }
  }, [searchFor, skip]);

  return (
    <div>
      <Transition appear show={props.isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={closeModal}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black opacity-20" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-5xl p-0 my-1 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <Dialog.Title
                  as="h3"
                  className="flex justify-between mb-2 bg-primary2 text-default-white px-4 py-2"
                >
                  <span className="text-lg font-bold leading-6 font-display">
                    Selecionar Cliente
                  </span>
                  <button onClick={closeModal} className="hover:text-gray-400">
                    <XIcon className="w-5 h-5" />
                  </button>
                </Dialog.Title>
                <div className="flex flex-col font-display p-2">
                  <div className="flex justify-between space-x-1 items-center">
                    <label className="relative block">
                      <span className="sr-only">Busca</span>
                      <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-5 w-5 stroke-gray-400"
                          viewBox="0 0 20 20"
                          fill="none"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                          />
                        </svg>
                      </span>
                      <input
                        className="placeholder:italic placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-gray-300 focus:ring-gray-300 focus:ring-1 sm:text-sm"
                        placeholder="Buscar por nome..."
                        type="search"
                        value={searchFor}
                        onChange={(e) => setSearchFor(e.target.value)}
                        ref={ref}
                      />
                    </label>
                    {currentCliente && (
                      <label className="relative block">
                        {currentCliente ? (
                          <div className="flex gap-2 items-center">
                            <span>{currentCliente.nome}</span>
                            {props.onClear && (
                              <button
                                className="rounded-full hover:bg-gray-300 p-1 bg-gray-200 text-gray-500 hover:text-gray-700"
                                onClick={() => {
                                  props.onClear && props.onClear();
                                  setSearchFor('');
                                  closeModal();
                                }}
                              >
                                <XIcon className="w-5 h-5" />
                              </button>
                            )}
                          </div>
                        ) : (
                          <span>Nenhum cliente selecionado</span>
                        )}
                      </label>
                    )}
                  </div>
                  {isSmall ? (
                    loading ? (
                      <div className="flex flex-col items-center justify-center h-full">
                        Aguarde ...
                      </div>
                    ) : (
                      <div className="flex flex-col space-y-2">
                        <div className="h-[35vh] mt-2 overflow-hidden overflow-y-auto">
                          {clientes.map((item) => (
                            <div
                              key={item.id}
                              className="border-b-[1px] border-solid border-gray-300 even:bg-gray-100 hover:bg-gray-300 cursor-pointer py-3"
                              onClick={() => {
                                props.onSelect(item);
                                closeModal();
                              }}
                            >
                              <div className="px-1 py-0 flex justify-between">
                                <div className="text-md text-gray-900 text-left font-bold">
                                  {item.nome}
                                </div>
                              </div>
                              <div className="px-1 py-0 flex justify-between">
                                <span className="text-sm text-gray-900 text-left">
                                  Apelido:
                                </span>
                                <div className="text-sm text-gray-900 text-left">
                                  {item.apelido}
                                </div>
                              </div>
                              <div className="px-1 py-0 flex justify-between">
                                <span className="text-sm text-gray-900 text-left">
                                  CPF/CNPJ:
                                </span>
                                <div className="text-sm text-gray-900 text-left">
                                  {item.cpf_cnpj}
                                </div>
                              </div>
                              <div className="px-1 py-0 flex justify-between">
                                <span className="text-sm text-gray-900 text-left">
                                  Cel:
                                </span>
                                <div className="text-sm text-gray-900 text-left">
                                  {item.foneNumero}
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                        {clientes.length > 9 && (
                          <AppPaginator
                            qtdeReg={qtdeReg}
                            curPage={skip}
                            perPage={10}
                            handlePageClick={({ selected }) => {
                              setSkip(selected);
                            }}
                          />
                        )}
                      </div>
                    )
                  ) : (
                    <div className="h-[45vh] mt-2 overflow-hidden overflow-y-auto">
                      {loading ? (
                        <div className="flex flex-col items-center justify-center h-full">
                          Aguarde ...
                        </div>
                      ) : (
                        <div className="flex flex-col space-y-2">
                          <table className="w-full divide-y divide-gray-200">
                            <thead className="bg-gray-100 sticky top-0 z-10">
                              <tr>
                                <th
                                  scope="col"
                                  className="px-1 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                  Nome
                                </th>
                                <th
                                  scope="col"
                                  className="px-1 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                  Apelido
                                </th>
                                <th
                                  scope="col"
                                  className="px-1 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                  Cidade
                                </th>
                                <th
                                  scope="col"
                                  className="px-1 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                  CPF/CNPJ
                                </th>
                                <th
                                  scope="col"
                                  className="px-1 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                  Contato
                                </th>
                              </tr>
                            </thead>
                            <tbody className="">
                              {clientes.length > 0 &&
                                clientes.map((item) => (
                                  <tr
                                    key={item.id}
                                    className="border-b-[1px] border-solid border-gray-300 even:bg-gray-100 hover:bg-gray-300 cursor-pointer"
                                    onClick={() => {
                                      setCurrentCliente(item);
                                      props.onSelect(item);
                                      closeModal();
                                    }}
                                  >
                                    {/* item */}
                                    <td className="px-1 py-0">
                                      <div className="text-sm text-gray-900 text-left">
                                        {item.nome}
                                      </div>
                                    </td>
                                    <td className="px-1 py-0">
                                      <div className="text-sm text-gray-900 text-left">
                                        {item.apelido}
                                      </div>
                                    </td>
                                    <td className="px-1 py-0">
                                      <div className="text-sm text-gray-900 text-left">
                                        {item?.enderecos[0]?.cidade || 'N/D'}
                                      </div>
                                    </td>
                                    <td className="px-1 py-0">
                                      <div className="text-sm text-gray-900 text-left">
                                        {item.cpf_cnpj}
                                      </div>
                                    </td>
                                    <td className="px-1 py-0">
                                      <div className="text-sm text-gray-900 text-left">
                                        {item.foneNumero}
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                          {clientes.length > 9 && (
                            <AppPaginator
                              qtdeReg={qtdeReg}
                              curPage={skip}
                              perPage={10}
                              handlePageClick={({ selected }) => {
                                setSkip(selected);
                              }}
                            />
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}
