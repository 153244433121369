import clsx from 'clsx';
import { ReactComponent as LoadingSVG } from '../assets/svg/loading.svg';

export default function LoadingComponent() {
  return (
    <div className={clsx('flex justify-center items-center h-full w-full')}>
      <LoadingSVG className="w-8 h-8 text-slate-400 animate-spin" />
    </div>
  );
}
