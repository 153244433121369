import React from 'react';
import { ProdutoCart } from '../../app.models';
import { useApp } from '../../app.hook';
import { parseCurrency } from '../../utils/numberHandler';
import clsx from 'clsx';
// import { Popover, Transition } from '@headlessui/react';

interface Props {
  produto: ProdutoCart;
}

export default function ExibirPrecoPromocaoConsumidorCarrinho({
  produto,
}: Props) {
  const { showPrecoConsumidorFinal } = useApp();

  const [preco, setPreco] = React.useState<number>(0);

  const precoFormatado = React.useMemo(() => {
    return parseCurrency(preco);
  }, [preco]);

  // efeitos
  React.useEffect(() => {
    if (produto) {
      if (showPrecoConsumidorFinal) {
        setPreco(produto.produto.precoPromoConsumidorFinal || 0);
      } else {
        setPreco(produto.produto.precoPromo || 0);
      }
    }
  }, [produto, showPrecoConsumidorFinal]);

  return (
    <>
      <div
        className={clsx(
          'text-sm line-through px-2 py-1 rounded-md',
          showPrecoConsumidorFinal
            ? 'text-zinc-500 hover:text-green-700'
            : 'text-zinc-800 hover:bg-zinc-200 hover:text-zinc-700'
        )}
      >
        {precoFormatado}
      </div>
      {/* <Popover className="relative">
        {({ open }) => (
          <>
            <Popover.Button
              className={clsx(
                open && 'grayscale',
                'text-sm line-through px-2 py-1 rounded-md',
                showPrecoConsumidorFinal
                  ? 'text-zinc-500 hover:bg-green-200 hover:text-green-700'
                  : 'text-zinc-800 hover:bg-zinc-200 hover:text-zinc-700'
              )}
            >
              {precoFormatado}
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute left-36 z-10 -top-20 w-screen max-w-fit -translate-x-1/2 transform px-4 sm:px-0">
                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5 bg-white py-2 px-4 flex flex-col gap-1">
                  <span className="text-xs text-slate-600 whitespace-nowrap">
                    Preço Promocional Custo:
                  </span>
                  <span className="">{parseCurrency(produto.preco || 0)}</span>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover> */}
    </>
  );
}
