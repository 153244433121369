/* eslint-disable prettier/prettier */
import api from '../../axios';
import { Info, Pedido, ProdutoCart } from '../../app.models';
import { getCryptoLocalStorage } from '../../app.service';
import { LOCAL_STORAGE_DEFAUT_CLI } from '../../app.settings';
import { convertImages } from '../../utils/functions';
// import format from 'date-fns/format';
// import { ptBR } from 'date-fns/locale';
// import { LOCAL_STORAGE_DEFAUT_CLI } from '../../app.settings';
// import { getCryptoLocalStorage } from '../../app.service';

interface SearchPedidos {
  info: Info;
  pedidos: Pedido[];
}

export interface ICotacao {
  cotacaoId: number;
  updated_at: Date;
}

export interface IProduto {
  produtoId: number;
  qtde: number;
  unitario: number;
  desc1: number;
  desc2: number;
  desc3: number;
  descGT: number;
  descMaxClasse: number;
  unitarioLiquido: number;
}

export interface ITotais {
  qtdeTotal: number;
  produtos: number;
  frete: number;
  total: number;
}

export interface IPedido {
  cotacoes: ICotacao[];
  produtos: IProduto[];
  totais: ITotais;
  obs: string;
  cliente: number;
  tabelaPrecos: number;
  fPagto: number;
  cPagto: number;
  transportadora: number;
  vendedor?: number | undefined;
}

export interface IPedidoAdd {
  pedido: IPedido;
}

type OrderParams = {
  searchFor?: string;
  situacao?: string;
  compradorId?: number;
  dataI?: Date;
  dataF?: Date;
  take?: number;
  skip?: number;
  sortBy?: string;
  ord?: number;
  defaultCli?: string;
};

export const getPedido = async (pedidoId: number): Promise<Pedido> => {
  const urlBase = '/pedidos/' + pedidoId;
  return (await api.get(urlBase)).data;
};

export async function getPedidos(
  params: OrderParams,
  apenasAprovados = false
): Promise<SearchPedidos> {
  const urlBase = '/pedidos/?';
  const urlWithParams = [urlBase];
  // const defaultCli = getCryptoLocalStorage(LOCAL_STORAGE_DEFAUT_CLI);

  urlWithParams[0] = urlWithParams[0].concat(`cliente=${params.defaultCli}`);

  if (params.searchFor) {
    urlWithParams[0] = urlWithParams[0].concat(
      `&searchFor=${params.searchFor}`
    );
  }

  if (params.dataI)
    urlWithParams[0] = urlWithParams[0].concat(`&dataI=${new Date(params.dataI).toISOString().slice(0, 10).replace('T', ' ')}`);

  if (params.dataF)
    urlWithParams[0] = urlWithParams[0].concat(`&dataF=${new Date(params.dataF).toISOString().slice(0, 10).replace('T', ' ')}`);

  if (apenasAprovados === false) {
    if (params.situacao)
      urlWithParams[0] = urlWithParams[0].concat(
        `&situacao=${params.situacao}`
      );
    if (params.compradorId)
      urlWithParams[0] = urlWithParams[0].concat(
        `&comprador=${params.compradorId}`
      );
    if (params.take)
      urlWithParams[0] = urlWithParams[0].concat(`&take=${params.take}`);
  } else {
    urlWithParams[0] = urlWithParams[0].concat(`&situacao=1`);
    urlWithParams[0].concat(`&take=${-1}`);
  }

  if (params.skip)
    urlWithParams[0] = urlWithParams[0].concat(`&skip=${params.skip}`);

  if (params.sortBy)
    urlWithParams[0] = urlWithParams[0].concat(`&sortBy=${params.sortBy}`);

  if (params.ord)
    urlWithParams[0] = urlWithParams[0].concat(`&ord=${params.ord}`);

  urlWithParams[0] = urlWithParams[0].concat(`&take=12`);

  return (await api.get(urlWithParams[0])).data;
}

export const getCotacoes = async (
  cotacoesIds: string,
  tabelaId = ''
): Promise<ProdutoCart[]> => {
  // if (!cotacoesIds) {
  //   toast.info('Sem carrinhos selecionados');
  //   return [];
  // }
  const tabId =
    tabelaId || getCryptoLocalStorage(LOCAL_STORAGE_DEFAUT_CLI)?.tabelaId;

  const clienteId = getCryptoLocalStorage(LOCAL_STORAGE_DEFAUT_CLI)?.clienteId;
  const url = `/produtos/cotacoes?cotacoesIds=${cotacoesIds}&clienteId=${clienteId}&tabelaId=${tabId}&sortBy&ord=0`;
  const data = (await api.get(url)).data.produtos;
  console.log('🚀 ~ file: pedido.service.ts ~ line 131 ~ data', data);
  const ret = data.map((pc: ProdutoCart) => ({
    ...pc,
    images:
      pc.images.length > 0
        ? convertImages(pc.images, pc.id, 'produtos')
        : [
          {
            small: 'https://via.placeholder.com/100?text=Sem Imagem',
            medium: 'https://via.placeholder.com/300?text=Sem Imagem',
            big: 'https://via.placeholder.com/600?text=Sem Imagem',
          },
        ],
  }));
  // console.log('🚀 ~ file: pedido.service.ts ~ line 145 ~ ret ~ ret', ret);

  return ret;
};

export const addPedido = async (pedido: IPedidoAdd) => {
  const url = '/pedidos';
  return (await api.post(url, pedido)).data;
};

export const retransmitirPedido = async (pedido: number) => {
  const url = `/pedidos/${pedido}/retransmitir`;
  return (await api.post(url, pedido)).data;
};
