import { Dialog } from '@headlessui/react';
import { AnnotationIcon, XIcon } from '@heroicons/react/outline';
import { useEffect, useState } from 'react';
import CondFormaTransp from '../../components/CondFormaTransp';
import Button from '../../components/layout/button';
import { parseCurrencyBRL } from '../../utils/numberHandler';
import ListBoxSelectComp from '../../components/ListBoxSelectComp';
import { getVendedores } from '../../app.service';
import { Vendedor } from '../../app.models';
import { useApp } from '../../app.hook';

interface ModalSendOrderProps {
  isOpen: boolean;
  resume: {
    qtde: number;
    total: number;
    frete: number;
  };
  vendedor: number;
  setIsOpen: (open: boolean) => void;
  action: (
    obs: string,
    fpagto: number,
    cpagto: number,
    transportadora: number,
    vendedor: number | undefined
  ) => void;
}

export default function ModalSendOrder(props: ModalSendOrderProps) {
  function closeModal() {
    props.setIsOpen(false);
  }
  const { empresa } = useApp();

  const [obs, setObs] = useState<string>('');
  const [condicao, setCondicao] = useState<number>();
  const [forma, setForma] = useState<number>();
  const [transportadora, setTransportadora] = useState<number>();
  const [error, setError] = useState<'forma' | 'cond' | 'transp' | undefined>();
  const [vendedores, setVendedores] = useState<{ id: number; desc: string }[]>(
    []
  );
  const [vendedor, setVendedor] = useState<number | undefined>(props.vendedor);

  useEffect(() => {
    setForma(undefined);
    setCondicao(undefined);
    setTransportadora(undefined);

    getVendedores().then((res: Vendedor[]) => {
      const mapping: Vendedor[] = res.filter((v: Vendedor) => v.isVisible);
      const vendedores = mapping
        .filter((v) => v.isVisible)
        .map((v: Vendedor) => ({
          id: v.id,
          desc: v.nome,
        }));
      setVendedores(vendedores);
    });
  }, []);

  const sendOrder = () => {
    if (!forma) {
      setError('forma');
      new Promise((t) => setTimeout(t, 500)).then(() => setError(undefined));
      return;
    }

    if (!condicao) {
      setError('cond');
      new Promise((t) => setTimeout(t, 500)).then(() => setError(undefined));
      return;
    }

    if (!transportadora) {
      setError('transp');
      new Promise((t) => setTimeout(t, 500)).then(() => setError(undefined));
      return;
    }

    // props.action(obs, condicao, forma, transportadora);
    props.action(obs, forma, condicao, transportadora, vendedor);

    setForma(undefined);
    setCondicao(undefined);
    setTransportadora(undefined);
    closeModal();
  };

  return (
    <Dialog
      as="div"
      className="fixed inset-0 z-10 overflow-y-auto font-display"
      onClose={closeModal}
      open={props.isOpen}
    >
      <div className="min-h-screen px-4 text-center">
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-20" />

        {/* This element is to trick the browser into centering the modal contents. */}
        <span className="inline-block h-screen align-middle" aria-hidden="true">
          &#8203;
        </span>

        <div className="inline-block w-[45vw] p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl border-[1px] border-solid border-gray-300">
          <Dialog.Title as="h3" className="flex justify-between">
            <span className="text-lg font-bold leading-6 text-red-900 font-display">
              Enviar Pedido
            </span>
            <button
              onClick={() => props.setIsOpen(false)}
              className="hover:text-gray-400"
            >
              <XIcon className="w-5 h-5" />
            </button>
          </Dialog.Title>

          <div className="px-2 flex flex-col space-y-2 my-2">
            <div className="w-full bg-gray-100 p-1">
              <div className="px-2 text-gray-500 flex justify-between">
                <span className="text-xl">Quantidade</span>
                <span className="font-bold text-xl">{props.resume.qtde}</span>
              </div>
              <div className="px-2 text-gray-500 flex justify-between">
                <span className="text-xl">Produtos</span>
                <span className="font-bold text-xl">
                  {parseCurrencyBRL(props.resume.total)}
                </span>
              </div>
              <div className="px-2 text-gray-500 flex justify-between ">
                <span className="text-xl">Frete</span>
                <span className="font-bold text-xl">
                  {parseCurrencyBRL(props.resume.frete)}
                </span>
              </div>
              <div className="border-b-[1px] border-solid border-gray-300"></div>
              <div className="px-2 text-gray-500 flex justify-between ">
                <span className="text-xl">Total</span>
                <span className="font-bold text-xl">
                  {parseCurrencyBRL(props.resume.frete + props.resume.total)}
                </span>
              </div>
            </div>

            {empresa.ocultarDetalhesFechamentoPedido ? null : (
              <div className="z-20">
                <CondFormaTransp
                  direction="row"
                  error={error}
                  valorTotal={props.resume.total}
                  condicao={condicao}
                  selectedCondicao={(condicao: number | undefined): void => {
                    setCondicao(condicao);
                  }}
                  forma={forma}
                  selectedForma={(forma: number | undefined): void => {
                    setForma(forma);
                  }}
                  transportadora={transportadora}
                  selectedTransportadora={(
                    transportadora: number | undefined
                  ): void => {
                    setTransportadora(transportadora);
                  }}
                />
              </div>
            )}
            <div className="z-10">
              <ListBoxSelectComp
                title="Vendedor"
                elements={vendedores}
                initial={props.vendedor ?? 0}
                action={setVendedor}
              />
            </div>
          </div>

          <div className="w-full">
            <label className="text-sm font-medium text-gray-700 flex space-x-2 items-center">
              <AnnotationIcon className="w-6 h-6" /> <span>Observações:</span>
            </label>
            <div className="mt-1 relative rounded-md shadow-sm">
              <textarea
                className="z-0 bg-gray-200 focus:ring-red-500 focus:border-red-500 block w-full p-3 sm:text-sm border-gray-300 rounded-md"
                rows={3}
                value={obs}
                onChange={(e) => setObs(e.target.value)}
              ></textarea>
            </div>
          </div>

          <div className="font-display flex justify-between mt-1">
            <Button
              title="Voltar"
              color="bg-gray-500 text-white hover:bg-gray-400 hover:text-gray-100"
              onClick={closeModal}
            />
            <Button
              title="Enviar Pedido"
              color="bg-green-500 text-white hover:bg-green-400 hover:text-gray-100"
              onClick={sendOrder}
            />
          </div>
        </div>
      </div>
    </Dialog>
  );
}
