import clsx from 'clsx';
import { IResponseCotacoesPorProduto } from '../service';
import { useEffect, useState } from 'react';
import { SearchCircleIcon } from '@heroicons/react/outline';
import AppPaginator from '../../../components/layout/pagination';
import AppPerPage from '../../../components/layout/appPerPage';
import { ReactComponent as SemProduto } from '../../../assets/svg/caixa_vazia.svg';

interface Props {
  items: IResponseCotacoesPorProduto[];
  limit?: number;
  onRowClick?: (item: IResponseCotacoesPorProduto) => void;
  filtered?: boolean;
  clearFilter?: () => void;
  verTudo?: () => void;
  close?: () => void;
  heightClass?: string;
  paginated?: boolean;
}

const InnerGridProdutoHanking = ({
  items,
  limit,
  onRowClick,
  filtered = false,
  clearFilter,
  verTudo,
  close,
  heightClass = 'h-full',
  paginated = false,
}: Props) => {
  const [curPage, setCurPage] = useState<number>(0);
  const [perPage, setPerPage] = useState<number>(15);

  const [rows, setRows] = useState<IResponseCotacoesPorProduto[]>([]);
  const grid = 'w-full grid grid-cols-[1.5fr_10fr_2fr_3fr_2fr]';
  const header = clsx(
    grid,
    'border-b-[1px] border-gray-200 border-solid p-2',
    'bg-gray-100 text-black',
    'h-10'
  );

  const [filter, setFilter] = useState('');

  const body = clsx(
    grid,
    'border-b-[1px] border-gray-200 border-solid p-2',
    'hover:bg-gray-200 cursor-pointer',
    'h-10'
  );

  function rowClick(row: IResponseCotacoesPorProduto) {
    onRowClick && onRowClick(row);
  }

  useEffect(() => {
    if (limit) setRows(items.slice(0, limit));
    else setRows(items);
  }, [items, limit]);

  const total = items.reduce((acc, item) => acc + item.total, 0);

  const currentRows = rows.filter((row) =>
    filter
      ? row.descricaoSite
          .concat(' ', row.codigoSgi, ' ', row.produtoId.toString())
          .replace(/[^a-zA-Z0-9]/g, '')
          .toLocaleLowerCase()
          .includes(filter.toLowerCase().replace(/[^a-zA-Z0-9]/g, ''))
      : true
  );

  const rowsToDisplay = currentRows.slice(
    curPage * perPage,
    curPage * perPage + perPage
  );

  const showVerTudo = limit && limit < items.length;

  if (items.length === 0) {
    return (
      <div
        className={clsx(
          'grid grid-row-[1fr_20fr_1fr]  overflow-hidden rounded-lg bg-white shadow-md border border-slate-200 border-solid',
          heightClass ?? 'min-h-full',
          'bg-gradient-to-tr from-slate-50 to-slate-100'
        )}
      >
        <div className="flex flex-col justify-center items-center">
          <SemProduto className="w-32 h-32 opacity-70" />
          <h1 className="text-slate-400">Sem produtos no período</h1>
        </div>
      </div>
    );
  }

  return (
    <div
      className={clsx(
        'grid grid-row-[1fr_30fr_1fr] overflow-hidden rounded-lg bg-white shadow-md border border-slate-200 border-solid',
        heightClass ?? 'h-[90vh]'
      )}
    >
      <div className={clsx(header, 'px-1')}>
        <div className="text-xs">Cod</div>
        <div className="text-xs">Produto</div>
        <div className={clsx('text-xs text-right')}>Qtde</div>
        <div className={clsx('text-xs text-center')}>Total</div>
        <div className={clsx('text-xs text-right px-1')}>(%)</div>
      </div>
      <div className={clsx('overflow-hidden overflow-y-auto min-h-[15em]')}>
        {rowsToDisplay.map((item, index) => (
          <div
            key={item.produtoId}
            className={body}
            onClick={() => {
              rowClick(item);
            }}
          >
            <div className="text-xs">{item.codigoSgi}</div>

            <div className="text-[0.7em] text-black">{item.descricaoSite}</div>
            <div className={clsx('text-xs text-gray-600 text-right')}>
              {Number(item.qtde).toLocaleString('pt-br', {
                style: 'decimal',
                maximumFractionDigits: 0,
              })}
            </div>
            <div className={clsx('text-xs text-gray-600 text-right')}>
              {Number(item.total).toLocaleString('pt-br', {
                style: 'decimal',
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              })}
            </div>
            <div className={clsx('text-xs text-gray-600 text-right')}>
              {Number((item.total / total) * 100).toLocaleString('pt-br', {
                style: 'decimal',
                maximumFractionDigits: 2,
              })}
            </div>
          </div>
        ))}
      </div>
      <div className="flex items-center bg-slate-100 h-10 p-1">
        {filtered ? (
          <button
            className="hover:underline text-xs text-red-700"
            onClick={() => clearFilter?.()}
          >
            Limpar filtro
          </button>
        ) : (
          verTudo &&
          showVerTudo && (
            <button
              className="hover:underline text-xs text-red-700"
              onClick={verTudo}
            >
              Ver todos
            </button>
          )
        )}
        {!limit && (
          <div className="flex items-center ml-2 gap-2">
            <div className="text-xs text-gray-600 flex items-center gap-1">
              <SearchCircleIcon className="w-6 h-6" />
            </div>
            <input
              placeholder="Filtrar código ou descrição"
              className="ml-1 text-xs w-56 px-2 py-1 border border-gray-300 border-solid rounded-md"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            />
            <div className="text-xs text-slate-500">
              Qtde: {currentRows.length}
            </div>
          </div>
        )}
        {paginated && (
          <div className="px-2 flex gap-2">
            <AppPerPage
              take={perPage}
              setTake={(perPage) => {
                setCurPage(0);
                setPerPage(perPage);
              }}
              listPerPage={[15, 25, 50, 100]}
            />
            <AppPaginator
              qtdeReg={currentRows.length}
              perPage={perPage}
              curPage={curPage}
              handlePageClick={(page) => setCurPage(page.selected)}
            />
          </div>
        )}
        {close && (
          <button
            className="hover:underline text-xs text-red-700 ml-auto"
            onClick={close}
          >
            Fechar
          </button>
        )}
      </div>
    </div>
  );
};

export default InnerGridProdutoHanking;
