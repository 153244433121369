import { ButtonHTMLAttributes, forwardRef } from 'react';
import { classNames } from '../../utils/functions';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: JSX.Element;
  title: string;
  color?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  full?: any;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Button = forwardRef((props: ButtonProps, ref: any) => {
  return (
    <button
      ref={ref}
      className={classNames(
        'disabled:bg-gray-500 disabled:text-white',
        'flex items-center rounded-2xl',
        'space-x-1',
        'py-2 px-4',
        'drop-shadow-md',
        props.full ? 'w-full' : '',
        props.color
          ? props.color
          : 'bg-primary2 text-default-white hover:bg-primary1'
      )}
      {...props}
    >
      {props.icon && <span className="">{props.icon}</span>}
      <span className="w-full text-center">{props.title}</span>
    </button>
  );
});

export default Button;
