import useMedia from 'use-media';
import LazyLoadingImage from '../../components/lazyLadingImage';
import { classNames } from '../../utils/functions';

interface UploadDisplayProps {
  title: string;
  subTitle?: string | null;
  img: string;
  onClick: () => void;
}

const UploadDisplay: React.FC<UploadDisplayProps> = ({
  title,
  subTitle,
  img,
  onClick,
}: UploadDisplayProps) => {
  const isSmall = useMedia({ maxWidth: 480 });
  if (isSmall)
    return (
      <button
        className={classNames(
          'flex flex-col mx-auto w-full p-2 m-2 group ring-2 md:hover:ring-primary1 ring-gray-300 bg-white rounded-md'
        )}
        onClick={onClick}
      >
        <LazyLoadingImage
          classNames={['mx-auto']}
          alt={title}
          height={150}
          width={150}
          src={img + '?time=' + new Date().getTime()}
          efect="blur"
        />
        <span className="text-md line-clamp-2 group-hover:line-clamp-4 w-full md:w-[150px] font-bold">
          {title}
        </span>
        <span className="text-xs line-clamp-2 group-hover:line-clamp-4 w-full md:w-[150px] ">
          {subTitle}
        </span>
      </button>
    );

  return (
    <button
      className={classNames(
        'flex flex-col m-2 p-1 group ring-2 ring-gray-300 hover:ring-primary1 bg-white rounded-md'
      )}
      onClick={onClick}
    >
      <LazyLoadingImage
        classNames={['mx-auto']}
        alt={title}
        height={150}
        width={150}
        src={img + '?time=' + new Date().getTime()}
        efect="blur"
      />
      <span className="text-md line-clamp-2 group-hover:line-clamp-4 w-[150px] font-bold">
        {title}
      </span>
      <span className="text-xs line-clamp-2 group-hover:line-clamp-4 w-full md:w-[150px] ">
        {subTitle}
      </span>
    </button>
  );
};

export default UploadDisplay;
