import { useEffect, useState } from 'react';
import { useApp } from '../../app.hook';
import { Marca, Modelo } from '../../app.models';
import { arrayGroupBy, classNames } from '../../utils/functions';
import { Disclosure } from '@headlessui/react';
import { useProduct } from './product.hook';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ROUTES_MAP } from '../../app.map.routes';
// import FiltersPromotions from './filtersPromotions';
import Display from './display';
import { getTake } from '../../utils/functions';
import FiltersText from './filtersText';
import { EnumOrigens } from '../../utils/origens';
import MultiSearch from '../../components/MultiSearch';
import MultiSearchSimple from '../../components/MultiSearchSimple';

interface MarcasModelos {
  marca: Marca;
  modelos: Modelo[];
}

export default function ProductsPromocoesFilter() {
  // const isSmall = useMedia({ maxWidth: 480 });
  const { modelos, pesquisasTopBar } = useApp();
  const {
    searchModelo,
    selectedSearchedModelo,
    setSelectedSearchedModelo,
    setOrigem,
    setSelectedModelo,
    setSelectedMarca,
  } = useProduct();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [filteredMarcas, setFilteredMarcas] = useState<MarcasModelos[]>([]);
  const [search, setSearch] = useState<string>();

  const _take = getTake();

  useEffect(() => {
    if (!search) {
      setFilteredMarcas([]);
    }
  }, [search]);

  // const backToSearch = () => {
  //   navigate(ROUTES_MAP.product + '?busca=' + searchParams.get('busca'));
  // };

  useEffect(() => {
    if (!searchModelo) {
      setFilteredMarcas([]);
      return;
    }
    setSearch(searchModelo);
    if (searchModelo.length < 3) {
      // alterar quando as promoções estiverem ativas
      setFilteredMarcas([]);
    }
    const find = searchModelo
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '') // Remove acentos
      .replace(/([^a-zA-z0-9]+)/g, '') // Substitui espaço e outros caracteres por hífen
      .replace(/\-\-+/g, '-') // Substitui multiplos hífens por um único hífen
      .replace(/(^-+|-+$)/, '')
      .toLocaleLowerCase();
    const filtered: Modelo[] = modelos.filter(
      (e) =>
        e.modeloDescPesquisa.toLowerCase().match(new RegExp(`${find}`, 'igm'))
          ?.length || 0 > 0
    );

    const arrayGroup = arrayGroupBy(filtered, 'marcaRaw.marcaDesc');
    const group = Object.entries(arrayGroup).map(([key, value]) => ({
      marca: key,
      modelos: value,
    }));

    setFilteredMarcas(group as unknown as MarcasModelos[]);
  }, [modelos, searchModelo]);

  // const handleSearchModelo = () => {
  //   if (!search) {
  //     navigate(`${ROUTES_MAP.product}/?busca=`);
  //     return;
  //   }
  //   navigate(`${ROUTES_MAP.product}/?busca=${search}`);
  // };

  if (
    searchParams.has('busca') &&
    searchParams.has('modelo') &&
    selectedSearchedModelo
  )
    return (
      <div className="flex flex-col bg-white">
        {/* <div className="bg-slate-200 px-1 py-2 flex justify-start space-x-4 items-center">
          <button
            type="button"
            title="voltar"
            className="bg-primary1 hover:opacity-70 p-1 m-1 text-default-white rounded-lg flex items-center space-x-2"
            onClick={backToSearch}
          >
            <ReplyIcon className="h-6 w-6" /> <span>Voltar</span>
          </button>
          <span className="text-lg">
            {modelos.find((el) => el.id === selectedSearchedModelo)?.modeloDesc}
          </span>
        </div> */}
        <div className="md:h-[88vh] overflow-hidden overflow-y-auto bg-white">
          <FiltersText />
        </div>
      </div>
    );

  return (
    <div className="flex flex-col md:h-[88vh] bg-white">
      {!pesquisasTopBar && (
        <div className="flex justify-center">
          {/* <TabSerach /> */}
          {/* <MultiSearch /> */}
          <MultiSearchSimple />
        </div>
      )}
      <div className="grid grid-cols-1 md:h-full overflow-hidden overflow-y-auto py-4">
        {filteredMarcas.length > 0 ? (
          filteredMarcas.map((e, i) => (
            <div key={i} className="flex flex-col space-y-2">
              <Disclosure defaultOpen={true}>
                {({ open }) => (
                  <>
                    <Disclosure.Button
                      className={classNames('px-2 py-1 sticky -top-5 z-[1]')}
                    >
                      <div className="flex space-x-1 p-2 text-gray-100 text-md font-bold bg-slate-500">
                        <span className={`p-0 `}>{open ? '-' : '+'}</span>
                        <span className="">{e.marca}</span>
                      </div>
                    </Disclosure.Button>
                    <Disclosure.Panel>
                      <div className="flex flex-wrap">
                        {e.modelos &&
                          e.modelos.length > 0 &&
                          e.modelos.map((m) => (
                            <Display
                              key={m.id}
                              id={m.id}
                              selected={selectedSearchedModelo}
                              title={m.modeloDesc}
                              subTitle={`Ano: ${m.anoI} - ${m.anoF}`}
                              img={m.images[0].img}
                              onClick={() => {
                                setSelectedModelo(m);
                                setSelectedMarca(m.marcaRaw);
                                setOrigem(EnumOrigens.MARCA_MODELO);
                                setSelectedSearchedModelo(undefined);
                                const route = `${
                                  ROUTES_MAP.product
                                }/?busca=${search}&modelo=${
                                  m.id
                                }&take=${_take.toString()}&skip=0&tags={"modelos":[${
                                  m.id
                                }]}&filtros=`;
                                navigate(route);

                                // setSelectedTabTipoPosition(refPai?.scrollTop || 0);
                                // setSelectedModelo(m);
                                new Promise((t) => setTimeout(t, 0)).then(
                                  () => {
                                    setSelectedSearchedModelo(m.id);
                                  }
                                );
                              }}
                            />
                          ))}
                      </div>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            </div>
          ))
        ) : (
          <div className="flex flex-col justify-center items-center h-full">
            {searchParams.get('busca') !== ''
              ? 'Nenhum resultado encontrado'
              : 'Pesquisar veículo'}
          </div>
        )}
        {/* {selectedSearchedModelo && <FiltersPromotions />} */}
      </div>
    </div>
  );
}
