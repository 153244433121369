import { useEffect, useState } from 'react';
import { classNames } from '../utils/functions';

interface ProgressProps {
  qtdeTotal: number;
  qtdeMovimentada: number;
  qtdeDisponivel: number;
}

export function Progress(props: ProgressProps) {
  const [percent, setPercent] = useState(0);

  useEffect(() => {
    const { qtdeTotal, qtdeMovimentada } = props;
    if (!qtdeTotal) return;
    const movimentada = qtdeMovimentada || 0;
    const percent = Math.floor((movimentada * 100) / qtdeTotal);
    setPercent(percent);
  }, [props]);

  return (
    <div className="flex flex-col w-full">
      <div
        className={classNames(
          'w-full h-1 rounded-lg overflow-hidden',
          'border-[0.5px] border-gray-300 border-solid'
        )}
      >
        {percent > 0 && (
          <div
            style={{ width: percent.toString().concat('%') }}
            className={`h-full bg-primary1`}
          ></div>
        )}
      </div>
      <div className="flex justify-between">
        <span className="text-xs text-gray-600">{`${
          percent > 0 ? percent + '%' : ''
        }`}</span>
        <span className="text-xs text-gray-600">{`${
          props.qtdeDisponivel > 1
            ? props.qtdeDisponivel + ' restantes'
            : props.qtdeDisponivel + ' restante'
        }`}</span>
      </div>
    </div>
  );
}
