import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { LOCAL_STORAGE_PREFIX } from '../../app.settings';

const DEFAULT = [50, 100];

interface PerPageProps {
  listPerPage?: number[];
  storageLocalName: string;
  take: number;
}

export default function PerPage(props: PerPageProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [listPerPage, setListPerPage] = useState<number[]>([]);

  useEffect(() => {
    if (props.listPerPage) {
      setListPerPage(props.listPerPage);
    } else {
      setListPerPage(DEFAULT);
    }
  }, [props.listPerPage]);

  // if (listPerPage.includes(take))
  return (
    <select
      className="py-[6px] px-2 rounded-md text-xs shadow ring-1 ring-gray-300"
      value={props.take}
      onChange={(e) => {
        const value = e.target.value;
        const params = searchParams;
        params.set('take', value);
        params.set('skip', String(0));
        localStorage.setItem(
          LOCAL_STORAGE_PREFIX + props.storageLocalName,
          value
        );
        setSearchParams(params);
      }}
    >
      {listPerPage.map((e, i) => (
        <option key={i} value={e}>
          {e} por página
        </option>
      ))}
      {/* <option value={200}>200 por página</option> */}
    </select>
  );
  // else return null;
}
