import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { Fragment } from 'react';
import Button from './layout/button';
import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';
import { LOCAL_STORAGE_JWT } from '../app.settings';

interface ModalUploadProps {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  title: string;
  uploadInfo?: string;
  pathUrl: string;
  deleteAction?: () => void;
  autoRelaod?: boolean;
  afterUpload?: () => void;
}

export default function ModalUploadDropZone(props: ModalUploadProps) {
  function closeModal() {
    props.setIsOpen(false);
  }

  // specify upload params and url for your files
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getUploadParams = ({ file }: any) => {
    const body = new FormData();
    body.append('file', file);
    return {
      url: props.pathUrl,
      headers: {
        Authorization: `Bearer ${localStorage.getItem(LOCAL_STORAGE_JWT)}`,
      },
      body,
    };
  };

  // receives array of files that are done uploading when submit button is clicked
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChangeStatus = ({}: any, status: any) => {
    // console.log(
    //   '🚀 ~ file: modalUploadDropZone.tsx ~ line 42 ~ handleChangeStatus ~ status',
    //   status
    // );
    if (status === 'headers_received') {
      //
    }
    if (status === 'done') {
      closeModal();
      if (props.afterUpload) props.afterUpload();
      if (!props.autoRelaod) return;
      setTimeout(() => self.location.reload(), 100);
    }
  };

  return (
    <div>
      <Transition appear show={props.isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={closeModal}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black opacity-20" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-3xl p-0 my-1 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <Dialog.Title
                  as="h3"
                  className="flex justify-between mb-2 bg-primary2 text-default-white px-4 py-2"
                >
                  <span className="text-lg font-bold leading-6 font-display">
                    {props.title}
                  </span>
                  <button onClick={closeModal} className="hover:text-gray-400">
                    <XIcon className="w-5 h-5" />
                  </button>
                </Dialog.Title>
                <div className="flex flex-col font-display py-4 px-6">
                  <div className="mt-1 flex flex-col">
                    {props.deleteAction && (
                      <div className="flex justify-center md:justify-end">
                        <Button
                          title={'Excluir Imagem Atual'}
                          onClick={() => {
                            props.deleteAction && props.deleteAction();
                            setTimeout(() => self.location.reload(), 500);
                          }}
                        />
                      </div>
                    )}
                  </div>
                  <p className="py-2">{props.uploadInfo}</p>
                  <div className="w-full h-[20vh]">
                    <Dropzone
                      getUploadParams={getUploadParams}
                      onChangeStatus={handleChangeStatus}
                      maxFiles={1}
                      multiple={false}
                      canCancel={false}
                      inputContent="Araste e solte ou clique aqui"
                      styles={{
                        dropzone: {
                          width: '100%',
                          height: '100%',
                          overflow: 'hidden',
                        },
                        dropzoneActive: { borderColor: '#ff0008' },
                      }}
                    />
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}
