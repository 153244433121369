import { useEffect, useState } from 'react';
import { Search } from '../../app.models';
import FiltersPromotions from './filtersPromotions';
import { productSearchPromotions } from './promotions.service';

export default function AllPromotions() {
  const [list, setList] = useState<Search>({} as Search);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    productSearchPromotions(100).then((data) => {
      setList(data);
      setLoading(false);
    });
  }, []);

  if (loading)
    return (
      <div className="flex flex-col justify-center items-center h-[88vh] animate-pulse">
        <span className="mx-auto"></span>Aguarde ...
      </div>
    );

  return (
    <div className="mt-28 md:mt-0 flex h-[88vh]">
      <FiltersPromotions
        listProdutos={list}
        filterEixos={[]}
        loading={loading}
      />
    </div>
  );
}
