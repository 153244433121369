import { useRef } from 'react';
import { useProduct } from './product.hook';
import SelectMarcaTipo from './selectMarcaTipo';
import SelectTipo from './selectTipo';

export default function ProductsMarcaModeloFilter() {
  // const isSmall = useMedia({ maxWidth: 480 });
  const { selectedMarca } = useProduct();

  const divRef = useRef<HTMLDivElement>(null);

  return (
    <div
      className="h-full md:shadow md:h-[88vh] md:overflow-hidden md:overflow-y-auto"
      ref={divRef}
    >
      {selectedMarca?.id ? (
        <SelectTipo refPai={divRef.current} />
      ) : (
        <SelectMarcaTipo refPai={divRef.current} />
      )}
    </div>
  );
}
