/* eslint-disable @typescript-eslint/no-explicit-any */
// import { useProduct } from '../pages/product/product.hook';
import clsx from 'clsx';
import ModeloSearch from './modeloSearch';
import SelectMarcaModelo from './selectMarcaModelo';

interface Props {
  reverseColors?: boolean;
  transparent?: boolean;
}
export default function MenuMarcaModelo({
  reverseColors = false,
  transparent = false,
}: Props) {
  return (
    <div
      className={clsx(
        'mx-1 flex-1 overflow-hidden px-2 py-1',
        transparent
          ? 'bg-transparent'
          : 'bg-gradient-to-b from-[#281e5d]/90 to-[#281e5d]'
      )}
    >
      <div className="flex justify-between items-center h-full">
        <div className="mx-1 my-1">
          <ModeloSearch reverseColors={reverseColors} action={close} />
        </div>
        <div className="my-1">
          <SelectMarcaModelo reverseColors={reverseColors} action={close} />
        </div>
      </div>
    </div>
  );
}
