import { Popover } from '@headlessui/react';
import { ChevronDownIcon, XIcon } from '@heroicons/react/outline';
import { useEffect, useState } from 'react';
import { useApp } from '../app.hook';
import { TabelaPreco } from '../app.models';
import { classNames } from '../utils/functions';
import { usePopper } from 'react-popper';

interface Props {
  action?: (tabelaPreco: TabelaPreco) => void;
  tabelaPreco: number | null;
}

export default function ComboTabelaPreco(props: Props) {
  const { tabelaPrecos } = useApp();

  const [selected, setSelected] = useState<TabelaPreco | undefined>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [referenceElement, setReferenceElement] = useState({} as any);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [popperElement, setPopperElement] = useState({} as any);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
  });

  useEffect((): void => {
    if (props.tabelaPreco) {
      const tabela = tabelaPrecos.find((t) => t.id === props.tabelaPreco);
      if (tabela) {
        setSelected(tabela);
      }
    }
  }, [props.tabelaPreco, tabelaPrecos]);

  useEffect(() => {
    if (selected) {
      props.action && props.action(selected);
    }
  }, [props, selected]);

  return (
    <Popover className="relative">
      {({ open, close }) => (
        <>
          <Popover.Button
            ref={setReferenceElement}
            className={`
                ${!props.action && 'cursor-default'}
                ${open ? '' : 'text-opacity-90'}
                h-full group inline-flex items-center justify-between rounded-md bg-primary1 px-3 py-2 text-sm font-medium text-white hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
          >
            <div className="line-clamp-1">
              {selected?.tabelaSgi.concat('- ', selected?.descricao) ||
                'Tabela de Preço'}
            </div>
            {props.action && (
              <ChevronDownIcon
                className={`${open ? '' : 'text-opacity-70'}
                  ml-2 h-5 w-5 text-orange-300 transition duration-150 ease-in-out group-hover:text-opacity-80`}
                aria-hidden="true"
              />
            )}
          </Popover.Button>

          {props.action && (
            <Popover.Panel
              className="absolute z-10 w-screen max-w-lg"
              ref={setPopperElement}
              style={styles.popper}
              {...attributes.popper}
            >
              <div className="rounded-lg p-2 shadow-lg ring-1 ring-black ring-opacity-5 max-w-lg bg-white">
                <div className="flex justify-between my-2 text-primary1 font-bold">
                  <span className="">Tabela de Preço</span>
                  <button
                    className="group hover:bg-primary1 hover:text-white p-1 rounded-full"
                    onClick={() => close()}
                  >
                    <XIcon className="w-5 h-5" />
                  </button>
                </div>
                <div className="relative grid gap-1 grid-cols-1 h-[48vh] overflow-hidden overflow-y-auto">
                  {tabelaPrecos.map((item) => (
                    <button
                      key={item.id}
                      className={classNames(
                        'flex items-center rounded-lg p-1 max-h-12 ',
                        'transition duration-150 ease-in-out',
                        'focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50',
                        selected?.id === item.id
                          ? 'bg-primary1 text-white'
                          : 'text-gray-900 bg-white hover:bg-gray-100'
                      )}
                      onClick={() => {
                        if (selected?.id === item.id) return;
                        setSelected((state) =>
                          state?.id === item.id ? undefined : item
                        );
                        close();
                      }}
                    >
                      <div className="ml-4">
                        <p className="text-sm font-medium">
                          {item.tabelaSgi} - {item.descricao}
                        </p>
                      </div>
                    </button>
                  ))}
                </div>
              </div>
            </Popover.Panel>
          )}
        </>
      )}
    </Popover>
  );
}
