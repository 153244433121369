import { DotsHorizontalIcon } from '@heroicons/react/outline';
import { useNavigate } from 'react-router-dom';
import { ROUTES_MAP } from '../app.map.routes';
import { useProduct } from '../pages/product/product.hook';
import { classNames } from '../utils/functions';

interface Props {
  reverseColors?: boolean;
  action?: () => void;
}

export default function SelectMarcaModelo({ reverseColors, action }: Props) {
  const { selectedMarca, selectedModelo, clear, clearModelo } = useProduct();
  const navigate = useNavigate();
  return (
    <div className="flex-grow-0 flex flex-col md:flex-row space-x-2 space-y-2 md:space-y-0 items-center lg:text-md md:font-bold px-1">
      <button
        className={`flex flex-grow-0 items-center space-x-2 px-2 hover:bg-default-white py-1 rounded-full group`}
        onClick={() => {
          clear();
          navigate(ROUTES_MAP.product + '/?marca=');
          if (action) action();
        }}
      >
        {selectedMarca ? (
          <img
            style={{
              width: '40px',
              height: '40px',
              objectFit: 'contain',
            }}
            className="bg-white p-[1px] rounded-full lg:flex"
            src={selectedMarca?.images[0].img}
          />
        ) : (
          <DotsHorizontalIcon className="w-10 h-10 text-gray-400 bg-white p-[1px] rounded-full lg:flex" />
        )}
        <span
          className={classNames(
            'font-bold text-gray-600 md:text-default-white group-hover:text-primary1',
            reverseColors ? 'md:text-primary1' : 'md:text-default-white'
          )}
        >
          <div>
            {String(
              selectedMarca?.marcaDesc || 'Selecione a marca'
            ).toLocaleUpperCase()}
          </div>
          <div className="text-[0.7em] text-black font-thin">
            {selectedMarca?.marcaDesc ? 'Clique para alterar' : ''}
          </div>
        </span>
      </button>

      {selectedMarca?.id && (
        <button
          className={`flex flex-grow-0 items-center space-x-2 px-2 hover:bg-default-white py-1 rounded-full group`}
          onClick={() => {
            clearModelo();
            navigate(ROUTES_MAP.product + '/?marca=' + selectedMarca.id);
            if (action) action();
          }}
        >
          {selectedModelo ? (
            <img
              style={{
                width: '40px',
                height: '40px',
                objectFit: 'contain',
              }}
              className="bg-white p-[1px] rounded-full lg:flex"
              src={selectedModelo?.images[0].img}
            />
          ) : (
            <DotsHorizontalIcon className="w-10 h-10 text-gray-400 bg-white p-[1px] rounded-full lg:flex" />
          )}
          <span
            className={classNames(
              'font-bold text-gray-600 group-hover:text-primary1',
              reverseColors ? 'md:text-primary1' : 'md:text-default-white'
            )}
          >
            <div>{selectedModelo?.modeloDesc || 'Selecione o modelo'}</div>
            <div className="text-[0.7em] text-black font-thin">
              {selectedModelo?.modeloDesc ? 'Clique para alterar' : ''}
            </div>
          </span>
        </button>
      )}
    </div>
  );
}
