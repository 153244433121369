import { Disclosure, Transition } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/outline';
import React, { useCallback, useEffect, useState } from 'react';
import { classNames } from '../utils/functions';
import ModalFilterObject from './modalFilterObject';
import ModalFilterObjectModelos from './modalFilterObjectModelos';
import { EnumOrigens } from '../utils/origens';
import { useProduct } from '../pages/product/product.hook';
import { useParams, useSearchParams } from 'react-router-dom';

type Props = {
  title: string;
  items: {
    label: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any;
  }[];
  selecteds?: string[] | undefined;
  onChange: (selecteds: string[]) => void;
  onUncheck: (uncheck: string) => void;
  selectedsLabels?: (labels: string[]) => void;
  highlight?: boolean;
};

export default function FilterObject(props: Props) {
  const [params] = useSearchParams();
  const { setOrigem } = useProduct();
  const defaultState = useCallback(
    () => new Array(props.items ? props.items.length : 0).fill(false),
    [props.items]
  );

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [checkedState, setCheckedState] = useState(defaultState);

  const [items, setItems] = useState<
    {
      label: string;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      value: any;
    }[]
  >([]);

  useEffect(() => {
    setCheckedState(defaultState);
  }, [defaultState]);

  useEffect(() => {
    const _items = props.items;
    if (!_items) return;
    const lenghtShow = 9;
    if (props.items.length > lenghtShow) {
      setItems(_items.slice(0, lenghtShow + 1));
      return;
    }

    setItems(_items);
  }, [props.items]);

  const change = (value: string[], isSelected: boolean) => {
    if (isSelected) {
      props.onChange(value);
    } else {
      props.onUncheck(props.title);
    }
  };

  function defautlOpen() {
    if (props.selecteds) {
      return (
        props.selecteds.includes(props.title.toLowerCase()) ||
        props.title.toLowerCase() === 'categorias'
      );
    }
  }

  if (!props.items || props.items.length === 0) return <></>;

  if (props.items && props.items.length === 1 && !props.items[0]?.label)
    return <></>;

  return (
    <>
      <Disclosure as="div" defaultOpen={defautlOpen()} className="my-3">
        {({ open }) => (
          <>
            <Disclosure.Button
              className={classNames(
                'flex justify-between w-full px-4 py-2 text-sm font-medium text-left bg-gray-300 rounded-lg hover:bg-gray-300 focus:outline-none focus-visible:ring focus-visible:ring-gray-300 mb-3',
                props.highlight
                  ? 'bg-blue-500 text-white hover:text-gray-900'
                  : 'text-gray-900'
              )}
            >
              <span>
                {props.title.replace('_', ' ').replace('-', ' ').toUpperCase()}
              </span>
              <ChevronUpIcon
                className={`${
                  open ? 'transform rotate-180' : ''
                } w-5 h-5 text-primary1`}
              />
            </Disclosure.Button>
            <Transition
              show={open}
              enter="transition duration-100 ease-out"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
            >
              <Disclosure.Panel className="px-2">
                {items &&
                  items.length > 0 &&
                  items.map(
                    (it, index) =>
                      it && (
                        <div
                          key={index}
                          className={classNames(
                            'flex items-start',
                            items.length > 1 && 'hover:font-semibold'
                          )}
                        >
                          <div className="flex items-center h-5">
                            <input
                              type="checkbox"
                              // disabled={
                              //   items.length < 2 &&
                              //   !props.selecteds?.includes(
                              //     props.title.toLowerCase()
                              //   )
                              // }
                              className={classNames(
                                'hover:bg-primary1 h-4 w-4 text-primary2 border-red-300 rounded cursor-pointer'
                              )}
                              value={it.value}
                              checked={
                                // items.length > 1 &&
                                props.selecteds &&
                                props.selecteds?.includes(
                                  props.title.toLowerCase()
                                )
                              }
                              onChange={(e) => {
                                setOrigem(
                                  EnumOrigens.FILTRO + props.title.toLowerCase()
                                );
                                change([it.value], e.target.checked);
                              }}
                              disabled={
                                params.has('categoria') &&
                                props.title.toLowerCase().includes('categoria')
                              }
                            />
                          </div>
                          <div className="ml-3 text-sm">
                            <label
                              // htmlFor={`custom-checkbox-${index}`}
                              className={classNames(
                                'text-xs ',
                                items.length < 2
                                  ? 'text-gray-400'
                                  : 'text-gray-700'
                              )}
                              dangerouslySetInnerHTML={{ __html: it.label }}
                            ></label>
                          </div>
                        </div>
                      )
                  )}
                {props.items.length > 9 && (
                  <button
                    onClick={() => setModalIsOpen(!modalIsOpen)}
                    className="text-xs text-slate-600 underline hover:font-semibold"
                  >
                    ver todos
                  </button>
                )}
              </Disclosure.Panel>
            </Transition>
          </>
        )}
      </Disclosure>
      {props.title.toLowerCase() !== 'modelos' ? (
        <ModalFilterObject
          isOpen={modalIsOpen}
          setIsOpen={setModalIsOpen}
          checkedState={checkedState}
          items={props.items}
          onSelect={(selected) => props.onChange([selected.toString()])}
          title={'Selecionar ' + props.title}
        />
      ) : (
        <ModalFilterObjectModelos
          isOpen={modalIsOpen}
          setIsOpen={setModalIsOpen}
          checkedState={checkedState}
          items={props.items}
          onSelect={(selected: any) => props.onChange([selected.toString()])}
        />
      )}
    </>
  );
}
