import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/outline';

type Element = {
  id: number;
  desc: string;
};

interface Props<T = number> {
  elements: Element[];
  title?: string;
  initial?: number | string | undefined;
  action: (value: T | undefined) => void;
}

export default function ListBoxSelectComp({
  elements,
  title,
  initial,
  action,
}: Props) {
  const [selected, _setSelected] = useState<Element | undefined>({
    id: 0,
    desc: 'Selecione um vendedor',
  });

  const setSelected = useCallback(
    (id: number) => {
      const el = elements.find((e) => {
        return e.id === id;
      });
      _setSelected(el);
      action(el?.id);
    },
    [action, elements]
  );

  useEffect(() => {
    if (initial) {
      setSelected(initial as number);
    }
  }, [initial, setSelected]);

  return (
    <div className="w-full">
      {title && <div className="text-xs">{title}</div>}
      <Listbox value={selected} onChange={(el) => el && setSelected(el as any)}>
        <div className="relative mt-1">
          <Listbox.Button className="relative w-full cursor-default rounded-lg bg-primary1 text-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
            <span className="block truncate">{selected?.desc}</span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute mt-1 max-h-40 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {elements.map((el) => (
                <Listbox.Option
                  key={el.id}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 pl-10 pr-4 ${
                      active ? 'bg-amber-100 text-amber-900' : 'text-gray-900'
                    }`
                  }
                  value={el.id}
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={`block truncate ${
                          selected ? 'font-medium' : 'font-normal'
                        }`}
                      >
                        {el.desc}
                      </span>
                      {selected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
}
