import { Dialog, Transition } from '@headlessui/react';
import { PlusCircleIcon, XIcon } from '@heroicons/react/outline';
import { Fragment, useEffect, useState } from 'react';
import useMedia from 'use-media';
import { Comprador } from '../../app.models';
import Button from '../../components/layout/button';
import { getCompradores } from './cart.service';

// const mockComprador = Array(50).fill({
//   nome: 'nome',
//   apelido: 'apelitp',
//   cpf_cnpj: 'cpf/cnpj',
//   foneNumero: 'fone',
// });

interface ModalCompradorSelectProps {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  updCard: (comprador: Comprador) => void;
  newCliente?: () => void;
}

export default function ModalCompradorSelect(props: ModalCompradorSelectProps) {
  const isSmall = useMedia({ maxWidth: 480 });
  function closeModal() {
    props.setIsOpen(false);
  }

  const [searchFor, setSearchFor] = useState('');
  const [compradores, setCompradores] = useState<Comprador[]>([]);

  useEffect(() => {
    if (searchFor.length > 2) {
      getCompradores(searchFor).then(setCompradores);
    }
  }, [searchFor]);

  return (
    <div>
      <Transition appear show={props.isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={closeModal}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black opacity-20" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-3xl p-0 my-1 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <Dialog.Title
                  as="h3"
                  className="flex justify-between mb-2 bg-primary2 text-default-white px-4 py-2"
                >
                  <span className="text-lg font-bold leading-6 font-display">
                    Selecionar Comprador
                  </span>
                  <button onClick={closeModal} className="hover:text-gray-400">
                    <XIcon className="w-5 h-5" />
                  </button>
                </Dialog.Title>
                <div className="flex flex-col font-display p-2">
                  <div className="flex justify-between space-x-1">
                    <label className="relative block">
                      <span className="sr-only">Busca</span>
                      <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-5 w-5 stroke-gray-400"
                          viewBox="0 0 20 20"
                          fill="none"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                          />
                        </svg>
                      </span>
                      <input
                        className="placeholder:italic placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-gray-300 focus:ring-gray-300 focus:ring-1 sm:text-sm"
                        placeholder="Busca por nome..."
                        type="text"
                        value={searchFor}
                        onChange={(e) => setSearchFor(e.target.value)}
                      />
                    </label>
                    {props.newCliente && (
                      <Button
                        title="Inserir Cliente"
                        icon={<PlusCircleIcon className="w-4 h-4" />}
                        color="bg-default-white text-primary2 hover:bg-gray-100 hover:text-gray-700"
                        onClick={() => props.newCliente && props.newCliente()}
                      />
                    )}
                  </div>
                  {isSmall ? (
                    <div className="h-48 mt-2 overflow-hidden overflow-y-auto">
                      {compradores.map((item) => (
                        <div
                          key={item.id}
                          className="border-b-[1px] border-solid border-gray-300 even:bg-gray-100 hover:bg-gray-300 cursor-pointer py-3"
                          onClick={() => {
                            props.updCard(item);
                            closeModal();
                          }}
                        >
                          {/* item */}
                          <div className="px-1 py-0 flex justify-between">
                            <div className="text-md text-gray-900 text-left font-bold">
                              {item.nome}
                            </div>
                          </div>
                          <div className="px-1 py-0 flex justify-between">
                            <span className="text-sm text-gray-900 text-left">
                              Apelido:
                            </span>
                            <div className="text-sm text-gray-900 text-left">
                              {item.apelido}
                            </div>
                          </div>
                          <div className="px-1 py-0 flex justify-between">
                            <span className="text-sm text-gray-900 text-left">
                              CPF/CNPJ:
                            </span>
                            <div className="text-sm text-gray-900 text-left">
                              {item.cpf_cnpj}
                            </div>
                          </div>
                          <div className="px-1 py-0 flex justify-between">
                            <span className="text-sm text-gray-900 text-left">
                              Cel:
                            </span>
                            <div className="text-sm text-gray-900 text-left">
                              {item.foneNumero}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="h-48 mt-2 overflow-hidden overflow-y-auto">
                      <table className="w-full divide-y divide-gray-200">
                        <thead className="bg-gray-100 sticky top-0 z-10">
                          <tr>
                            <th
                              scope="col"
                              className="px-1 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Nome
                            </th>
                            <th
                              scope="col"
                              className="px-1 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Apelido
                            </th>
                            <th
                              scope="col"
                              className="px-1 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              CPF/CNPJ
                            </th>
                            <th
                              scope="col"
                              className="px-1 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Contato
                            </th>
                          </tr>
                        </thead>
                        <tbody className="">
                          {compradores.map((item) => (
                            <tr
                              key={item.id}
                              className="border-b-[1px] border-solid border-gray-300 even:bg-gray-100 hover:bg-gray-300 cursor-pointer"
                              onClick={() => {
                                props.updCard(item);
                                closeModal();
                              }}
                            >
                              {/* item */}
                              <td className="px-1 py-0 whitespace-nowrap">
                                <div className="text-sm text-gray-900 text-left">
                                  {item.nome}
                                </div>
                              </td>
                              <td className="px-1 py-0 whitespace-nowrap">
                                <div className="text-sm text-gray-900 text-left">
                                  {item.apelido}
                                </div>
                              </td>
                              <td className="px-1 py-0 whitespace-nowrap">
                                <div className="text-sm text-gray-900 text-left">
                                  {item.cpf_cnpj}
                                </div>
                              </td>
                              <td className="px-1 py-0 whitespace-nowrap">
                                <div className="text-sm text-gray-900 text-left">
                                  {item.foneNumero}
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}
