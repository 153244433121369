import { Popover, Switch } from '@headlessui/react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import DateRange from '../../components/dateRange';
import SelectFilter, { Filter } from '../../components/selectfilter';
import { useCarts } from './carts.hook';
import { Info } from '../../app.models';
import Button from '../../components/layout/button';
import { useCart } from '../cart/cart.hook';
import { ROUTES_MAP } from '../../app.map.routes';
import { useApp } from '../../app.hook';
import SelectCliente from '../../components/selectCliente';
import PerPage from '../../components/layout/perPage';
import { LOCAL_STORAGE_PREFIX } from '../../app.settings';

interface ParamsCartsProps {
  showingRegs: number;
  qtdeRegs: number;
  startDate: Date | undefined;
  endDate: Date | undefined;
  info?: Info;
  dateRange: (startDate: Date | undefined, endDate: Date | undefined) => void;
}

export default function ParamsCarts(props: ParamsCartsProps) {
  const { isPerfilManager, currentClientAtivo } = useApp();
  const {
    cartStatus,
    defCliente,
    setDefCliente,
    showZeroCarts,
    setShowZeroCarts,
  } = useCarts();
  const { newCart } = useCart();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigator = useNavigate();
  const params = searchParams;

  const handleStatus = (filters: Filter[]) => {
    const obj = Object.values(filters);
    params.set('situacao', obj.toString());
    params.set('skip', '0');
    setSearchParams(params);
  };

  return (
    <Popover.Group className="flex flex-col md:flex-row md:justify-between md:space-x-3 space-y-2 md:items-center py-1 px-0 bg-gray-50 text-gray-400">
      <div className="flex items-center space-x-1">
        <DateRange
          action={props.dateRange}
          starDate={props.startDate || undefined}
          endDate={props.endDate || undefined}
        />
        {cartStatus && (
          <SelectFilter
            filters={cartStatus.map((e) => ({
              value: e.id,
              display: e.situacao,
            }))}
            action={handleStatus}
            placeholder="Situações (todas)"
          />
        )}
        {isPerfilManager && (
          // <span className="mx-2">{currentClientAtivo?.nome}</span>
          <SelectCliente
            action={(clienteId) => {
              setDefCliente(clienteId.toString());
            }}
            clear={() => {
              setDefCliente('');
            }}
            clienteId={defCliente}
          />
          // <button
          //   className={classNames(
          //     'text-sm text-gray-600 group bg-gray-200 px-3 py-2 rounded-md inline-flex items-center font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75'
          //   )}
          // >
          //   <div title={defCliente?.nome || 'Cliente (todos)'}>
          //     {truncateStringWithEllipsis(
          //       defCliente?.nome || 'Cliente (todos)',
          //       25
          //     )}
          //   </div>
          // </button>
        )}
      </div>

      <div className="flex items-center space-x-3">
        <div className="flex gap-1 items-center">
          <span className="">Exibir carrinhos zerados</span>
          <Switch
            checked={showZeroCarts}
            onChange={(showCartsZerados: boolean) => {
              const value = showCartsZerados ? 'true' : 'false';
              params.set('exibirZerados', value);
              setSearchParams(params);
              localStorage.setItem(
                LOCAL_STORAGE_PREFIX + 'page-exibirZerados-carts',
                value
              );
            }}
            className={`${
              showZeroCarts ? 'bg-blue-600' : 'bg-gray-200'
            } relative inline-flex h-6 w-11 items-center rounded-full`}
          >
            <span className="sr-only">Exibir carrinhos zerados</span>
            <span
              className={`${
                showZeroCarts ? 'translate-x-6' : 'translate-x-1'
              } inline-block h-4 w-4 transform rounded-full bg-white transition`}
            />
          </Switch>
        </div>
        {props.info?.qtdePorPagina && (
          <PerPage
            listPerPage={[12, 24, 36, 48, 100]}
            storageLocalName="page-take-carts"
            take={parseInt(props.info.qtdePorPagina)}
          />
        )}
        <Button
          title="Novo Carrinho"
          color="bg-default-white text-primary2 mr-2 hover:bg-gray-100 hover:text-gray-700"
          onClick={() => {
            newCart();
            navigator(ROUTES_MAP.cart);
          }}
        />
      </div>
    </Popover.Group>
  );
}
