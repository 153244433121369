/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import App from './pages/App';
import Initial from './pages/Initial';
import { LOCAL_STORAGE_JWT, LOCAL_STORAGE_USER } from './app.settings';
import { getCryptoLocalStorage } from './app.service';

export default function InitialOrApp() {
  const token = localStorage.getItem(LOCAL_STORAGE_JWT);
  const user = getCryptoLocalStorage(LOCAL_STORAGE_USER);
  return token && user ? <App /> : <Initial />;
  // return !token && !user ? <App /> : <Initial />;
}
