import { ErrorTransmission, ProdutoPedido } from '../../../app.models';
import AppStep from '../../../components/appStep';
import Button from '../../../components/layout/button';
import ListProducts from './listProducts';
import { useOrder } from '../order.hook';
import {
  CalculatorIcon,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
} from '@heroicons/react/outline';
import useMedia from 'use-media';
import CardProducts from './cardProducts';
import { useRef, useState } from 'react';
import { parseCurrencyBRL } from '../../../utils/numberHandler';
import { toast } from 'react-toastify';
import { LoadingSVG } from '../../../components/loadingSVG';
import { classNames } from '../../../utils/functions';
import ModalErrors from '../../cart/modalErrors';

interface ResumeProps {
  products: ProdutoPedido[];
  resume: {
    qtde: number;
    total: number;
    frete: number;
  };
  itemHandler: (qtde: number, idx: number) => void;
  removeItem: (idx: number) => void;
  removePromotion: (idx: number) => void;
  setStep: (step: number) => void;
  selectedsCotacoes: number[];
  tabelaPreco: string;
}

export default function Resume(props: ResumeProps) {
  const isSmall = useMedia({ maxWidth: 480 });
  const { steps } = useOrder();
  // const [searchParams, setSearchParams] = useSearchParams();

  const previous = () => {
    // const params = searchParams;
    // params.set('step', '0');
    // setSearchParams(params);
    props.setStep(0);
  };

  // verificação de erros de transmissão
  const modalErrorData = useRef<ErrorTransmission[]>([]);
  const [verifing, setVerifing] = useState<boolean>(false);
  const [modalIsOpenErros, setModalIsOpenErros] = useState<boolean>(false);

  const validateTransmission = async () => {
    const errors: ErrorTransmission[] = [];
    const cotacoesIds = props.selectedsCotacoes.toString();
    if (!cotacoesIds) {
      toast.error('Selecione pelo menos um carrinho');
      return;
    }
    const tabelaId = props.tabelaPreco;
    if (!tabelaId) {
      toast.error('Selecione uma tabela de preço');
      return;
    }
    const produtos = props.products;
    // const produtos = await getCotacoes(cotacoesIds, tabelaId);
    if (produtos.length === 0) {
      return errors;
    }

    produtos.forEach((produto, index) => {
      if (produto.isPromocao) {
        if (produto?.promocao && produto?.promocao?.qtdeDisponivel === 0) {
          errors.push({
            image: produto?.images[0].small,
            produto: produto.descricao,
            qtde: produto.qtdeDisponivel,
            promocionalPrice: produto.precoPromo,
            price: produto.preco,
            qtdeDisponivel: produto.promocao?.qtdeDisponivel || 0,
            itemId: produto.id,
            error: `:(  Quantidade esgotada para promoção`,
            index,
            actions: [
              {
                title: `Manter com valor ${parseCurrencyBRL(produto.preco)}`,
                action: 'replace',
              },
              {
                title: 'Remover item',
                action: 'remove',
              },
            ],
          });
          return;
        }
        if (
          produto?.promocao &&
          produto.qtdeDisponivel > produto?.promocao?.qtdeDisponivel
        ) {
          errors.push({
            image: produto?.images[0].small,
            produto: produto.descricao,
            qtde: produto.qtdeDisponivel,
            qtdeDisponivel: produto.promocao?.qtdeDisponivel || 0,
            promocionalPrice: produto.precoPromo,
            price: produto.preco,
            itemId: produto.id,
            error: `Quantidade promocional excedida, restante: ${
              produto.promocao?.qtdeDisponivel || 0
            } und(s)`,
            index,
            actions: [
              {
                title: `Atualizar quantidade (${produto.promocao?.qtdeDisponivel})`,
                action: 'update',
              },
              {
                title: 'Remover item',
                action: 'remove',
              },
            ],
          });
          return;
        }
        if (!produto?.promocao) {
          errors.push({
            image: produto?.images[0].small,
            produto: produto.descricao,
            qtde: produto.qtdeDisponivel,
            qtdeDisponivel: 0,
            promocionalPrice: produto.precoPromo,
            price: produto.preco,
            itemId: produto.id,
            error:
              ':( Promoção acabou... Você quer manter o item no valor normal?',
            index,
            actions: [
              {
                title: `Manter com valor ${parseCurrencyBRL(produto.preco)}`,
                action: 'replace',
              },
              {
                title: 'Remover item',
                action: 'remove',
              },
            ],
          });
          return;
        }
      }
    });

    return errors;
  };

  const verifyTransmission = async (): Promise<boolean> => {
    setVerifing(true);
    modalErrorData.current = [];
    const errors = await validateTransmission();

    if (errors && errors.length > 0) {
      modalErrorData.current = errors;
      setModalIsOpenErros(true);
      setVerifing(false);
      return false;
    }

    setVerifing(false);
    return true;
  };

  const processErrors = async (actions: Record<string, string>[]) => {
    for await (const { index, action } of actions) {
      switch (action) {
        case 'update': {
          console.log('update', index);
          const upd = props.products[Number(index)];
          props.itemHandler(upd.promocao?.qtdeDisponivel || 0, Number(index));
          break;
        }

        case 'remove': {
          console.log('remove', index);
          props.removeItem(Number(index));
          break;
        }

        case 'replace': {
          console.log('replace', index);
          props.removePromotion(Number(index));
          break;
        }

        default:
          console.log('none', index);
      }
    }
  };

  const next = async () => {
    const verify = await verifyTransmission();
    if (!verify) {
      return;
    }
    // const params = searchParams;
    // params.set('step', '2');
    // setSearchParams(params);
    props.setStep(2);
  };

  return (
    <div className="">
      <AppStep steps={steps} current={1} />
      <div className="flex justify-between px-4 py-2">
        <span>
          {!isSmall ? (
            <Button
              title="Anterior"
              color="bg-primary2 text-default-white hover:bg-primary1 hover:text-gray-100"
              onClick={previous}
            />
          ) : (
            <Button
              title={''}
              color="bg-primary2 text-default-white hover:bg-primary1 hover:text-gray-100"
              onClick={previous}
              icon={<ChevronDoubleLeftIcon className="w-4 h-4" />}
            />
          )}
        </span>
        <div className="flex items-center">
          <CalculatorIcon className="w-10 h-10 text-primary2 mx-2 hidden md:inline" />
          <div className="flex flex-col">
            <div className="flex justify-between space-x-6 items-center">
              <span className="font-bold">Qtde:</span>
              <span className="font-bold">{props.resume.qtde}</span>
            </div>
            <div className="flex justify-between space-x-6 items-center">
              <span className="font-bold">Total:</span>
              <span className="font-bold text-blue-800">
                {props.resume.total.toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </span>
            </div>
          </div>
        </div>
        <span>
          {!isSmall ? (
            <>
              {!verifing ? (
                <Button
                  title="Próximo"
                  color="bg-blue-700 text-default-white hover:bg-blue-400 hover:text-gray-100"
                  onClick={next}
                />
              ) : (
                <div className="flex justify-center items-center text-gray-800 bg-gray-300 rounded-full px-2 py-1 w-full">
                  <LoadingSVG className={classNames('fill-current py-1')} />{' '}
                  <span>Analizando pedido</span>
                </div>
              )}
            </>
          ) : (
            <>
              {!verifing ? (
                <Button
                  title={''}
                  color="bg-blue-700 text-default-white hover:bg-blue-400 hover:text-gray-100"
                  onClick={next}
                  icon={<ChevronDoubleRightIcon className="w-4 h-4" />}
                />
              ) : (
                <div className="flex justify-center items-center text-gray-800 bg-gray-300 rounded-full px-2 py-1 w-full">
                  <LoadingSVG className={classNames('fill-current py-1')} />{' '}
                  <span>...</span>
                </div>
              )}
            </>
          )}
        </span>
      </div>
      <div className="h-full md:h-[64vh] md:overflow-hidden md:overflow-y-auto px-0">
        {isSmall ? (
          <CardProducts
            products={props.products}
            itemHandler={props.itemHandler}
          />
        ) : (
          <ListProducts
            products={props.products}
            itemHandler={props.itemHandler}
          />
        )}
      </div>
      <ModalErrors
        isOpen={modalIsOpenErros}
        setIsOpen={setModalIsOpenErros}
        errors={modalErrorData.current}
        afterClose={processErrors}
      />
    </div>
  );
}
