import { Switch } from '@headlessui/react';
import { ReplyIcon, SaveIcon } from '@heroicons/react/outline';
import { useCallback, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import useMedia from 'use-media';
import { ROUTES_MAP } from '../../app.map.routes';
import { Cliente, Usuario } from '../../app.models';
import { useUsers } from './users.hook';
import { addUser, updUsuario } from './users.service';
import { useApp } from '../../app.hook';
import MaskedInput from 'react-text-mask';
import getMask from '../../utils/masks';

export default function UserForm() {
  const isSmall = useMedia({ maxWidth: 480 });
  const { isPerfilManager } = useApp();
  const { loading, filtered, loadUsers, defCliente } = useUsers();
  const navigate = useNavigate();
  const params = useParams();
  const {
    register,
    setValue,
    formState: { errors },
    control,
    handleSubmit,
  } = useForm<Usuario>();

  const loadForm = useCallback(
    (data: Usuario) => {
      if (!data) return;
      const entries = Object.entries(data);
      entries.forEach((entry) => {
        const [key, value] = entry;
        if (!key) return;

        switch (key) {
          case 'cliente':
            setValue(key, value?.id);
            break;

          default: {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            setValue<any>(key, value);
          }
        }
      });
    },
    [setValue]
  );

  useEffect(() => {
    if (params.id) {
      const user = filtered.find((u) => u.id === params.id);
      if (user) {
        loadForm(user);
      }
    } else {
      setValue('cliente', defCliente as unknown as Cliente);
    }
  }, [defCliente, filtered, loadForm, params.id, setValue]);

  const submit = async (data: Usuario) => {
    if (!params.id || !data.id || data.id !== params.id) {
      // verificar se é manager
      const newData = data;
      if (isPerfilManager) {
        if (!defCliente) return;
        newData.cliente = defCliente as unknown as Cliente;
      }
      await addUser(newData);
    } else {
      const newData = data;
      if (isPerfilManager) {
        if (!defCliente) return;
        newData.cliente = defCliente as unknown as Cliente;
      }
      await updUsuario(newData);
    }
    loadUsers();
    navigate(ROUTES_MAP.users);
  };

  if (isSmall) return <div className="mt-24 p-2"></div>;
  else
    return (
      <div className="mt-24 md:mt-0 p-2 h-[88vh]">
        <div className="h-[88vh] overflow-hidden overflow-y-auto">
          {loading ? (
            <div className="flex flex-col justify-center items-center h-[88vh] animate-pulse">
              <span className="mx-auto"></span>Aguarde ...
            </div>
          ) : (
            <div className="">
              <div className="bg-gray-200 my-2 p-2 flex items-center justify-between">
                <button
                  type="button"
                  title="voltar"
                  className="bg-primary1 hover:opacity-70 p-1 m-1 text-default-white rounded-full"
                  onClick={() => navigate('/usuarios')}
                >
                  <ReplyIcon className="h-6 w-6" />
                </button>
                <strong>
                  {params.id ? 'Alterar Usuário' : 'Inserir Usuário'}
                </strong>
              </div>
              <form
                autoComplete="off"
                onSubmit={handleSubmit(submit)}
                className="flex flex-col justify-center items-center h-full max-w-xl mx-auto"
              >
                {params.id && <input type="hidden" {...register('id')} />}
                <input type="hidden" {...register('cliente')} />
                <div className="flex flex-col w-full">
                  <label
                    htmlFor="nome"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Nome
                  </label>
                  <div className="mt-1 relative shadow-sm">
                    <input
                      type="text"
                      id="nome"
                      className="form-input px-3 py-2 w-full rounded-md bg-gray-200 text-gray-700 border-gray-200 border-2 focus:outline-none focus:bg-white focus:border-gray-500"
                      placeholder="Nome Completo"
                      {...register('nome', { required: true })}
                      autoComplete="off"
                    />
                  </div>
                  <span className="text-xs text-red-500">
                    {errors.nome && '* Campo requerido'}
                  </span>
                </div>
                <div className="flex flex-col w-full">
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    E-mail
                  </label>
                  <div className="mt-1 relative shadow-sm">
                    <input
                      type="text"
                      id="email"
                      className="form-input px-3 py-2 w-full rounded-md bg-gray-200 text-gray-700 border-gray-200 border-2 focus:outline-none focus:bg-white focus:border-gray-500 disabled:bg-gray-400 disabled:text-white"
                      placeholder="Email Válido"
                      {...register('email', {
                        required: true,
                        disabled: params.id ? true : false,
                      })}
                      autoComplete="off"
                    />
                  </div>
                  <span className="text-xs text-red-500">
                    {errors.email && '* Campo requerido'}
                  </span>
                </div>
                <div className="flex flex-col w-full">
                  <label
                    htmlFor="telefone"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Telefone
                  </label>
                  <div className="mt-1 relative shadow-sm">
                    <Controller
                      name="telefone"
                      control={control}
                      render={({ field }) => (
                        <MaskedInput
                          mask={getMask('telefone-11')}
                          type="text"
                          className="form-input px-3 py-2 w-full rounded-md bg-gray-200 text-gray-700 border-gray-200 border-2 focus:outline-none focus:bg-white focus:border-gray-500"
                          placeholder=""
                          {...field}
                        />
                      )}
                    />
                    {/* <input
                      type="text"
                      id="telefone"
                      className="form-input px-3 py-2 w-full rounded-md bg-gray-200 text-gray-700 border-gray-200 border-2 focus:outline-none focus:bg-white focus:border-gray-500"
                      placeholder="Número Atual"
                      {...register('telefone', { required: false })}
                      autoComplete="off"
                    /> */}
                  </div>
                  <span className="text-xs text-red-500">
                    {errors.telefone && '* Campo requerido'}
                  </span>
                </div>
                {!params.id && (
                  <div className="flex flex-col w-full">
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Senha
                    </label>
                    <div className="mt-1 relative shadow-sm">
                      <input
                        type="password"
                        id="password"
                        className="form-input px-3 py-2 w-full rounded-md bg-gray-200 text-gray-700 border-gray-200 border-2 focus:outline-none focus:bg-white focus:border-gray-500"
                        placeholder="Senha inicial"
                        {...register('password', { required: true })}
                        autoComplete="new-password"
                      />
                    </div>
                    <span className="text-xs text-red-500">
                      {errors.password && '* Campo requerido'}
                    </span>
                  </div>
                )}
                {params.id && (
                  <div className="flex w-full my-3">
                    <label
                      htmlFor="isAtivo"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Usuário Ativo
                    </label>
                    <Controller
                      control={control}
                      name="isAtivo"
                      render={({ field }) => (
                        <Switch
                          // eslint-disable-next-line @typescript-eslint/no-explicit-any
                          onChange={(e: any) => field.onChange(e)}
                          checked={field.value}
                          className={`${
                            field.value ? 'bg-blue-600' : 'bg-gray-200'
                          } relative inline-flex items-center h-6 rounded-full w-11 transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mx-4`}
                        >
                          <span
                            className={`${
                              field.value ? 'translate-x-6' : 'translate-x-1'
                            } inline-block w-4 h-4 transform bg-white rounded-full transition-transform`}
                          />
                        </Switch>
                      )}
                    />
                  </div>
                )}
                <div className="flex w-full justify-end my-2">
                  <button
                    type="submit"
                    title="Salvar"
                    className="bg-primary1 hover:opacity-70 text-default-white flex items-center rounded-lg px-3 py-2"
                  >
                    <SaveIcon className="h-6 w-6" /> Salvar
                  </button>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
    );
}
