/* eslint-disable prettier/prettier */
import { Content, TDocumentDefinitions } from 'pdfmake/interfaces';
import { Pedido } from '../../app.models';
import accounting from '../../utils/accounting';
import { parseCurrencyBRL } from '../../utils/numberHandler';
import { logoFullBase64 } from './logoFullBase64';
import GetTableProdutosDD, { tableWidths } from './getTableProdutosDD';


interface Props {
  pedido: Pedido;
}

const LineFull: any = {
  canvas: [
    {
      type: 'line',
      x1: 0,
      y1: 0,
      x2: 515,
      y2: 0,
      lineWidth: 1,
    },
  ],
  margin: [0, 1, 0, 1]
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function sumField({ data, field }: { data: any[]; field: string }) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const reduce = data.reduce((acc: number, cur: any) => {
    acc += accounting.unformat(cur[field]);
    return acc;
  }, 0);

  return accounting.toFixed(reduce).replace('.', ',');
}

function defaultHeader(
  currentPage: number,
  pageSize: { width: number },
  pedido: Pedido
): Content {
  return [
    {
      columns: [
        {
          image: logoFullBase64,
          width: 90,
          height: 40,

        },
        {
          stack: [
            {
              text: `Pedido: ${pedido.id}`,
              alignment: 'right',
              fontSize: 8,
            },
            {
              text: `Referência: ${pedido.xRef ?? 'N/D'}`,
              alignment: 'right',
              fontSize: 8,
            },
            {
              text: `Data: ${new Date(pedido.created_at).toLocaleDateString('pt-BR')}`,
              alignment: 'right',
              fontSize: 8,
            },
            {
              text: `Situação: ${pedido.situacaoPedido === 0 ? 'Pendente' : 'Transmitido'}`,
              alignment: 'right',
              fontSize: 8,
              color: pedido.situacaoPedido === 0 ? 'red' : 'green',
            },
          ]
        }
      ],
      margin: [40, 30, 40, 0]
    },
    {
      canvas: [
        {
          type: 'line',
          x1: 30,
          y1: 0,
          x2: pageSize.width - 30,
          y2: 0,
          lineWidth: 1,
        },
      ],
      margin: [0, 5, 0, 1]
    },
  ];
}

function defaultFooter(
  currentPage: number,
  pageCount: number,
  pageSize: { width: number },
): Content {
  return [
    {
      canvas: [
        {
          type: 'line',
          x1: 30,
          y1: 0,
          x2: pageSize.width - 30,
          y2: 0,
          lineWidth: 1,
        },
      ],
    },
    {
      stack: [
        {
          text: 'Pag. '.concat(
            currentPage.toString(),
            ' de ',
            pageCount.toString(),
            // eslint-disable-next-line prettier/prettier
            ` - ${new Date().getDate()}/${new Date().getMonth() + 1
            }/${new Date().getFullYear()}`
          ),
          margin: [30, 0, 30, 0],
          fontSize: 8,
        },
      ],
    },
  ];
}

export default function PrintOrderDD({
  pedido
}: Props): TDocumentDefinitions {

  const dd: TDocumentDefinitions = {
    defaultStyle: {
      font: 'Roboto',
    },

    pageSize: 'A4',

    pageOrientation: 'portrait',

    pageMargins: [40, 90, 40, 60],

    header: (currentPage, pageCount, pageSize) =>
      defaultHeader(currentPage, pageSize, pedido),

    content: [
      {
        text: 'Pedido',
        fontSize: 12,
        alignment: 'center',
        margin: [0, 0, 0, 10],
      },
      {
        stack: [
          {
            columns: [
              { text: `Nome/Razão: ${pedido.cliente.nome}`, fontSize: 10, alignment: 'left' },
              { text: `CPF/CNPJ: ${pedido.cliente.cpf_cnpj}`, fontSize: 10, alignment: 'right' },
            ]
          },
          { text: `Contato: ${pedido.cliente.foneNumero}`, fontSize: 10 },
        ]
      },
      {
        stack: [
          { text: `Produtos`, fontSize: 10, alignment: 'left' },
          // LineFull
        ],
        margin: [0, 10, 0, 0]
      },
      {
        table: {
          //   layout: 'lightHorizontalLines',
          widths: tableWidths,
          headerRows: 1,
          body: GetTableProdutosDD({ items: pedido.produtos as any }),
        },
        layout: {
          fillColor: function (rowIndex) {
            return rowIndex === 0 ? '#CCCCCC' : null;
          },
        },
        style: {
          fontSize: 10,
        },
        margin: [0, 0, 0, 10],
      },
      {
        columns: [
          { text: `Quantidade de Itens: ${pedido.qtdeTotal}` },
          { text: `Total do Pedido: ${parseCurrencyBRL(pedido.total)}`, alignment: 'right' },
        ]
      }
    ],

    footer: defaultFooter,

    // styles: {
    //   header: {
    //     fontSize: 10,
    //     bold: true,
    //     margin: [40, 30, 30, 30]
    //   },
    // },
  };

  return dd;
}
