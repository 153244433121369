import { Transition, Dialog } from '@headlessui/react';
import { SearchIcon, XIcon } from '@heroicons/react/outline';
import {
  Fragment,
  LegacyRef,
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Modelo } from '../../app.models';
import { arrayGroupBy } from '../../utils/functions';
import { useApp } from '../../app.hook';
import Display from '../product/display';
import { EnumOrigens } from '../../utils/origens';
import { SearchType } from '.';

interface ModalBuscaPorVeiculoProps {
  onSearch: (modelo: Modelo, origem: string) => void;
  searchTipo: SearchType;
}

const ModalBuscaPorVeiculo = forwardRef(
  (
    { onSearch, searchTipo }: ModalBuscaPorVeiculoProps,
    ref: LegacyRef<HTMLInputElement>
  ) => {
    const innerRef = useRef<HTMLInputElement | null>(null);
    const { modelos: defaultModelos } = useApp();
    const [search, setSearch] = useState<string>('');
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [modelos, setModelos] = useState<Modelo[]>([]);

    const [selectedSearchedModelo, setSelectedSearchedModelo] = useState<
      number | undefined
    >(undefined);

    const open = () => {
      setIsOpen(true);
    };

    const close = () => {
      setIsOpen(false);
    };

    const getModelos = useCallback(() => {
      if (!search) return;
      const find = search
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '') // Remove acentos
        .replace(/([^a-zA-z0-9]+)/g, '') // Substitui espaço e outros caracteres por hífen
        .replace(/\-\-+/g, '-') // Substitui multiplos hífens por um único hífen
        .replace(/(^-+|-+$)/, '')
        .toLocaleLowerCase();
      const filtered: Modelo[] = defaultModelos.filter(
        (m) =>
          m.modeloDescPesquisa.toLowerCase().match(new RegExp(`${find}`, 'igm'))
            ?.length || 0 > 0
      );

      const arrayGroup = arrayGroupBy(filtered, 'marcaRaw.marcaDesc');
      const group = Object.entries(arrayGroup).map(([key, value]) => ({
        marca: key,
        modelos: value,
      }));

      setModelos(group.flatMap((g) => g.modelos));
      // open();
    }, [defaultModelos, search]);

    useEffect(() => {
      if (search.length > 1) getModelos();
    }, [getModelos, search]);

    useEffect(() => {
      if (searchTipo !== 'veiculo') {
        setSearch('');
      }
    }, [searchTipo]);

    useEffect(() => {
      const tOut = setTimeout(() => {
        if (isOpen && modelos?.length) {
          innerRef.current?.scrollTo({
            top: 0,
            behavior: 'smooth',
          });
          innerRef.current?.focus();
          // colocar o focus no primeiro item
          const first = innerRef.current?.querySelector('button');
          first?.focus();
        }
      }, 100);
      return () => clearTimeout(tOut);
    }, [isOpen, modelos?.length]);

    // controle de movimentação do teclado, teclas de seta usando tabulação
    useEffect(() => {
      const handleKeyDown = (e: KeyboardEvent) => {
        if (e.key === 'ArrowLeft') {
          const items = innerRef.current?.querySelectorAll('button');
          if (!items) return;
          const getSelectedTabIndex = (
            items: NodeListOf<HTMLButtonElement>
          ) => {
            for (let i = 0; i < items.length; i++) {
              if (items[i] === document.activeElement) {
                return i;
              }
            }
            return -1;
          };
          const indexSelected = getSelectedTabIndex(items);
          console.log({ indexSelected });
          items[indexSelected - 1]?.focus();
        }

        if (e.key === 'ArrowRight') {
          const items = innerRef.current?.querySelectorAll('button');
          if (!items) return;
          const getSelectedTabIndex = (
            items: NodeListOf<HTMLButtonElement>
          ) => {
            for (let i = 0; i < items.length; i++) {
              if (items[i] === document.activeElement) {
                return i;
              }
            }
            return -1;
          };
          const indexSelected = getSelectedTabIndex(items);
          console.log({ indexSelected });
          items[indexSelected + 1]?.focus();
        }
      };
      window.addEventListener('keydown', handleKeyDown);
      return () => {
        window.removeEventListener('keydown', handleKeyDown);
      };
    }, [innerRef]);

    return (
      <>
        <div className="flex flex-col">
          <div className="w-64 border border-solid flex items-center bg-white rounded-full text-gray-600 pr-1">
            <label htmlFor="search" className="text-[0.6em] px-[10px]">
              Pesquisar Veículo
            </label>
            <input
              ref={ref}
              type="search"
              value={search}
              placeholder="Pesquisar por veiculo..."
              className="text-xs bg-white w-full border-0 focus:outline-none focus:shadow-none"
              onChange={(e) => setSearch(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === 'Enter') open();
              }}
            />
            <span className="w-1" />
            <button onClick={() => open()} className="hover:text-gray-400">
              <SearchIcon className="w-6 h-6 p-1" />
            </button>
          </div>
        </div>
        <Transition appear show={isOpen} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-50 overflow-y-auto"
            onClose={close}
          >
            <div className="px-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-black opacity-20" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="inline-block h-screen align-middle"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="inline-block font-display w-full max-w-3xl overflow-hidden align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                  <Dialog.Title
                    as="h3"
                    className="flex justify-between mb-2 bg-primary2 text-default-white px-4 py-2"
                  >
                    <span className="text-lg font-bold leading-6 font-display">
                      Selecione um veículo
                    </span>
                    <button onClick={close} className="hover:text-gray-400">
                      <XIcon className="w-5 h-5" />
                    </button>
                  </Dialog.Title>
                  {/* content */}
                  <div className="flex justify-center">
                    {modelos?.length ? (
                      <div
                        ref={innerRef}
                        className="mx-auto grid grid-cols-4 gap-1 h-[50vh] px-8 overflow-hidden overflow-y-auto"
                      >
                        {modelos.map((m) => (
                          <Display
                            key={m.id}
                            id={m.id}
                            selected={selectedSearchedModelo}
                            title={m.modeloDesc}
                            subTitle={`Ano: ${m.anoI} - ${m.anoF}`}
                            img={m?.images[0]?.img}
                            marcaImg={m.marcaRaw?.images[0]?.img}
                            onClick={async () => {
                              const origem = EnumOrigens.MARCA_MODELO;
                              setSelectedSearchedModelo(undefined);
                              onSearch(m, origem);
                              await new Promise((t) => setTimeout(t, 0)).then(
                                () => {
                                  setSelectedSearchedModelo(m.id);
                                }
                              );
                              close();
                            }}
                          />
                        ))}
                      </div>
                    ) : (
                      <div className="flex flex-col justify-center items-center h-[50vh] animate-pulse">
                        <span className="mx-auto"></span>Modelos não localizados
                        ...
                      </div>
                    )}
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition>
      </>
    );
  }
);

export default ModalBuscaPorVeiculo;
