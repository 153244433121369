import useMedia from 'use-media';
import LazyLoadingImage from '../../components/lazyLadingImage';
import { classNames } from '../../utils/functions';

interface DisplayProps {
  id: number;
  title: string;
  subTitle?: string;
  img: string;
  onClick: () => void;
  selected: number | undefined;
  marcaImg?: string;
}

const Display: React.FC<DisplayProps> = ({
  id,
  title,
  subTitle,
  img,
  onClick,
  selected,
  marcaImg,
}: DisplayProps) => {
  // const isSmall = useMedia({ maxWidth: 480 });
  // console.log({ img });
  // if (isSmall)
  //   return (
  //     <button
  //       className={classNames(
  //         'flex flex-col w-full mx-4 my-2 group ring-gray-300 ring-2 md:hover:ring-2 md:hover:ring-primary1 bg-white rounded-md z-0',
  //         selected === id && 'ring-2 ring-primary1'
  //       )}
  //       onClick={onClick}
  //     >
  //       <LazyLoadingImage
  //         classNames={['mx-auto']}
  //         alt={title}
  //         height={150}
  //         width={150}
  //         src={img}
  //         efect="blur"
  //       />
  //       <span className="text-md line-clamp-2 group-hover:line-clamp-4 w-full md:w-[150px] font-bold">
  //         {title}
  //       </span>
  //       <span className="text-xs line-clamp-2 group-hover:line-clamp-4 w-full md:w-[150px] ">
  //         {subTitle}
  //       </span>
  //     </button>
  //   );
  const isSmall = useMedia({ maxWidth: 480 });
  const isMedium = useMedia({ minWidth: 481, maxWidth: 1024 });
  const isBig = useMedia({ minWidth: 1025 });

  const getImageSize = (): number => {
    if (isSmall) return 150;
    if (isMedium) return 126;
    if (isBig) return 112;

    return 150;
  };

  return (
    <div className="p-2">
      <button
        className={classNames(
          'w-full',
          selected !== id
            ? 'flex flex-col m-2 md:m-3 p-1 group ring-2 ring-gray-100 hover:ring-2 hover:ring-primary1 bg-white rounded-md'
            : 'flex flex-col m-2 md:m-3 p-1 group ring-4 ring-primary1 shadow-lg rounded-md'
        )}
        onClick={onClick}
        title="Clique para selecionar e continuar"
        data-selected={selected === id}
      >
        {marcaImg ? (
          <div className="relative z-10">
            <LazyLoadingImage
              classNames={['w-6 h-6']}
              alt={marcaImg}
              height={40}
              width={40}
              src={marcaImg}
              efect="blur"
            />
          </div>
        ) : null}
        <LazyLoadingImage
          classNames={['mx-auto']}
          alt={title}
          height={getImageSize()}
          width={getImageSize()}
          src={img}
          efect="blur"
        />
        <span
          className={`text-md text-center line-clamp-1 group-hover:line-clamp-2 w-full font-bold`}
        >
          {title}
        </span>
        {subTitle && (
          <span className="text-xs text-center line-clamp-1 group-hover:line-clamp-2 w-full ">
            {subTitle}
          </span>
        )}
      </button>
    </div>
  );
};

export default Display;
