import { Dialog, Transition } from '@headlessui/react';
import { ThumbDownIcon, ThumbUpIcon, XIcon } from '@heroicons/react/outline';
import { Fragment } from 'react';
import Button from './layout/button';

interface ModalConfirmationChangeForm {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  title: string;
  question: string;
  confirm: () => void;
  cancel: () => void;
}

export default function ModalConfirmationChangeForm(
  props: ModalConfirmationChangeForm
) {
  function closeModal() {
    props.setIsOpen(false);
  }

  const confirm = () => {
    props.confirm();
    closeModal();
  };

  const cancel = () => {
    props.cancel();
    closeModal();
  };

  return (
    <div>
      <Transition appear show={props.isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={closeModal}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black opacity-20" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-xl p-0 my-1 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <Dialog.Title
                  as="h3"
                  className="flex justify-between mb-2 bg-primary2 text-default-white px-4 py-2"
                >
                  <span className="text-lg font-bold leading-6 font-display">
                    {props.title}
                  </span>
                  <button onClick={closeModal} className="hover:text-gray-400">
                    <XIcon className="w-5 h-5" />
                  </button>
                </Dialog.Title>
                <div className="flex flex-col font-display py-4 px-6">
                  <p className="text-md font-display font-bold mb-8">
                    {props.question}
                  </p>
                  <div className="flex justify-between">
                    <Button
                      title="Não"
                      type="button"
                      icon={<ThumbDownIcon className="w-4 h-4" />}
                      onClick={cancel}
                      color="bg-red-500 text-white hover:bg-red-400 hover:text-gray-100"
                    />
                    <Button
                      title="Sim"
                      type="button"
                      icon={<ThumbUpIcon className="w-4 h-4" />}
                      onClick={confirm}
                      color="bg-green-500 text-white hover:bg-green-400 hover:text-gray-100"
                    />
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}
