import { Popover } from '@headlessui/react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import DateRange from '../../components/dateRange';
// import PerPage from '../../components/layout/perPage';
import SelectFilter, { Filter } from '../../components/selectfilter';
import { useOrder } from './order.hook';
import { Info } from '../../app.models';
import Button from '../../components/layout/button';
import { ROUTES_MAP } from '../../app.map.routes';
import { useApp } from '../../app.hook';
import SelectCliente from '../../components/selectCliente';
import clsx from 'clsx';
import { truncateStringWithEllipsis } from '../../utils/functions';

interface ParamsOrdersProps {
  showingRegs: number;
  qtdeRegs: number;
  startDate: Date | undefined;
  endDate: Date | undefined;
  info?: Info;
  dateRange: (startDate: Date | undefined, endDate: Date | undefined) => void;
}

export default function ParamsOrders(props: ParamsOrdersProps) {
  const { isPerfilManager, currentClientAtivo } = useApp();
  const { pedidoStatus, defCliente, setDefCliente, setSelectedTabelaId } =
    useOrder();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigator = useNavigate();
  const params = searchParams;

  const handleStatus = (filters: Filter[]) => {
    const obj = Object.values(filters);

    params.set('situacao', obj.toString());
    params.set('skip', '0');
    setSearchParams(params);
  };

  return (
    <Popover.Group className="flex flex-col md:flex-row md:justify-between md:space-x-3 space-y-2 md:items-center py-1 bg-gray-50 text-gray-400">
      <div className="flex items-center space-x-1">
        <DateRange
          action={props.dateRange}
          starDate={props.startDate || undefined}
          endDate={props.endDate || undefined}
        />
        {pedidoStatus && (
          <SelectFilter
            filters={pedidoStatus.map((e) => ({
              value: e.id,
              display: e.situacao,
            }))}
            action={handleStatus}
            placeholder="Situações (todas)"
          />
        )}
        {isPerfilManager && (
          // <span className="mx-2">{currentClientAtivo?.nome}</span>
          <SelectCliente
            action={(clienteId) => {
              setDefCliente(clienteId.toString());
            }}
            clear={() => {
              setDefCliente('');
            }}
            clienteId={defCliente}
          />
        )}
        {/* {isPerfilManager && (
          <button
            className={clsx(
              'text-sm text-gray-600 group bg-gray-200 px-3 py-2 rounded-md inline-flex items-center font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75'
            )}
          >
            <div title={defCliente || 'Cliente (todos)'}>
              {truncateStringWithEllipsis(defCliente || 'Cliente (todos)', 25)}
            </div>
          </button>
        )} */}
      </div>
      <div className="flex items-center space-x-1">
        {/* {props.info?.qtdePorPagina && (
          <PerPage
            listPerPage={[12, 24, 36, 48]}
            storageLocalName="page-take-pedidos"
            take={parseInt(props.info.qtdePorPagina)}
          />
        )} */}
        {defCliente ? (
          <Button
            title="Novo Pedido"
            color="bg-default-white text-primary2 mr-2 hover:bg-gray-100 hover:text-gray-700"
            onClick={() => {
              setSelectedTabelaId('');
              navigator(ROUTES_MAP.transmission + '?step=0');
            }}
          />
        ) : (
          <span className="text-primary2 mr-2 bg-gray-300 px-2 py-1 rounded-full">
            Selecione um Cliente
          </span>
        )}
      </div>
    </Popover.Group>
  );
}
