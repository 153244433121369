import clsx from 'clsx';
import { IResponseCotacoesPorCliente } from '../service';
import { useEffect, useState } from 'react';
import { SearchCircleIcon } from '@heroicons/react/outline';
import AppPerPage from '../../../components/layout/appPerPage';
import AppPaginator from '../../../components/layout/pagination';

interface Props {
  items: IResponseCotacoesPorCliente[];
  limit?: number;
  onRowClick?: (item: IResponseCotacoesPorCliente) => void;
  filtered?: boolean;
  clearFilter?: () => void;
  verTudo?: () => void;
  close?: () => void;
  heightClass?: string;
  paginated?: boolean;
  showQtde?: boolean;
}

const InnerGridClienteRanking = ({
  items,
  limit,
  onRowClick,
  filtered = false,
  clearFilter,
  verTudo,
  close,
  heightClass,
  paginated = false,
  showQtde = false,
}: Props) => {
  const [curPage, setCurPage] = useState<number>(0);
  const [perPage, setPerPage] = useState<number>(15);

  const [rows, setRows] = useState<IResponseCotacoesPorCliente[]>([]);

  const grid = showQtde
    ? 'grid-cols-[1fr_10fr_1fr_3fr_2fr]'
    : 'grid-cols-[1fr_12fr_3fr_2fr]';

  const header = clsx(
    'w-full grid',
    grid,
    'border-b-[1px] border-gray-200 border-solid p-2',
    'bg-gray-100 text-black',
    'h-10'
  );

  const [filter, setFilter] = useState('');

  const body = clsx(
    'w-full grid',
    grid,
    'border-b-[1px] border-gray-200 border-solid p-2',
    'hover:bg-gray-200 cursor-pointer',
    'h-10'
  );

  function rowClick(row: IResponseCotacoesPorCliente) {
    onRowClick?.(row);
  }

  useEffect(() => {
    if (limit) setRows(items.slice(0, limit));
    else setRows(items);
  }, [items, limit]);

  const total = items.reduce((acc, item) => acc + item.total, 0);
  const currentRows = rows.filter((row) =>
    filter
      ? row.nome
          // replace para remover acentos e espaços e caracteres especiais
          .toLocaleLowerCase()
          .concat(' ', row.apelido, ' ', row.cpf_cnpj)
          .replace(/[^a-zA-Z0-9]/g, '')
          .includes(filter.toLowerCase().replace(/[^a-zA-Z0-9]/g, ''))
      : true
  );

  const rowsToDisplay = currentRows.slice(
    curPage * perPage,
    curPage * perPage + perPage
  );

  const showVerTudo = limit && limit < items.length;
  const CLASS_NAME_BASE =
    'grid-row-[1fr_20fr_0.7fr] grid h-full overflow-hidden rounded-lg bg-white shadow-md border border-slate-200 border-solid';

  if (items.length === 0) {
    return (
      <div className={clsx(CLASS_NAME_BASE)}>
        <div>
          <h1>Sem Reultado</h1>
        </div>
      </div>
    );
  }

  return (
    <div className={clsx(CLASS_NAME_BASE)}>
      <div className={clsx(header, 'px-1')}>
        <div className="text-xs">#</div>
        <div className="text-xs">Cliente</div>
        {showQtde && <div className={clsx('text-xs')}>Qtde</div>}
        <div className={clsx('text-xs text-center')}>Total</div>
        <div className={clsx('text-xs text-center')}>(%)</div>
      </div>
      <div
        className={clsx(
          heightClass ?? 'h-[90vh]',
          'overflow-hidden overflow-y-auto'
        )}
      >
        {rowsToDisplay.map((item, index) => (
          <div
            key={item.clienteId}
            className={body}
            onClick={() => rowClick(item)}
          >
            <div className="text-xs">{index + 1}</div>
            <div>
              <div className="text-[0.8em]">{item.apelido || item.nome}</div>
              <div className="text-[0.5em] text-gray-500">
                {item.nome} - CNPJ: {item.cpf_cnpj}
              </div>
            </div>
            {showQtde && (
              <div className="text-xs text-center">{item?.qtde ?? 0}</div>
            )}
            <div className={clsx('text-xs text-gray-600 text-right')}>
              {Number(item.total).toLocaleString('pt-br', {
                style: 'decimal',
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              })}
            </div>
            <div className={clsx('text-xs text-gray-600 text-right')}>
              {Number((item.total / total) * 100).toLocaleString('pt-br', {
                style: 'decimal',
                maximumFractionDigits: 2,
              })}
            </div>
          </div>
        ))}
      </div>
      <div className="flex items-center bg-slate-100 h-10 p-1">
        {filtered ? (
          <button
            className="hover:underline text-xs text-red-700"
            onClick={() => clearFilter?.()}
          >
            Limpar filtro
          </button>
        ) : (
          verTudo &&
          showVerTudo && (
            <button
              className="hover:underline text-xs text-red-700"
              onClick={verTudo}
            >
              Ver todos
            </button>
          )
        )}
        {!limit && (
          <div className="flex items-center ml-2 gap-2">
            <div className="text-xs text-gray-600 flex items-center gap-1">
              <SearchCircleIcon className="w-6 h-6" />
            </div>
            <input
              placeholder="Filtrar por nome ou CNPJ"
              className="ml-1 text-xs w-56 px-2 py-1 border border-gray-300 border-solid rounded-md"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            />
            <div className="text-xs text-slate-500">
              Qtde: {currentRows.length}
            </div>
          </div>
        )}
        {paginated && (
          <div className="px-2 flex gap-2">
            <AppPerPage
              take={perPage}
              setTake={(perPage) => {
                setCurPage(0);
                setPerPage(perPage);
              }}
              listPerPage={[15, 25, 50, 100]}
            />
            <AppPaginator
              qtdeReg={currentRows.length}
              perPage={perPage}
              curPage={curPage}
              handlePageClick={(page) => setCurPage(page.selected)}
            />
          </div>
        )}
        {close && (
          <button
            className="hover:underline text-xs text-red-700 ml-auto"
            onClick={close}
          >
            Fechar
          </button>
        )}
      </div>
    </div>
  );
};

export default InnerGridClienteRanking;
