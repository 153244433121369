import { Marca, VeiculosByProduct } from '../../app.models';
import LazyLoadingImage from '../../components/lazyLadingImage';
import { classNames } from '../../utils/functions';

interface VeiculoCompativelProps {
  key: number;
  veiculo: VeiculosByProduct;
  marcas: Marca[];
  onClick?: (veiculo: VeiculosByProduct) => void;
}

export default function ShowVeiculoCompativelLine({
  key,
  veiculo,
  marcas,
  onClick,
}: VeiculoCompativelProps) {
  return (
    <div
      key={key}
      className={classNames(
        'flex flex-col p-2 group bg-white h-10 cursor-pointer hover:scale-105 hover:shadow-md hover:border hover:border-primary1 hover:border-opacity-50 transition-all duration-300 ease-in-out'
      )}
      onClick={() => {
        onClick?.(veiculo);
      }}
    >
      <div className="flex flex-row h-6 items-center">
        <div className="w-20">
          <LazyLoadingImage
            classNames={['w-6 h-6']}
            alt={veiculo.marcaDesc}
            height={30}
            width={30}
            src={
              marcas.find((m) => m.id === veiculo.marcaId)?.images[0].img ||
              'https://via.placeholder.com/100?text=Sem Imagem'
            }
            efect="blur"
          />
        </div>
        <div className="text-[0.7em] text-left line-clamp-2 group-hover:line-clamp-4 w-full font-bold text-slate-700">
          {veiculo.modeloDesc}
        </div>
        <div className="w-36 text-[0.6em] text-center">
          {veiculo.anoI} - {veiculo.anoF}
        </div>
      </div>
    </div>
  );
}
