import { createNumberMask, emailMask } from 'text-mask-addons';

export function somenteNumero(snum: string) {
  let s2 = '';
  for (let i = 0; i < snum.length; i++) {
    if (snum[i] >= '0' && snum[i] <= '9') {
      s2 += snum[i];
    }
  }
  return s2;
}

export function formatarTelefone(telefone: string) {
  // Nested function to retain only numeric characters from the input string
  if (!telefone) {
    return '';
  }

  let sTel = somenteNumero(telefone); // Remove any formatting characters

  if (sTel === '') {
    return '';
  } else {
    let bZero = false;

    if (sTel[0] === '0') {
      bZero = true;
      sTel = sTel.substring(1); // Remove leading '0'
    }

    const iDigitos = sTel.length;
    let result;

    // Apply the correct formatting based on the number of digits
    switch (iDigitos) {
      case 8:
        result = sTel.replace(/(\d{4})(\d{4})/, '$1-$2'); // 8 digits without DDD
        break;
      case 9:
        result = sTel.replace(/(\d{1})(\d{4})(\d{4})/, '$1 $2-$3'); // 9 digits without DDD
        break;
      case 10:
        result = sTel.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3'); // 8 digits with DDD
        break;
      case 11:
        result = sTel.replace(/(\d{2})(\d{1})(\d{4})(\d{4})/, '($1)$2 $3-$4'); // 9 digits with DDD
        break;
      case 12:
        result = sTel.replace(/(\d{2})(\d{2})(\d{4})(\d{4})/, '$1($2) $3-$4'); // 12 digits, possibly with operator
        break;
      case 13:
        result = sTel.replace(
          /(\d{2})(\d{2})(\d{1})(\d{4})(\d{4})/,
          '$1($2)$3 $4-$5'
        ); // 13 digits, possibly with operator
        break;
      default:
        result = telefone; // Keep the original input if it doesn't match expected formats
        break;
    }

    // Re-add the leading '0' if it was present in the original input
    if (bZero) {
      result = '0' + result;
    }

    return result;
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function getMask(type: string) {
  switch (type) {
    case 'celular':
      return [
        '(',
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ];

    case 'text':
      return false;

    case 'email':
      return emailMask;

    case 'number':
      return [/\d*/];

    case 'rn':
      return [
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ];

    case 'rg':
      return [
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ];

    case 'currency':
      return createNumberMask({
        prefix: 'R$ ',
        suffix: '',
        allowDecimal: true,
        decimalSymbol: ',',
        decimalLimit: 2,
      });

    case 'cpf':
      return [
        /\d/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
      ];

    case 'cnpj':
      return [
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        '/',
        /\d/,
        /\d/,
      ];

    case 'cep':
      return [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];

    case 'telefone':
      return [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];

    case 'telefone-8':
      return [/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

    case 'telefone-9':
      return [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

    case 'telefone-10':
      return [
        '(',
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ];

    case 'telefone-11':
    case 'telefone-12':
    case 'telefone-13':
    case 'telefone-14':
    case 'telefone-15':
      return [
        '(',
        /\d/,
        /\d/,
        ')',
        /\d/,
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ];

    default:
      return [/\*/g];
  }
}
