import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { Fragment } from 'react';
import CropedImage from './CropedImage';
import Button from './layout/button';

interface ModalUploadProps {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  title: string;
  uploadInfo?: string;
  aspect: number;
  pathUrl: string;
  deleteAction?: () => void;
  autoRelaod?: boolean;
  afterUpload?: () => void;
}

export default function ModalUpload(props: ModalUploadProps) {
  function closeModal() {
    props.setIsOpen(false);
  }

  return (
    <div>
      <Transition appear show={props.isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={closeModal}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black opacity-20" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-xl p-0 my-1 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <Dialog.Title
                  as="h3"
                  className="flex justify-between mb-2 bg-primary2 text-default-white px-4 py-2"
                >
                  <span className="text-lg font-bold leading-6 font-display">
                    {props.title}
                  </span>
                  <button onClick={closeModal} className="hover:text-gray-400">
                    <XIcon className="w-5 h-5" />
                  </button>
                </Dialog.Title>
                <div className="flex flex-col font-display py-4 px-6">
                  <div className="mt-1 flex flex-col">
                    {props.deleteAction && (
                      <div className="flex justify-center md:justify-end">
                        <Button
                          title={'Excluir Imagem Atual'}
                          onClick={() => {
                            props.deleteAction && props.deleteAction();
                            setTimeout(() => self.location.reload(), 500);
                          }}
                        />
                      </div>
                    )}
                    <CropedImage
                      pathUrl={props.pathUrl}
                      aspect={props.aspect}
                      uploadInfo={props.uploadInfo}
                      postUploadAction={() => {
                        closeModal();
                        new Promise((t) => setTimeout(t, 1000)).then(() => {
                          if (props.afterUpload) props.afterUpload();
                        });
                        if (!props.autoRelaod) return;
                        setTimeout(() => self.location.reload(), 100);
                      }}
                    />
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}
