/* eslint-disable @typescript-eslint/no-explicit-any */
import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/outline';
import { classNames } from '../../utils/functions';
import { useProduct } from './product.hook';

interface ShowCategoriasProps {
  onClick: (categId: number) => void;
  nodes: INodes[];
  title: string;
  colorReverse?: boolean;
}

interface INodes {
  id: number;
  descricao: string;
  values: INodes[];
  loading: boolean;
  selected: number | undefined | null;
  onClick: (categId: number) => void;
}

const menuSimpleClass =
  'flex items-center justify-between w-full hover:text-primary1 disabled:text-gray-100';
const menuSubClass =
  'flex flex-col w-full cursor-pointer items-center hover:text-primary1';

const MenuSimple = (node: INodes) => {
  return (
    <button
      className={classNames(
        menuSimpleClass,
        node.selected && node.selected === node.id
          ? 'py-[1px] bg-primary1 text-default-white rounded-xl px-1 font-semibold hover:text-default-white'
          : 'py-[1px] text-gray-800'
      )}
      onClick={() => {
        node.onClick(node.id);
        // console.log('clique menu simples', node.id);
      }}
      disabled={node.loading && node.selected === node.id}
    >
      <div className="flex items-center space-x-1">
        <span className="text-base text-left">{node.descricao}</span>
        {node.loading && node.selected === node.id && (
          <div className="pl-3">
            {' '}
            <svg
              className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="#fff"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="#fff"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          </div>
        )}
      </div>
    </button>
  );
};

const MenuSub = (node: INodes) => {
  return (
    <Disclosure>
      {({ open }) => (
        <>
          <Disclosure.Button className={menuSimpleClass}>
            <div className="flex items-center space-x-1">
              <span className={`p-0 text-gray-700`}>{open ? '-' : '+'}</span>
              <span className="text-xs text-gray-700 truncate">
                {node.descricao}
              </span>
            </div>
          </Disclosure.Button>
          <Disclosure.Panel>
            <ul className="flex flex-col space-x-0 list-none">
              {node.values &&
                node.values.length > 0 &&
                node.values.map((subNode, subIdx) => (
                  <li key={subIdx} className={menuSubClass}>
                    <MenuSimple
                      {...subNode}
                      onClick={() => node.onClick(subNode.id)}
                      selected={node.selected}
                    />
                  </li>
                ))}
            </ul>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default function ShowCategorias(props: ShowCategoriasProps) {
  const { selectedLastCategoria, loading } = useProduct();
  return (
    // <div>
    <Disclosure as="div" defaultOpen={true} className="w-full">
      {({ open }) => (
        <>
          <Disclosure.Button
            className={classNames(
              'flex justify-between w-full px-1 py-1 text-sm font-medium text-left  focus:outline-none focus-visible:ring focus-visible:ring-gray-300 focus-visible:ring-opacity-75 mb-3',
              props.colorReverse
                ? `bg-amber-400 text-default-white hover:bg-primary2 rounded-sm`
                : 'text-amber-900 bg-gray-100 hover:bg-gray-200'
            )}
          >
            <span>{props.title}</span>
            <ChevronUpIcon
              className={`${open ? 'transform rotate-180' : ''} w-5 h-5`}
            />
          </Disclosure.Button>
          <Disclosure.Panel className="px-4">
            <ul className="w-full flex flex-col space-y-0 list-disc">
              {props.nodes &&
                props.nodes.length > 0 &&
                props.nodes.map((node, idx) => {
                  return (
                    <li className="list-none" key={idx}>
                      {node.values && node.values.length > 0 ? (
                        <MenuSub
                          {...node}
                          onClick={() => props.onClick(node.id)}
                          selected={
                            selectedLastCategoria && selectedLastCategoria
                          }
                          loading={loading}
                        />
                      ) : (
                        <MenuSimple
                          {...node}
                          onClick={() => props.onClick(node.id)}
                          selected={
                            selectedLastCategoria && selectedLastCategoria
                          }
                          loading={loading}
                        />
                      )}
                    </li>
                  );
                })}
            </ul>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
    // </div>
  );
}
