/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useRef, useState } from 'react';
import { Produto, Search } from '../../app.models';
import Filter from '../../components/filter';
import { arrayGroupBy, classNames } from '../../utils/functions';
import Badge from '../../components/badge';
import useMedia from 'use-media';
import CardType from '../product/cardType';
import DisplayProductCard from '../product/displayProductCard';
import DisplayProductList from '../product/displayProductList';
import TableProduct from '../product/tableProduct';
import {
  TableIcon,
  ViewGridIcon,
  ViewListIcon,
} from '@heroicons/react/outline';
import { useProduct } from '../product/product.hook';
import { EnumOrigens } from '../../utils/origens';

type Props = {
  listProdutos: Search;
  filterEixos: any[];
  loading: boolean;
};

const _classNames = (active: boolean) =>
  classNames(
    active && 'text-gray-500 shadow border-[1px] border-gray-200 border-solid',
    'hover:text-gray-500 hover:bg-gray-50 rounded-md p-1 mx-1'
  );

export default function FiltersPromotions(props: Props) {
  const isSmall = useMedia({ maxWidth: 480 });
  const { setOrigem } = useProduct();
  const [list, setList] = useState<Produto[]>([]);
  const [selectedsCategorias, setSelectedsCategorias] = useState<string[]>([]);
  const [selectedsEixos, setSelectedsEixos] = useState<number[]>([]);
  const [selectedsEixosLabels, setSelectedsEixosLabel] = useState<string[]>([]);

  const [activedsFilters, setActivedsFilters] = useState<string[]>([]);

  const [produtoCategoriasFilter, setProdutoCategoriasFilter] = useState<any[]>(
    []
  );

  const [cardType, setCardType] = useState<string>('card');
  const [orderType, setOrderType] = useState<string>('alfa');

  const filtered = useRef<Produto[]>([]);

  useEffect(() => {
    setActivedsFilters([]);
    setSelectedsEixos([]);
    setSelectedsCategorias([]);
  }, [props.listProdutos]);

  const filtrosCategorias = (array: Produto[]) => {
    const filterCategorias = arrayGroupBy(array, 'categoria.descricao');
    const items = Object.entries(filterCategorias).map((el) => {
      return {
        label: el[0] + ' (' + el[1].length + ')',
        value: el[0],
      };
    });
    return items;
  };

  //filtro ativos
  useEffect(() => {
    const array: string[] = [];
    const newfilter = selectedsEixosLabels.map((el) => el.toString());
    array.push(...newfilter);
    array.push(...selectedsCategorias);
    setActivedsFilters(array);
  }, [selectedsCategorias, selectedsEixosLabels]);

  // listagem
  useEffect(() => {
    if (
      props.listProdutos &&
      props.listProdutos.produtos &&
      props.listProdutos.produtos.length > 0
    ) {
      filtered.current = props.listProdutos.produtos;

      if (selectedsEixos.length > 0) {
        filtered.current = filtered.current.filter((f) =>
          selectedsEixos.includes(f.id)
        );
      }
      setProdutoCategoriasFilter(filtrosCategorias(filtered.current));

      if (selectedsCategorias.length > 0) {
        filtered.current = filtered.current.filter((f) =>
          selectedsCategorias.includes(f.categoria.descricao)
        );
      }

      filtered.current = filtered.current.sort((a, b) =>
        orderType === 'alfa'
          ? a.descricaoSite.localeCompare(b.descricaoSite)
          : parseInt(a.codigoSgi) - parseInt(b.codigoSgi)
      );
      setList(filtered.current);
    }
  }, [
    props.listProdutos,
    orderType,
    produtoCategoriasFilter.length,
    selectedsCategorias,
    selectedsEixos,
  ]);

  if (props.loading) {
    return (
      <div className="flex flex-col md:flex-row h-full w-full">
        <div className="w-full md:w-5/6 flex flex-col justify-center items-center h-full animate-pulse">
          <span className="mx-auto"></span>Aguarde ...
        </div>
      </div>
    );
  }

  // if (isSmall) {
  //   return (
  //     <div className="flex flex-col md:flex-row h-full">
  //       <div className="w-full md:w-1/6 md:shadow-lg h-full md:overflow-hidden md:overflow-y-auto p-2">
  //         {props.filterEixos && props.filterEixos.length > 1 && (
  //           <Filter
  //             title="Eixos"
  //             // eslint-disable-next-line @typescript-eslint/no-explicit-any
  //             items={
  //               props.filterEixos &&
  //               props.filterEixos.map((el: any) => ({
  //                 label: el.label,
  //                 value: el.value,
  //               }))
  //             }
  //             onChange={(e) => {
  //               const toNumber: number[] = [];
  //               const concat = Array<string>().concat([], ...e);
  //               concat.map((el) => toNumber.push(parseInt(el)));
  //               setSelectedsEixos(toNumber);
  //               setSelectedsCategorias([]);
  //             }}
  //             selectedsLabels={setSelectedsEixosLabel}
  //           />
  //         )}
  //         {produtoCategoriasFilter.length > 1 && (
  //           <Filter
  //             title="Filtros"
  //             // eslint-disable-next-line @typescript-eslint/no-explicit-any
  //             items={produtoCategoriasFilter.map((el: any) => ({
  //               label: el.label,
  //               value: el.value,
  //             }))}
  //             onChange={setSelectedsCategorias}
  //           />
  //         )}
  //       </div>
  //       <div className="flex flex-col w-full md:w-5/6 h-full">
  //         <div>
  //           <CardType
  //             // cardType={cardType}
  //             // setCardType={setCardType}
  //             qtdeReg={list.length}
  //           />
  //         </div>

  //         <div className="p-4 overflow-hidden overflow-y-auto grid gap-x-4 gap-y-6 grid-cols-none md:grid-cols-2 lg:grid-cols-4">
  //           {list.map((it, i) => (
  //             <DisplayProductCard key={i} product={it} />
  //           ))}
  //         </div>
  //       </div>
  //     </div>
  //   );
  // }

  return (
    <div className="flex h-full min-w-full">
      <div className="w-1/6 shadow-lg h-full overflow-hidden overflow-y-auto p-2">
        {props.filterEixos && props.filterEixos.length > 1 && (
          <Filter
            title="Eixos"
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            items={
              props.filterEixos &&
              props.filterEixos.map((el: any) => ({
                label: el.label,
                value: el.value,
              }))
            }
            onChange={(e) => {
              const toNumber: number[] = [];
              const concat = Array<string>().concat([], ...e);
              concat.map((el) => toNumber.push(parseInt(el)));
              setSelectedsEixos(toNumber);
              setSelectedsCategorias([]);
            }}
            selectedsLabels={setSelectedsEixosLabel}
          />
        )}
        {produtoCategoriasFilter.length > 1 && (
          <Filter
            title="Filtros"
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            items={produtoCategoriasFilter.map((el: any) => ({
              label: el.label,
              value: el.value,
            }))}
            onChange={setSelectedsCategorias}
          />
        )}
      </div>
      <div className="flex flex-col w-5/6 h-full">
        <div className="flex justify-end bg-gray-50 text-gray-400">
          <span className="p-1 rounded-md hidden md:inline">
            <button
              className={_classNames(orderType === 'alfa')}
              type="button"
              onClick={() => setOrderType && setOrderType('alfa')}
              title="Ordem Alfabética"
            >
              <img
                className="h-6 w-6 opacity-50"
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAAA8UlEQVRIid2UMQ4BURCGv2XPQCki0W5F4Q4aBRINJ6BT2mtxABfYqLiAREuDXcVO8Wze27fvhYI/mWJm/5l/Znby4J+xADJgbuFlYs7YSeL2GwIN4C6JD6DpK1AzxCdACCRAHRi7dmjDHkiBHvAU3wTnFbWkeKZYCrR9BHQrmgFBIRYAU5cuy5BIR5H4kfgHA9/7TKvC64o+hq8LhJpY2T6LP98KlwmOrsWrYEU+0QXoGjjeVzQkf4duwKCE5yXQB67kz8TIwnUW6ABnSVoaOGvenxLVNjaBU0myiljzPa4ygam4bg3qJNbOfRFTsfPfxAuUolP9esbLDwAAAABJRU5ErkJggg=="
              />
            </button>
            <button
              className={_classNames(orderType === 'num')}
              type="button"
              onClick={() => setOrderType && setOrderType('num')}
              title="Ordem Numérica"
            >
              <img
                className="h-6 w-6 opacity-60"
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAABmJLR0QA/wD/AP+gvaeTAAABNklEQVRIie3Wu0rEUBDG8Z+XysZCDF6wstM3UNBOEK0EGxEfQXwU8RV8DMFiG1G0UUGwUbTQykIQrxiLnEB2ybrJ6qbZ/cMQMjkz30xykgk9upUh7OEp2FzJ+GMclRWdxDXijC2UzJHGNaU/xzeFEWziq6TgnxgPBp861PFgju+hpEhb5N3qSug+4bxnnOUGA3iroJZ/oa33uBJ6wimLqOEZj9jHRKeLmcErvnGIO8lGKTNtWm6uPHYko3A3nE9nEg13UriRrZDkFn1N1tRwiihHOArXSs3nFbxLptTSL+tOgtBFEEqFo+CLw5pCrEm+WB9Yb7E2K5Ae4wZf1DQ6w4akyxcsFyx0FOfq/1xiXGGsSIJVyY6OcY+DjM22iI1wqb7jQp3Cdk7Vqc0XiI9wpn6z9ehCfgCYCVwSivT9bQAAAABJRU5ErkJggg=="
              />
            </button>
            <button
              className={_classNames(cardType === 'card')}
              type="button"
              onClick={() => setCardType('card')}
              title="Visão Cartão"
            >
              <ViewGridIcon className="h-6 w-6" />
            </button>
            <button
              className={_classNames(cardType === 'list')}
              type="button"
              onClick={() => setCardType('list')}
              title="Visão Lista"
            >
              <ViewListIcon className="h-6 w-6" />
            </button>
            <button
              className={_classNames(cardType === 'table')}
              type="button"
              onClick={() => setCardType('table')}
              title="Visão Tabela"
            >
              <TableIcon className="h-6 w-6" />
            </button>
          </span>
        </div>
        {activedsFilters.length > 0 && (
          <div className="px-1 py-1 text-sm font-medium text-left text-gray-900 bg-gray-100 hover:bg-gray-200 focus:outline-none focus-visible:ring focus-visible:ring-gray-300 focus-visible:ring-opacity-75 mb-3">
            {activedsFilters.map((el, i) => (
              <Badge
                key={i}
                label={el}
                id={i}
                // deleteAction={(key) => console.log(key)}
              />
            ))}
          </div>
        )}

        {cardType === 'card' && (
          <div className="p-4 overflow-hidden overflow-y-auto grid gap-x-4 gap-y-6 grid-cols-none md:grid-cols-2 lg:grid-cols-4">
            {list.map((it, i) => (
              <DisplayProductCard
                key={i}
                product={it}
                origem={EnumOrigens.DESTAQUE}
              />
            ))}
          </div>
        )}
        {cardType === 'list' && (
          <div className="p-4 overflow-hidden overflow-y-auto flex flex-col space-y-2">
            {list.map((it, i) => (
              <DisplayProductList
                key={i}
                product={it}
                origem={EnumOrigens.DESTAQUE}
              />
            ))}
          </div>
        )}
        {cardType === 'table' && (
          <div className="overflow-hidden overflow-y-auto flex flex-col space-y-2">
            <TableProduct items={list} origem={EnumOrigens.DESTAQUE} />
          </div>
        )}
        {list && list.length === 0 && (
          <div className="flex flex-col md:flex-row h-full w-full">
            <div className="w-5/6 flex flex-col justify-center items-center h-full">
              <span className="mx-auto">Sem ofertas ativas</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
