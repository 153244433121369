import { ReplyIcon, UploadIcon } from '@heroicons/react/outline';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useApp } from '../../app.hook';
import { ROUTES_MAP } from '../../app.map.routes';
import { Produto } from '../../app.models';
import { genericDel, getProduct } from '../../app.service';
import Button from '../../components/layout/button';
import ModalConfirmation from '../../components/modalConfirmation';
import ModalUpload from '../../components/modalUpload';
import Unautorized from '../unauthorized';
import UploadDisplay from './upload.display';

interface IModalUploadData {
  pathUrl: string;
  uuid: string;
  imageDelIndex: number;
}

export default function UploadProduct() {
  const { id } = useParams();
  const [produto, setProduto] = useState<Produto>();
  const navigate = useNavigate();
  const [openModalUpload, setOpenModalUpload] = useState(false);
  const [modalOpenConfirm, setModalOpenConfirm] = useState(false);
  const modalUploadData = useRef<IModalUploadData>({
    pathUrl: '',
    uuid: '',
    imageDelIndex: 0,
  });

  useEffect(() => {
    if (id) {
      setProduto(undefined);
      getProduct(Number(id)).then((data) => {
        setProduto(data.produto);
      });
    }
  }, [id]);

  const { isPerfilManager } = useApp();
  if (!isPerfilManager) return <Unautorized />;

  return (
    <div className="mt-[19vh] md:mt-0 h-full md:h-[88vh] md:overflow-hidden md:overflow-y-auto">
      <div className="h-16 md:h-12 flex items-center justify-between space-x-1 bg-gray-300 px-4">
        <Button
          title="Voltar"
          icon={<ReplyIcon className="w-4 h-4" />}
          color="bg-default-white text-primary2 hover:bg-gray-100 hover:text-gray-700"
          onClick={() => id && navigate(ROUTES_MAP.productById(parseInt(id)))}
        />
        <Button
          title="Inserir Imagem"
          icon={<UploadIcon className="w-4 h-4" />}
          color="bg-green-400 text-white hover:bg-green-500 hover:text-gray-100"
          onClick={() => {
            if (!produto) return;
            modalUploadData.current.pathUrl = `/produtos/${produto.id}/upload`;
            setTimeout(() => {
              setOpenModalUpload(true);
            }, 100);
          }}
        />
      </div>
      <div className="md:h-[83vh] md:overflow-hidden md:overflow-y-auto">
        <div className="flex flex-col px-4">
          {produto && (
            <div className="flex flex-col space-y-3">
              <div className="text-xl text-center font-bold">
                {produto.descricaoSite}
              </div>
              <div className="text-md odd:bg-gray-100 odd:rounded-lg px-2 py-2">
                <span className="font-bold ">Categoria</span>:{' '}
                {produto.categoria.descricao}
              </div>
              <div className="text-md odd:bg-gray-100 odd:rounded-lg px-2 py-2">
                <span className="font-bold">Referência</span>:{' '}
                {produto.referencia}
              </div>
              <div className="text-md odd:bg-gray-100 odd:rounded-lg px-2 py-2">
                <span className="font-bold">Código</span>: {produto.codigoSgi}
              </div>
              <div className="text-md odd:bg-gray-100 odd:rounded-lg px-2 py-2">
                <span className="font-bold">Características</span>:{' '}
                {produto.descricaoCompleta}
              </div>
            </div>
          )}
          {produto && (
            <div className="flex flex-wrap p-2">
              {produto.images.map((el, idx) => (
                <UploadDisplay
                  key={idx}
                  title={String(idx + 1)}
                  img={el.medium}
                  onClick={() => {
                    const uuid = el.small.split('-')[2].split('.')[0];
                    modalUploadData.current.uuid = uuid;
                    modalUploadData.current.imageDelIndex = idx;
                    setTimeout(() => {
                      setModalOpenConfirm(true);
                    }, 100);
                  }}
                />
              ))}
            </div>
          )}
        </div>
        <ModalUpload
          isOpen={openModalUpload}
          setIsOpen={setOpenModalUpload}
          title={'Upload de Imagem'}
          uploadInfo={'PNG, JPG, GIF up to 1MB'}
          aspect={1.333}
          afterUpload={() => self.location.reload()}
          pathUrl={modalUploadData.current.pathUrl}
        />
        <ModalConfirmation
          isOpen={modalOpenConfirm}
          setIsOpen={setModalOpenConfirm}
          title="Confirmação"
          question={
            'Remover imagem nº ' + (modalUploadData.current.imageDelIndex + 1)
          }
          answer={() => {
            if (!produto) return;
            const url = `/produtos/${produto.id}/fotos/${modalUploadData.current.uuid}`;
            setTimeout(() => {
              genericDel(url).then(() => self.location.reload());
            }, 100);
          }}
        />
      </div>
    </div>
  );
}
