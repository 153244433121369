import { Dialog, Transition } from '@headlessui/react';
import { ThumbDownIcon, ThumbUpIcon, XIcon } from '@heroicons/react/outline';
import { Fragment, useState } from 'react';
import { Usuario } from '../../app.models';
import Button from '../../components/layout/button';
import { altSenha } from './users.service';

interface ModalPasswordProps {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  user: Usuario;
  afterAltSenha?: () => void;
}

export default function ModalPassword(props: ModalPasswordProps) {
  const [password, setPassword] = useState<string>();
  const [password2, setPassword2] = useState<string>();
  const [error, setError] = useState<string>();

  function closeModal() {
    props.setIsOpen(false);
  }

  const confirm = async () => {
    const user = props.user;
    if (!password || !password2) {
      setError('Preencha todos os campos');
      return;
    }
    if (password !== password2) {
      setError('As senhas não conferem');
      return;
    }
    user.password = password;
    altSenha(user.id, user.email, password);
    props.afterAltSenha && props.afterAltSenha();
    closeModal();
  };

  return (
    <div>
      <Transition appear show={props.isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={closeModal}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black opacity-20" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-xl p-0 my-1 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <Dialog.Title
                  as="h3"
                  className="flex justify-between mb-2 bg-primary2 text-default-white px-4 py-2"
                >
                  <span className="text-lg font-bold leading-6 font-display">
                    Alterar Senha - {props.user.nome.toUpperCase()}
                  </span>
                  <button onClick={closeModal} className="hover:text-gray-400">
                    <XIcon className="w-5 h-5" />
                  </button>
                </Dialog.Title>
                <div className="flex flex-col font-display py-4 px-6">
                  <div className="flex flex-col text-md font-display font-bold mb-8">
                    <div className="flex flex-col w-full">
                      <label
                        htmlFor="password"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Nova Senha
                      </label>
                      <div className="mt-1 relative shadow-sm">
                        <input
                          type="password"
                          id="password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          className="form-input px-3 py-2 w-full rounded-md bg-gray-200 text-gray-700 border-gray-200 border-2 focus:outline-none focus:bg-white focus:border-gray-500"
                          autoComplete="off"
                        />
                      </div>
                    </div>
                    <div className="flex flex-col w-full">
                      <label
                        htmlFor="password2"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Confirmar Senha
                      </label>
                      <div className="mt-1 relative shadow-sm">
                        <input
                          type="password"
                          id="password2"
                          value={password2}
                          onChange={(e) => setPassword2(e.target.value)}
                          className="form-input px-3 py-2 w-full rounded-md bg-gray-200 text-gray-700 border-gray-200 border-2 focus:outline-none focus:bg-white focus:border-gray-500"
                          autoComplete="off"
                        />
                      </div>
                    </div>
                  </div>
                  <span className="text-xs text-red-500">{error && error}</span>
                  <div className="flex justify-between">
                    <Button
                      title="Não"
                      type="button"
                      icon={<ThumbDownIcon className="w-4 h-4" />}
                      onClick={closeModal}
                      color="bg-red-500 text-white hover:bg-red-400 hover:text-gray-100"
                    />
                    <Button
                      title="Sim"
                      type="button"
                      icon={<ThumbUpIcon className="w-4 h-4" />}
                      onClick={confirm}
                      color="bg-green-500 text-white hover:bg-green-400 hover:text-gray-100"
                    />
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}
