import { BanIcon } from '@heroicons/react/outline';

export default function Unautorized() {
  return (
    <div className="mt-32 md:mt-0 md:pt-20 mx-2 flex flex-col space-y-3 items-center ">
      <BanIcon className="w-52 h-52 text-red-500" />
      <span className="text-gray-700 text-3xl text-center">
        Página não autorizada para este perfil!
      </span>
    </div>
  );
}
