import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ROUTES_MAP } from '../../app.map.routes';
import { ProdutoCart } from '../../app.models';
import InputQtdeMin from '../../components/inputQtdeMin';
// import { replaceImgWithError } from '../../utils/functions';
import externalLink from '../../assets/svg/external-link.svg';
import { ReactSVG } from 'react-svg';
import { useProduct } from '../product/product.hook';
import { EnumOrigens } from '../../utils/origens';
import ExibirPrecoPromocaoConsumidorCarrinho from './exibirPrecoPromocaoConsumidorCarrinho';
import ExibirPrecoConsumidorFinal from '../../components/ExibirPrecoConsumidorFinal';

interface LineProductsProps {
  products: ProdutoCart[];
  qtdeAction: (itemId: number, qtde: number) => void;
  delAction: (item: ProdutoCart) => void;
  active: boolean;
}

export default function LineProducts({
  products,
  qtdeAction,
  delAction,
  active,
}: LineProductsProps) {
  const { setOrigem } = useProduct();
  const [items, setItems] = useState<ProdutoCart[]>([]);

  useEffect(() => {
    setItems(products);
  }, [products]);

  return (
    <table className="w-full divide-y divide-gray-200">
      <thead className="bg-slate-200 sticky top-0 z-[1]">
        <tr>
          <th
            scope="col"
            className="px-1 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Item
          </th>
          <th
            scope="col"
            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Produto
          </th>
          <th
            scope="col"
            className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Ref
          </th>
          <th
            scope="col"
            className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Qtde
          </th>
          <th
            scope="col"
            className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Unitário
          </th>
          <th
            scope="col"
            className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Valor
          </th>
        </tr>
      </thead>
      <tbody className="space-y-1">
        {items &&
          items.map((item, index) => (
            <tr
              key={item.id}
              className="border-b-[1px] border-solid border-slate-400"
            >
              {/* item */}
              <td className="whitespace-nowrap align-middle w-1/12">
                <div className="text-xs text-gray-900 text-center">
                  {index + 1}
                </div>
              </td>
              {/* imagem/produto*/}
              <td className="text-xs text-gray-900 w-4/12 align-middle truncate">
                {/* <div className="">{item.produto.descricaoSite}</div> */}
                <Link
                  onClick={() => {
                    setOrigem(EnumOrigens.CARRINHO);
                  }}
                  to={ROUTES_MAP.productById(item.produto.id)}
                  className="group hover:underline"
                >
                  <span className="inline-flex group-hover:underline">
                    <ReactSVG
                      className="w-3 h-3 my-auto mr-1"
                      src={externalLink}
                    />
                    {item.produto.descricaoSite}{' '}
                  </span>
                </Link>
              </td>
              {/* refs */}
              <td className="whitespace-nowrap align-middle w-2/12">
                <div className="text-xs text-center">
                  {item.produto.codigoSgi}
                </div>
                {/* <div className="text-[0.7em] text-gray-500">
                  <span className="">Cat: </span>
                  <span className="font-bold">
                    {item.produto.categoria.descricao}
                  </span>
                </div>

                <div className="text-[0.7em] text-gray-500">
                  <span className="">Ref: </span>
                  <span className="font-bold">{item.produto.referencia}</span>
                </div>

                <div className="text-[0.7em] text-gray-500">
                  <span className="">Cód: </span>
                  <span className="font-bold">{item.produto.codigoSgi}</span>
                </div> */}
              </td>
              {/* qtde */}
              <td className="whitespace-nowrap align-middle w-2/12">
                {active ? (
                  <InputQtdeMin
                    initialValue={item.qtde}
                    action={(qtde) => {
                      qtdeAction(item.produto.id, qtde);
                    }}
                    delAction={() => {
                      delAction(item);
                    }}
                  />
                ) : (
                  <div className="text-lg text-center w-full">{item.qtde}</div>
                )}
              </td>
              {/* unitario */}
              <td className="whitespace-nowrap align-middle w-2/12 px-1 text-sm">
                <div>
                  {item.isPromocao ? (
                    <div className="flex flex-col">
                      {item.produto?.promocao ? (
                        <div>
                          <div className="flex flex-col items-end mr-2">
                            <span className="line-through text-gray-400 text-[0.7em]">
                              {/* {parseCurrency(item.produto.precoPromo)} */}
                              <ExibirPrecoPromocaoConsumidorCarrinho
                                produto={item}
                              />
                            </span>
                            <span className="text-amber-500 font-bold">
                              {/* {parseCurrency(item.unitarioLiquido)} */}
                              <ExibirPrecoConsumidorFinal
                                item={item}
                                className="text-inherit"
                                field="unitarioLiquido"
                                fieldConsumidorFinal="unitarioLiquidoConsumidorFinal"
                                isTotal
                              />
                            </span>
                          </div>
                          {/* {item.produto.promocao && (
                            <div className="flex justify-end">
                              <div className="w-[50%]">
                                <Progress
                                  qtdeTotal={
                                    item.produto.promocao.qtdeEmPromocao
                                  }
                                  qtdeMovimentada={
                                    item.produto.promocao.qtdeMovimentada
                                  }
                                  qtdeDisponivel={
                                    item.produto.promocao.qtdeDisponivel
                                  }
                                />
                              </div>
                            </div>
                          )} */}
                        </div>
                      ) : (
                        <div className="flex flex-col items-end space-y-1 text-gray-700">
                          <div className="text-red-500">:( Saiu de oferta</div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="flex flex-col justify-start">
                      <div className="flex my-3 justify-end mr-2 text-gray-700">
                        <span className="font-bold">
                          {/* {parseCurrency(item.unitarioLiquido)} */}
                          <ExibirPrecoConsumidorFinal
                            item={item}
                            className="text-inherit"
                            field="unitarioLiquido"
                            fieldConsumidorFinal="unitarioLiquidoConsumidorFinal"
                            isTotal
                          />
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </td>
              {/* valor */}
              <td className="whitespace-nowrap text-right align-middle w-1/12">
                {/* <div className="font-bold text-gray-500 text-right mx-1">
                  {parseCurrency(item.totalItem)}
                </div> */}
                <ExibirPrecoConsumidorFinal
                  item={item}
                  className="font-bold text-gray-500 text-right mx-1"
                  field="totalItem"
                  fieldConsumidorFinal="totalItemConsumidorFinal"
                  isTotal
                />
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  );
}
