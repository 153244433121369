// import { useProduct } from './hook';

import FiltersAll from './filtersAll';

interface ProductsAllProps {
  handlerPage?: (page: number) => void;
}

export default function ProductsAll(props: ProductsAllProps) {
  return (
    <div className="">
      <div className="h-full md:shadow md:h-[88vh] md:overflow-hidden md:overflow-y-auto">
        <FiltersAll handlerPage={props.handlerPage} />
      </div>
    </div>
  );
}
